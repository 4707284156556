import { useContext, useEffect, useRef } from 'react'

import { Toast } from 'primereact/toast'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { CarLoanContext, DealershipContext } from '../contexts'
import { useFetchCarLoan, useFetchDealership } from '../dataHooks'
import Agreement from './components/CarLoanForm/Agreement'
import CustomerAgreement from './components/CarLoanForm/CustomerAgreement'
import { AgreementMethod } from './components/CarLoanForm/CustomerAgreement'
import { CustomerVehicleData } from './components/CarLoanForm/CustomerVehicleData'
import { DriverLicenseUpload } from './components/CarLoanForm/DriverLicenseUpload'
import { NewCarLoan } from './components/CarLoanForm/NewCarLoan'
import Notes from './components/CarLoanForm/Notes'
import Timeline from './components/CarLoanForm/Timeline'

type Inputs = {
  checkedInAt: Date | string
  checkedOutAt: Date | string
  loanStartedAt: Date | string
  loanEndsAt: Date | string
  insuranceExcess: number
  dealerComments: string
  contactId?: number
  carId?: number
}

export default function CarLoanForm() {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<Inputs>()

  const insuranceExcess = watch('insuranceExcess')
  const { carLoan, setCarLoan }: any = useContext(CarLoanContext)
  const { dealership }: any = useContext(DealershipContext)
  const notification = useRef(null)
  const navigate = useNavigate()

  useFetchCarLoan()
  useFetchDealership()

  useEffect(() => {
    if (carLoan) {
      const checked_in_at = carLoan.checked_in_at === null ? '' : new Date(carLoan.checked_in_at)
      const checked_out_at = carLoan.checked_out_at === null ? '' : new Date(carLoan.checked_out_at)
      const loan_started_at =
        carLoan.loan_started_at === null ? '' : new Date(carLoan.loan_started_at)
      const loan_ends_at = carLoan.loan_ends_at === null ? '' : new Date(carLoan.loan_ends_at)

      reset({
        checkedInAt: checked_in_at,
        checkedOutAt: checked_out_at,
        loanStartedAt: loan_started_at,
        loanEndsAt: loan_ends_at,
        insuranceExcess: carLoan.insurance_excess ?? insuranceExcess,
        dealerComments: carLoan.dealer_comments || '',
      })
    }
  }, [carLoan])

  const csrf = document.querySelector("meta[name='csrf-token']")?.getAttribute('content')

  // Use react-router-dom's useNavigate hook to navigate back
  const handleBack = () => {
    navigate(`/dealerships/${dealership.id}/car_loans`)
  }

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const url = `/dealerships/${dealership.id}/car_loans/${carLoan.id}.json`
    const formData = {
      insurance_excess: data.insuranceExcess,
      checked_in_at: data.checkedInAt,
      checked_out_at: data.checkedOutAt,
      loan_started_at: data.loanStartedAt,
      loan_ends_at: data.loanEndsAt,
      dealer_comments: data.dealerComments,
      agreement_text: carLoan.agreement,
    }

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      body: JSON.stringify(formData),
    })

    if (response.ok) {
      notification.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Data saved successfully',
      })
      handleBack()
    } else if (response.status === 422) {
      response.json().then((data) => {
        const fieldNames = {
          checked_in_at: 'Check-in Date/time',
          checked_out_at: 'Check-out Date/time',
          loan_started_at: 'Loan Start Date/time',
          loan_ends_at: 'Loan End Date/time',
          insurance_excess: 'Insurance Excess',
          // Add more mappings as needed
        }

        const errors = Object.entries(data.errors).map(([key, value]) => {
          const fieldName = fieldNames[key] || key // Use the mapped name if it exists, otherwise use the original key
          return <li key={key}>{`${fieldName}: ${(value as string[]).join(', ')}`}</li>
        })

        notification.current.show({
          severity: 'error',
          summary: 'Error',
          content: () => (
            <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
              <div className="font-medium text-lg my-3 text-900">{errors}</div>
            </div>
          ),
        })
      })
    }
  }

  const onCreateCarLoan: SubmitHandler<Inputs> = async (data) => {
    const url = `/dealerships/${dealership.id}/car_loans.json`
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const formData = {
      contact_id: data.contactId,
      car_id: data.carId,
      dealership_id: dealership.id,
      timezone,
    }
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      body: JSON.stringify(formData),
    })

    if (response.ok) {
      const carLoanData = await response.json()
      setCarLoan(carLoanData)
    } else {
      notification.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to create car loan',
      })
    }
  }

  const onUpdateCarLoan: SubmitHandler<Inputs> = async (data) => {
    const url = `/dealerships/${dealership.id}/car_loans/${carLoan.id}.json`
    const formData = {
      car_loan: {
        contact_id: data.contactId,
        car_id: data.carId,
      },
    }

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      body: JSON.stringify(formData),
    })

    if (response.ok) {
      const carLoanData = await response.json()
      setCarLoan(carLoanData)
    } else {
      notification.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to update car loan',
      })
    }
  }

  const sendConfirmationMessage = async (method: AgreementMethod) => {
    if (method === 'email') {
      const url = `/dealerships/${dealership.id}/car_loans/${carLoan.id}/send_agreement_confirmation_email.json`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf,
        },
      })

      if (response.ok) {
        notification.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Confirmation email sent',
        })
      } else if (response.status === 422) {
        const body = await response.json()
        notification.current.show({ severity: 'error', summary: 'Error', detail: body.message })
      }

      return response
    } else if (method === 'sms') {
    }
    // TODO: Implement SMS verification
  }

  const verifyConfirmationCode = async (code: string) => {
    const url = `/dealerships/${dealership.id}/car_loans/${carLoan.id}/verify_confirmation_code.json`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      body: JSON.stringify({ confirmation_code: code }),
    })

    if (response.ok) {
      notification.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Confirmation code verified',
      })
      loadAgreementAgreedAt()
    } else if (response.status === 422) {
      const body = await response.json()
      notification.current.show({ severity: 'error', summary: 'Error', detail: body.message })
      return false
    }

    return response
  }

  const recordSignature = async (signature: string) => {
    const url = `/dealerships/${dealership.id}/car_loans/${carLoan.id}/record_signature.json`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      body: JSON.stringify({ signature }),
    })

    if (response.ok) {
      notification.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Signature recorded',
      })
      loadAgreementAgreedAt()
    } else if (response.status === 422) {
      const body = await response.json()
      notification.current.show({ severity: 'error', summary: 'Error', detail: body.message })
      return false
    }

    return response
  }

  const uploadDriversLicense = async (file: File, type: string = 'front') => {
    const formData = new FormData()

    formData.append('file', file)

    try {
      const action = type === 'front' ? 'upload_drivers_license' : 'upload_drivers_license_back'
      const response = await fetch(
        `/dealerships/${dealership.id}/car_loans/${carLoan.id}/${action}`,
        {
          method: 'POST',
          headers: {
            'X-CSRF-Token': csrf,
            Accept: 'application/json',
          },
          body: formData,
        }
      )

      if (response.ok) {
        const json = await response.json()
        const updatedCarLoan =
          type === 'front'
            ? {
                ...carLoan,
                drivers_license_url: json.url,
                drivers_license_name: json.name,
              }
            : {
                ...carLoan,
                drivers_license_back_url: json.url,
                drivers_license_back_name: json.name,
              }
        setCarLoan(updatedCarLoan)
        notification.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Upload successful!',
        })
      } else {
        throw new Error('Failed to upload file')
      }
    } catch (error) {
      console.error('Upload failed:', error)
      notification.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Upload failed! Please try again.',
      })
    }
  }

  const deleteDriversLicense = async (type: string = 'front') => {
    const action = type === 'front' ? 'delete_drivers_license' : 'delete_drivers_license_back'

    try {
      const response = await fetch(
        `/dealerships/${dealership.id}/car_loans/${carLoan.id}/${action}`,
        {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': csrf,
            Accept: 'application/json',
          },
        }
      )

      if (response.ok) {
        const updatedCarLoan =
          type === 'front'
            ? { ...carLoan, drivers_license_url: '', drivers_license_name: '' }
            : { ...carLoan, drivers_license_back_url: '', drivers_license_back_name: '' }
        setCarLoan(updatedCarLoan)
        notification.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'File deleted successfully!',
        })
      } else {
        notification.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Delete failed! Please try again.',
        })
        throw new Error('Failed to delete file')
      }
    } catch (error) {
      notification.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Delete failed! Please try again.',
      })
    }
  }

  const loadAgreementAgreedAt = async () => {
    const url = `/dealerships/${dealership.id}/car_loans/${carLoan.id}.json`
    const response = await fetch(url)
    const data = await response.json()
    const updatedCarLoan = {
      ...carLoan,
      agreement_agreed_at: data.agreement_agreed_at ? new Date(data.agreement_agreed_at) : null,
    }
    setCarLoan(updatedCarLoan)
  }

  return (
    <div className="pt-3 pb-3 container-fluid">
      <div className="mb-3 d-flex align-items-center">
        <div className="btn btn-outline-secondary btn-block-md-down mr-2 mb-2" onClick={handleBack}>
          <i className="fas fa-arrow-left mr-2"></i>
          Back
        </div>
        <div>
          <h4>
            <i className="fa fa-steering-wheel"></i>
            {!carLoan ? ' New Car Loan' : 'Car Loan'}
          </h4>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Toast ref={notification} />
        {carLoan && (
          <>
            <CustomerVehicleData onUpdateCarLoan={onUpdateCarLoan} />
            <Agreement control={control} />
            {insuranceExcess && (
              <>
                <CustomerAgreement
                  sendConfirmationMessage={sendConfirmationMessage}
                  verifyConfirmationCode={verifyConfirmationCode}
                  recordSignature={recordSignature}
                />
                {carLoan.agreement_agreed_at && (
                  <>
                    <DriverLicenseUpload
                      uploadDriversLicense={uploadDriversLicense}
                      deleteDriversLicense={deleteDriversLicense}
                    />
                    {carLoan.drivers_license_url && carLoan.drivers_license_back_url && (
                      <>
                        <Timeline control={control} />
                        <Notes control={control} />
                      </>
                    )}
                  </>
                )}
              </>
            )}

            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </>
        )}
        {!carLoan && dealership && <NewCarLoan onCreateCarLoan={onCreateCarLoan} />}

        <button onClick={handleBack} className="btn btn-link">
          Back
        </button>
      </form>
    </div>
  )
}

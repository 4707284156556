import { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import Select from 'react-select'

import { Input } from '../entries/FormElements'
import { humanize } from '../entries/utils'
import { standardHeaders } from '../entries/utils'

let seatOptions = Array.from({ length: 10 }, (v, i) => ({ value: i + 1, label: i + 1 }))

const Attribute = ({ attribute, overwrittenFields, setOverwrittenFields, value, type }) => {
  const unlocked = overwrittenFields.includes(attribute)
  const locked = !unlocked
  let [loading, setLoading] = useState(false)
  let { carId } = useParams()

  let [newValue, setNewValue] = useState(value)

  console.log(attribute)

  const save = () => {
    setLoading(true)
    fetch(`/cars/${carId}`, {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify({
        car: {
          [attribute]: newValue,
          overwritten_catalogue_fields: overwrittenFields,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false)
        setOverwrittenFields(overwrittenFields.filter((f) => f !== attribute))
      })
  }

  return (
    <div className="box p-3 mb-3">
      <div className="form-group">
        <label>{humanize(attribute)}</label>
        <div className="row">
          <div className="col-9">
            {type === 'select' && (
              <Select
                isDisabled={locked}
                placeholder={`${humanize(attribute)}...`}
                options={seatOptions}
                defaultValue={{ value: value, label: value }}
                onChange={(e) => setNewValue(e.value)}
              />
            )}
            {type === 'input' && (
              <Input
                disabled={locked}
                placeholder={`${humanize(attribute)}...`}
                defaultValue={value}
                onChange={(e) => setNewValue(e.target.value)}
              />
            )}
          </div>
          <div className="col-3">
            {locked && (
              <button
                className={'btn btn-block ' + (locked ? 'btn-outline-success' : 'btn-danger')}
                disabled={unlocked}
                onClick={() => {
                  setOverwrittenFields([...overwrittenFields, attribute])
                }}
              >
                <i className="fas mr-1 fa-lock"></i>
                Unlock
              </button>
            )}
            {unlocked && (
              <button
                className={'btn btn-outline-success btn-block'}
                disabled={locked}
                onClick={save}
              >
                {loading ? (
                  <i className="fas mr-1 fa-spinner fa-spin"></i>
                ) : (
                  <>
                    <i className="fas mr-1 fa-save"></i>
                    Save
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const EditRedbookDetails = () => {
  let [overwrittenFields, setOverwrittenFields] = useState([])
  let [car, setCar] = useState(false)

  let { carId, dealershipSlug } = useParams()

  useEffect(() => {
    fetch(`/dealerships/${dealershipSlug}/cars/${carId}/edit_redbook_details.json`)
      .then((response) => response.json())
      .then((data) => {
        setCar(data)
        setOverwrittenFields(data.overwritten_catalogue_fields)
      })
  }, [])

  return (
    <div className="container py-4">
      <h2>Overwrite Catalogue Details</h2>
      <p className="text-secondary">
        Only required in rare circumstances where the catalogue data is incorrect.{' '}
        <b>Usually NOT required.</b>
      </p>
      {car && (
        <>
          <Attribute
            attribute="seats"
            overwrittenFields={overwrittenFields}
            setOverwrittenFields={setOverwrittenFields}
            type="select"
            value={car.seats}
          />
          <Attribute
            attribute="doors"
            overwrittenFields={overwrittenFields}
            setOverwrittenFields={setOverwrittenFields}
            type="select"
            value={car.doors}
          />
          <Attribute
            attribute="year"
            overwrittenFields={overwrittenFields}
            setOverwrittenFields={setOverwrittenFields}
            type="input"
            value={car.year}
          />
          <Attribute
            attribute="model"
            overwrittenFields={overwrittenFields}
            setOverwrittenFields={setOverwrittenFields}
            type="input"
            value={car.model}
          />
          <Attribute
            attribute="badge"
            overwrittenFields={overwrittenFields}
            setOverwrittenFields={setOverwrittenFields}
            type="input"
            value={car.badge}
          />
        </>
      )}
    </div>
  )
}

export default EditRedbookDetails

import { Calendar } from 'primereact/calendar'
import { Controller } from 'react-hook-form'

const Timeline = ({ control }) => {
  return (
    <div className="card mb-4">
      <div className="card-header">Dates</div>
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-sm-3 d-flex align-items-center">
            <label htmlFor="check-in" className="mb-0">
              Loan start date
            </label>
          </div>
          <div className="col-sm-9">
            <Controller
              name="loanStartedAt"
              control={control}
              render={({ field }) => (
                <Calendar
                  {...field}
                  dateFormat="dd/mm/yy"
                  showTime
                  hourFormat="12"
                  stepMinute={15}
                  touchUI
                />
              )}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-sm-3 d-flex align-items-center">
            <label htmlFor="check-in" className="mb-0">
              Loan end date
            </label>
          </div>
          <div className="col-sm-9">
            <Controller
              name="loanEndsAt"
              control={control}
              render={({ field }) => (
                <Calendar
                  {...field}
                  dateFormat="dd/mm/yy"
                  showTime
                  hourFormat="12"
                  stepMinute={15}
                  touchUI
                />
              )}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-sm-3 d-flex align-items-center">
            <label htmlFor="check-in" className="mb-0">
              Vehicle Check-out Date/Time
            </label>
          </div>
          <div className="col-sm-9">
            <Controller
              name="checkedOutAt"
              control={control}
              render={({ field }) => (
                <Calendar
                  {...field}
                  dateFormat="dd/mm/yy"
                  showTime
                  hourFormat="12"
                  stepMinute={15}
                  touchUI
                />
              )}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-sm-3 d-flex align-items-center">
            <label htmlFor="check-out" className="mb-0">
              Vehicle Check-in Date/Time
            </label>
          </div>
          <div className="col-sm-9">
            <Controller
              name="checkedInAt"
              control={control}
              render={({ field }) => (
                <Calendar
                  {...field}
                  dateFormat="dd/mm/yy"
                  showTime
                  hourFormat="12"
                  stepMinute={15}
                  touchUI
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Timeline

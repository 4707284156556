import * as Routes from '../../routes'

export const PhoneNumbersService = {
  getPhoneNumbers(params, csrf, dealershipId) {
    const queryParams = params
      ? Object.keys(params)
          .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
          .join('&')
      : ''
    let phoneNumbersRoute = ''

    phoneNumbersRoute = Routes.dealership_phone_numbers_path(dealershipId)

    return fetch(`${phoneNumbersRoute}.json?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      credentials: 'same-origin',
    }).then((res) => res.json())
  },
  createPhoneNumber(phoneNumberData, csrf) {
    return fetch(Routes.dealership_phone_numbers_path(phoneNumberData.dealership_id), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      body: JSON.stringify({ phone_number: phoneNumberData }),
    }).then((res) => res.json())
  },
}

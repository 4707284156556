import { useContext, useEffect, useRef, useState } from 'react'

import { FilterMatchMode, FilterService, PrimeReactProvider } from 'primereact/api'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { IconField } from 'primereact/iconfield'
import { InputIcon } from 'primereact/inputicon'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import { SplitButton } from 'primereact/splitbutton'
import { Tag } from 'primereact/tag'
import { Toast } from 'primereact/toast'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { CarLoanProvider, DealershipContext } from '../contexts'
import { useFetchCarLoan, useFetchDealership } from '../dataHooks'
import { time_ago, truncateString } from '../entries/utils'
import { standardHeaders } from '../entries/utils'
import AuditTrailSlideIn from '../shared/AuditTrailSlideIn'
import showToast from '../shared/ShowToast'
import ItemStatus from './ItemStatus'
import { CarLoanService } from './carLoanService'

const Action = ({ carLoan, onActionComplete, notification }) => {
  const navigate = useNavigate()
  let url = `/dealerships/${carLoan.dealership_id}/car_loans/${carLoan.id}`
  const [showSlideIn, setShowSlideIn] = useState(false)
  const handleHideSlideIn = () => {
    setShowSlideIn(false)
  }

  useFetchCarLoan()

  const items = [
    {
      label: 'Duplicate',
      icon: 'fa fa-copy',
      command: () => {
        const duplicateUrl = `${url}/duplicate`
        fetch(duplicateUrl, {
          method: 'POST',
          headers: standardHeaders,
        }).then((data) => {
          data.json().then((newCarLoan) => {
            url = `/dealerships/${carLoan.dealership_id}/car_loans/${newCarLoan.id}/edit`
            navigate(url)
          })
        })
      },
    },
    {
      label: 'Audit Trail',
      icon: 'fa fa-history',
      command: () => {
        setShowSlideIn(true)
      },
    },
    {
      label: 'Destroy',
      icon: 'fa fa-trash',
      command: () => {
        fetch(url, {
          method: 'DELETE',
          headers: standardHeaders,
        }).then(() => {
          showToast(notification, 'success', 'Car Loan deleted!', '')
          onActionComplete()
        })
      },
    },
  ]

  return (
    <div style={{ position: 'relative' }}>
      <SplitButton
        label="Edit"
        buttonProps={{ id: 'edit-button' }}
        onClick={() => {
          navigate(`${url}/edit`)
        }}
        model={items}
        menuButtonProps={{ id: 'action-button' }}
        outlined
        rounded
      />
      {showSlideIn && (
        <AuditTrailSlideIn
          AuditTrailObject={carLoan}
          AuditTrailObjectName={`Car Loan-${carLoan.car.make}-${carLoan.car.model}-${carLoan.car.year}-${carLoan.car.stocknum}`}
          Url={`${url}/audit_trail`}
          ShowSlideIn={showSlideIn}
          onHide={handleHideSlideIn}
        />
      )}
    </div>
  )
}

const customLocationFilter = (value, filters) => {
  if (value === '' || (value !== '' && filters === '')) {
    return true
  }
  return value === filters
}

// Use a calendar input to select a date range
const createdAtFilter = (value, filters) => {
  if (filters === '') {
    return true
  }
  return value === filters
}

FilterService.register('custom_location', customLocationFilter)
FilterService.register('created_at', createdAtFilter)

export const LastEditBy = (carLoan) => {
  return (
    <div>
      <div>{carLoan.last_edit_by.name}</div>
      <div className="small text-secondary">{time_ago(carLoan.updated_at)}</div>
    </div>
  )
}

export const CreatedAt = (carLoan) => {
  return (
    <div>
      <div>
        {new Date(carLoan.created_at).toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}
      </div>
    </div>
  )
}

export const Title = (carLoan) => {
  let url = `/dealerships/${carLoan.dealership_id}/car_loans/${carLoan.id}/edit`
  return (
    <>
      <Link to={url}>{truncateString(carLoan.title, 50)}</Link>
      <div className="small text-secondary">Created: {time_ago(carLoan.created_at)}</div>
    </>
  )
}

export const Contact = (carLoan) => {
  let url = `/dealerships/${carLoan.dealership_id}/contacts/${carLoan.contact_id}`
  return (
    <div>
      <Link to={url}>{carLoan.contact_name}</Link>
      <div className="small text-secondary">{carLoan.contact_phone_number}</div>
      <div className="small text-secondary">{carLoan.contact_email}</div>
    </div>
  )
}

export const Status = (carLoan) => {
  return <ItemStatus status={carLoan.status} />
}

export const Location = (carLoan) => {
  return carLoan.location || 'All'
}

const App = () => {
  const { dealership } = useContext(DealershipContext)
  const locations = dealership?.locations
  const dealershipId = dealership?.id
  const notification = useRef(null)
  const [fromCreatedAtDate, setFromCreatedAtDate] = useState('')
  const [toCreatedAtDate, setToCreatedAtDate] = useState('')
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  let [loading, setLoading] = useState(true)

  useFetchDealership()

  const getSeverity = (status) => {
    switch (status) {
      case 'Ready':
        return 'primary'

      case 'Completed':
        return 'success'

      case 'In Progress':
        return 'warning'
    }
  }

  const [totalRecords, setTotalRecords] = useState(0)
  const [carLoans, setCarLoans] = useState(null)

  const [statuses] = useState(['Completed', 'Ready', 'In Progress'])

  const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />
  }

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Select Status"
        className="p-column-filter"
        showClear
        style={{ minWidth: '12rem' }}
      />
    )
  }

  const createdAtRowFilterTemplate = (options) => {
    const [from, to] = options.value ?? [null, null]

    return (
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center mb-2">
          <label htmlFor="from_date" className="flex-shrink-0 mr-2" style={{ minWidth: '50px' }}>
            After
          </label>
          <input
            type="date"
            value={from}
            onChange={(e) => {
              setFromCreatedAtDate(e.target.value)
              options.filterApplyCallback([e.target.value, toCreatedAtDate])
            }}
            className="flex-grow-1 p-inputtext p-component p-column-filter"
          />
        </div>
        <div className="d-flex align-items-center">
          <label htmlFor="until_date" className="flex-shrink-0 mr-2" style={{ minWidth: '50px' }}>
            Before
          </label>
          <input
            type="date"
            value={to}
            onChange={(e) => {
              setToCreatedAtDate(e.target.value)
              options.filterApplyCallback([fromCreatedAtDate, e.target.value])
            }}
            className="flex-grow-1 p-inputtext p-component p-column-filter"
          />
        </div>
      </div>
    )
  }

  const locationOptionsMap = {
    Location: locations?.map((location) => ({ label: location.name, value: location.id })),
  }

  const locationRowFilterTemplate = (options) => rowFilterTemplate(options, 'Location')

  const rowFilterTemplate = (options, name, width = '10rem') => {
    const selectedOptions = locationOptionsMap[name]
    return (
      <Dropdown
        value={options.value}
        options={selectedOptions}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder={`Select ${name}`}
        className="p-column-filter"
        style={{ maxWidth: width }}
      />
    )
  }

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 50,
    page: 1,
    sortField: 'created_at',
    sortOrder: -1,
    filters: {
      status: { value: null, matchMode: 'equals' },
      title: { value: null, matchMode: 'contains' },
      location: { value: null, matchMode: 'contains' },
      contact_name: { value: null, matchMode: 'contains' },
      contact_phone_number: { value: null, matchMode: 'contains' },
      contact_email: { value: null, matchMode: 'contains' },
      last_edit_by: { value: null, matchMode: 'contains' },
      created_at: { value: null, matchMode: 'contains' },
    },
  })

  useEffect(() => {
    const createdAtFilter = lazyState.filters.created_at?.value

    if (
      !createdAtFilter ||
      (Array.isArray(createdAtFilter) && createdAtFilter.every((date) => !date))
    ) {
      setFromCreatedAtDate('') // Reset the "from" date state
      setToCreatedAtDate('') // Reset the "to" date state
    }
    loadLazyData()
  }, [lazyState, dealership])

  const loadLazyData = () => {
    setLoading(true)
    let page_number = 0
    if (lazyState.page === 0 || lazyState.first === 0) {
      page_number = 1
    } else {
      page_number = lazyState.page + 1
    }

    if (lazyState.sortField === undefined) {
      lazyState.sortField = 'created_at'
    }

    if (lazyState.sortOrder === undefined) {
      lazyState.sortOrder = -1
    }

    let params = {
      page: page_number,
      rows: lazyState.rows,
      sortField: lazyState.sortField,
      sortOrder: lazyState.sortOrder,
      filters: JSON.stringify(lazyState.filters),
    }

    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
    let dealershipId = dealership?.id

    if (dealershipId) {
      let urlParams = new URLSearchParams(window.location.search)
      try {
        CarLoanService.getCarLoans({ params, csrf, dealershipId, urlParams }).then((data) => {
          const carLoansData = data.data.car_loans.map((carLoan) => ({
            ...carLoan,
            created_at: new Date(carLoan.created_at), // Ensure this is a Date object
          }))
          setTotalRecords(data.data.total_records)
          setCarLoans(carLoansData)
          setLoading(false)
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  const onFilter = (event) => {
    event['first'] = 0
    setlazyState(event)
  }

  const onSort = (event) => {
    setlazyState(event)
  }

  const onPage = (event) => {
    setlazyState(event)
  }

  const onPageChange = (event) => {
    event['filters'] = lazyState.filters
    setlazyState(event)
  }

  const handleActionComplete = () => {
    loadLazyData()
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    setGlobalFilterValue(value)

    setlazyState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        global: { value, matchMode: FilterMatchMode.CONTAINS },
      },
    }))
  }

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-start align-items-center">
        <IconField iconPosition="left">
          <InputIcon className="fa fa-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField>
        <div className="ml-2 font-weight-light">Search by Contact details or Stock Number</div>
      </div>
    )
  }
  const header = renderHeader()

  return (
    <PrimeReactProvider>
      <CarLoanProvider>
        <div className="pt-3 container-fluid">
          <Toast ref={notification} />
          <div className="mb-3 d-flex align-items-center">
            <h4 className="m-0">Car Loans</h4>
            <div className="ml-auto">
              <Link
                to={`/dealerships/${dealershipId}/car_loans/new`}
                className="btn btn-outline-success"
              >
                <i className="fa fa-plus mr-1"></i>
                New Car Loan
              </Link>
            </div>
          </div>
          <div className="box">
            <DataTable
              value={carLoans}
              tableStyle={{ minWidth: '50rem' }}
              onPage={onPage}
              onSort={onSort}
              onFilter={onFilter}
              first={lazyState.first}
              sortField={lazyState.sortField}
              sortOrder={lazyState.sortOrder}
              rows={lazyState.rows}
              filters={lazyState.filters}
              totalRecords={totalRecords}
              lazy
              style={{ overflow: 'visible' }}
              wrapper={{ style: { overflow: 'visible' } }}
              filterDisplay="row"
              header={header}
              loading={loading}
            >
              <Column sortable body={Title} field="title" header="Title"></Column>
              <Column sortable body={Contact} field="contact_name" header="Contact"></Column>
              <Column
                field="status"
                header="Status"
                showFilterMenu={false}
                filterMenuStyle={{ width: '14rem' }}
                body={Status}
                filter
                filterElement={statusRowFilterTemplate}
              />
              <Column
                body={Location}
                field="location"
                header="Location"
                showFilterMenu={false}
                filterMenuStyle={{ width: '14rem' }}
                filter
                filterElement={locationRowFilterTemplate}
              />
              <Column
                sortable
                body={CreatedAt}
                showFilterMenu={false}
                filterMenuStyle={{ width: '14rem' }}
                filter
                filterElement={createdAtRowFilterTemplate}
                field="created_at"
                header="Creation Date"
                dataType="date"
              />
              <Column
                body={LastEditBy}
                field="last_edit_by"
                header="Last Edited By"
                dataType="date"
              ></Column>
              <Column
                body={(carLoan) => (
                  <Action
                    carLoan={carLoan}
                    onActionComplete={handleActionComplete}
                    notification={notification}
                  />
                )}
                header="Actions"
              ></Column>
            </DataTable>
            <Paginator
              first={lazyState.first}
              rows={lazyState.rows}
              totalRecords={totalRecords}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </CarLoanProvider>
    </PrimeReactProvider>
  )
}

export default App

import { useEffect, useState } from 'react'

import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Link } from 'react-router-dom'
import Select from 'react-select'

const Button = (rowData) => (
  <>
    <Link
      className="btn btn-outline-primary btn-sm mr-2"
      to="/stock_image_sets/new"
      state={{
        manufacturer_id: rowData.manufacturer?.id,
        family_id: rowData.variant?.family_id,
        year: rowData.year,
        badge: rowData.badge,
        simple_colour: rowData.simple_colour,
        bodyconfiguration: rowData.variant?.bodyconfiguration,
      }}
    >
      Add Stock Image
    </Link>
    <Link className="btn btn-outline-primary btn-sm" to={`/cars/${rowData.slug}`}>
      View Car
    </Link>
  </>
)

const Model = (rowData) => (
  <>
    <div>{rowData.model}</div>
    <div className="small text-secondary">{rowData.badge || '(No Badge)'}</div>
    <div className="small text-secondary">{rowData.variant?.bodyconfiguration}</div>
  </>
)

const ToDoCars = () => {
  let [data, setData] = useState([])
  let [loading, setLoading] = useState(false)
  let [manufacturers, setManufacturers] = useState(null)
  let [selectedManufacturer, setSelectedManufacturer] = useState(null)

  useEffect(() => {
    setLoading(true)
    let url = '/stock_image_sets/cars_needing_stock_images.json'

    if (selectedManufacturer) {
      url += `?manufacturer_id=${selectedManufacturer}`
    }

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data)
        setManufacturers(data.manufacturers)
        setLoading(false)
        setSelectedManufacturer(data.selected_manufacturer.id)
      })
  }, [selectedManufacturer])

  let manufacturerOptions = manufacturers?.map((manufacturer) => ({
    value: manufacturer.id,
    label: manufacturer.name,
  }))
  let selectedManufacturerOption = manufacturerOptions?.find(
    (manufacturer) => manufacturer.value === selectedManufacturer
  )

  return (
    <div className="box mb-3">
      <div className="p-3">
        <div className="d-flex">
          <div>
            <h5>Cars which need stock images</h5>
            <div className="text-secondary">
              {data.total} {selectedManufacturerOption?.label} cars currently need stock images
              added
            </div>
          </div>
          <div className="ml-auto">
            <Select
              options={manufacturerOptions}
              onChange={(e) => setSelectedManufacturer(e?.value)}
              placeholder="Filter by Manufacturer"
              value={selectedManufacturerOption}
            />
          </div>
        </div>
      </div>
      <DataTable
        value={data.cars}
        loading={loading}
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
      >
        <Column field="year" header="Year" />
        <Column field="manufacturer.name" header="Make" />
        <Column field="model" header="Model" body={Model} />
        <Column field="simple_colour" header="Colour" />
        <Column field={Button} header="Buttons" />
      </DataTable>
    </div>
  )
}

export default ToDoCars

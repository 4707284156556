import { useState } from 'react'

import { Link } from 'react-router-dom'

const sections = [
  {
    title: 'Analytics',
    links: [{ name: 'Website Analytics', href: '/admin/website_analytics' }],
  },
  {
    title: 'Server',
    links: [
      { name: 'Vercel Deployments', href: '/admin/deployments' },
      { name: 'Vercel Site Builds', href: '/admin/website_builds', reactRouter: true },
    ],
  },
  {
    title: 'Inventory',
    links: [
      { name: 'Carsales Accounts', href: '/carsales_accounts', reactRouter: true },
      { name: 'Stock Images', href: '/stock_image_sets', reactRouter: true },
      { name: 'Stock Rules', href: '/stock_rules' },
    ],
  },
  {
    title: 'Search via Meilisearch',
    links: [
      { name: 'Meilisearch Indexes', href: '/admin/meilisearch' },
      { name: 'Meilisearch Tasks', href: '/admin/meilisearch_tasks' },
    ],
  },
  {
    title: 'Leads',
    links: [
      { name: 'Leads WITHOUT notifications', href: '/admin/leads_without_notifications' },
      { name: 'Email Leads Log', href: '/email_leads' },
      { name: 'Incoming Emails', href: '/incoming_emails', reactRouter: true },
      { name: 'Push Notifications Log', href: '/admin/push_notifications' },
    ],
  },
  {
    title: 'FTP',
    links: [
      { name: 'FTP Accounts', href: '/ftp_accounts' },
      { name: 'Webhook Recipients', href: '/webhook_recipients' },
    ],
  },
  {
    title: 'Pages',
    links: [{ name: 'Page Errors', href: '/admin/page_errors' }],
  },
  {
    title: 'Logistics Pricing',
    links: [{ name: 'Logistics Pricing', href: '/delivery_prices' }],
  },
  {
    title: 'Billing',
    links: [
      { name: 'Billing', href: '/admin/billing', reactRouter: true },
      { name: 'NEVDIS Lookups', href: '/nevdis' },
      { name: 'Autograb', href: '/admin/autograb' },
      { name: 'Vehicle Detail Lookups', href: '/admin/vehicle_detail_lookups' },
      { name: 'Phone Calls Cost', href: '/admin/dealership_phone_calls_cost', reactRouter: true },
    ],
  },
  {
    title: 'API Calls',
    links: [
      { name: 'API Logs', href: '/admin/apicalls', reactRouter: true },
      { name: 'Revalidations', href: '/admin/revalidations' },
      { name: 'SMS Log', href: '/admin/texts' },
      { name: 'PDF Exports', href: '/admin/pdf_exports' },
      { name: 'API Keys', href: '/apikeys' },
      { name: 'Redbook Lookups', href: '/admin/billing' },
    ],
  },
  {
    title: 'Plugins',
    links: [{ name: 'Plugins', href: '/plugins' }],
  },
  {
    title: 'API Docs',
    links: [{ name: 'Gitbook', href: '/gitbook_url' }],
  },
  {
    title: 'Identifiers',
    links: [{ name: 'ID/Slug Details', href: '/identifiers' }],
  },
  {
    title: 'Ad Accounts',
    links: [
      { name: 'Facebook Ads', href: '/social_ad_accounts/facebook' },
      { name: 'Google Ads', href: '/social_ad_accounts/google' },
    ],
  },
]

const Anchor = ({ to, children }) => <a href={to}>{children}</a>

const Item = ({ link }) => {
  const LinkComponent = link.reactRouter ? Link : Anchor

  return (
    <div className="col-md-3 mb-2">
      <LinkComponent to={link.href}>
        <div className="monitoring-box shadow-sm bg-white p-3 rounded">
          <h6 className="m-0 text-center">{link.name}</h6>
        </div>
      </LinkComponent>
    </div>
  )
}

const MonitoringDashboard = () => {
  let [query, setQuery] = useState('')

  let filteredSections = sections

  if (query) {
    filteredSections = sections.map((section) => ({
      ...section,
      links: section.links.filter((link) => link.name.toLowerCase().includes(query.toLowerCase())),
    }))
  }

  return (
    <div className="container py-4">
      <input
        type="text"
        className="form-control mb-3"
        placeholder="Search..."
        onChange={(e) => setQuery(e.target.value)}
      />
      {filteredSections.map((section, index) => (
        <div key={index} className="mt-3">
          <h4>{section.title}</h4>
          <div className="row mb-3">
            {section.links.map((link, idx) => (
              <Item link={link} key={idx} />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default MonitoringDashboard

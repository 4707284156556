import { ReactNode } from 'react'

import { AnalyticsDashboardProvider } from './AnalyticsDashboardContext'
import { ConfigProvider } from './ConfigContext'
import { ItemsProvider } from './ItemsContext'
import { PreviewDataProvider } from './PreviewDataContext'
import { TabsProvider } from './TabsContext'
import { TimeRangeLocationProvider } from './TimeRangeLocationContext'

const ContextWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <AnalyticsDashboardProvider>
      <ConfigProvider>
        <TimeRangeLocationProvider>
          <TabsProvider>
            <ItemsProvider>
              <PreviewDataProvider>{children}</PreviewDataProvider>
            </ItemsProvider>
          </TabsProvider>
        </TimeRangeLocationProvider>
      </ConfigProvider>
    </AnalyticsDashboardProvider>
  )
}

export default ContextWrapper

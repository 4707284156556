import { useEffect, useRef, useState } from 'react'

import { Toast } from 'primereact/toast'
import { useFormContext, useWatch } from 'react-hook-form'
import { Link, useParams, useSearchParams } from 'react-router-dom'

import * as Routes from '../../../routes'
import Loading from '../../Loading'
import { useFetchDealership } from '../../dataHooks'
import { humanize } from '../../editor/common'
import { standardHeaders } from '../../entries/utils'
import { Form, FormCheckbox, FormInput, FormMultiSelect } from '../../formUtils'
import showToast from '../../shared/ShowToast'
import { Dealership, Location } from '../../types'

export type PaymentProcessor = {
  id: number
  name: string
  locations: {
    id: number
    name: string
  }[]
  types: string[]
  car_types: string[]
  deposit_amount: string
  authorization_key: string
  authorization_secret: string
  authorization_type: string
  merchant_id: string
  apple_pay_enabled: boolean | string
  google_pay_enabled: boolean | string
  google_merchant_id: string
  supports_all_types: boolean | string
}

const PaymentProcessorForm: React.FC<{ formType: 'new' | 'edit' }> = ({ formType }) => {
  const { dealership, loading: dealershipLoading } = useFetchDealership() as {
    dealership: Dealership
    loading: boolean
  }
  const [paymentProcessorTypeOptions, setPaymentProcessorTypeOptions] = useState<string[]>([])
  const [paymentProcessorData, setPaymentProcessorData] = useState<PaymentProcessor>()
  const [paymentProcessorLoading, setPaymentProcessorLoading] = useState<boolean>(true)

  const { control, setValue } = useFormContext()

  const googlePayEnabled = useWatch({ control, name: 'google_pay_enabled' })
  const supportsAllTypes = useWatch({ control, name: 'supports_all_types', defaultValue: true })

  useEffect(() => {
    if (paymentProcessorData?.supports_all_types !== undefined) {
      setValue('supports_all_types', paymentProcessorData?.supports_all_types)
    }
  }, [paymentProcessorData?.supports_all_types, setValue])

  const [searchParams] = useSearchParams()
  const authorizationType =
    searchParams.get('authorization_type') || paymentProcessorData?.authorization_type

  const { processorId } = useParams() as { processorId: string }

  const locationOptions: { label: string; value: number }[] =
    dealership?.locations
      ?.filter((location: Location) => !['service', 'parts'].includes(location.location_type))
      .map((location: Location) => ({
        label: location.name,
        value: location.id,
      })) || []

  useEffect(() => {
    if (!dealership) return

    const fetchPaymentProcessorTypeOptions = async () => {
      try {
        setPaymentProcessorLoading(true)
        const response = await fetch(`${Routes.payment_processor_types_path()}`)
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        if (!data) {
          throw new Error('Failed to fetch data')
        }
        setPaymentProcessorTypeOptions(data)
        setPaymentProcessorLoading(false)
      } catch (error) {
        console.error('Error fetching payment processors:', error)
        setPaymentProcessorLoading(false)
      }
    }
    fetchPaymentProcessorTypeOptions()
  }, [dealership])

  useEffect(() => {
    if (!dealership || formType !== 'edit') return

    const fetchPaymentProcessorData = async () => {
      try {
        const response = await fetch(
          `${Routes.dealership_payment_processor_path(dealership.id, processorId)}`
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        if (!data) {
          throw new Error('Failed to fetch data')
        }
        setPaymentProcessorData(data)
      } catch (error) {
        console.error('Error fetching payment processors:', error)
      }
    }
    fetchPaymentProcessorData()
  }, [dealership])

  if (
    dealershipLoading ||
    paymentProcessorLoading ||
    (formType === 'edit' && paymentProcessorData === undefined)
  ) {
    return (
      <div className="d-flex justify-content-center mt-3">
        <Loading />
      </div>
    )
  }

  return (
    <>
      <h2 className="mb-3">{formType === 'edit' ? 'Editing ' : 'New '}Payment Processor</h2>

      {/* Authorization Type (hidden) */}
      <FormInput
        type="hidden"
        name="authorization_type"
        label={''}
        defaultValue={authorizationType}
      />

      {/* Authorization Key */}
      <div className="mb-2">
        <FormInput
          name="authorization_key"
          label={`${humanize(authorizationType)} key`}
          type="text"
          required
          defaultValue={paymentProcessorData?.authorization_key}
        />
      </div>

      {/* Authorization Secret */}
      <div>
        <FormInput
          name="authorization_secret"
          label={`${humanize(authorizationType)} secret`}
          type="password"
          required
          defaultValue={paymentProcessorData?.authorization_secret}
        />
      </div>

      {/* Merchant Id */}
      {authorizationType === 'st_george' && (
        <div>
          <FormInput
            name="merchant_id"
            label="St George Merchant ID"
            type="text"
            required
            defaultValue={paymentProcessorData?.merchant_id}
          />
        </div>
      )}

      {/* Location IDs */}
      <div>
        <FormMultiSelect
          name="location_ids"
          label="Location Ids"
          className="js-choice"
          options={locationOptions}
          multiple
          defaultValue={paymentProcessorData?.locations.map(
            (location) => location.id as unknown as string
          )}
        />
      </div>

      {/* Car Types */}
      <div>
        <FormMultiSelect
          name="car_types"
          label="Car Types"
          options={['new', 'used', 'demo']}
          defaultValue={paymentProcessorData?.car_types}
        />
      </div>

      {/* Deposit Amount */}
      <div>
        <FormInput
          name="deposit_amount"
          label="Deposit Amount"
          type="text"
          className="cleave-currency"
          defaultValue={paymentProcessorData?.deposit_amount || '200'}
          formatValue={(val: string) => {
            if (val.startsWith('$')) {
              return val
            }
            return `$${val}`
          }}
        />
      </div>

      {/* Apple Pay Enabled */}
      {authorizationType === 'st_george' && (
        <div>
          <FormCheckbox
            name="apple_pay_enabled"
            label="Apple Pay Enabled"
            defaultValue={paymentProcessorData?.apple_pay_enabled as any}
          />
        </div>
      )}

      {/* Google Pay Enabled */}
      {authorizationType === 'st_george' && (
        <div>
          <FormCheckbox
            name="google_pay_enabled"
            label="Google Pay Enabled"
            defaultValue={paymentProcessorData?.google_pay_enabled as any}
          />
        </div>
      )}

      {/* Google Merchant ID */}
      {authorizationType === 'st_george' && googlePayEnabled && (
        <div>
          <FormInput
            name="google_merchant_id"
            label="Google Merchant ID"
            type="text"
            required
            defaultValue={paymentProcessorData?.google_merchant_id}
          />
        </div>
      )}

      {/* Supports All Types */}
      <div>
        <FormCheckbox
          name="supports_all_types"
          label="Supports all types"
          defaultValue={paymentProcessorData?.supports_all_types ?? (true as any)}
        />
      </div>

      {/* Types */}
      <div>
        <FormMultiSelect
          name="types"
          label="Types"
          disabled={supportsAllTypes}
          options={paymentProcessorTypeOptions}
          defaultValue={paymentProcessorData?.types || paymentProcessorTypeOptions}
        />
      </div>

      {/* Submit Button */}
      <button type="submit" className="btn btn-outline-primary">
        {formType === 'edit' ? 'Update ' : 'Create '}Payment Processor
      </button>
    </>
  )
}

const FormWrapper: React.FC<{ formType: 'new' | 'edit' }> = ({ formType }) => {
  const { dealershipSlug, processorId } = useParams() as {
    dealershipSlug: string
    processorId: string
  }

  const notification = useRef(null)

  function transformData(data: PaymentProcessor) {
    const transformedData = { ...data }

    for (const [key, value] of Object.entries(transformedData)) {
      switch (key) {
        case 'apple_pay_enabled':
        case 'google_pay_enabled':
        case 'supports_all_types':
          transformedData[key] = value === true ? '1' : '0'
          break
        case 'car_types':
        case 'types':
        case 'location_ids':
          value === undefined ? (transformedData[key] = []) : (transformedData[key] = value)
          break
        case 'merchant_id':
          value === undefined
            ? (transformedData[key] = '')
            : (transformedData[key] = value as string)
          break
        case 'deposit_amount':
          transformedData[key] = String(value).replace('$', '') as string
          break
        default:
          break
      }
    }
    return {
      payment_processor: {
        ...transformedData,
      },
    }
  }
  // Handle form submission
  const handleSubmit = async (paymentProcessorData: PaymentProcessor) => {
    const transformedData = transformData(paymentProcessorData)

    const URL =
      formType === 'new'
        ? Routes.dealership_payment_processors_path(dealershipSlug)
        : Routes.dealership_payment_processor_path(dealershipSlug, processorId)

    // Submit form data to the backend
    try {
      const response = await fetch(URL, {
        method: formType === 'new' ? 'POST' : 'PATCH',
        headers: standardHeaders,
        body: JSON.stringify(transformedData),
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      if ([200, 201].includes(response.status)) {
        showToast(
          notification,
          'success',
          'Success',
          `Payment processor ${formType === 'edit' ? 'saved' : 'created'} successfully`
        )
        setTimeout(() => {
          window.location.href = Routes.dealership_payment_processors_path(dealershipSlug)
        }, 2000)
      }
    } catch (error) {
      console.error('Error submitting payment processor:', error)
      showToast(
        notification,
        'error',
        'Error',
        `Payment processor couldn't be ${formType === 'edit' ? 'saved' : 'created'}`
      )
    }
  }
  return (
    <>
      <Toast ref={notification} />

      <Form onSubmit={handleSubmit} className="p-3 box">
        <PaymentProcessorForm formType={formType} />
      </Form>
      <div className="ml-3 mt-3">
        <Link to={Routes.dealership_payment_processors_path(dealershipSlug)}>Back</Link>
      </div>
    </>
  )
}

export default FormWrapper

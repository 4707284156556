import { useEffect, useState } from 'react'

import moment from 'moment'
import { useParams } from 'react-router-dom'

import Loading from '../Loading'

const Distribution = ({ car_id, destinations, setDestinations }) => {
  let [loading, setLoading] = useState(false)
  let { dealershipSlug } = useParams()

  useEffect(() => {
    if (destinations && destinations.length == 0) {
      setLoading(true)
      fetch(`/cars/${car_id}/export_destinations.json`)
        .then((res) => res.json())
        .then((data) => {
          setDestinations(data)
          setLoading(false)
        })
    }
  }, [])

  return (
    <>
      <div className="p-3">
        <h4>Destinations</h4>
        {loading && <Loading />}
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Feed Name</th>
              <th>Destination Name</th>
              <th>Last Feed Run</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {destinations.feeds &&
              destinations.feeds.map((destination) => (
                <tr key={destination.id}>
                  <td>{destination.name}</td>
                  <td>{destination.ftp_account?.name || destination.schema}</td>
                  <td>
                    {destination.last_feed_run?.created_at && (
                      <>
                        <i className="fa fa-check text-success mr-2"></i>
                        {moment(destination.last_feed_run?.created_at).fromNow()}
                      </>
                    )}
                  </td>
                  <td>
                    <a
                      href={`/dealerships/${dealershipSlug}/feeds/${destination.id}`}
                      className="btn btn-sm btn-outline-primary"
                    >
                      View Feed
                    </a>
                  </td>
                </tr>
              ))}
            {destinations.websites &&
              destinations.websites.map((website) => (
                <tr key={website.id}>
                  <td>{website.name}</td>
                  <td>
                    {
                      <a href={website.url} target="_blank">
                        {website.url}
                      </a>
                    }
                  </td>
                  <td>
                    <i className="fa fa-check text-success mr-2"></i>
                    Continuous Feed
                  </td>
                  <td></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Distribution

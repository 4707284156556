import { useEffect, useState } from 'react'

import { Dialog } from 'primereact/dialog'
import { useParams } from 'react-router-dom'

import Loading from '../Loading'
import { useFetchDealership } from '../dataHooks'
import { Select } from '../entries/FormElements'
import { truncateString } from '../entries/utils'
import { standardHeaders } from '../entries/utils'

const Plugin = ({ plugin, setPlugins }) => {
  let [visible, setVisible] = useState(false)
  let [loading, setLoading] = useState(false)
  let { dealershipSlug } = useParams()

  let [websiteIds, setWebsiteIds] = useState(false)
  const { dealership } = useFetchDealership()

  let websiteOptions = dealership?.websites?.map((w) => ({ label: w.name, value: w.id }))

  const addPlugin = () => {
    setLoading(true)
    fetch(`/dealerships/${dealershipSlug}/dealership_plugins`, {
      method: 'POST',
      headers: standardHeaders,
      body: JSON.stringify({
        plugin: {
          plugin_id: plugin.id,
          website_ids: websiteIds,
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false)
        setPlugins(res)
        setVisible(false)
      })
  }

  const removePlugin = () => {
    setLoading(true)
    fetch(`/dealerships/${dealershipSlug}/dealership_plugins/${plugin.id}`, {
      method: 'DELETE',
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false)
        setPlugins(res)
        setVisible(false)
      })
  }

  return (
    <div className="col-md-4 mb-2">
      <div
        className={
          'border rounded bg-white p-3 h-100' + (plugin.installed_on_dealership ? ' shadow-sm' : '')
        }
      >
        <div className="d-flex flex-column h-100">
          <div className="text-center">
            {plugin.image_url && (
              <img src={plugin.image_url} className="img-fluid rounded mb-2" width={100} />
            )}
            <div className="mt-auto">
              <h4>{plugin.name}</h4>
              <div className="text-secondary mb-3">
                <div className="mb-2">{truncateString(plugin.description, 100)}</div>
                {plugin.custom_pricing && <div>{plugin.custom_pricing}</div>}
                {plugin.monthly_price && <div>${plugin.monthly_price?.toLocaleString()}/month</div>}
              </div>
            </div>
          </div>
          <div className="mt-auto">
            {plugin.installed_on_dealership ? (
              <>
                <div className="text-center pb-3">
                  {plugin.websites.map((w) => (
                    <div className="badge badge-secondary mr-1" key={w.id}>
                      {w.name}
                    </div>
                  ))}
                </div>
                <div className="btn btn-outline-danger btn-block" onClick={removePlugin}>
                  {loading ? (
                    <>
                      <i className="fa fa-spinner fa-spin mr-2"></i>
                      Loading
                    </>
                  ) : (
                    <>
                      <i className="fa fa-times mr-2"></i>
                      Remove
                    </>
                  )}
                </div>
              </>
            ) : (
              <div className="btn btn-outline-success btn-block" onClick={() => setVisible(true)}>
                Add Plugin
                <i className="fa fa-arrow-right ml-2"></i>
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog
        header={plugin.name}
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        dismissableMask={true}
      >
        <div className="mb-3">{plugin.description}</div>
        <h5>Terms</h5>
        <ul>
          <li>Once confirmed, will be available immediately.</li>
          <li>No lock in contract. Can be cancelled at any time.</li>
          <li>Billing via invoices from Total Dealer PTY LTD.</li>
        </ul>
        {plugin.ask_for_applicable_websites && (
          <Select
            label="Websites"
            isMulti={true}
            options={websiteOptions}
            onChange={(options) => setWebsiteIds(options.map((o) => o.value))}
          />
        )}
        <div
          className={'btn btn-outline-success btn-block' + (loading ? ' disabled' : '')}
          onClick={addPlugin}
          disabled={loading}
        >
          {loading ? (
            <>
              <i className="fa fa-spinner fa-spin mr-2"></i>
              Loading
            </>
          ) : (
            <>
              <i className="fa fa-check mr-2"></i>
              Confirm
            </>
          )}
        </div>
      </Dialog>
    </div>
  )
}

const Plugins = () => {
  let [loading, setLoading] = useState(false)
  let [plugins, setPlugins] = useState(false)
  let { dealershipSlug } = useParams()

  useEffect(() => {
    setLoading(true)
    fetch(`/plugins.json?dealership_id=${dealershipSlug}`)
      .then((res) => res.json())
      .then((res) => {
        setLoading(false)
        setPlugins(res)
      })
  }, [])

  return (
    <div className="p-3">
      <h4>Dealer Studio Plugins</h4>
      <p className="text-secondary">Launching Soon!</p>
      {loading && <Loading />}
      <div className="row">
        {plugins &&
          plugins.map((plugin) => (
            <Plugin plugin={plugin} key={plugin.id} plugins={plugins} setPlugins={setPlugins} />
          ))}
      </div>
    </div>
  )
}

export default Plugins

import { useContext } from 'react'

import { AnalyticsDashboardContext } from './AnalyticsDashboardContext'
import { ConfigContext } from './ConfigContext'
import { ItemsContext } from './ItemsContext'
import { PreviewDataContext } from './PreviewDataContext'
import { TabsContext } from './TabsContext'
import { TimeRangeLocationContext } from './TimeRangeLocationContext'

export function useConfig() {
  const context = useContext(ConfigContext)
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider')
  }
  return context
}

export function useTimeRangeLocation() {
  const context = useContext(TimeRangeLocationContext)
  if (!context) {
    throw new Error('useTimeRangeLocation must be used within TimeRangeLocationProvider')
  }
  return context
}

export function useAnalyticsDashboard() {
  const context = useContext(AnalyticsDashboardContext)
  if (!context) {
    throw new Error('useAnalyticsDashboard must be used within AnalyticsDashboardProvider')
  }
  return context
}

export function useTabs() {
  const context = useContext(TabsContext)
  if (!context) {
    throw new Error('useTabs must be used within TabsProvider')
  }
  return context
}

export function useItems() {
  const context = useContext(ItemsContext)
  if (!context) {
    throw new Error('useItems must be used within ItemsProvider')
  }
  return context
}

export function usePreviewData() {
  const context = useContext(PreviewDataContext)
  if (!context) {
    throw new Error('usePreviewData must be used within PreviewDataProvider')
  }
  return context
}

import { useContext, useState } from 'react'

import { useParams } from 'react-router-dom'

import { CurrentUserContext, LeadClusterContext } from '../contexts'
import { updateLeadClusterAPI } from './utils'

const RequiresApproval = () => {
  let [loading, setLoading] = useState(false)
  let { leadCluster, setLeadCluster } = useContext(LeadClusterContext)
  let currentUser = useContext(CurrentUserContext)
  let { dealershipSlug } = useParams()

  const toggle = async (approved) => {
    try {
      setLoading(true)

      const data = {
        lead_cluster: {
          approved: approved,
        },
      }

      const res = await updateLeadClusterAPI(leadCluster.id, data, dealershipSlug)

      if (res.error) {
        throw new Error(res.message || 'Failed to update approval status.')
      }

      setLeadCluster({
        ...leadCluster,
        lead_state: res.lead_state,
        requires_approval: res.requires_approval,
        approver: res.approver,
      })
    } catch (error) {
      console.error('Error updating approval status:', error)
      showToast(notification, 'error', 'Failed to update lead approval.')
    } finally {
      setLoading(false) // Ensures loading is reset in all cases
    }
  }

  const Loading = () => (
    <div>
      <i className="fa fa-spinner fa-spin"></i>
    </div>
  )

  if (!leadCluster?.requires_approval) {
    return null
  }

  let manager =
    currentUser?.dealerships && currentUser?.dealerships[leadCluster?.dealership_id]?.manager

  return (
    <div>
      {manager ? (
        <button
          className="btn btn-outline-success btn-sm btn-block mt-2"
          onClick={() => toggle(true)}
          disabled={!manager}
          id="approval-btn"
        >
          {loading ? (
            <Loading />
          ) : (
            <span>
              <i className="fa fa-check mr-1"></i>
              Approve
            </span>
          )}
        </button>
      ) : (
        <div className="small text-center text-secondary mt-1">
          <i className="fa fa-lock mr-1"></i>
          Manager Approval Required
        </div>
      )}
    </div>
  )
}

export default RequiresApproval

import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react'

import { useParams } from 'react-router-dom'

import { CurrentUserContext } from '../../contexts'
import {
  useFetchDealership,
  useFetchDealershipGroup,
  useFetchManufacturer,
  useFetchWebsite,
} from '../../dataHooks'
import { fetchAnalyticsBlock } from '../api'
import {
  AnalyticsBlock,
  DashboardLevel,
  Dealership,
  DealershipGroup,
  Manufacturer,
  SetState,
  User,
  Website,
} from '../types'

type AnalyticsDashboardContextType = {
  analyticsBlock: AnalyticsBlock
  analyticsBlockLoading: boolean
  canView: boolean
  dashboardLevel: DashboardLevel | undefined
  dashboardLevelLoaded: boolean
  editModeEnabled: boolean
  toggleEditMode: () => void
  currentUser: User
  dealership: Dealership
  website: Website
  manufacturer: Manufacturer
  dealershipGroup: DealershipGroup
  layoutSaved: boolean
  setLayoutSaved: SetState<boolean>
}

export const AnalyticsDashboardContext = createContext<AnalyticsDashboardContextType>({
  editModeEnabled: false,
  toggleEditMode: () => {},
  canView: false,
  dashboardLevel: undefined,
  dashboardLevelLoaded: false,
  analyticsBlockLoading: false,
  analyticsBlock: {
    data: {
      tabs: [],
      config: {},
    },
    id: 0,
    created_at: '',
    updated_at: '',
    created_by: 0,
    dealership_id: 0,
  },
  currentUser: {
    id: 0,
    email: '',
    admin: false,
    name: '',
    position: '',
    slug: '',
    avatar_url: '',
    dealerships: {},
  },
  dealership: {
    id: '',
    name: '',
    slug: '',
  },
  website: {
    id: '',
    name: '',
    slug: '',
  },
  manufacturer: {
    id: '',
    name: '',
    slug: '',
  },
  dealershipGroup: {
    id: '',
    name: '',
    dealerships: [],
  },
  layoutSaved: false,
  setLayoutSaved: () => {},
})

export const AnalyticsDashboardProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [editModeEnabled, setEditModeEnabled] = useState<boolean>(false)
  const [canView, setCanView] = useState<boolean>(false)
  const [layoutSaved, setLayoutSaved] = useState<boolean>(false)
  const currentUser = useContext(CurrentUserContext) as User

  const { dealership, loading: dealershipLoading } = useFetchDealership() as {
    dealership: Dealership
    loading: boolean
  }
  const { website, loading: websiteLoading } = useFetchWebsite() as {
    website: Website
    loading: boolean
  }

  const { manufacturer, loading: manufacturerLoading } = useFetchManufacturer() as {
    manufacturer: Manufacturer
    loading: boolean
  }

  const { dealershipGroup, loading: dealershipGroupLoading } = useFetchDealershipGroup() as {
    dealershipGroup: DealershipGroup
    loading: boolean
  }

  const [analyticsBlock, setAnalyticsBlock] = useState<AnalyticsBlock | null>(null)
  const [analyticsBlockLoading, setAnalyticsBlockLoading] = useState<boolean>(true)

  const { dealershipSlug, websiteSlug, manufacturerSlug, dealershipGroupId } = useParams()

  // Memoize the dashboard level based on the URL params
  // Recalculates only in the slug params change
  const dashboardLevel: DashboardLevel | undefined = useMemo(() => {
    if (manufacturerSlug) return 'Manufacturer'
    if (websiteSlug) return 'Website'
    if (dealershipGroupId) return 'Dealership Group'
    if (dealershipSlug && !websiteSlug) return 'Dealership'
    return undefined
  }, [dealershipSlug, websiteSlug, manufacturerSlug, dealershipGroupId])

  // Memoize if the dashboard level is loaded
  // Recalculates only when the dashboardLevel ot loading states change
  const dashboardLevelLoaded: boolean = useMemo(() => {
    return (
      (dashboardLevel === 'Dealership' && !!dealership && !dealershipLoading) ||
      (dashboardLevel === 'Website' &&
        !!website &&
        !websiteLoading &&
        !!dealership &&
        !dealershipLoading) ||
      (dashboardLevel === 'Manufacturer' && !!manufacturer && !manufacturerLoading) ||
      (dashboardLevel === 'Dealership Group' && !!dealershipGroup && !dealershipGroupLoading)
    )
  }, [
    dashboardLevel,
    dealership,
    dealershipLoading,
    website,
    websiteLoading,
    manufacturer,
    manufacturerLoading,
    dealershipGroup,
    dealershipGroupLoading,
  ])

  // Memoize the dealership comparison
  const dealershipSlugToCompare = useMemo(() => {
    return dealershipSlug || dealership?.slug
  }, [dealershipSlug, dealership])

  // Memoize the current users's permissions
  // Creates a stable reference that only changes when the currentUser changes
  const userPermissions = useMemo(() => {
    if (!currentUser) return { isAdmin: false, dealerships: {}, manufacturers: {} }
    return {
      isAdmin: currentUser.admin,
      dealerships: currentUser.dealerships || {},
      manufacturers: currentUser.manufacturers || {},
    }
  }, [currentUser])

  // Permissions checking useEffect
  // Uses memoized values to avoid unnecessary re-renders
  // Will only re-run when the memoized dependencies change
  useEffect(() => {
    if (dashboardLevelLoaded && currentUser && !dealershipLoading) {
      // Early return if the user is an admin
      if (userPermissions.isAdmin) {
        setCanView(true)
        return
      }

      // Check is user has manager access for the dealership
      const hasManagerAccess = Object.values(userPermissions.dealerships).some(
        (userDealership) =>
          dealershipSlugToCompare === userDealership.slug && userDealership.manager
      )

      // Check if the user has manager access for the dealership group
      const hasManagerAccessForGroup = Object.values(userPermissions.dealerships).some(
        (userDealership) =>
          dealershipGroup?.dealerships.some(
            (groupDealership) =>
              userDealership.slug === groupDealership.slug && userDealership.manager
          )
      )

      // Check if the user has manufacturer level access
      const hasManufacturerAccess =
        userPermissions.manufacturers &&
        Object.values(userPermissions.manufacturers).some(
          (userManufacturer) => manufacturerSlug === userManufacturer.slug
        )

      // Set the canView state based on the permissions
      setCanView(hasManagerAccess || hasManufacturerAccess || hasManagerAccessForGroup)
    }
  }, [
    dashboardLevelLoaded,
    dealershipSlugToCompare,
    dealershipLoading,
    manufacturerSlug,
    userPermissions,
  ])

  useEffect(() => {
    if (!dashboardLevelLoaded || !dashboardLevel || !canView) return

    const fetchAnalyticsBlockData = async () => {
      setAnalyticsBlockLoading(true)
      try {
        const response = await fetchAnalyticsBlock(
          dashboardLevel,
          dashboardLevelLoaded,
          dealership,
          dealershipGroup,
          manufacturer,
          website
        )
        setAnalyticsBlock(response)
      } catch (error) {
        console.error('Error fetching analytics block:', error)
      } finally {
        setAnalyticsBlockLoading(false)
      }
    }
    fetchAnalyticsBlockData()
  }, [dashboardLevel, dashboardLevelLoaded, dealership, manufacturer, website, canView])

  const contextValue = useMemo(
    () => ({
      analyticsBlock,
      analyticsBlockLoading,
      canView,
      dashboardLevel,
      dashboardLevelLoaded,
      editModeEnabled,
      toggleEditMode: () => setEditModeEnabled((prev) => !prev),
      currentUser,
      dealership,
      website,
      manufacturer,
      dealershipGroup,
      layoutSaved,
      setLayoutSaved,
    }),
    [
      analyticsBlock,
      analyticsBlockLoading,
      canView,
      dashboardLevel,
      dashboardLevelLoaded,
      editModeEnabled,
      currentUser,
      dealership,
      website,
      manufacturer,
      dealershipGroup,
      layoutSaved,
      setLayoutSaved,
    ]
  )

  return (
    <AnalyticsDashboardContext.Provider value={contextValue}>
      {children}
    </AnalyticsDashboardContext.Provider>
  )
}

import { useEffect, useState } from 'react'

import { useToggleRefinement } from 'react-instantsearch'
import { useParams } from 'react-router-dom'

function ToggleRefinement(props) {
  const { value, refine } = useToggleRefinement(props)
  const [toggleValue, setToggleValue] = useState(false)
  let { dealershipSlug } = useParams()

  useEffect(() => {
    // Load the saved toggle value from localStorage
    const savedToggle = localStorage.getItem(`toggle_${dealershipSlug}`)
    if (savedToggle !== null) {
      const parsedToggle = JSON.parse(savedToggle)
      if (parsedToggle[props.attribute] !== undefined) {
        setToggleValue(parsedToggle[props.attribute])
      }
    }
  }, [props.attribute])

  useEffect(() => {
    // Retrieve the existing toggle object from localStorage
    const savedToggle = localStorage.getItem(`toggle_${dealershipSlug}`)
    let toggleObject = {}
    if (savedToggle !== null) {
      toggleObject = JSON.parse(savedToggle)
    }
    // Update the toggle object with the new value
    toggleObject[props.attribute] = toggleValue
    // Save the updated toggle object to localStorage
    localStorage.setItem(`toggle_${dealershipSlug}`, JSON.stringify(toggleObject))
  }, [toggleValue, props.attribute])

  return (
    <div className="ais-ToggleRefinement ais-ToggleRefinement--noRefinement">
      <label className="ais-ToggleRefinement-label">
        <input
          className="ais-ToggleRefinement-checkbox"
          type="checkbox"
          checked={value.isRefined}
          onChange={(event) => {
            setToggleValue(!toggleValue)
            refine({ isRefined: toggleValue })
          }}
        />
        <span className="ais-ToggleRefinement-labelText">
          {props.label}
          <span className="ais-RefinementList-count">{value.onFacetValue.count}</span>
        </span>
      </label>
    </div>
  )
}

export default ToggleRefinement

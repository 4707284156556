import { useEffect, useState } from 'react'

import { Dialog } from 'primereact/dialog'
import { useParams } from 'react-router-dom'

import { standardHeaders } from '../entries/utils'
import SortUsers from './SortUsers'

const SortUsersPopup = ({ visible, setVisible }) => {
  let [users, setUsers] = useState([])
  let [updatedUsers, setUpdatedUsers] = useState([])
  let [loadingUsers, setLoadingUsers] = useState(false)
  let [loading, setLoading] = useState(false)
  let { dealershipSlug } = useParams()

  useEffect(() => {
    if (visible) {
      setLoadingUsers(true)
      fetch(`/dealerships/${dealershipSlug}/users.json`)
        .then((res) => res.json())
        .then((data) => {
          setUsers(data)
          setLoadingUsers(false)
        })
    }
  }, [visible])

  const sendUsersToServer = () => {
    if (updatedUsers !== users) {
      fetch(`/dealerships/${dealershipSlug}/users/sort`, {
        method: 'POST',
        headers: standardHeaders,
        body: JSON.stringify({
          user_ids: updatedUsers.map((u) => u.id),
        }),
      }).then(() => window.location.reload())
    }
  }

  return (
    <Dialog
      header="Sort Users"
      visible={visible}
      style={{ width: '50vw' }}
      onHide={() => setVisible(false)}
      dismissableMask={true}
    >
      <div className="row">
        {users && users.length > 0 && (
          <SortUsers users={users} setUpdated={(users) => setUpdatedUsers(users)} />
        )}
      </div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => {
          sendUsersToServer()
        }}
        disabled={loading}
      >
        {loading ? 'Loading...' : 'Save changes'}
      </button>
    </Dialog>
  )
}

export default SortUsersPopup

// MANAGED BY App.js
import { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Message } from 'primereact/message'
import { Panel } from 'primereact/panel'
import { Skeleton } from 'primereact/skeleton'
import { Toast } from 'primereact/toast'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import * as Routes from '../../routes'
import Appointments from '../appointments/Appointments'
import { LeadClusterContext } from '../contexts'
import { useFetchDealership } from '../dataHooks'
import { csrfToken } from '../entries/utils'
import { truncateString } from '../entries/utils'
import LeadPayment from '../leads/LeadPayment'
import PhoneCalls from '../phoneCalls/phoneCalls'
import showToast from '../shared/ShowToast'
import Cars from './Cars'
import EmailConversation from './EmailConversation'
import LeadClusterStateChanges from './LeadClusterStateChanges'
import LeadContainer from './LeadContainer'
import LeadEventsContainer from './LeadEvents'
import ProgressTracker from './ProgressTracker'
import LeadClusterSidebar from './Sidebar'
import SmsConversation from './SmsConversation'

const TabOne = () => {
  return (
    <>
      {/* {lead?.creditScoreRequests.map((creditScoreRequest) => <CreditScoreRequest creditScoreRequest={creditScoreRequest} key={creditScoreRequest.id} />)} */}
      <Appointments />
      <PhoneCalls />
      <LeadEventsContainer />
    </>
  )
}

export const LeadMain = ({ sidebar = true }) => {
  const navigate = useNavigate()
  let { dealershipSlug } = useParams()

  const handleBack = () => {
    // Go back to the previous page
    navigate(-1)
  }
  const { leadCluster, notification } = useContext(LeadClusterContext)

  const [leadClusterFiles, setLeadClusterFiles] = useState(leadCluster.files)

  const firstLead = leadCluster.leads[0]

  const fileInputRef = useRef(null)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [isUploading, setIsUploading] = useState(false)

  const MAX_FILE_SIZE = 50 * 1024 * 1024 // 50MB in bytes

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files)
    const validFiles = []
    let error = ''

    files.forEach((file) => {
      if (file.size > MAX_FILE_SIZE) {
        error = `File ${file.name} exceeds the maximum size of 50MB.`
      } else {
        validFiles.push(file)
      }
    })

    if (error) {
      showToast(notification, 'error', 'Error', error)
    } else {
      setSelectedFiles(validFiles)
    }
  }

  const handleFileUpload = () => {
    if (!selectedFiles || selectedFiles.length === 0) return

    setIsUploading(true)

    const formData = new FormData()
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files[]', selectedFiles[i])
    }

    async function uploadFile(leadCluster, formData) {
      try {
        const response = await fetch(
          Routes.file_upload_dealership_lead_cluster_path(
            leadCluster.dealership_id,
            leadCluster.id
          ),
          {
            method: 'POST',
            headers: {
              'X-CSRF-Token': csrfToken,
            },
            body: formData,
          }
        )

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json()
        return data
      } catch (error) {
        throw error
      }
    }

    uploadFile(leadCluster, formData)
      .then((data) => {
        showToast(notification, 'success', 'Success', 'File uploaded successfully.')
        setLeadClusterFiles(data.files)
        setSelectedFiles([])
        fileInputRef.current.value = ''
      })
      .catch(() => {
        showToast(notification, 'success', 'Error', 'File upload failed.')
      })
      .finally(() => {
        setIsUploading(false)
      })
  }

  const handleFileDelete = async (fileId) => {
    async function deleteFile(leadCluster, fileId) {
      try {
        const response = await fetch(
          Routes.file_delete_dealership_lead_cluster_path(
            leadCluster.dealership_id,
            leadCluster.id
          ),
          {
            method: 'DELETE',
            headers: {
              'X-CSRF-Token': csrfToken,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ file_id: fileId }),
          }
        )

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json()
        return data
      } catch (error) {
        throw error
      }
    }

    deleteFile(leadCluster, fileId)
      .then((data) => {
        showToast(notification, 'success', 'Success', 'File deleted successfully.')
        setLeadClusterFiles(data.files)
      })
      .catch((error) => {
        showToast(notification, 'error', 'Error', 'File delete failed.')
      })
  }

  const truncateFilename = (filename) => {
    const extension = filename.split('.').pop()
    const nameWithoutExtension = filename.slice(0, -(extension.length + 1))
    const truncatedName = truncateString(nameWithoutExtension, 32)

    return `${truncatedName + extension}`
  }

  const textsCount = leadCluster.texts?.filter((t) => t.id !== 'openingMessage').length

  return (
    <>
      {sidebar ? (
        <div className="px-3 pt-1">
          <button
            className="btn btn-outline-secondary btn-block-md-down mr-auto mb-2"
            onClick={handleBack}
          >
            <i className="fas fa-arrow-left mr-2" /> Back
          </button>
        </div>
      ) : (
        <div className="px-3 pt-1">
          <a
            className="btn btn-outline-primary btn-block mb-2"
            href={`/dealerships/${dealershipSlug}/lead_clusters/${leadCluster.id}`}
            target="_blank"
          >
            <i className="fa fa-external-link mr-1"></i>
            Open Lead
          </a>
        </div>
      )}
      {leadCluster.requires_approval && (
        <div className="mb-2 px-3 w-100">
          <Message
            className="w-100"
            severity="warn"
            text={`Lead requires manager approval to move to status "${leadCluster.lead_state}"`}
          />
        </div>
      )}
      <ProgressTracker />
      <Tabs>
        <div className="px-3 py-1">
          <div className="rounded border overflow-hidden lead-tabs">
            <TabList>
              <div className="d-flex">
                <Tab>Lead Events</Tab>
                <Tab>
                  <div id="sms-conversation-button">SMS Conversation ({textsCount})</div>
                </Tab>
                <Tab>
                  <div id="email-conversation-button">Email Conversation</div>
                </Tab>
              </div>
            </TabList>
          </div>
        </div>
        <TabPanel>
          <div className="px-3">
            {leadCluster.payments.map((payment) => (
              <LeadPayment payment={payment} key={payment.id} />
            ))}
            <Cars />
            {leadCluster.state_changes.length > 0 && (
              <LeadClusterStateChanges stateChanges={leadCluster.state_changes} />
            )}
            {firstLead?.visited_pages?.length > 0 && (
              <p className="small mb-1 mt-2 text-secondary text-center">
                {leadCluster?.contact.first_name} visited {firstLead.visited_pages.length} pages
                before submitting first lead
              </p>
            )}
          </div>
          <div className="px-3 py-2">
            <Panel header={`Leads (${leadCluster.leads.length})`} toggleable>
              {leadCluster.leads.map((lead, i) => (
                <LeadContainer lead={lead} key={lead.id} position={i} />
              ))}
            </Panel>
          </div>
          <div className="px-3 py-2">
            <Panel
              header={`File Uploads (${leadClusterFiles.length})`}
              toggleable
              collapsed={leadClusterFiles.length === 0}
            >
              {leadClusterFiles.map((file) => (
                <div
                  key={file.id}
                  className="d-flex justify-content-between align-items-center mb-2"
                >
                  <a href={file.url} className="text-primary" target="_blank" download>
                    {truncateFilename(file.filename)}
                  </a>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleFileDelete(file.id)}
                  >
                    Delete
                  </button>
                </div>
              ))}

              <div className="mt-2">
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  multiple
                  accept=".csv,.pdf,.txt,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg"
                />
                <button
                  className="btn btn-primary"
                  disabled={selectedFiles.length === 0 || isUploading}
                  onClick={handleFileUpload}
                >
                  {isUploading ? (
                    <span>
                      <i className="fa fa-spinner fa-spin mr-1"></i>Uploading...
                    </span>
                  ) : (
                    'Upload File'
                  )}
                </button>
              </div>
            </Panel>
          </div>
          <TabOne />
        </TabPanel>
        <TabPanel>
          <SmsConversation />
        </TabPanel>
        <TabPanel>
          <EmailConversation />
        </TabPanel>
      </Tabs>
    </>
  )
}

const Show = ({ defaultLeadClusterId, sidebar = true, dealershipSlug }) => {
  const { leadClusterId } = useParams()
  const notification = useRef(null)
  const { dealership } = useFetchDealership()

  const clusterId = leadClusterId || defaultLeadClusterId

  const location = useLocation()
  const locationState = location.state

  const [status, setStatus] = useState('loading')

  // Always call useState and useContext unconditionally
  const [localLeadCluster, setLocalLeadCluster] = useState(locationState)
  const leadClusterContext = useContext(LeadClusterContext)

  // Decide which leadCluster and setLeadCluster to use based on 'sidebar'
  const leadCluster = sidebar ? localLeadCluster : leadClusterContext.leadCluster
  const setLeadCluster = sidebar ? setLocalLeadCluster : leadClusterContext.setLeadCluster

  useEffect(() => {
    if (status !== 'completed' && dealership?.slug) {
      fetch(`/dealerships/${dealershipSlug || dealership.slug}/lead_clusters/${clusterId}.json`)
        .then((response) => {
          if (!response.ok) {
            if (response.status === 404) {
              throw new Error('Not Found')
            } else {
              throw new Error('An error occurred')
            }
          }
          return response.json()
        })
        .then((data) => {
          setLeadCluster(data)
          setStatus('completed')
        })
        .catch((error) => {
          if (error.message === 'Not Found') {
            setStatus('not_found')
          } else {
            setStatus('error')
          }
        })
    }
  }, [dealership?.slug])

  useEffect(() => {
    document.body.scrollTo(0, 0)
  }, [])

  const contextValue = useMemo(
    () => ({ leadCluster, setLeadCluster, notification }),
    [leadCluster, setLeadCluster, notification]
  )

  if (status === 'not_found') {
    return (
      <div className="text-center mt-5 text-danger">
        <h2>Lead Cluster Not Found</h2>
      </div>
    )
  }

  const Content = (
    <div className="w-100">
      <div className="row no-gutters h-100">
        <div className={sidebar ? 'col-md-9' : 'col-md-12'}>
          <div className="py-3">
            {status === 'completed' ? (
              <LeadMain sidebar={sidebar} />
            ) : (
              <div className="px-3 pt-1 py-3">
                <Skeleton width="10rem" className="mb-2 w-100" height="10rem" />
                <Skeleton width="10rem" className="mb-2 w-100" height="10rem" />
                <Skeleton width="10rem" className="mb-2 w-100" height="10rem" />
              </div>
            )}
          </div>
        </div>
        <Toast ref={notification} />
        {sidebar && <LeadClusterSidebar status={status} notification={notification} />}
      </div>
    </div>
  )

  // Wrap in LeadClusterContext.Provider if sidebar is false
  // Otherwise we end up in a situation where we have LeadClusterContext.Provider twice (nested)
  // And when we call setLeadCluster from the grandchild it does not update the grandfather
  return sidebar ? (
    <LeadClusterContext.Provider value={contextValue}>{Content}</LeadClusterContext.Provider>
  ) : (
    Content
  )
}

export default Show

import LoadingBoxes from '../../../../entries/LoadingBoxes'
import { useAnalyticsDashboard, usePreviewData } from '../../../contexts/hooks'
import { useViewportData } from '../../../hooks'
import { ModuleProps } from '../../../types'
import { getColor, isEmptyObject } from '../../../utils'

type Campaign = {
  campaign_name: string
  clicks: string
  spend: string
  date_start: string
  date_stop: string
  leads: string
  cost_per_lead: string
  leads_difference: string
  cost_per_lead_difference: string
  clicks_difference: string
  spend_difference: string
}

const EngagementByCampaign: React.FC<ModuleProps> = ({ item, timeRange, selectedLocation }) => {
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)
  const { editingPreviewData } = usePreviewData()
  const { editModeEnabled } = useAnalyticsDashboard()

  function format(value: string, currency: boolean = false): string {
    // Convert string to number
    const parsedValue = parseFloat(value)
    // If value is not a number, return empty string
    if (isNaN(parsedValue)) {
      return ''
    }
    // If value is negative, return formatted string with negative sign
    if (parsedValue < 0) {
      if (currency) {
        const stringValue = parsedValue.toFixed(2).toString()
        return '-$' + stringValue.slice(1)
      } else {
        return parsedValue.toString()
      }
      // If value is positive, return formatted string with positive sign
    } else if (parsedValue > 0) {
      if (currency) {
        return '+$' + parsedValue.toFixed(2).toString()
      } else {
        return '+' + parsedValue.toString()
      }
    }
  }

  // TODO: Improve this logic or maybe move to BE
  // Show preview data first, if it exists and isn't empty. Then check is data exists and isn't empty. If they are empty set to []
  const notEmptyPreviewData = isEmptyObject(editingPreviewData?.[item?.module])
    ? []
    : editingPreviewData?.[item?.module]

  const notEmptyData = isEmptyObject(data?.[item?.module]) ? [] : data?.[item?.module]

  const dataToShow =
    editModeEnabled && editingPreviewData?.[item?.module] ? notEmptyPreviewData : notEmptyData

  return (
    <div ref={viewportRef}>
      {!loading && dataToShow ? (
        <div>
          {/* table for displaying campaign data */}
          <table className="table table-sm table-striped mt-3" style={{ fontSize: '15px' }}>
            <thead>
              <tr className="text-center">
                <th className="text-left">Campaign Name</th>
                <th>Clicks</th>
                <th>Total Leads</th>
                <th>Cost Per Lead</th>
                <th>Amount spent</th>
              </tr>
            </thead>
            <tbody>
              {dataToShow?.map((campaign: Campaign) => {
                if (!campaign) return null
                const {
                  campaign_name: name,
                  clicks,
                  leads,
                  leads_difference: leadDiff,
                  cost_per_lead: cpl,
                  cost_per_lead_difference: cplDiff,
                  spend,
                  clicks_difference: clicksDiff,
                  spend_difference: spendDiff,
                } = campaign
                return (
                  <tr key={name}>
                    <td>{name}</td>
                    <td className="text-center">
                      <b>{clicks}</b>
                      <br />
                      <small className={getColor(clicksDiff)}>{format(clicksDiff)}</small>
                    </td>
                    <td className="text-center">
                      <b>{leads}</b>
                      <br />
                      <small className={getColor(leadDiff)}>{format(leadDiff)}</small>
                    </td>
                    <td className="text-center">
                      <b>
                        {parseFloat(cpl).toFixed(2) !== 'NaN'
                          ? `$${parseFloat(cpl).toFixed(2)}`
                          : null}
                      </b>
                      <br />
                      <small className={getColor(cplDiff, true)}>{format(cplDiff, true)}</small>
                    </td>
                    <td className="text-center">
                      <b>
                        {parseFloat(spend).toFixed(2) !== 'NaN'
                          ? `$${parseFloat(spend).toFixed(2)}`
                          : null}
                      </b>
                      <br />
                      <small className={getColor(spendDiff, true)}>{format(spendDiff, true)}</small>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <LoadingBoxes />
      )}
    </div>
  )
}

export default EngagementByCampaign

import React from 'react'

import { Tooltip } from 'react-tooltip'

const ItemStatus = (blog) => {
  const visible = blog.status === 'Visible'
  return (
    <td>
      <div className={visible ? 'text-success' : 'text-danger'} id={`status-${blog.id}`}>
        <i className="fa fa-info-circle mr-1"></i>
        {blog.status}
      </div>
      <Tooltip anchorSelect={`#status-${blog.id}`} place="bottom" style={{ zIndex: 10000 }}>
        {visible
          ? 'Visible indicates that the item is shown on the live site.'
          : 'Hidden indicates that the item is not currently shown on the live site.'}
      </Tooltip>
    </td>
  )
}

export default ItemStatus

import { useEffect, useRef, useState } from 'react'

import { Toast } from 'primereact/toast'

import showToast from '../../../../shared/ShowToast'
import { Exit, Save } from '../../../components/Icons'
import { useAnalyticsDashboard, useConfig, useItems } from '../../../contexts/hooks'
import { useSetModuleLayouts } from '../../../hooks'

const EditMode: React.FC = ({}) => {
  const [sidebarWidth, setSidebarWidth] = useState(0)
  const { editModeEnabled, toggleEditMode, setLayoutSaved } = useAnalyticsDashboard()
  const { config } = useConfig()
  const { handleSetPreviousItems } = useItems()
  const { setModuleLayouts, loading } = useSetModuleLayouts()

  async function handleSaveLayout(): Promise<void> {
    toggleEditMode()
    await setModuleLayouts()
    setLayoutSaved(!loading)
    handleNotificationEvent('save')
  }

  function handleExitWithoutSaving(): void {
    toggleEditMode()
    handleSetPreviousItems()
    handleNotificationEvent('exit')
  }

  // Dynamically gets the sidebar width, so the edit mode buttons can be centered
  const sidebar = document.getElementById('sidebar')
  useEffect(() => {
    if (sidebar) {
      const handleResize = () => {
        setSidebarWidth(sidebar.offsetWidth)
      }

      const resizeObserver = new ResizeObserver(handleResize)
      resizeObserver.observe(sidebar)
      setSidebarWidth(sidebar.offsetWidth)

      return () => {
        resizeObserver.unobserve(sidebar)
      }
    }
  }, [sidebar])

  const notification = useRef(null)

  const handleNotificationEvent = (type: string) => {
    if (type === 'save') {
      showToast(notification, 'success', 'Layout Saved', 'Your layout has been saved successfully')
    } else if (type === 'exit') {
      showToast(
        notification,
        'info',
        'Layout Reset',
        'Your layout has been reset to the previous layout'
      )
    } else if (type === 'default') {
      showToast(
        notification,
        'info',
        'Default Layout',
        'Your layout has been reset to the default layout'
      )
    }
  }

  return (
    <>
      <Toast ref={notification} />

      <div
        className={
          'EditModeButton btn px-4 btn-primary' +
          (editModeEnabled || config?.fixedMode ? ' d-none' : ' d-block')
        }
        onClick={toggleEditMode}
      >
        <i className="fas fa-edit mr-2"></i>Enter Edit Mode
      </div>
      <div>
        <div
          className={editModeEnabled ? ' d-flex position-fixed' : ' d-none'}
          style={
            editModeEnabled
              ? {
                  zIndex: '100',
                  width: `calc(100% - ${sidebarWidth}px)`,
                  left: `${sidebarWidth}px`,
                  height: '90px',
                  bottom: '0%',
                  marginBottom: `20px`,
                }
              : null
          }
        >
          <div
            style={
              editModeEnabled
                ? {
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                    margin: 'auto',
                    backgroundColor: 'rgba(245, 247, 251, 0.85)',
                    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
                    borderRadius: '10px',
                  }
                : null
            }
            className="edit-mode-bar d-flex"
          >
            <button
              className={'btn btn-success edit-btn SaveLayoutButton'}
              onClick={handleSaveLayout}
            >
              Save Layout <Save />
            </button>
            <button
              className={'btn btn-danger edit-btn ExitWithoutSavingButton'}
              onClick={handleExitWithoutSaving}
            >
              Exit Without Saving <Exit />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditMode

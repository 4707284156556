import { useEffect, useState } from 'react'

import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { MultiSelect } from 'primereact/multiselect'
import { useHits, useInstantSearch } from 'react-instantsearch'
import { Link, useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { humanize, statusClassNames } from '../entries/utils'

const getFieldValue = (hit, field) => {
  switch (field) {
    case 'created_at':
      return (
        <div>
          <a href={`/valuations/${hit.id}`}>{hit.category}</a>
          <small className="text-secondary">{moment(hit[field]).fromNow()}</small>
        </div>
      )
    case 'vehicle':
      return `${hit.year} ${hit.make} ${hit.model} ${hit.badge} ${hit.series}`
    case 'kms':
    case 'retail_price':
    case 'trade_price':
      return hit[field]?.toLocaleString()
    case 'price_adjustment':
      return hit[field]?.toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        maximumFractionDigits: 0,
      })
    case 'offer_price':
      return hit[field] ? (
        <span className="text-success">${hit[field]?.toLocaleString() || ''}</span>
      ) : (
        ''
      )
    case 'lead':
      return (
        hit[field] && (
          <>
            <div>
              {hit.lead?.lead_cluster_id && (
                <Link
                  to={Routes.dealership_lead_cluster_path(
                    hit.dealership_slug,
                    hit.lead?.lead_cluster_id
                  )}
                >
                  {hit[field]?.name}
                </Link>
              )}
            </div>
            {hit[field]?.lead_state && (
              <div
                className={`badge badge-sm mt-1 badge-${statusClassNames[hit[field]?.lead_state]}`}
              >
                {humanize(hit[field].lead_state)}
              </div>
            )}
          </>
        )
      )
    case 'offer_accepted':
      return hit[field] ? <i className="fa fa-check text-success"></i> : ''
    case 'location_name':
    case 'for_sale':
    default:
      return hit[field]
  }
}

const Hits = ({ hits }) => {
  let { dealershipSlug } = useParams()
  const { status } = useInstantSearch()
  const [columns, setColumns] = useState([
    { field: 'created_at', header: 'Created At', priority: 0 },
    { field: 'provider', header: 'Provider', priority: 1 },
    { field: 'location_name', header: 'Location', priority: 2 },
    { field: 'vehicle', header: 'Vehicle', priority: 3 },
    { field: 'kms', header: 'KMs', priority: 4 },
    { field: 'retail_price', header: 'Retail Price', priority: 5 },
    { field: 'trade_price', header: 'Trade Price', priority: 6 },
    { field: 'price_adjustment', header: 'Price Adjustment', priority: 7 },
    { field: 'offer_price', header: 'Offer Price', sortable: true, priority: 8 },
    { field: 'lead', header: 'Lead', priority: 9 },
    { field: 'offer_accepted', header: 'Offer Accepted', sortable: true, priority: 10 },
  ])
  const [columnsVisibility, setColumnsVisibility] = useState([
    { field: 'for_sale', header: 'For Sale', sortable: true, priority: 7 },
  ])

  const onColumnToggle = (event) => {
    let selectedColumns = event.value
    let orderedSelectedColumns = columnsVisibility.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    )

    setVisibleColumns(orderedSelectedColumns)
    setTotalCol([...columns, ...orderedSelectedColumns])
  }
  const [visibleColumns, setVisibleColumns] = useState([])

  useEffect(() => {
    const valuationsVisibleColumns = localStorage.getItem(
      `visibleColumns-valuations-${dealershipSlug}`
    )

    if (valuationsVisibleColumns) {
      const orderedSelectedColumns = columnsVisibility.filter((col) =>
        JSON.parse(valuationsVisibleColumns).some((sCol) => sCol.field === col.field)
      )
      setVisibleColumns(orderedSelectedColumns)
      setTotalCol([...columns, ...orderedSelectedColumns])
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(
      `visibleColumns-valuations-${dealershipSlug}`,
      JSON.stringify(visibleColumns)
    )
  }, [visibleColumns])

  const header = (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
      <div className="text-center">More Columns: </div>
      <MultiSelect
        value={visibleColumns}
        options={columnsVisibility}
        optionLabel="header"
        onChange={onColumnToggle}
        className="w-full sm:w-40rem"
        display="chip"
        style={{ width: '30%', marginLeft: '10px' }}
      />
    </div>
  )
  const [totalCol, setTotalCol] = useState([...columns, ...visibleColumns])
  return (
    <div className="card">
      <DataTable
        value={hits}
        header={header}
        tableStyle={{ minWidth: '50rem' }}
        stateStorage="local"
        stateKey={`dt-valuations-${dealershipSlug}`}
        loading={status === 'stalled'}
      >
        {totalCol
          .sort((a, b) => a.priority - b.priority)
          .map((col) => (
            <Column
              key={col.field}
              field={col.field}
              header={col.header}
              sortable={col.sortable !== undefined ? col.sortable : false}
              body={(rowData) => getFieldValue(rowData, col.field)}
            />
          ))}
      </DataTable>
    </div>
  )
}

const CustomHitsNew = connectHits(Hits)

export default CustomHitsNew

// TODO (Codemod generated): ensure your usage correctly maps the props from the connector to the hook
function connectHits(Component) {
  const Hits = (props) => {
    const data = useHits(props)

    return <Component {...props} {...data} />
  }

  return Hits
}

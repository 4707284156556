import { useParams } from 'react-router-dom'

import Loading from '../../Loading'
import { fetchOnClient, useQuery } from '../../tanstackPlaceholderUtils'
import {
  SpecificationCategoriesForm,
  SpecificationCategoriesFormData,
} from './SpecificationCategoriesForm'

const SpecificationCategoriesPage = () => {
  const { manufacturerSlug } = useParams()
  const { data, loading, error } = useQuery<SpecificationCategoriesFormData>({
    query: fetchOnClient,
    queryKey: `/manufacturers/${manufacturerSlug}/model_specification_categories.json`,
  })
  if (loading) {
    return (
      <div className="p-3">
        <Loading />
      </div>
    )
  }
  if (error) {
    return (
      <div className="p-3">
        <h2>An error occurred</h2>
        <p className="text-danger">{error?.message}</p>
      </div>
    )
  }
  return (
    <div className="w-100 p-3">
      <div className="container">
        <h4>Specification Categories</h4>
        <p>
          Add custom specification categories for the manufacturer's model specification tables.
        </p>
        <div className="row my-5">
          <div style={{ maxWidth: '1200px' }} className="col-12">
            <SpecificationCategoriesForm manufacturerSlug={manufacturerSlug} defaultValues={data} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpecificationCategoriesPage

import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { TimeRange } from '../AnalyticsDashboard/types'
import { fetchResultData } from '../AnalyticsDashboard/utils'
import TimeRangePicker from '../entries/TimeRange'
import { showLoading } from '../entries/utils'
import ChartSection from './components/ChartSection'
import DataAccuracy from './components/DataAccuracy'
import RecentLeads from './components/RecentLeads'

const MarketingAnalytics: React.FC = () => {
  const { dealershipSlug } = useParams()

  const [loading, setLoading] = useState<boolean>(true)
  const [marketingData, setMarketingData] = useState<any>(null)
  const [timeRange, setTimeRange] = useState<TimeRange>({
    start: 0,
    end: 0,
    timeframe: '',
    type: '',
  })

  const subText =
    'Only shows website leads - All other lead sources are excluded (eg. Chatbot, Carsales etc.)'

  useEffect(() => {
    if (!dealershipSlug) return

    const fetchData = async () => {
      setLoading(true)
      try {
        const queryString = new URLSearchParams(timeRange as any).toString()
        const URL = `${Routes.data_dealership_leads_path(dealershipSlug)}.json?${queryString}`
        const data = await fetchResultData(URL)
        setMarketingData(data)
      } catch (err) {
        setLoading(false)
        throw err
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [dealershipSlug, timeRange]) // Dependency on both dealershipSlug and timeRange

  const handleTimeRangeChange = (dateRange: TimeRange): void => {
    setTimeRange(dateRange)
  }

  return (
    <div className="w-100">
      <div className="p-4">
        <div className="row">
          <h4 className="col-6">Marketing Analytics</h4>
          <div className="col-6 d-flex justify-content-end">
            <TimeRangePicker handleUpdateTimeRange={handleTimeRangeChange} />
          </div>
        </div>
      </div>

      {marketingData && !loading ? (
        <div>
          <div className="row">
            <div className="col-md-6">
              <ChartSection
                title="Marketing Source"
                subtitle={marketingData?.timeframe?.name || 'Last 30 days'}
                chartData={marketingData?.marketing_performance?.data}
                chartType="Pie"
                details={marketingData?.marketing_performance?.details}
                subText={subText}
              />
              <RecentLeads
                leadsBySource={marketingData?.recent_leads}
                dealershipSlug={dealershipSlug}
              />
            </div>
            <div className="col-md-6">
              <ChartSection
                title="Device"
                subtitle={marketingData?.timeframe?.name || 'Last 30 days'}
                chartData={marketingData?.device_performance?.data}
                chartType="Pie"
                details={marketingData?.device_performance?.details}
                subText={subText}
              />
              <ChartSection
                title="Leads By Source"
                subtitle={marketingData?.timeframe?.name || 'Last 30 days'}
                chartData={marketingData?.leads_by_source}
                chartType="Pie"
              />
            </div>
          </div>
          <DataAccuracy />
        </div>
      ) : (
        showLoading()
      )}
    </div>
  )
}

export default MarketingAnalytics

import React, { ReactNode, createContext, useEffect, useState } from 'react'

import { AnalyticsBlockConfig } from '../types'
import { isEmptyObject } from '../utils'
import { useAnalyticsDashboard } from './hooks'

type ConfigContextType = {
  config: AnalyticsBlockConfig
  handleUpdateConfig: (newConfig: AnalyticsBlockConfig) => void
}

export const ConfigContext = createContext<ConfigContextType>({
  config: {} as AnalyticsBlockConfig,
  handleUpdateConfig: () => {},
})

export const ConfigProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { analyticsBlock } = useAnalyticsDashboard()
  const [config, setConfig] = useState<AnalyticsBlockConfig>(
    !isEmptyObject(analyticsBlock?.data?.config) && analyticsBlock?.data?.config
      ? analyticsBlock.data.config
      : { layoutSize: 'large', defaultTimeRangeOverride: [] }
  )

  const handleUpdateConfig = (newConfig: AnalyticsBlockConfig) => {
    setConfig((prevConfig) => ({ ...prevConfig, ...newConfig }))
  }

  useEffect(() => {
    if (config && !config.layoutSize) {
      handleUpdateConfig({ layoutSize: 'medium' })
    }
  }, [])

  useEffect(() => {
    if (analyticsBlock?.data?.config) {
      handleUpdateConfig(analyticsBlock.data.config)
    }
  }, [analyticsBlock])

  return (
    <ConfigContext.Provider value={{ config, handleUpdateConfig }}>
      {children}
    </ConfigContext.Provider>
  )
}

import { useContext, useEffect, useState } from 'react'

import moment from 'moment'
import { Panel } from 'primereact/panel'

import { CarContext } from '../contexts'
import { humanize } from '../entries/utils'
import { standardHeaders } from '../entries/utils'
import { UserDateTime } from '../shared/FormattedDateTime'
import SlideIn from '../shared/SlideIn'

const CarExport = ({ carExport }) => {
  return (
    <div className="mb-2">
      <Panel
        toggleable
        header={`Feed: ${carExport.feed.name} - ${carExport.feed.schema && carExport.feed.schema} (${moment(carExport.created_at).fromNow()})`}
        collapsed={true}
      >
        <div>
          <b>Status:</b> {humanize(carExport.status)}
        </div>
        <b>Created:</b> {UserDateTime({ timestamp: carExport.created_at }).formatedDateTime}
        <div dangerouslySetInnerHTML={{ __html: carExport.log }}></div>
      </Panel>
    </div>
  )
}

const PhotoExports = () => {
  let { car } = useContext(CarContext)
  let [carExports, setCarExports] = useState([])
  let [visible, setVisible] = useState(false)
  let [loadingData, setLoadingData] = useState(false)
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!visible) return

    loadData()
  }, [car, visible])

  const reexportPhotos = () => {
    setLoading(true)
    fetch(`/cars/${car.id}/export_photos`, {
      method: 'POST',
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false)
      })
  }

  const loadData = () => {
    setLoadingData(true)
    setCarExports([])
    fetch(`/cars/${car.id}/car_exports.json`)
      .then((res) => res.json())
      .then((data) => {
        setCarExports(data)
        setLoadingData(false)
      })
  }

  return (
    <>
      <SlideIn header="FTP Photo Exports" isOpen={visible} setIsOpen={setVisible}>
        <div className="p-3">
          <div className="mb-3">
            <p>Advanced Use Only. This is a log for photos exported by FTP to third parties.</p>
            <div className="btn btn-outline-primary mr-2" onClick={reexportPhotos}>
              {loading ? <i className="fa fa-spinner fa-spin mr-1"></i> : <>Reexport all</>}
            </div>
            <div className="btn btn-outline-primary" onClick={loadData}>
              {loadingData ? <i className="fa fa-spinner fa-spin mr-1"></i> : <>Refresh</>}
            </div>
          </div>
          {carExports.map((carExport) => (
            <CarExport key={carExport.id} carExport={carExport} />
          ))}
        </div>
      </SlideIn>
      <a
        href={'#'}
        className="dropdown-item"
        onClick={(e) => {
          e.preventDefault()
          setVisible(true)
        }}
      >
        <i className="fa fa-upload mr-1"></i>
        Photo Exports
      </a>
    </>
  )
}

export default PhotoExports

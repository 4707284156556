import React from 'react'

import { ChartDataset } from 'chart.js'

import { BarChart, PieChart } from '../../AnalyticsDashboard/features/Charts/components/Charts'
import { ChartDataItem } from '../../AnalyticsDashboard/features/Charts/types'
import { generateChartDataConfig } from '../../AnalyticsDashboard/features/Charts/utils'
import { ChartType } from '../../AnalyticsDashboard/types'
import { defaultResponsiveLayoutSizeStyle } from '../../AnalyticsDashboard/utils'

interface ChartSectionProps {
  title: string
  subtitle?: string
  chartData: ChartDataItem[]
  chartType: ChartType
  details?: { name: string; performance: string; industry_average: string }[]
  subText?: React.ReactNode
}

const ChartSection: React.FC<ChartSectionProps> = ({
  title,
  subtitle,
  chartData,
  chartType,
  details,
  subText,
}) => {
  const chartOptionsConfig = ({ chartType }: any) => {
    if (chartType === 'Pie') {
      return {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              generateLabels: (chart) => {
                const datasets = chart.data.datasets as ChartDataset<'pie'>[]
                return datasets[0].data.map((data, i) => ({
                  text: `${chart?.data?.labels[i]}(${data})`,
                  fillStyle: datasets[0].backgroundColor[i] as string,
                  lineWidth: 0,
                }))
              },
              padding: 5,
              boxWidth: 10,
            },
          },
        },
      }
    } else if (chartType === 'Bar') {
      return {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      }
    }
    return {}
  }

  return (
    <div className="box position-relative mb-3 p-3 mt-3">
      <div className="d-flex justify-content-between align-items-center">
        <h4>{title}</h4>
        {subtitle && <small className="text-secondary">{subtitle}</small>}
      </div>
      <div className="position-relative">
        {chartType === 'Pie' && (
          <PieChart
            chartDataConfig={generateChartDataConfig({ chartData, chartType })}
            chartOptionsConfig={chartOptionsConfig({ chartType })}
            responsiveLayoutSizeStyles={defaultResponsiveLayoutSizeStyle}
          />
        )}
        {chartType === 'Bar' && (
          <BarChart
            chartDataConfig={generateChartDataConfig({ chartData, chartType })}
            chartOptionsConfig={chartOptionsConfig({ chartType })}
            responsiveLayoutSizeStyles={defaultResponsiveLayoutSizeStyle}
          />
        )}
        {subText}
        {details?.length > 0 && (
          <table className="table table-sm mt-3">
            <thead>
              <tr>
                <th>Source</th>
                <th>Your Performance</th>
                <th>Industry AVG</th>
              </tr>
            </thead>
            <tbody>
              {details.map((source, index) => (
                <tr key={`source-${index}`}>
                  <td>{source.name}</td>
                  <td>{source.performance}</td>
                  <td>{source.industry_average}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

export default ChartSection

import { useContext } from 'react'

import { LeadClusterContext } from '../contexts'

const LeadSource = ({ fallbackSource }) => {
  const { leadCluster } = useContext(LeadClusterContext)

  let source = leadCluster?.source || fallbackSource
  if (!source) return <></>
  return (
    <>
      {source === 'Autotrader' && (
        <div className="badge badge-success badge-autotrader mr-1">Autotrader</div>
      )}
      {source === 'Carsales' && (
        <div className="badge badge-success badge-carsales mr-1">Carsales</div>
      )}
      {source === 'Facebook' && (
        <div className="badge badge-success badge-facebook mr-1">Facebook</div>
      )}
      {source === 'Chatbot' && (
        <div className="badge badge-success badge-chatbot mr-1">Chatbot</div>
      )}
      {source === 'Website' && <div className="badge badge-success mr-1">Website</div>}
      {source === 'Drive' && <div className="badge badge-success badge-drive mr-1">Drive</div>}
      {source === 'Facebook Marketplace' && (
        <div className="badge badge-success badge-facebook mr-1">FB Marketplace</div>
      )}
      {source === 'Walk In' && <div className="badge badge-success mr-1">Walk In</div>}
      {source === 'Phone Call' && <div className="badge badge-success mr-1">Phone Call</div>}
      {source === 'Manufacturer' && <div className="badge badge-success mr-1">OEM</div>}
      {leadCluster?.has_payments && (
        <div className="badge badge-success badge-payment mr-1">Deposit</div>
      )}
    </>
  )
}

export default LeadSource

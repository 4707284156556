import { useEffect, useState } from 'react'

import { formatDistanceToNow } from 'date-fns'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Link, useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'

const CareersListing = () => {
  let [careers, setCareers] = useState([])
  let [loading, setLoading] = useState(true)
  let { dealershipSlug } = useParams()

  useEffect(() => {
    setLoading(true)
    fetch(Routes.dealership_careers_path(dealershipSlug), {
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then((data) => {
        setCareers(data)
        setLoading(false)
      })
  }, [])

  return (
    <div>
      <h1>Listing Careers</h1>
      <div className="box">
        <DataTable value={careers} loading={loading}>
          <Column
            field="created_at"
            header="Created"
            body={(rowData) => moment(rowData.created_at).fromNow()}
          />
          <Column field="title" header="Title" />
          <Column
            body={(rowData) => (
              <a
                href={Routes.dealership_career_path(dealershipSlug, rowData.id)}
                className="btn btn-link"
              >
                Show
              </a>
            )}
          />
          <Column
            body={(rowData) => (
              <a
                href={Routes.edit_dealership_career_path(dealershipSlug, rowData.id)}
                className="btn btn-link"
              >
                Edit
              </a>
            )}
          />
          <Column
            body={(rowData) => (
              <a
                href={Routes.dealership_career_path(dealershipSlug, rowData.id)}
                className="btn btn-link text-danger"
                data-method="delete"
                onClick={(e) => {
                  if (!window.confirm('Are you sure?')) e.preventDefault()
                }}
              >
                Destroy
              </a>
            )}
          />
          <Column
            body={(rowData) =>
              rowData.url ? (
                <Link
                  to={rowData.url}
                  className="btn btn-outline-success btn-sm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Live
                </Link>
              ) : null
            }
          />
        </DataTable>
      </div>
      <div className="mt-3">
        <a href={Routes.new_dealership_career_path(dealershipSlug)} className="btn btn-success">
          New Career
        </a>
      </div>
    </div>
  )
}

export default CareersListing

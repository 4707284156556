import { useCallback, useEffect, useRef, useState } from 'react'

import {
  DndContext,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'

import LoadingBoxes from '../../../../entries/LoadingBoxes'
import { useAnalyticsDashboard, useConfig, useItems } from '../../../contexts/hooks'
import { useWindowWidth } from '../../../hooks'
import type { ModuleItem } from '../../../types'
import BlankModule from './BlankModule'
import Item from './Item'
import SortableItem from './SortableItem'

const DragAndDrop: React.FC<{}> = () => {
  const { config } = useConfig()
  const { editModeEnabled } = useAnalyticsDashboard()
  const { items, setItems, handleDrop } = useItems()
  const windowWidth = useWindowWidth()
  const [activeId, setActiveId] = useState<string | null>(null)
  const [rowWidth, setRowWidth] = useState<number | null>(null)
  const rowRef = useRef<HTMLDivElement>(null)
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  // Sets the activeId state when a drag starts
  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id as string) // Should always be a string // item.module
  }, [])

  // Updates the items state when a drag is over another item
  const handleDragOver = useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event

      // If the active and over ids are different, update the items state
      if (over && active.id !== over?.id) {
        setItems((items) => {
          const oldIndex = items.findIndex((item) => item.module === active.id)
          const newIndex = items.findIndex((item) => item.module === over?.id)

          const updatedItems = arrayMove(items, oldIndex, newIndex)

          // Sync the tabLayouts state with the updated items
          handleDrop(updatedItems)

          return updatedItems
        })
      }
    },
    [handleDrop]
  )

  const handleDragEnd = useCallback(() => {
    // Always reset the activeId state
    setActiveId(null)
  }, [])

  // Set activeId to null if the drag is cancelled
  const handleDragCancel = useCallback(() => {
    setActiveId(null)
  }, [])

  // Sets the active item for the drag overlay
  const activeItem = items?.find((item) => item.module === activeId)

  const modulesToCols =
    config?.layoutSize === 'large'
      ? '6'
      : config?.layoutSize === 'medium'
        ? '4'
        : config?.layoutSize === 'small'
          ? '3'
          : '6'

  // Update rowWidth on load and resize
  useEffect(() => {
    const updateRowWidth = () => {
      if (rowRef.current) {
        const currentRowWidth = rowRef.current.offsetWidth
        const remInPx = parseFloat(getComputedStyle(document.documentElement).fontSize)
        setRowWidth(currentRowWidth - remInPx * 2) // Subtract 2rem padding
      }
    }

    updateRowWidth() // Set initial width
    window.addEventListener('resize', updateRowWidth) // Update on resize
    return () => window.removeEventListener('resize', updateRowWidth)
  }, [])

  if (!items || items.length === 0) return <div />

  return (
    <>
      <div className="row DragAndDropRow" ref={rowRef}>
        <DndContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          onDragCancel={handleDragCancel}
          collisionDetection={closestCenter}
          sensors={sensors}
        >
          {/* The below map needs to happen to use the module name as the unique identifier */}
          <SortableContext items={items.map((item) => item.module)} strategy={() => null}>
            {(config?.layoutSize === 'medium' && windowWidth > 1400) ||
            (config?.layoutSize === 'small' && windowWidth < 1600 && windowWidth > 1400) ? (
              <MediumLayout items={items} rowWidth={rowWidth} />
            ) : config?.layoutSize === 'small' && windowWidth > 1600 ? (
              <SmallLayout items={items} rowWidth={rowWidth} />
            ) : (
              <LargeLayout items={items} rowWidth={rowWidth} />
            )}
            <div className={`col-12 col-xl-${modulesToCols} p-3`}>
              <BlankModule editModeEnabled={editModeEnabled} items={items} />
            </div>
          </SortableContext>
          <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
            {activeId ? (
              <>
                <Item id={activeId} item={activeItem} isDragging isOverlay />
              </>
            ) : (
              <LoadingBoxes />
            )}
          </DragOverlay>
        </DndContext>
      </div>
    </>
  )
}

const LargeLayout: React.FC<{ items: ModuleItem[]; rowWidth: number }> = ({ items, rowWidth }) => (
  <>
    <div className="p-0 col-12 col-xl-6">
      {items.map((item, index) => {
        if (index % 2 !== 0) return null
        return (
          <div className="p-3" key={index}>
            <SortableItem id={item.module} item={item} itemIndex={index} rowWidth={rowWidth} />
          </div>
        )
      })}
    </div>
    <div className="p-0 col-12 col-xl-6">
      {items.map((item, index) => {
        if (index % 2 === 0) return null
        return (
          <div className="p-3" key={index}>
            <SortableItem id={item.module} item={item} />
          </div>
        )
      })}
    </div>
  </>
)

const MediumLayout: React.FC<{ items: ModuleItem[]; rowWidth: number }> = ({ items, rowWidth }) => (
  <>
    <div className="p-0 col-12 col-xl-6 col-xxl-4">
      {items.map((item, index) => {
        if (index % 3 !== 0) return null
        return (
          <div className="p-3" key={index}>
            <SortableItem id={item.module} item={item} itemIndex={index} rowWidth={rowWidth} />
          </div>
        )
      })}
    </div>
    <div className="p-0 col-12 col-xl-6 col-xxl-4">
      {items.map((item, index) => {
        if (index % 3 !== 1) return null
        return (
          <div className="p-3" key={index}>
            <SortableItem id={item.module} item={item} />
          </div>
        )
      })}
    </div>
    <div className="p-0 col-12 col-xl-6 col-xxl-4">
      {items.map((item, index) => {
        if (index % 3 !== 2) return null
        return (
          <div className="p-3" key={index}>
            <SortableItem id={item.module} item={item} />
          </div>
        )
      })}
    </div>
  </>
)

const SmallLayout: React.FC<{
  items: ModuleItem[]
  rowWidth: number | null
}> = ({ items, rowWidth }) => (
  <>
    <div className="p-0 col-12 col-xl-6 col-xxl-4 col-xxxl-3">
      {items.map((item, index) => {
        if (index % 4 !== 0) return null
        return (
          <div className="p-3" key={index}>
            <SortableItem id={item.module} item={item} itemIndex={index} rowWidth={rowWidth} />
          </div>
        )
      })}
    </div>
    <div className="p-0 col-12 col-xl-6 col-xxl-4 col-xxxl-3">
      {items.map((item, index) => {
        if (index % 4 !== 1) return null
        return (
          <div className="p-3" key={index}>
            <SortableItem id={item.module} item={item} />
          </div>
        )
      })}
    </div>
    <div className="p-0 col-12 col-xl-6 col-xxl-4 col-xxxl-3">
      {items.map((item, index) => {
        if (index % 4 !== 2) return null
        return (
          <div className="p-3" key={index}>
            <SortableItem id={item.module} item={item} />
          </div>
        )
      })}
    </div>
    <div className="p-0 col-12 col-xl-6 col-xxl-4 col-xxxl-3">
      {items.map((item, index) => {
        if (index % 4 !== 3) return null
        return (
          <div className="p-3" key={index}>
            <SortableItem id={item.module} item={item} />
          </div>
        )
      })}
    </div>
  </>
)

export default DragAndDrop

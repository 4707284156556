import { useContext } from 'react'

import { Dropdown } from 'primereact/dropdown'
import { Controller } from 'react-hook-form'

import { TestDriveContext } from '../../../contexts'

interface DisclaimerProps {
  control: any
}

interface formatDisclaimerProps {
  disclaimer: string
  dealershipName: string
}

const formatDisclaimer = ({ disclaimer, dealershipName }: formatDisclaimerProps) => {
  return disclaimer?.replace(/\[dealership_name\]/g, dealershipName)
}

const Disclaimer = ({ control }: DisclaimerProps) => {
  const { testDrive }: any = useContext(TestDriveContext)

  if (!testDrive) return null
  const { disclaimer, dealership, insurance_excess_max } = testDrive

  // prepare options that start from $0 and increment by $500 up to the max insurance excess
  const insuranceExcessOptions = Array.from(
    { length: insurance_excess_max / 500 },
    (_, i) => i + 1
  ).map((i) => ({ label: `$${i * 500}`, value: i * 500 }))
  return (
    <div className="card mb-4">
      <div className="card-header">Disclaimer</div>
      <div className="card-body">
        <div style={{ maxHeight: '160px', overflowY: 'auto' }}>
          <div
            className="disclaimer-container pl-2"
            dangerouslySetInnerHTML={{
              __html: formatDisclaimer({ disclaimer, dealershipName: dealership?.name }),
            }}
          ></div>
        </div>
      </div>
      <div className="card-footer">
        <label className="mr-2">Insurance Excess</label>
        <Controller
          name="insuranceExcess"
          control={control}
          render={({ field }) => (
            <Dropdown
              {...field}
              options={insuranceExcessOptions}
              placeholder="-- Select an amount --"
            />
          )}
        />
      </div>
    </div>
  )
}

export default Disclaimer

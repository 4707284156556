import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'

const CreatedAt = (delivery) => {
  let created_at = delivery.created_at

  return <span className="small">{moment(created_at).fromNow()}</span>
}

const Salesperson = (delivery) => {
  let salesperson = delivery.salesperson

  if (!salesperson) {
    return null
  }

  return <span>{salesperson.name}</span>
}

const SmsSent = (delivery) => {
  let sms_sent = delivery.sms_sent

  if (!sms_sent) {
    return null
  }

  return (
    <span>
      <i className="fa fa-check text-success"></i>
    </span>
  )
}

const DeliveryDropdown = (delivery) => {
  let { dealershipSlug } = useParams()

  return (
    <div className="dropdown">
      <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">
        Action
      </button>
      <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
        <li>
          <a
            href={Routes.delivery_action_dealership_delivery_path(delivery.id, {
              delivery_action: 'send_customer_email',
              dealership_id: dealershipSlug,
            })}
            className="dropdown-item"
            data-method="post"
          >
            Resend Email
          </a>
        </li>
        <li>
          <a
            href={Routes.delivery_action_dealership_delivery_path(delivery.id, {
              delivery_action: 'send_customer_sms',
              dealership_id: dealershipSlug,
            })}
            className="dropdown-item"
            data-method="post"
          >
            Send SMS
          </a>
        </li>
        <li>
          <a
            href={Routes.edit_dealership_delivery_path(delivery.id, {
              dealership_id: dealershipSlug,
            })}
            className="dropdown-item"
          >
            Edit
          </a>
        </li>
        <li>
          <a
            href={Routes.dealership_delivery_path(delivery.id, { dealership_id: dealershipSlug })}
            data-method="delete"
            className="dropdown-item"
          >
            Destroy
          </a>
        </li>
      </ul>
    </div>
  )
}

const EmailStatus = (delivery) => {
  let email_status = delivery.email_status

  if (!email_status) {
    return null
  }

  return <span>{email_status}</span>
}

const Customer = (delivery) => {
  let { dealershipSlug } = useParams()

  let contact = delivery.contact

  return (
    <Link to={Routes.dealership_contact_path(contact.id, { dealership_id: dealershipSlug })}>
      <span>
        {contact.first_name} {contact.last_name}
      </span>
    </Link>
  )
}

const Thumbnail = (delivery) => {
  let thumbnail = delivery.thumbnail

  if (!thumbnail) {
    return null
  }

  return <img src={thumbnail} alt="thumbnail" style={{ width: '50px' }} className="rounded" />
}

const Vehicle = (delivery) => {
  let vehicle = delivery.vehicle

  if (!vehicle) {
    return null
  }

  return (
    <div>
      <span>
        {vehicle.year} {vehicle.make?.name} {vehicle.model?.name}
      </span>
    </div>
  )
}

const fetchDeliveries = async ({ queryKey }) => {
  const [, dealershipSlug, page, rows] = queryKey
  const response = await fetch(
    Routes.dealership_deliveries_path({
      dealership_id: dealershipSlug,
      page: page,
      per_page: rows,
    }),
    {
      headers: standardHeaders,
    }
  )
  if (!response.ok) throw new Error('Failed to fetch deliveries')
  return response.json()
}

const Deliveries = () => {
  const { dealershipSlug } = useParams()
  const [page, setPage] = useState(1)
  const rows = 25

  // Fetch deliveries using TanStack Query
  const { data, error, isLoading } = useQuery({
    queryKey: ['deliveries', dealershipSlug, page, rows],
    queryFn: fetchDeliveries,
    keepPreviousData: true, // Helps prevent flickering when changing pages
    staleTime: 1000 * 60 * 5, // Cache results for 5 minutes
  })

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  if (error) return <div>Error loading deliveries</div>

  return (
    <div className="p-3 w-100">
      <div className="d-flex align-items-center mb-2">
        <h4 className="mb-0">{data?.deliveries_count?.toLocaleString()} Deliveries</h4>
        <div className="ml-auto">
          <a
            href={Routes.csv_dealership_deliveries_path({ dealership_id: dealershipSlug })}
            className="btn btn-outline-primary mr-2"
          >
            Bulk Upload
          </a>
          <Link
            to={Routes.dealership_contacts_path({ dealership_id: dealershipSlug })}
            className="btn btn-primary"
          >
            <i className="fa fa-plus mr-1"></i>
            Delivery
          </Link>
        </div>
      </div>

      <div className="box">
        <DataTable
          lazy
          value={data?.deliveries || []}
          loading={isLoading}
          paginator
          rows={rows}
          first={(page - 1) * rows}
          totalRecords={data?.deliveries_count}
          onPage={onPageChange}
        >
          <Column field="created_at" header="Created" body={CreatedAt} />
          <Column field="thumbnail" header="Image" body={Thumbnail} />
          <Column field="salesperson" header="Salesperson" body={Salesperson} />
          <Column field="contact" header="Name" body={Customer} />
          <Column header="Vehicle" body={Vehicle} />
          <Column field="email" header="Email" body={EmailStatus} />
          <Column field="sms_sent" header="SMS Sent" body={SmsSent} />
          <Column field="email_template" header="Template" />
          <Column body={DeliveryDropdown} header="Action" />
        </DataTable>
      </div>
    </div>
  )
}

export default Deliveries

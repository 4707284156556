import React from 'react'

import { Tooltip } from 'react-tooltip'

const ItemStatus = (location) => {
  const visible = location.visible
  const status = visible ? 'Visible' : 'Hidden'
  return (
    <div>
      <div className={visible ? 'text-success' : 'text-danger'} id={`status-${location.id}`}>
        <i className="fa fa-info-circle mr-1"></i>
        {status}
      </div>
      <Tooltip anchorSelect={`#status-${location.id}`} place="bottom" style={{ zIndex: 10000 }}>
        {visible
          ? 'Visible indicates that the location is shown on the live site.'
          : 'Hidden indicates that the location is not currently shown on the live site.'}
      </Tooltip>
    </div>
  )
}

export default ItemStatus

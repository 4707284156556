import { useContext, useState } from 'react'

import { Sidebar } from 'primereact/sidebar'
import { Link, useParams } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

import * as Routes from '../../routes'
import { SearchContext } from '../contexts'
import { humanize, timeAgoInDays } from '../entries/utils'
import { standardHeaders } from '../entries/utils'
import { CopyableText } from '../shared/CopyableText'
import CarHealth from './CarHealth'
import CarPrice from './CarPrice'
import EditingComments from './CarSearchItemComments'
import CarSettings from './CarSettings'
import CatalogueWarning from './CatalogueWarning'
import ExportDestinations from './ExportDestinations'
import EditCarForm from './Form/EditCarForm'
import LeadStatsTooltip from './LeadStatsTooltip'
import PhotosPopup from './PhotosPopup'
import RestrictionsPopup from './RestrictionsPopup'
import CarShow from './show'

const CarDetails = ({ car, setCar, hit, setLeadsView, setVisible }) => {
  let leadsCount = hit.lead_clusters_count
  const location = useLocation()
  const navigate = useNavigate()
  const { dealershipSlug } = useParams()

  const handleClick = (e) => {
    e.preventDefault()

    const isAllCarsPage = location.pathname === '/admin/all_cars'

    if (isAllCarsPage) {
      navigate(`/cars/${car.slug}`)
    } else {
      window.history.pushState({}, '', Routes.dealership_car_path(dealershipSlug, car.slug))
      setVisible(true)
    }
  }

  return (
    <div className="car-grid">
      <div className="item">
        <div onClick={handleClick} style={{ cursor: 'pointer' }}>
          <img className="rounded border" src={car.primary_image_url} loading="lazy" />
        </div>
      </div>
      <div className="item">
        <a onClick={handleClick} href="#">
          {hit.carsales_title || hit.name}
        </a>
        <div className="small text-secondary">
          <Subtitle car={car} />
        </div>
        {hit.reserved ? <div className="badge badge-success mr-1">RESERVED</div> : ''}
        {hit.has_spin ? <div className="badge badge-success mr-1">360 SPIN</div> : ''}
        {hit.preorder ? <div className="badge badge-danger mr-1">PREORDER CAR</div> : ''}
        {hit.certified ? (
          <div className="badge badge-success">
            <i className="fa fa-success"></i>
            Certified
          </div>
        ) : (
          ''
        )}
        <CatalogueWarning hit={hit} />
      </div>
      <div className="item">
        <CarPrice hit={car} setCar={setCar} />
      </div>
      <div className="item d-none d-lg-block">
        {hit.created_at ? timeAgoInDays(hit.created_at) : hit.days_old}
        <div className="small text-secondary">days</div>
      </div>
      <div className="item d-none d-lg-block">
        <div className={hit.photos_count === 0 ? 'text-danger' : ''}>
          <i className="fa fa-camera mr-2"></i>
          {hit.photos_count}
        </div>
        {!car.preorder && <CarHealth hit={hit} />}
        <div className="test-drives-count" id={`test-drives-count-${car.id}`}>
          {hit.test_drives_count > 0 ? (
            <a href={`/dealerships/${hit.dealership_slug}/test_drives?car_id=${hit.id}`}>
              <i className="fa fa-steering-wheel mr-2"></i>
              <Tooltip
                anchorSelect={`#test-drives-count-${car.id}`}
                place="bottom"
                style={{ zIndex: 10000 }}
              >
                {hit.test_drives_count} Test Drives
              </Tooltip>
              {hit.test_drives_count}
            </a>
          ) : (
            <>
              <i className="fa fa-steering-wheel mr-2"></i>
              <Tooltip
                anchorSelect={`#test-drives-count-${car.id}`}
                place="bottom"
                style={{ zIndex: 10000 }}
              >
                {hit.test_drives_count} Test Drives
              </Tooltip>
              {hit.test_drives_count}
            </>
          )}
        </div>
      </div>
      <div className="item d-none d-md-block">
        <div>
          <i className="fa fa-eye mr-2"></i>
          {hit.views}
        </div>
        <div className={leadsCount > 0 ? 'text-success' : ''}>
          <span
            id={`leads-count-${car.id}`}
            onClick={() => setLeadsView(car)}
            style={{ cursor: 'pointer' }}
          >
            <i className="fa fa-envelope mr-2"></i>
            {leadsCount || 0}
          </span>
        </div>
        {leadsCount > 0 && <LeadStatsTooltip hit={hit} />}
        <div className={hit.phone_calls > 0 ? 'text-success' : ''}>
          <i className="fa fa-phone mr-2"></i>
          {hit.phone_calls}
        </div>
      </div>
    </div>
  )
}

const Loading = () => (
  <>
    <i className="fa fa-spinner fa-spin mr-2" />
    Loading...
  </>
)

export const MarkAsSold = ({ car, setCar }) => {
  let [loading, setLoading] = useState(false)

  const changeStatus = (status) => {
    setLoading(true)
    fetch(`/cars/${car.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        car: {
          status: status,
        },
      }),
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then((res) => {
        setCar(res)
        setLoading(false)
      })
  }

  return (
    <div className="btn-group" id="change-vehicle-status">
      {car.status === 'in_stock' ? (
        <div
          onClick={() => changeStatus('sold')}
          className={'btn btn-outline-danger btn-sm' + (loading ? ' disabled' : '')}
          id="mark-as-sold"
          disabled={loading}
        >
          {loading ? <Loading /> : 'Mark as Sold'}
        </div>
      ) : (
        <div
          onClick={() => changeStatus('in_stock')}
          className={'btn btn-outline-success btn-sm' + (loading ? ' disabled' : '')}
          id="mark-as-in-stock"
          disabled={loading}
        >
          {loading ? <Loading /> : 'Mark as In Stock'}
        </div>
      )}
      <div
        className={
          'btn btn-sm dropdown-toggle' +
          (car.status === 'in_stock' ? ' btn-outline-danger' : ' btn-outline-success')
        }
        data-toggle="dropdown"
      />
      <div className="dropdown-menu dropdown-menu-right">
        <button
          className="dropdown-item"
          onClick={() => changeStatus('withdrawn')}
          id="mark-as-withdrawn"
          disabled={loading}
        >
          Mark as Withdrawn
        </button>
      </div>
    </div>
  )
}

export const CarStatus = ({ car }) => (
  <span className={car.status === 'in_stock' ? 'text-success' : 'text-danger'}>
    {humanize(car.status)}
  </span>
)

const odometerMissing = (car) => car.car_type === 'used' && car.km === 0

export const Subtitle = ({ car }) => (
  <>
    <span className="subtitle-item">
      <CarStatus car={car} />
    </span>
    <span className="subtitle-item">{humanize(car.car_type)}</span>
    {car.colour && <span className="subtitle-item">{car.colour}</span>}
    <span className="subtitle-item" id={`car-km-${car.id}`}>
      <span className={odometerMissing(car) ? ' text-danger' : ''}>
        {car.km.toLocaleString()} kms
      </span>
      {odometerMissing(car) && (
        <Tooltip anchorSelect={`#car-km-${car.id}`} place="bottom" style={{ zIndex: 10000 }}>
          Odometer missing
        </Tooltip>
      )}
    </span>
    <span className="subtitle-item" id={`car-stocknum-${car.id}`}>
      <CopyableText text={car.stocknum} />
    </span>
    <Tooltip anchorSelect={`#car-stocknum-${car.id}`} place="bottom" style={{ zIndex: 10000 }}>
      Stock Number
    </Tooltip>
    {car.regplate && (
      <>
        <Tooltip anchorSelect={`#car-rego-${car.id}`} place="bottom" style={{ zIndex: 10000 }}>
          Rego
        </Tooltip>
        <span className="subtitle-item" id={`car-rego-${car.id}`}>
          <CopyableText text={car.regplate} />
        </span>
      </>
    )}
    <span className="subtitle-item">{car.location_name}</span>
  </>
)

const CarSearchItem = ({
  hit,
  setAutograb,
  hasAutograb,
  setLeadsView,
  carSwitches = [],
  addRecentlyUpdatedCar,
}) => {
  let car = hit
  let [popupOpened, setPopupOpened] = useState(false)
  let [carSettingsVisible, setCarSettingsVisible] = useState(false)
  let [editingComments, setEditingComments] = useState(false)
  let [visible, setVisible] = useState(false)
  let { dealershipSlug } = useParams()
  let { handleSelectHit, selectedHits } = useContext(SearchContext)

  function handleCheckboxChange() {
    handleSelectHit(hit.id.toString())
  }

  // When a car is updated, this is saved to the recentlyUpdatedCars array which is passed down to this component
  // If the car is in the array, we want to use the updated data instead of the data from the search index
  // If this does not happen, when a user changes a page in the search results, the data will be reverted to the data from the search index
  const setCar = (car) => {
    addRecentlyUpdatedCar(car)
  }

  var url = `/cars/${car?.slug}`

  const commentsBlank = car.dealercomments === null || car.dealercomments === ''

  return (
    <div className="box my-2 car-box">
      <Sidebar
        visible={visible}
        style={{ width: '80vw' }}
        position="right"
        onHide={() => {
          window.history.pushState({}, '', `/dealerships/${dealershipSlug}/cars`)
          setVisible(false)
        }}
        header={car.carsales_title || car.name}
      >
        <CarShow defaultCarId={hit.id} setVisible={setVisible} />
      </Sidebar>
      <div className="d-flex">
        <div className="item pt-2 pl-2">
          <div className="custom-control custom-checkbox small text-secondary">
            <input
              type="checkbox"
              id={`select-${hit.id}`}
              className={'custom-control-input'}
              checked={selectedHits.includes(hit.id.toString())}
              onChange={handleCheckboxChange}
            />
            <label className="custom-control-label" htmlFor={`select-${hit.id}`}>
              Select
            </label>
          </div>
        </div>
        {dealershipSlug && (
          <div className="ml-auto d-flex">
            <div className="mr-1">
              <div
                className={
                  'badge badge-sm' + (commentsBlank ? ' badge-danger' : ' badge-secondary')
                }
                onClick={() => setEditingComments(!editingComments)}
                style={{ cursor: 'pointer' }}
              >
                <small>
                  {commentsBlank && <i className="fa fa-exclamation-triangle mr-1"></i>}
                  {editingComments ? (
                    'Stop Editing'
                  ) : (
                    <>{commentsBlank ? <>Add Comments</> : 'Edit Comments'}</>
                  )}
                </small>
              </div>
            </div>
            <CarSettings
              car={car}
              setCar={setCar}
              visible={carSettingsVisible}
              setVisible={setCarSettingsVisible}
            />
          </div>
        )}
      </div>
      {editingComments ? (
        <EditingComments setCar={setCar} car={car} setEditingComments={setEditingComments} />
      ) : (
        <CarDetails
          car={car}
          hit={hit}
          setCar={setCar}
          setLeadsView={setLeadsView}
          url={url}
          setVisible={setVisible}
        />
      )}
      <hr className="divider m-0"></hr>
      {editingComments ? (
        <></>
      ) : (
        <div className="d-lg-flex">
          <div className="button-grid border-bottom">
            <div className="item">
              {dealershipSlug && (
                <>
                  <div className="d-md-none">
                    <a
                      href={url + '/edit'}
                      className="btn btn-secondary btn-sm mr-1"
                      id={`edit-btn-${car.id}`}
                    >
                      <i className="fa fa-edit" />
                    </a>
                    <Tooltip
                      anchorSelect={`edit-btn-${car.id}`}
                      place="bottom"
                      style={{ zIndex: 10000 }}
                    >
                      Edit
                    </Tooltip>
                  </div>
                  <div className="d-none d-md-inline-block">
                    <EditCarForm carId={car.id} setCar={setCar} carStocknum={car.stocknum} />
                  </div>
                  <a href={url + '/new_image'} className="btn btn-outline-secondary btn-sm mr-1">
                    <i className="fa fa-plus mr-2" />
                    Photos
                  </a>
                  {hit.photos_count > 1 && (
                    <>
                      <a
                        onClick={() => {
                          setPopupOpened(true, $(`#model-${car.id}`).modal())
                        }}
                        className="btn btn-outline-secondary btn-sm mr-1 d-none d-lg-inline"
                        id={`reorder-btn-${car.id}`}
                      >
                        <i className="fa fa-sync" />
                      </a>
                      <Tooltip
                        anchorSelect={`#reorder-btn-${car.id}`}
                        place="bottom"
                        style={{ zIndex: 10000 }}
                      >
                        Reorder Photos
                      </Tooltip>
                      <PhotosPopup hit={hit} opened={popupOpened} setCar={setCar} />
                    </>
                  )}
                  <MarkAsSold car={car} setCar={setCar} />
                </>
              )}
              {hasAutograb && (car.regplate || car.vin) && car.status == 'in_stock' && (
                <div className="btn-group d-none d-xl-inline-block">
                  <button
                    className="btn btn-sm btn-outline-primary btn-sm ml-1"
                    onClick={(e) => setAutograb(car)}
                  >
                    Market Overlay
                  </button>
                  <button
                    id="btnGroupDrop1"
                    type="button"
                    className="btn btn-outline-primary dropdown-toggle btn-sm"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  ></button>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="btnGroupDrop1"
                  >
                    <a
                      className="dropdown-item"
                      href={`/dealerships/${dealershipSlug}/cars/autograb`}
                    >
                      Market Overview
                    </a>
                    {car.vin && (
                      <a
                        className="dropdown-item"
                        target="_blank"
                        href={`https://app.autograb.com.au/portfolio?vin=${car.vin}`}
                      >
                        Open in Autograb
                      </a>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="button-grid ml-auto d-flex flex-column flex-lg-row">
            <RestrictionsPopup hit={hit} setCar={setCar} />

            <ExportDestinations car={car} setCar={setCar} hit={hit} carSwitches={carSwitches} />
          </div>
        </div>
      )}
    </div>
  )
}

export default CarSearchItem

import React, { useState } from 'react'

import { formatDistanceToNow } from 'date-fns'

import { humanize } from '../entries/utils'

function apicallClass(status) {
  switch (status) {
    case 'success':
      return 'text-success'
    case 'pending':
      return 'text-info'
    case 'failed':
      return 'text-danger'
    default:
      return 'text-warning'
  }
}

const Apicall = ({ apicall }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const toggleCollapse = () => setIsCollapsed(!isCollapsed)

  const timeAgo = formatDistanceToNow(new Date(apicall.created_at), { addSuffix: true })
  return (
    <div className="px-3 py-1">
      <div className="box bg-white rounded p-3">
        <div className="row align-items-center">
          <div className="d-flex px-3 w-100">
            <div className="mr-3">
              <i className={'fa fa-envelope fa-lg ' + apicallClass(apicall.status)} />
            </div>
            <div className="mr-auto">
              {apicall.service === 'Autogate Service' ? (
                <>
                  Lead received from third party ({apicall.service}) {timeAgo}
                </>
              ) : (
                <>
                  Lead sent to third party ({apicall.service}) {timeAgo} ({apicall.response_code}) -{' '}
                  {humanize(apicall.status)}
                </>
              )}
            </div>
            <div>
              {(apicall.body || apicall.response_errors) && (
                <button
                  className="btn btn-outline-secondary btn-sm"
                  data-toggle="collapse"
                  onClick={toggleCollapse}
                >
                  <i className={`fa fa-${isCollapsed ? 'plus' : 'minus'}`}></i>
                </button>
              )}
            </div>
          </div>
          {(apicall.body || apicall.response_errors) && (
            <div className={`collapse ${!isCollapsed ? 'show' : ''}`}>
              <div className="text-secondary p-3" style={{ overflowWrap: 'anywhere' }}>
                <h6>Response Code = {apicall.response_code}</h6>
                {apicall.endpoint_url && <b>Endpoint = {apicall.endpoint_url}</b>}
                <h6>Request Body</h6>
                <p>{apicall.body}</p>
                <h6>Response Body</h6>
                <p>{String(apicall.response_body)}</p>
                {apicall.response_errors && <p>{String(apicall.response_errors)}</p>}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Apicall

/**
 * Reusable utilities for working with forms using `react-hook-form` and
 * client-side data fetching. This is a minimalistic attempt.
 * See https://ui.shadcn.com/docs/components/form for a full-fledged example.
 **/
import type { DetailedHTMLProps, InputHTMLAttributes, SelectHTMLAttributes } from 'react'

import {
  Controller,
  type FieldValues,
  FormProvider,
  type RegisterOptions,
  type UseFormProps,
  useForm,
  useFormContext,
  useFormState,
} from 'react-hook-form'
import Select from 'react-select'

type ValidationRules = Omit<
  RegisterOptions<FieldValues, string>,
  'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
>

/**
 * Handle the response from a `fetch` and forcefully throw an appropriate error
 * if the response has an issue
 **/
export async function handleResponse<T = unknown>(res: Response): Promise<T> {
  if (res.status === 503) {
    // This is for heroku timeout errors. After 30 seconds of no response it will by default return a 503 error
    const error = new Error('Service Temporarily Unavailable') as Error & {
      status: number
    }
    error.status = 503
    throw error
  } else {
    if (!res.ok) {
      const json = await res.json()
      if (json.error) {
        const error = new Error(json.error) as Error & {
          status: number
        }
        error.status = res.status
        throw error
      } else {
        const error = new Error(
          `An unexpected error occurred${res?.statusText ? `: ${res.statusText}` : ''}`
        ) as Error & {
          status: number
        }
        if (res.status) {
          error.status = res.status
        }
        throw error
      }
    }

    if (res.status === 204) {
      // avoid throwing an error for NO CONTENT delete actions
      return res.statusText as T
    }
    return res.json()
  }
}

/**
 * A simple, unopinionated provider for forms intended to make it easy
 * to use `react-hook-form` for managing form state.
 */
export function Form<TFormFields extends object>({
  children,
  onSubmit,
  className,
  ...formProps
}: {
  children: React.ReactNode
  onSubmit: (data: TFormFields) => Promise<void>
  className?: string
} & UseFormProps<TFormFields>) {
  const form = useForm({ ...formProps })
  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(async (data) => {
          await onSubmit(data)
        })}
        className={className}
      >
        {children}
      </form>
    </FormProvider>
  )
}

/**
 * A form input which uses `react-hook-form` for managing its state.
 * Simply pass `name` and `label` to use this input, and optionally pass
 * any other props you would pass to `<input />`
 */
export function FormInput({
  name,
  label,
  rules,
  defaultValue,
  formatValue = (val: string) => val,
  ...inputProps
}: {
  label: string
  rules?: ValidationRules
  defaultValue?: string | boolean
  formatValue?: (val: string) => string
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) {
  const { control } = useFormContext()
  const { errors, isSubmitting } = useFormState()
  const errorMessage = errors[name]?.message ?? null

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <div style={{ display: inputProps?.type === 'hidden' ? 'none' : '' }}>
          <div className="form-group">
            <label htmlFor={name}>
              {label}
              {inputProps?.required && <span className="text-danger">*</span>}
            </label>
            <input
              {...inputProps}
              {...field}
              placeholder={inputProps?.placeholder ?? label}
              disabled={isSubmitting}
              className="form-control"
              value={formatValue(field.value) ?? ''}
              style={{ width: '100%' }}
            />
          </div>
          {errorMessage && <p className="text-danger">{String(errorMessage)}</p>}
        </div>
      )}
    />
  )
}

/**
 * A checkbox input which uses `react-hook-form` for managing its state.
 * Simply pass `name` and `label` to use this input, and optionally pass
 * any other props you would pass to `<input type="checkbox" />`
 */
export function FormCheckbox({
  name,
  label,
  rules,
  defaultValue,
  ...inputProps
}: {
  label: string
  rules?: ValidationRules
  defaultValue?: boolean
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) {
  const { control } = useFormContext()
  const { errors, isSubmitting } = useFormState()
  const errorMessage = errors[name]?.message ?? null

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <div>
          <div className="form-group d-flex mb-0">
            <input
              {...inputProps}
              {...field}
              type="checkbox"
              disabled={isSubmitting}
              className="form-control"
              checked={Boolean(field.value)}
              style={{ width: 'auto' }}
            />
            <label
              htmlFor={name}
              style={{
                order: '1',
                marginLeft: '0.5rem',
                marginBottom: '0',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {label}
              {inputProps?.required && <span className="text-danger">*</span>}
            </label>
          </div>
          {errorMessage && <p className="text-danger">{String(errorMessage)}</p>}
        </div>
      )}
    />
  )
}

/**
 * A select input which uses `react-hook-form` for managing its state.
 * Simply pass `name`, `label` and `options` to use this input, and optionally pass
 * any other props you would pass to `<select />`
 */
export function FormSelect({
  name,
  label,
  options,
  rules,
  defaultValue,
  formatOption = (val: string) => val,
  ...selectProps
}: {
  label: string
  options: string[]
  rules?: ValidationRules
  formatOption?: (val: string) => string
} & DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>) {
  const { control } = useFormContext()
  const { errors, isSubmitting } = useFormState()
  const errorMessage = errors[name]?.message ?? null

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field }) => (
        <div>
          <div className="form-group">
            <label htmlFor={name}>
              {label}
              {selectProps?.required && <span className="text-danger">*</span>}
            </label>
            <select
              {...selectProps}
              {...field}
              disabled={isSubmitting || selectProps.disabled}
              className="form-control"
              value={field.value ?? ''}
            >
              <option value="" disabled>
                Select a form type
              </option>
              {options.map((option, i) => (
                <option value={option} key={`option-${i}`}>
                  {formatOption(option)}
                </option>
              ))}
            </select>
          </div>
          {errorMessage && <p className="text-danger">{String(errorMessage)}</p>}
        </div>
      )}
    />
  )
}

/**
 * A multi select input which uses `react-hook-form` for managing its state and
 * react-select for the UI.
 * Simply pass `name`, `label` and `options` to use this input
 */
export function FormMultiSelect({
  name,
  label,
  options,
  rules,
  defaultValue,
  formatOption = (val: string) => val,
  ...selectProps
}: {
  label: string
  options: string[] | { label: string; value: number }[]
  rules?: ValidationRules
  formatOption?: (val: string) => string
  defaultValue?: unknown
} & DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>) {
  const { control } = useFormContext()
  const { errors, isSubmitting } = useFormState()
  const errorMessage = errors[name]?.message ?? null

  // Check if options is an array of strings or an array of objects

  let selectOptions = []
  if (typeof options[0] === 'string') {
    selectOptions = (options as string[]).map((option) => ({
      value: option,
      label: formatOption(option),
    }))
  } else {
    selectOptions = options as { label: string; value: number }[]
  }

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <div>
          <div className="form-group">
            <label htmlFor={name}>
              {label}
              {selectProps?.required && <span className="text-danger">*</span>}
            </label>
            <Select
              {...field}
              options={selectOptions}
              isDisabled={isSubmitting || selectProps.disabled}
              className="react-select-container"
              classNamePrefix="react-select"
              isMulti
              value={selectOptions.filter((option) => field.value?.includes(option.value))}
              onChange={(selectedOptions) => {
                const selectedValues = selectedOptions
                  ? selectedOptions.map((option) => option.value)
                  : []
                field.onChange(selectedValues)
              }}
            />
          </div>
          {errorMessage && <p className="text-danger">{String(errorMessage)}</p>}
        </div>
      )}
    />
  )
}

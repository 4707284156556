import { useEffect, useRef, useState } from 'react'

import { Toast } from 'primereact/toast'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { csrfToken } from '../entries/utils'
import showToast from './../shared/ShowToast'
import LeadClusterAssignmentRulesForm from './form'

const LeadClusterAssignmentRulesEdit = () => {
  const navigate = useNavigate()
  const notification = useRef(null)
  let { dealershipSlug, leadClusterAssignmentRuleId } = useParams()
  let [leadClusterAssignmentRule, setLeadClusterAssignmentRule] = useState(undefined)

  useEffect(() => {
    fetchLeadClusterAssignmentRule(dealershipSlug)
  }, [])

  // Use react-router-dom's useNavigate hook to navigate back
  const handleBack = () => {
    navigate(`/dealerships/${dealershipSlug}/lead_cluster_assignment_rules`)
  }

  const fetchLeadClusterAssignmentRule = async (dealershipSlug) => {
    try {
      const URL = Routes.dealership_lead_cluster_assignment_rule_path(
        dealershipSlug,
        leadClusterAssignmentRuleId
      )
      const response = await fetch(URL, {
        headers: {
          Accept: 'application/json',
        },
      })
      const data = await response.json()
      setLeadClusterAssignmentRule(data)
    } catch (error) {
      console.error('Error fetching lead cluster assignment rule:', error)
      showToast(notification, 'error', 'Error fetching lead cluster assignment rule.', '')
    }
  }

  const submitFunction = async (data) => {
    const URL = Routes.dealership_lead_cluster_assignment_rule_path(
      dealershipSlug,
      leadClusterAssignmentRuleId
    )
    try {
      let response = await fetch(URL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify(data),
      })
      if (response.ok) {
        notification.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Data saved successfully',
        })
        handleBack()
      } else {
        throw response
      }
    } catch (err) {
      err = await err.json()
      console.error('Error updating lead cluster assignment rule:', err)
      for (var key in err) {
        showToast(notification, 'error', key, err[key])
      }
    }
  }

  return (
    <>
      {leadClusterAssignmentRule && (
        <div className="container py-3">
          <Toast ref={notification} />
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="box p-3">
                <h1>Edit Lead Cluster Assignment Rule</h1>
                {leadClusterAssignmentRule && (
                  <LeadClusterAssignmentRulesForm
                    dealershipSlug={dealershipSlug}
                    leadClusterAssignmentRule={leadClusterAssignmentRule}
                    submitFunction={submitFunction}
                    handleBack={handleBack}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default LeadClusterAssignmentRulesEdit

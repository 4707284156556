import { useContext, useEffect, useState } from 'react'

import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Panel } from 'primereact/panel'

const DealershipPhoneCallsCost = () => {
  const [data, setData] = useState([])
  const [summary, setSummary] = useState([])
  const [loading, setLoading] = useState(false)
  const [expandedRows, setExpandedRows] = useState(null)
  const currentMonthName = moment().format('MMMM')
  const previousMonthName = moment().subtract(1, 'month').format('MMMM')
  const monthBeforePreviousMonthName = moment().subtract(2, 'month').format('MMMM')

  useEffect(() => {
    if (true) {
      setLoading(true)
      fetch(`/admin/dealership_phone_calls_cost.json`)
        .then((response) => response.json())
        .then((data) => {
          setData(data.dealership_phone_calls_cost)
          setSummary(data.summary_details)
          setLoading(false)
        })
    }
  }, [])

  const rowExpansionTemplate = (rowData) => {
    return (
      <div className="p-3">
        <h5>Locations for {rowData.dealership_name}</h5>
        <DataTable value={rowData.dealership_locations} className="p-datatable-sm">
          <Column field="location_id" header="Location ID" />
          <Column field="location_name" header="Location Name" />
          <Column
            field="location_periods.this_month.duration"
            body={(rowData) => cellData(rowData['location_periods'], 'this_month')}
            header={`Duration (${currentMonthName})`}
          />
          <Column
            field="location_periods.last_month.duration"
            body={(rowData) => cellData(rowData['location_periods'], 'last_month')}
            header={`Duration (${previousMonthName})`}
          />
          <Column
            field="location_periods.month_before_last.duration"
            body={(rowData) => cellData(rowData['location_periods'], 'month_before_last')}
            header={`Duration (${monthBeforePreviousMonthName})`}
          />
        </DataTable>
      </div>
    )
  }

  const cellData = (rowData, field) => {
    if (
      rowData[field] &&
      rowData[field]['duration'] !== undefined &&
      rowData[field]['price'] !== undefined
    ) {
      return `${rowData[field]['duration']} ( $ ${rowData[field]['price']} )`
    }
    return 0
  }

  return (
    <div className="p-3 container">
      <Panel header="Dealership Phone Call Costs">
        <ul>
          <li>{summary.total_duration_for_this_month} minutes call duration for this month</li>
          <li>{summary.total_duration_for_last_month} minutes call duration for last month</li>
          <li>
            {summary.total_phone_calls_duration_last_3_months} minutes call duration for last 3
            months
          </li>
          <li>${summary.approximate_cost_this_month} approximate cost this month</li>
          <li>${summary.approximate_cost_last_month} approximate cost last month</li>
        </ul>
      </Panel>
      <DataTable
        value={data}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="dealership_id"
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 20]}
        className="p-datatable-sm"
        loading={loading}
      >
        <Column expander style={{ width: '3em' }} />
        <Column field="dealership_id" header="Dealership ID" sortable />
        <Column field="dealership_name" header="Dealership Name" sortable />
        <Column
          field="dealership_periods.this_month.duration"
          body={(rowData) => cellData(rowData['dealership_periods'], 'this_month')}
          header={`Duration (${currentMonthName})`}
          sortable
        />
        <Column
          field="dealership_periods.last_month.duration"
          body={(rowData) => cellData(rowData['dealership_periods'], 'last_month')}
          header={`Duration (${previousMonthName})`}
          sortable
        />
        <Column
          field="dealership_periods.month_before_last.duration"
          body={(rowData) => cellData(rowData['dealership_periods'], 'month_before_last')}
          header={`Duration (${monthBeforePreviousMonthName})`}
          sortable
        />
      </DataTable>
    </div>
  )
}

export default DealershipPhoneCallsCost

import { useState } from 'react'

import { Sidebar } from 'primereact/sidebar'

import { useFetchDealership } from '../dataHooks'
import Redbook from './redbook'

const sidebarStyle = () => ({
  height: '100%',
  width: '100%',
  maxHeight: '100vh',
  maxWidth: '1200px',
  borderRadius: 0,
  backgroundColor: '#fff',
})

export const CatalogueWarning = ({ hit }) => {
  let { dealership } = useFetchDealership()
  let [visible, setVisible] = useState(false)

  const toggleVisible = (e) => {
    e.preventDefault()
    setVisible(!visible)
  }

  return (
    <>
      {hit.item_type === 'car' && dealership?.preferred_catalogue === 'redbook' ? (
        <>
          {!hit.has_redbook && (
            <a href="#" onClick={toggleVisible}>
              <div className="badge badge-danger">REDBOOK MISSING</div>
            </a>
          )}
        </>
      ) : (
        <>
          {!hit.has_nvic && (
            <a href="#" onClick={toggleVisible}>
              <div className="badge badge-danger">NVIC MISSING</div>
            </a>
          )}
        </>
      )}
      <Sidebar
        visible={visible}
        onHide={() => {
          setVisible(!visible)
        }}
        header="Select Variant"
        position="right"
        style={{ ...sidebarStyle() }}
      >
        <Redbook hitId={hit.id} />
      </Sidebar>
    </>
  )
}

export default CatalogueWarning

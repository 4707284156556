import { useRef } from 'react'

import { Toast } from 'primereact/toast'

export const CopyableText = ({ text }) => {
  const toast = useRef(null)

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'Copied',
          detail: 'Text copied to clipboard!',
        })
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err)
      })
  }

  if (!text) return

  return (
    <div style={{ display: 'inline-block' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Toast ref={toast} />
        <span>{text}</span>
        <div onClick={() => copyToClipboard(text)} className="ml-1" style={{ cursor: 'pointer' }}>
          <i className="fa fa-copy text-secondary" />
        </div>
      </div>
    </div>
  )
}

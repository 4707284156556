import { useEffect, useState } from 'react'

import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'

import { Toggle } from '../settings/leadSettings'

const Name = (dealership) => {
  return <a href={`/dealerships/${dealership.slug}`}>{dealership.name}</a>
}

const TwoFactor = (dealership) => {
  return (
    <Toggle
      attributeName="enforce_two_factor"
      objectType="dealership"
      rowData={dealership}
      url={`/dealerships/${dealership.id}`}
    />
  )
}

const Dealerships = () => {
  const [dealerships, setDealerships] = useState([])
  let [loading, setLoading] = useState(true)
  let [page, setPage] = useState(1)
  let [totalCount, setTotalCount] = useState(0)

  let rows = 50

  useEffect(() => {
    setLoading(true)
    fetch(`/dealerships.json?page=${page}&per_page=${rows}`)
      .then((response) => response.json())
      .then((data) => {
        setDealerships(data.dealerships)
        setTotalCount(data.total_count)
        setLoading(false)
      })
  }, [page])

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  return (
    <div className="container py-4">
      <div className="d-flex mb-2">
        <h4>Dealerships</h4>
        <div className="ml-auto">
          <a href="/dealerships/new" className="btn btn-outline-primary">
            <i className="fa fa-plus mr-2"></i>
            Dealership
          </a>
        </div>
      </div>
      <div className="box">
        <DataTable
          value={dealerships}
          paginator
          lazy
          rows={rows}
          first={(page - 1) * rows}
          totalRecords={totalCount}
          onPage={onPageChange}
          loading={loading}
        >
          <Column field="name" header="Name" body={Name} />
          <Column field="two_factor" header="Enforce Two Factor" body={TwoFactor} />
        </DataTable>
      </div>
    </div>
  )
}

export default Dealerships

import { useContext, useEffect, useState } from 'react'

import { Dialog } from 'primereact/dialog'

import * as Routes from '../../routes'
import { LeadClusterContext } from '../contexts'
import { standardHeaders } from '../entries/utils'
import Apicall from '../leads/Apicall'
import DealerEmail from '../leads/DealerEmail'
import Email from '../leads/Email'

const ResendButton = ({ lead_id }) => {
  let { leadCluster } = useContext(LeadClusterContext)
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  const resendToThirdParties = () => {
    setLoading(true)
    const fetchUrl = lead_id
      ? Routes.resend_to_third_parties_dealership_lead_path(leadCluster.dealership_id, lead_id)
      : Routes.resend_to_third_parties_dealership_lead_cluster_path(
          leadCluster.dealership_id,
          leadCluster.id
        )
    console.log('fetchUrl', fetchUrl)
    fetch(fetchUrl, {
      method: 'POST',
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then(() => {
        setLoading(false)
        setSent(true)
      })
  }

  return (
    <div
      onClick={resendToThirdParties}
      className={'btn btn-outline-secondary btn-block mb-3' + (sent ? ' disabled' : '')}
      disabled={sent || loading}
    >
      {sent ? (
        <div className="text-success">
          <i className="fa fa-check mr-2"></i>
          Resent to third party
        </div>
      ) : (
        <>
          <i className="fa fa-recycle mr-2"></i>
          {loading ? 'Loading...' : 'Resend to third parties'}
        </>
      )}
    </div>
  )
}

const ResendLeadButton = ({ lead_id }) => {
  let { leadCluster } = useContext(LeadClusterContext)
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  const resendToCustomer = () => {
    setLoading(true)
    fetch(`/dealerships/${leadCluster.dealership_id}/leads/${lead_id}/resend_customer_email`, {
      method: 'POST',
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then(() => {
        setLoading(false)
        setSent(true)
      })
  }

  return (
    <div
      onClick={resendToCustomer}
      className={'btn btn-outline-secondary btn-block mb-3' + (sent ? ' disabled' : '')}
      disabled={sent || loading}
    >
      {sent ? (
        <div className="text-success">
          <i className="fa fa-check mr-2"></i>
          Resent to Customer
        </div>
      ) : (
        <>
          <i className="fa fa-recycle mr-2"></i>
          {loading ? 'Loading...' : 'Resend to Customer'}
        </>
      )}
    </div>
  )
}

const Emails = ({ lead }) => {
  let [visible, setVisible] = useState(false)
  let { leadCluster } = useContext(LeadClusterContext)
  const { emails = [], dealer_emails = [], apicalls = [] } = lead || leadCluster
  const [totalCount, setTotalCount] = useState(0)

  const title = lead ? 'Emails/API calls' : 'API calls'

  const buttonClass = `${lead ? 'btn-sm ml-2' : 'btn-block'}`

  useEffect(() => {
    if (lead) {
      setTotalCount(emails?.length + dealer_emails?.length + apicalls?.length)
    } else {
      setTotalCount(apicalls?.length)
    }
  }, [lead, leadCluster])

  return (
    <>
      <div className={`btn btn-outline-secondary ${buttonClass}`} onClick={() => setVisible(true)}>
        <i className="fa fa-envelope"></i>
        <span className="ml-2">{lead ? totalCount : `${title} (${totalCount || 0})`}</span>
      </div>
      <Dialog
        header={title}
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        dismissableMask={true}
      >
        {lead && emails?.map((email) => <Email email={email} key={email.id} />)}
        {apicalls?.map((apicall) => (
          <Apicall apicall={apicall} key={apicall.id} />
        ))}
        {lead &&
          dealer_emails?.map((dealerEmail) => (
            <DealerEmail dealerEmail={dealerEmail} key={dealerEmail.id} />
          ))}
        <div className="px-3 mt-2">
          <ResendButton lead_id={lead?.id} />
          {lead && <ResendLeadButton lead_id={lead?.id} />}
        </div>
      </Dialog>
    </>
  )
}

export default Emails

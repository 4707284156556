import { useRef } from 'react'

import { Toast } from 'primereact/toast'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { csrfToken } from '../entries/utils'
import showToast from './../shared/ShowToast'
import LeadClusterAssignmentRulesForm from './form'

const LeadClusterAssignmentRulesNew = () => {
  let leadClusterAssignmentRule = {
    location_id: undefined,
    category: undefined,
    dealership_user_id: undefined,
  }
  const navigate = useNavigate()
  const notification = useRef(null)
  let { dealershipSlug } = useParams()

  // Use react-router-dom's useNavigate hook to navigate back
  const handleBack = () => {
    navigate(`/dealerships/${dealershipSlug}/lead_cluster_assignment_rules`)
  }

  const submitFunction = async (data) => {
    const URL = Routes.dealership_lead_cluster_assignment_rules_path(dealershipSlug)
    try {
      let response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify(data),
      })
      if (response.ok) {
        notification.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Data saved successfully',
        })
        handleBack()
      } else {
        throw response
      }
    } catch (err) {
      err = await err.json()
      console.error('Error creating lead cluster assignment rules:', err)
      for (var key in err) {
        showToast(notification, 'error', key, err[key])
      }
    }
  }

  return (
    <>
      <div className="container py-3">
        <Toast ref={notification} />
        <div className="row d-flex justify-content-center">
          <div className="col-lg-8">
            <div className="box p-3">
              <h1>New Lead Cluster Assignment Rule</h1>
              <LeadClusterAssignmentRulesForm
                dealershipSlug={dealershipSlug}
                leadClusterAssignmentRule={leadClusterAssignmentRule}
                submitFunction={submitFunction}
                handleBack={handleBack}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeadClusterAssignmentRulesNew

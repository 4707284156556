import { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import Select from 'react-select'

import { CarDetails } from './redbook'

const startYear = 1960
const endYear = new Date().getFullYear() // Get the current year
const yearOptions = Array.from({ length: endYear - startYear + 1 }, (_, index) => {
  const year = startYear + index
  return { value: year, label: year.toString() }
}).reverse()

// Helper to encode query parameters
const encodeParams = (params) =>
  Object.entries(params)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')

// Reusable fetch operations with encoded parameters
const fetchOperations = {
  manufacturers: (year) =>
    `/manufacturers/glasses_data.json?${encodeParams({
      operation: 'GetListOfMakes',
      year,
    })}`,
  models: (year, manufacturer) =>
    `/manufacturers/glasses_data.json?${encodeParams({
      operation: 'GetListOfModels',
      year,
      manufacturer_code: manufacturer,
    })}`,
  variants: (year, manufacturer, model) =>
    `/manufacturers/glasses_data.json?${encodeParams({
      operation: 'GetListOfVariants',
      year,
      manufacturer_code: manufacturer,
      family_code: model,
    })}`,
  series: (year, manufacturer, model, variant) =>
    `/manufacturers/glasses_data.json?${encodeParams({
      operation: 'GetListOfSeries',
      year,
      manufacturer_code: manufacturer,
      family_code: model,
      variant_name: variant,
    })}`,
  nvic: (year, manufacturer, model, variant, selectedSeries) =>
    `/manufacturers/glasses_data.json?${encodeParams({
      operation: 'GetListOfNVICForAdvancedSearch',
      year,
      manufacturer_code: manufacturer,
      family_code: model,
      variant_name: variant,
      series_code: selectedSeries,
    })}`,
}

// Reusable fetchData function
const fetchData = async (url, setData, setLoading) => {
  try {
    const response = await fetch(url)
    const data = await response.json()
    setData(data)
  } catch (error) {
    console.error('Failed to fetch data:', error)
  } finally {
    setLoading(false)
  }
}

const NvicVariant = ({ nvic }) => {
  let { carId, dealershipSlug } = useParams()

  let url = `/dealerships/${dealershipSlug}/cars/new?nvic_code=${nvic.nvic_cur}`

  if (carId) {
    url = `/cars/${carId}/edit?nvic_code=${nvic.nvic_cur}`
  }

  return (
    <div className="box p-3 mb-2">
      <p>{nvic.modelname}</p>
      <a href={url} className="btn btn-sm btn-outline-primary">
        Select NVIC Variant
      </a>
    </div>
  )
}

const NvicSearch = ({
  catalogue,
  setCatalogue,
  defaultYear,
  manufacturer_id,
  title,
  subtitle,
  build_date,
}) => {
  let [loading, setLoading] = useState(true)

  // select options
  let [manufacturers, setManufacturers] = useState([])
  let [models, setModels] = useState([])
  let [variants, setVariants] = useState([])
  let [series, setSeries] = useState([])

  // form values
  let [year, setYear] = useState(defaultYear)
  let [model, setModel] = useState(null)
  let [manufacturer, setManufacturer] = useState(null)
  let [variant, setVariant] = useState(null)
  let [selectedSeries, setSelectedSeries] = useState(null)

  // NVIC Variants
  let [nvicVariants, setNvicVariants] = useState([])

  // Fetch manufacturers
  useEffect(() => {
    if (catalogue !== 'glasses' || !year) return

    setLoading(true)
    fetchData(fetchOperations.manufacturers(year), setManufacturers, setLoading)
  }, [year])

  // Fetch models
  useEffect(() => {
    if (catalogue !== 'glasses' || !year || !manufacturer) return

    setLoading(true)
    fetchData(fetchOperations.models(year, manufacturer), setModels, setLoading)
  }, [year, manufacturer])

  // Fetch variants
  useEffect(() => {
    if (catalogue !== 'glasses' || !year || !manufacturer || !model) return

    setLoading(true)
    fetchData(fetchOperations.variants(year, manufacturer, model), setVariants, setLoading)
  }, [year, manufacturer, model])

  // Fetch series
  useEffect(() => {
    if (catalogue !== 'glasses' || !year || !manufacturer || !model || !variant) return

    setLoading(true)
    fetchData(fetchOperations.series(year, manufacturer, model, variant), setSeries, setLoading)
  }, [year, manufacturer, model, variant])

  // Search NVIC variants
  const search = () => {
    if (catalogue !== 'glasses' || !year || !manufacturer || !model || !variant) return

    setLoading(true)
    fetchData(
      fetchOperations.nvic(year, manufacturer, model, variant, selectedSeries),
      setNvicVariants,
      setLoading
    )
  }

  let manufacturerOptions = manufacturers?.map((m) => {
    return { value: m.code, label: m.name }
  })

  let modelOptions = models?.map((m) => {
    return { value: m.code, label: m.name }
  })

  let variantOptions = variants?.map((v) => {
    return { value: v.name, label: v.name }
  })

  let seriesOptions = series?.map((s) => {
    return { value: s.code, label: s.name }
  })

  let searchDisabled = loading || manufacturer === null || model === null || variant === null

  return (
    <div className="row">
      <div className="col-md-4 col-xl-3">
        <CarDetails
          manufacturer_id={manufacturer_id}
          title={title}
          subtitle={subtitle}
          build_date={build_date}
        />
        <div className="box p-3">
          <div className="mb-3">
            <Select
              options={yearOptions}
              value={yearOptions.filter((y) => y.value === year)}
              onChange={(option) => setYear(option.value)}
            />
          </div>
          <div className="mb-3">
            <Select
              options={manufacturerOptions}
              value={manufacturerOptions.filter((m) => m.value === manufacturer)}
              onChange={(option) => {
                setManufacturer(option.value)
                setModel(null)
                setModels([])
                setVariant(null)
                setVariants([])
                setSelectedSeries(null)
                setSeries([])
              }}
              isDisabled={loading || manufacturerOptions === undefined}
            />
          </div>
          <div className="mb-3">
            <Select
              options={modelOptions}
              value={modelOptions.filter((m) => m.value === model)}
              onChange={(option) => {
                setModel(option.value)
                setVariant(null)
                setVariants([])
                setSelectedSeries(null)
                setSeries([])
              }}
              isDisabled={loading || modelOptions === undefined || modelOptions.length === 0}
            />
          </div>
          <div className="mb-3">
            <Select
              options={variantOptions}
              value={variantOptions.filter((v) => v.value === variant)}
              onChange={(option) => {
                setVariant(option.value)
                setSelectedSeries(null)
                setSeries([])
              }}
              isDisabled={loading || variantOptions === undefined || variantOptions.length === 0}
            />
          </div>
          <div className="mb-3">
            <Select
              options={seriesOptions}
              value={seriesOptions.filter((s) => s.value === selectedSeries)}
              onChange={(option) => setSelectedSeries(option.value)}
              isDisabled={loading || seriesOptions === undefined || seriesOptions.length === 0}
            />
          </div>
          <div
            className={'btn btn-outline-primary btn-block' + (searchDisabled ? ' disabled' : '')}
            onClick={search}
          >
            {loading ? 'Loading...' : 'Search'}
          </div>
        </div>
        <div className="text-center">
          <a
            href="#"
            className="small"
            onClick={(e) => {
              e.preventDefault()
              setCatalogue('redbook')
            }}
          >
            Switch to Redbook Catalogue
          </a>
        </div>
      </div>
      <div className="col-md-8 col-xl-9">
        {nvicVariants.length > 0 && (
          <>
            {nvicVariants.map((nvic) => (
              <NvicVariant key={nvic.nvic_cur} nvic={nvic} />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default NvicSearch

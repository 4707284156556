import { Tooltip } from 'react-tooltip'

const ItemStatus = (page) => {
  const visible = page.status === 'Visible'
  return (
    <div className="d-flex">
      <div className={visible ? 'text-success' : 'text-danger'} id={`status-${page.id}`}>
        <i className="fa fa-info-circle mr-1"></i>
        {page.status}
      </div>
      <Tooltip anchorSelect={`#status-${page.id}`} place="bottom" style={{ zIndex: 10000 }}>
        {visible
          ? 'Visible indicates that the item is shown on the live site.'
          : 'Hidden indicates that the item is not currently shown on the live site.'}
      </Tooltip>
    </div>
  )
}

export default ItemStatus

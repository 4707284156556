import { useContext } from 'react'

import * as Routes from '../../routes'
import { LeadClusterContext, LeadContext } from '../contexts'
import { standardHeaders } from '../entries/utils'

export const updateLeadClusterAPI = async (
  leadClusterId,
  data,
  dealershipId = window.dealer_slug
) => {
  const url = Routes.dealership_lead_cluster_path(dealershipId, leadClusterId)

  try {
    const response = await fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: standardHeaders,
    })

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({})) // Handle non-JSON errors
      throw new Error(`Error ${response.status}: ${errorData.message || response.statusText}`)
    }

    return response.json() // Ensure only valid JSON is returned
  } catch (error) {
    console.error(`Failed to update lead cluster ${leadClusterId}:`, error)
    throw error // Ensure errors propagate properly
  }
}

export const updateLeadClusterUserId = async (leadClusterId, userId, dealershipSlug) => {
  const data = {
    lead_cluster: {
      user_id: userId,
    },
  }

  return await updateLeadClusterAPI(leadClusterId, data, dealershipSlug)
}

export const updateLeadClusterState = async (leadClusterId, leadState, dealershipSlug) => {
  let data = {
    lead_cluster: {
      lead_state: leadState,
    },
  }
  return await updateLeadClusterAPI(leadClusterId, data, dealershipSlug)
}

export const deleteLeadClusterAPI = (dealershipSlug, leadClusterId) => {
  return fetch(Routes.dealership_lead_cluster_path(dealershipSlug, leadClusterId), {
    method: 'DELETE',
    headers: standardHeaders,
  })
}

export const updateContactAPI = (contactId, data) => {
  return fetch(
    `${window.location.origin}/dealerships/${window.dealer_slug}/contacts/${contactId}`,
    {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: standardHeaders,
    }
  )
}

export const ownerContext = () => {
  const { lead, setLead } = useContext(LeadContext)
  const { leadCluster, setLeadCluster } = useContext(LeadClusterContext)

  // Check if lead is present, otherwise use leadCluster
  return {
    owner: lead ?? leadCluster,
    setOwner: lead ? setLead : setLeadCluster,
  }
}

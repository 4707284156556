import { useEffect, useState } from 'react'

import { getPreviousTimePeriod } from '../../api'
import { useAnalyticsDashboard, useTabs, useTimeRangeLocation } from '../../contexts/hooks'

export const usePreviousTimePeriod = (module: string) => {
  const { timeRange } = useTimeRangeLocation()
  const { selectedTab } = useTabs()
  const { dashboardLevel, dashboardLevelLoaded, dealership, website, manufacturer } =
    useAnalyticsDashboard()

  const [totalForTimePeriod, setTotalForTimePeriod] = useState<number | string | null>(null)
  const [previousPeriod, setPreviousPeriod] = useState<number | null>(null)

  const [lastYear, setLastYear] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  // Need to add filtered campaigns

  useEffect(() => {
    if (!dashboardLevelLoaded) return

    const controller = new AbortController()
    const signal = controller.signal

    const fetchPreviousTimePeriod = async () => {
      setLoading(true)
      try {
        const data = await getPreviousTimePeriod(
          module,
          timeRange,
          selectedTab,
          dashboardLevel,
          dashboardLevelLoaded,
          dealership,
          website,
          manufacturer,
          signal
        )
        setPreviousPeriod(data?.previousPeriod)
        setLastYear(data?.lastYear)
        setTotalForTimePeriod(data?.total)
      } catch (error) {
        console.error('Failed to fetch previous time period data', error)
      } finally {
        setLoading(false)
      }
    }
    fetchPreviousTimePeriod()
    // return () => controller.abort()
  }, [
    module,
    timeRange,
    selectedTab,
    dashboardLevel,
    dashboardLevelLoaded,
    dealership,
    website,
    manufacturer,
  ])

  return { totalForTimePeriod, previousPeriod, lastYear, loading }
}

import React from 'react'

import { EditingPreviewTotal } from '../../../contexts/PreviewDataContext'
import { ModuleComponentConfig, ModuleItem } from '../../../types'
import { formatTotal } from '../utils'

const allowedModulesForTotal = [
  'Google Adwords Ads',
  'Google Display Ads',
  'Google VLA Ads',
  'Google Ads',
  'Facebook Ads',
  'Google Analytics',
]

export const TotalForTimePeriod: React.FC<{
  item: ModuleItem
  moduleConfig: ModuleComponentConfig
  totalForTimePeriod: string | number | null
  editingPreviewTotal: EditingPreviewTotal | null
}> = ({ item, moduleConfig, totalForTimePeriod, editingPreviewTotal }) => {
  if (!allowedModulesForTotal.includes(moduleConfig?.group)) return null
  return (
    <>
      <h3 className="text-center mt-2">
        {moduleConfig?.group === 'Facebook Ads'
          ? formatTotal(
              editingPreviewTotal?.[item?.module]?.total || totalForTimePeriod,
              moduleConfig?.isCurrency,
              moduleConfig?.isPercentage
            )
          : editingPreviewTotal?.[item?.module]?.total || totalForTimePeriod}
      </h3>
    </>
  )
}

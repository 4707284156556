import React from 'react'

import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Link, useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { Dealership, LeadCluster } from './types'

interface LeadClustersSectionProps {
  dealership: Dealership
  leadClusters: LeadCluster[]
}

const Category = (rowData: LeadCluster) => {
  let { dealershipSlug } = useParams<{ dealershipSlug: string }>()

  return (
    <>
      <i className="fa fa-bolt mr-1" />
      <Link to={Routes.dealership_lead_cluster_path(dealershipSlug, rowData.id)}>
        {rowData.category}
      </Link>
    </>
  )
}

const LeadState = (rowData: LeadCluster) => {
  return (
    <>
      <div
        className={`badge badge-${rowData?.lead_status_option?.colour_class_name || 'secondary'}`}
      >
        {rowData.lead_state}
      </div>
    </>
  )
}

const Item = (rowData: LeadCluster) => {
  if (!rowData.item) {
    return null // Early return if item is not available
  }

  const { slug, image_url, year, make, model } = rowData.item

  const content = (
    <>
      {image_url && (
        <img
          src={image_url}
          alt={`${year} ${make} ${model}`}
          style={{ width: '50px' }}
          className="mr-2 rounded"
        />
      )}
      {year} {make} {model}
    </>
  )

  if (slug) {
    return <Link to={Routes.car_path(slug)}>{content}</Link>
  }

  return <div>{content}</div>
}

const UserName = (rowData: LeadCluster) => {
  if (!rowData.user) {
    return (
      <div className="text-danger">
        <i className="fa fa-exclamation-circle mr-1"></i>
        Unassigned
      </div>
    )
  }

  return <>{rowData.user.name}</>
}

const LeadClustersSection: React.FC<LeadClustersSectionProps> = ({ leadClusters }) => (
  <div className="box mb-3 overflow-hidden">
    <div className="p-3">
      <h4>
        <i className="fa fa-bolt mr-1" />
        Lead Clusters
      </h4>
    </div>
    <DataTable value={leadClusters}>
      <Column field="category" header="Subject" body={Category} />
      <Column header="Item" body={Item} />
      <Column field="lead_state" header="Status" body={LeadState} />
      <Column field="user.name" header="User" body={UserName} />
      <Column
        field="created_at"
        header="Created"
        body={(rowData) => moment(rowData.created_at).fromNow()}
      />
    </DataTable>
  </div>
)

export default LeadClustersSection

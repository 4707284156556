import { useState } from 'react'

import { useParams } from 'react-router-dom'
import Select from 'react-select'

import { useFetchDealership } from '../dataHooks'

const stateOptions = [
  { label: 'NSW', value: 'NSW' },
  { label: 'QLD', value: 'QLD' },
  { label: 'VIC', value: 'VIC' },
  { label: 'ACT', value: 'ACT' },
  { label: 'NT', value: 'NT' },
  { label: 'SA', value: 'SA' },
  { label: 'TAS', value: 'TAS' },
  { label: 'WA', value: 'WA' },
]

const RegoSearch = ({ setVariants }) => {
  let { dealershipSlug } = useParams()
  let [rego, setRego] = useState('')
  let [state, setState] = useState()
  let [loading, setLoading] = useState(false)
  const { dealership } = useFetchDealership()

  let search = () => {
    if (!rego || !state) {
      return
    }

    if (disabledFeature) {
      return
    }

    setLoading(true)
    fetch(`/dealerships/${dealershipSlug}/cars/search_rego?rego=${rego}&state=${state}`)
      .then((res) => res.json())
      .then((res) => {
        setVariants([res.variant])
        setLoading(false)
      })
  }

  if (!dealership) {
    return <p>Loading...</p>
  }

  let disabledFeature = !dealership.redbook_rego_search

  return (
    <>
      <div className="box p-3 mb-3">
        <h5>Rego Search</h5>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            disabled={disabledFeature}
            placeholder="Rego..."
            onChange={(event) => setRego(event.target.value)}
          />
        </div>
        <div className="mb-3">
          <Select
            options={stateOptions}
            isDisabled={disabledFeature}
            placeholder="State..."
            onChange={(event) => setState(event.value)}
          />
        </div>
        <div
          className={
            'btn btn-outline-primary btn-block' + (loading || disabledFeature ? ' disabled' : '')
          }
          onClick={search}
          disabled={loading || disabledFeature}
        >
          {loading ? 'Loading...' : 'Search'}
        </div>
        {disabledFeature && (
          <div className="text-center">
            <small className="text-secondary">Contact support to enable feature.</small>
          </div>
        )}
      </div>
      <div className="text-center mb-2">
        <small className="text-secondary">OR</small>
      </div>
    </>
  )
}

export default RegoSearch

import { useContext, useEffect, useRef, useState } from 'react'

import moment from 'moment'
import { PrimeReactProvider } from 'primereact/api'
import { FilterMatchMode, FilterService } from 'primereact/api'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { SplitButton } from 'primereact/splitbutton'
import { Tag } from 'primereact/tag'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import Loading from '../Loading'
import { CurrentUserContext } from '../contexts'
import { useFetchDealership } from '../dataHooks'
import DataTableSearch from '../entries/DataTableSearch'
import { csrfToken, getInitials, humanize, truncateString } from '../entries/utils'
import { CopyableText } from '../shared/CopyableText'
import { StarRating } from '../shared/StarRating'
import { EditUserSidebar, NewUserSidebar } from './Form'
import ItemRole from './ItemRole'
import Show from './Show'
import SortableUsers from './SortableUsers'
import { UserService } from './userService'

const customFilter = (value, filters) => {
  if (value === '' || (value !== '' && filters === '')) {
    return true
  }
  return value === filters
}

FilterService.register('custom_category', customFilter)

const Image = ({ user, toggleVisibility }) => {
  let initials = getInitials(user.name || user.email)
  return (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault()
        toggleVisibility(user.id)
      }}
    >
      {user.image_url ? (
        <img src={user.image_url} alt={user.name} height={50} className="rounded-circle border" />
      ) : (
        <div className={`AvatarInitials ${initials[0]}`}>{initials}</div>
      )}
    </a>
  )
}

const Name = ({ toggleVisibility, rowStates, user }) => {
  return (
    <div>
      <a
        href={'#'}
        onClick={(e) => {
          e.preventDefault()
          toggleVisibility(user.id)
        }}
      >
        {truncateString(user.name, 30)}
      </a>
      {rowStates && (
        <Show
          userId={user.id}
          visible={rowStates[user?.id]?.visible}
          setVisible={() => toggleVisibility(user.id)}
          userName={user.name}
        />
      )}
      <div className="small mt-2 text-secondary">
        <CopyableText text={user.email} />
      </div>
      <div>
        {user.two_factor_enabled && (
          <span className="badge badge-success">
            <i className="fa fa-check mr-1"></i>
            2FA
          </span>
        )}
      </div>
    </div>
  )
}

const Locations = (user) => {
  if (!user.locations) {
    return null
  }

  return (
    <>
      <div className="small">{user.displayed_locations}</div>
    </>
  )
}

const Category = (user) => {
  return (
    <>
      {user.category}
      <div className="small text-secondary">{user.position}</div>
    </>
  )
}

const Rating = (user) => {
  return (
    <div className="d-flex">
      <div>
        {user.rating} ({user.reviews_count})
      </div>
      <div className="ml-auto">
        <StarRating stars={user.rating} count={user.reviews_count} />
      </div>
    </div>
  )
}

const LastSignIn = (user) => {
  if (user.last_sign_in_at == 'Never') {
    return (
      <div>
        <div>{user.last_sign_in_at}</div>
      </div>
    )
  } else {
    let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    // Parse the given UTC date string
    const utcDate = moment.utc(user.last_sign_in_at)

    // Convert to the user's local timezone
    const localDate = utcDate.tz(userTimezone)

    return (
      <div>
        <div>{localDate.format('DD/MM/YYYY, h:mm a')}</div>
        <div className="small text-secondary">{localDate.fromNow()}</div>
        <div className="small text-secondary">{user.last_sign_in_origin}</div>
      </div>
    )
  }
}

const Action = (user, loadLazyData) => {
  const currentUser = useContext(CurrentUserContext)
  let showLoginAs = currentUser?.admin
  let { dealershipSlug } = useParams()

  const user_path = dealershipSlug
    ? Routes.dealership_user_path(dealershipSlug, user.id)
    : Routes.user_path(user.id)
  const invite_user_path = dealershipSlug
    ? Routes.invite_dealership_user_path(dealershipSlug, user.id)
    : Routes.invite_user_path(user.id)
  let [editing, setEditing] = useState(false)

  const items = [
    {
      label: 'Resend Invite',
      icon: 'fa fa-envelope mr-2',
      command: () => {
        fetch(invite_user_path, {
          method: 'POST',
          headers: {
            'X-CSRF-Token': csrfToken,
          },
        }).then(() => {
          window.location.reload()
        })
      },
    },
    {
      label: 'Match Reviews',
      icon: 'fa fa-star mr-2',
      disabled: !dealershipSlug,
      command: () => {
        fetch(Routes.match_reviews_dealership_user_path(dealershipSlug, user.id), {
          method: 'POST',
          headers: {
            'X-CSRF-Token': csrfToken,
          },
        }).then(() => {
          window.location.reload()
        })
      },
    },

    {
      label: 'Remove Reviews',
      icon: 'fa fa-star mr-2',
      disabled: !dealershipSlug,
      command: () => {
        fetch(Routes.remove_reviews_dealership_user_path(dealershipSlug, user.id), {
          method: 'POST',
          headers: {
            'X-CSRF-Token': csrfToken,
          },
        }).then(() => {
          window.location.reload()
        })
      },
    },
    {
      label: 'Send Reset Password Email',
      icon: 'fa fa-envelope mr-2',
      disabled: !dealershipSlug,
      command: () => {
        fetch(
          Routes.send_reset_password_instructions_dealership_user_path(dealershipSlug, user.id),
          {
            method: 'POST',
            headers: {
              'X-CSRF-Token': csrfToken,
            },
          }
        ).then(() => {
          window.location.reload()
        })
      },
    },
    {
      label: 'Login As',
      icon: 'fa fa-user mr-2',
      disabled: !showLoginAs,
      command: () => {
        fetch(Routes.impersonate_user_path(user.id), {
          method: 'POST',
          headers: {
            'X-CSRF-Token': csrfToken,
          },
        }).then(() => {
          window.location.reload()
        })
      },
    },
    {
      label: 'Remove from Dealership',
      icon: 'fa fa-trash',
      command: () => {
        const userConfirmation = window.confirm('Are you sure?')

        if (!userConfirmation) {
          return
        }
        fetch(user_path, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': csrfToken,
          },
        }).then(() => {
          window.location.reload()
        })
      },
    },
  ]
  return (
    <div style={{ position: 'relative' }}>
      <EditUserSidebar
        visible={editing}
        setVisible={setEditing}
        userId={user.id}
        refreshData={loadLazyData}
      />
      <SplitButton
        label="Edit"
        buttonProps={{ id: 'edit-button' }}
        onClick={() => setEditing(true)}
        model={items}
        menuButtonProps={{ id: 'action-button' }}
        outlined
        rounded
        className="edit-user-button"
      />
    </div>
  )
}

const Heading = ({ dealership }) => (
  <div className="clearfix">
    <div className="float-right">
      <div className="btn-group mb-3">
        <NewUserSidebar />
        <button
          type="button"
          className="btn btn-outline-success dropdown-toggle"
          aria-haspopup="true"
          data-toggle="dropdown"
        />
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
          <SortableUsers />
          <a href={`/dealerships/${dealership.slug}/users/new`} className="dropdown-item">
            <i className="fa fa-plus"></i> User (OLD)
          </a>
        </div>
      </div>
    </div>
    <h4>
      <>{dealership.name} Team</>
    </h4>
  </div>
)

const App = ({ categories = [], locations = [] }) => {
  let { dealershipSlug } = useParams()
  const { dealership } = useFetchDealership()

  const dt = useRef(null)

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    role: { value: null, matchMode: FilterMatchMode.EQUALS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    category: { value: '', matchMode: FilterMatchMode.CUSTOM },
    locations: { value: '', matchMode: FilterMatchMode.CONTAINS },
  })

  const getSeverity = (role) => {
    switch (role) {
      case 'default':
        return 'danger'

      case 'manager':
        return 'success'
    }
  }

  const [roles] = useState(['manager', 'default'])

  const roleItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />
  }

  const roleRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={roles}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={roleItemTemplate}
        placeholder="Select Role"
        className="p-column-filter"
        showClear
        style={{ maxWidth: '8rem' }}
      />
    )
  }

  const optionsMap = {
    Category: categories.map((category) => ({ label: humanize(category), value: category })),
    Location: locations.map((location) => ({ label: location, value: location })),
  }

  const categoryRowFilterTemplate = (options) => rowFilterTemplate(options, 'Category')
  const locationRowFilterTemplate = (options) => rowFilterTemplate(options, 'Location')

  const rowFilterTemplate = (options, name, width = '8rem') => {
    const selectedOptions = optionsMap[name]
    return (
      <Dropdown
        value={options.value}
        options={selectedOptions}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder={`Select ${name}`}
        className="p-column-filter"
        style={{ maxWidth: width }}
      />
    )
  }

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  const [totalRecords, setTotalRecords] = useState(0)
  const [users, setUsers] = useState(null)
  let [loading, setLoading] = useState(false)
  let [page, setPage] = useState(1)
  const [rowStates, setRowStates] = useState({})
  let rows = 25

  const [lazyState, setlazyState] = useState({
    sortField: null,
    sortOrder: null,
    filters: {
      global: { value: null, matchMode: 'contains' }, // FilterMatchMode.CONTAINS is not used because lazyState was not updating
      role: { value: null, matchMode: 'equals' },
      name: { value: null, matchMode: 'contains' },
      category: { value: '', matchMode: 'custom_category' },
      locations: { value: '', matchMode: 'contains' },
    },
  })

  useEffect(() => {
    const initialStates = users?.reduce((acc, user) => {
      if (!acc[user.id]) {
        acc[user.id] = {
          visible: false,
        }
      }
      return acc
    }, {})
    setRowStates(initialStates)
  }, [users])

  const toggleVisibility = (id) => {
    setRowStates((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        visible: !prevState[id]?.visible,
      },
    }))
  }

  useEffect(() => {
    loadLazyData()
  }, [lazyState, page, filters])

  const loadLazyData = () => {
    let params = { page: page, rows: rows, filters: JSON.stringify(filters) }
    let csrf = csrfToken
    let dealershipId = dealershipSlug || null
    try {
      setLoading(true)
      UserService.getUsers(params, csrf, dealershipId).then((data) => {
        setTotalRecords(data.data.total_records)
        setUsers(data.data.users)
        setLoading(false)
      })
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const header = DataTableSearch({ filters: lazyState.filters, setFilters: setFilters, dt: dt })

  return (
    <PrimeReactProvider>
      {dealership && <Heading dealership={dealership} />}
      <div className="box">
        {loading && (
          <div className="p-4">
            <Loading />
          </div>
        )}
        {users && (
          <DataTable
            value={users}
            ref={dt}
            paginator
            rows={rows}
            header={header}
            totalRecords={totalRecords}
            filters={lazyState.filters}
            first={(page - 1) * rows}
            onPage={onPageChange}
            onFilter={(e) => setFilters(e.filters)}
            tableStyle={{ minWidth: '50rem' }}
            removableSort
            sortMode="multiple"
            scrollable
            globalFilterFields={['name', 'locations', 'role', 'category', 'email']}
            style={{ overflow: 'visible' }}
            wrapper={{ style: { overflow: 'visible' } }}
            filterDisplay="row"
            loading={loading}
            lazy
          >
            <Column
              field="id"
              header="ID"
              exportable={true}
              headerStyle={{ display: 'none' }}
              bodyStyle={{ display: 'none' }}
            />
            <Column
              body={(rowData) => (
                <Image user={rowData} toggleVisibility={toggleVisibility} rowStates={rowStates} />
              )}
              field="image"
              header="Image"
              exportable={false}
            />
            <Column
              body={(rowData) => (
                <Name user={rowData} toggleVisibility={toggleVisibility} rowStates={rowStates} />
              )}
              filter
              field="name"
              header="Name"
              filterPlaceholder="Search by name"
              exportable={true}
            />
            <Column
              field="role"
              header="Role"
              sortable
              showFilterMenu={false}
              filterMenuStyle={{ width: '8rem' }}
              style={{ maxWidth: '8rem' }}
              body={ItemRole}
              filter
              filterElement={roleRowFilterTemplate}
              exportable={true}
            />
            <Column
              body={Locations}
              sortable
              field="locations"
              header="Location(s)"
              showFilterMenu={false}
              filterMenuStyle={{ width: '8rem' }}
              style={{ maxWidth: '8rem' }}
              filter
              filterElement={locationRowFilterTemplate}
              exportable={false}
            />
            <Column
              body={Category}
              sortable
              field="category"
              header="Category"
              showFilterMenu={false}
              filterMenuStyle={{ width: '8rem' }}
              style={{ maxWidth: '8rem' }}
              filter
              filterElement={categoryRowFilterTemplate}
              exportable={false}
            />
            <Column
              body={Rating}
              field="rating"
              style={{ maxWidth: '10rem' }}
              header="Google Rating"
            />
            <Column
              body={LastSignIn}
              field="last_sign_in_at"
              header="Last Login In"
              exportable={false}
            />
            <Column
              body={(rowData) => Action(rowData, loadLazyData)}
              header="Actions"
              exportable={false}
            />
          </DataTable>
        )}
      </div>
    </PrimeReactProvider>
  )
}

const Wrapper = () => {
  let [loading, setLoading] = useState(false)
  let [params, setParams] = useState(false)
  let { dealershipSlug } = useParams()

  useEffect(() => {
    setLoading(true)
    let url = `/users/react_index.json`
    if (dealershipSlug) {
      url = `/dealerships/${dealershipSlug}/users/react_index.json`
    }

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setParams(data)
        setLoading(false)
      })
  }, [])

  if (loading) {
    return (
      <div className="box m-4 w-100">
        <div className="p-4">
          <Loading />
        </div>
      </div>
    )
  }

  return (
    <div className="p-4 w-100">
      <App locations={params.locations} categories={params.categories} />
    </div>
  )
}

export default Wrapper

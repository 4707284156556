import { useEffect, useState } from 'react'

import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import Select from 'react-select'

import { useAnalyticsDashboard } from '../../../contexts/hooks'
import { useViewportData } from '../../../hooks'
import { Car, ModuleProps } from '../../../types'

const Stocknum = (car: Car) => {
  return (
    <a href={`/cars/${car.slug}`} target="_blank" rel="noreferrer">
      {car.stocknum}
    </a>
  )
}

const CarsPopup = ({ value, rowData, nudOption, selectedLocation }) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false)
  const [cars, setCars] = useState<Car[]>([])
  const [loading, setLoading] = useState(false)
  const { dealership } = useAnalyticsDashboard()

  useEffect(() => {
    if (!isDialogVisible) return
    const fetchCarsData = async () => {
      setLoading(true)
      console.log(selectedLocation)
      try {
        // Should probably be a rails route but I can't find it
        const params = new URLSearchParams()

        // Add parameters only if they are defined
        if (dealership?.slug) params.set('dealership_slug', dealership.slug)
        if (value !== undefined) params.set('lead_clusters_count', value)
        if (rowData?.range) params.set('age_range', rowData.range)
        if (nudOption) params.set('car_type', nudOption)
        if (selectedLocation?.id) params.set('location_id', selectedLocation.id)

        const response = await fetch(
          `/api/v1/dealerships/${dealership?.slug}/cars/cars_by_age_and_lead_cluster_count.json?${params.toString()}`
        )
        const carsData = await response.json()
        setCars(carsData)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    fetchCarsData()
  }, [isDialogVisible])

  return (
    <>
      <span onClick={() => setIsDialogVisible(true)} style={{ cursor: 'pointer' }}>
        {rowData[value]}
      </span>
      <Dialog
        header="Cars"
        visible={isDialogVisible}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogVisible(false)
        }}
        dismissableMask
      >
        <div className="p-grid p-dir-col">
          <DataTable value={cars} loading={loading}>
            <Column field="days_old" header="Days Old" sortable />
            <Column field="stocknum" header="Stock #" body={Stocknum} />
            <Column field="make" header="Make" />
            <Column field="model" header="Model" />
            <Column field="lead_clusters_count" header="Lead Clusters" sortable />
          </DataTable>
        </div>
      </Dialog>
    </>
  )
}

interface Option {
  label: string
  value: string
}

const nudOptions: Option[] = [
  { label: 'Used', value: 'used' },
  { label: 'Demo', value: 'demo' },
  { label: 'New', value: 'new' },
]

const LeadClusterCountByAge: React.FC<ModuleProps> = ({ item, timeRange, selectedLocation }) => {
  const [nudOption, setNudOption] = useState('used')
  const { data, loading, viewportRef } = useViewportData(
    item?.module,
    timeRange,
    selectedLocation,
    { car_type: nudOption }
  )
  const [transformedData, setTransformedData] = useState<any>(null)
  const [columnTotals, setColumnTotals] = useState<{}>({})
  const [overallTotal, setOverallTotal] = useState<number>(0)

  useEffect(() => {
    if (data && data.car_lead_cluster_matrix) {
      const rows = Object.entries(data.car_lead_cluster_matrix).map(([range, values]) => {
        // Calculate row total
        const rowTotal = Object.values(values).reduce((sum, count) => sum + count, 0)
        return {
          range,
          ...(typeof values === 'object' ? values : {}),
          total: rowTotal, // Add row total
        }
      })

      // Calculate column totals
      const totals = { '0': 0, '1': 0, '2': 0, '3': 0, '4': 0, '5+': 0 }
      let grandTotal = 0

      rows.forEach((row) => {
        Object.keys(totals).forEach((key) => {
          totals[key] += row[key]
        })
        grandTotal += row.total
      })

      setColumnTotals(totals)
      setOverallTotal(grandTotal)
      setTransformedData(rows)
    }
  }, [data])

  const sharedParams = { nudOption, selectedLocation }

  return (
    <div ref={viewportRef} className="mt-2">
      <div className="mb-2">
        <Select
          options={nudOptions}
          value={nudOptions.find((option) => option.value === nudOption)}
          onChange={(option) => setNudOption(option.value)}
        />
      </div>

      <DataTable value={transformedData} loading={loading}>
        <Column
          field="range"
          headerClassName="data-table-header"
          className="data-table-header"
          footer="Total"
        />
        <Column
          field="0"
          header="0"
          headerClassName="data-table-header"
          footer={columnTotals['0']}
          body={(rowData) => <CarsPopup rowData={rowData} value={0} {...sharedParams} />}
        />
        <Column
          field="1"
          header="1"
          headerClassName="data-table-header"
          footer={columnTotals['1']}
          body={(rowData) => <CarsPopup rowData={rowData} value={1} {...sharedParams} />}
        />
        <Column
          field="2"
          header="2"
          headerClassName="data-table-header"
          footer={columnTotals['2']}
          body={(rowData) => <CarsPopup rowData={rowData} value={2} {...sharedParams} />}
        />
        <Column
          field="3"
          header="3"
          headerClassName="data-table-header"
          footer={columnTotals['3']}
          body={(rowData) => <CarsPopup rowData={rowData} value={3} {...sharedParams} />}
        />
        <Column
          field="4"
          header="4"
          headerClassName="data-table-header"
          footer={columnTotals['4']}
          body={(rowData) => <CarsPopup rowData={rowData} value={4} {...sharedParams} />}
        />
        <Column
          field="5+"
          header="5+"
          headerClassName="data-table-header"
          footer={columnTotals['5+']}
          body={(rowData) => <CarsPopup rowData={rowData} value="5+" {...sharedParams} />}
        />
        <Column
          field="total"
          header="Total"
          headerClassName="data-table-header"
          footer={overallTotal}
        />
      </DataTable>
    </div>
  )
}

export default LeadClusterCountByAge

import { useContext, useState } from 'react'

import { Dialog } from 'primereact/dialog'

import { CarContext } from '../contexts'
import { standardHeaders } from '../entries/utils'
import { CommentTextarea } from './CarSearchItemComments'

const VariablesPopup = () => {
  let [visible, setVisible] = useState(false)

  return (
    <div className="ml-auto">
      <Dialog
        header="Variables"
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        dismissableMask={true}
      >
        Dealer comments also supports variables. To use variables, just use curly brackets.
        Available variables are:
        <ul>
          <li>
            <code>make</code>
          </li>
          <li>
            <code>model</code>
          </li>
          <li>
            <code>badge</code>
          </li>
          <li>
            <code>year</code>
          </li>
          <li>
            <code>km</code>
          </li>
          <li>
            <code>features</code>
          </li>
        </ul>
      </Dialog>
      <div className="btn btn-outline-secondary btn-sm" onClick={() => setVisible(true)}>
        Variables
      </div>
    </div>
  )
}

let blank_comments = ['', '<p></p>']

const DealerComments = () => {
  let { setCar, car } = useContext(CarContext)

  let [editing, setEditing] = useState(false)
  let [dealercomments, setDealercomments] = useState(car.dealercomments)
  let [formattedComments, setFormattedComments] = useState(car.dealercomments_with_formatting)
  let [loading, setLoading] = useState(false)

  const onSave = () => {
    setLoading(true)
    fetch(`/cars/${car.id}`, {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify({
        car: {
          dealercomments: dealercomments,
        },
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        setLoading(false)
        setCar({ ...car, dealercomments: dealercomments })
        setFormattedComments(res.dealercomments_with_formatting)
        setEditing(false)
      })
      .catch((error) => console.log('Error:', error))
  }

  return (
    <>
      <div className="d-flex">
        <h4>Dealer comments</h4>
        <div>
          <div
            className="btn btn-sm btn-outline-secondary ml-2"
            onClick={() => setEditing(!editing)}
          >
            {editing ? (
              <>
                <i className="fa fa-times mr-1"></i>Cancel
              </>
            ) : (
              <>
                <i className="fa fa-edit mr-1"></i>Edit
              </>
            )}
          </div>
          {editing && (
            <div
              className={'btn btn-sm btn-outline-success ml-2' + (loading ? ' disabled' : '')}
              onClick={onSave}
            >
              {loading ? (
                <>Loading...</>
              ) : (
                <>
                  <i className="fa fa-save mr-1"></i>Save
                </>
              )}
            </div>
          )}
        </div>
        {editing && <VariablesPopup />}
      </div>
      {editing ? (
        <CommentTextarea
          comments={dealercomments}
          setComments={setDealercomments}
          loading={loading}
        />
      ) : (
        <div>
          {car.dealercomments && !blank_comments.includes(car.dealercomments) ? (
            <div
              style={{ whiteSpace: 'pre-line' }}
              className="text-secondary"
              dangerouslySetInnerHTML={{ __html: formattedComments }}
            />
          ) : (
            <div className="text-danger">
              <i className="fa fa-exclamation-triangle mr-1"></i>
              No dealer comments
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default DealerComments

import { useRef, useState } from 'react'

import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { FormProvider, useFieldArray, useForm, useFormContext, useFormState } from 'react-hook-form'

import { standardHeaders } from '../../entries/utils'
import { PendingText } from '../../tanstackPlaceholderUtils'

export type SpecificationsFormData = {
  name: string
  manufacturer_name: string
  model_specifications: {
    id?: number
    category: string
    value?: string
  }[]
}

type EditableAction = 'none' | number // edit all or edit a specific row

const SpecificationsFormFields = ({
  editAction,
  setEditAction,
}: {
  editAction: EditableAction
  setEditAction: (val: EditableAction) => void
}) => {
  const { control, register } = useFormContext<SpecificationsFormData>()
  const { errors } = useFormState()

  const { fields } = useFieldArray({
    control,
    name: 'model_specifications',
  })

  return (
    <div className="mb-3">
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th style={{ width: '46%' }} scope="col">
              Category
            </th>
            <th style={{ width: '46%' }} scope="col">
              Value
            </th>
            <th style={{ width: '8%' }} scope="col">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => {
            const isEditable = editAction === index
            return (
              <tr key={item.id}>
                <td>{item.category}</td>
                <td>
                  <InputText
                    className={'w-100 p-1 ' + (isEditable ? '' : ' border-0 bg-white')}
                    {...register(`model_specifications.${index}.value`)}
                    disabled={!isEditable}
                  />
                  {errors?.model_specifications?.[index]?.value && (
                    <span className="d-block small text-danger">
                      {errors?.model_specifications?.[index]?.value?.message}
                    </span>
                  )}
                </td>
                <td className="d-flex justify-content-end">
                  {isEditable ? (
                    <button type="submit" className="btn btn-block btn-sm btn-outline-success">
                      <PendingText text="Save" />
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-block btn-sm btn-outline-success"
                      onClick={() => setTimeout(() => setEditAction(index), 50)}
                      disabled={editAction !== 'none'}
                    >
                      Edit
                    </button>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export const SpecificationsForm = ({
  modelSlug,
  defaultValues = {},
}: {
  modelSlug: string
  defaultValues?: Partial<SpecificationsFormData>
}) => {
  // Manage form state
  const [editAction, setEditAction] = useState<EditableAction>('none')
  const form = useForm<SpecificationsFormData>({
    mode: 'onSubmit',
    defaultValues,
  })
  const toast = useRef(null)

  // Handle form data submission
  const onSubmit = async (data: SpecificationsFormData) => {
    const id = data.model_specifications[editAction]?.id
    const value = data.model_specifications[editAction]?.value
    const url = `/models/${modelSlug}/model_specifications/${id}`

    const method = 'PATCH'
    try {
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify({
          model_specification: { value: value },
        }),
        headers: standardHeaders,
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      toast.current.show({
        severity: 'success',
        summary: 'Saved Successfully',
      })
      setEditAction('none')
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Unable To Save',
        detail: `A server error occurred: ${error.message}`,
      })
    }
  }
  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(async (data) => {
          await onSubmit(data)
        })}
      >
        <Toast ref={toast} />
        <div className="bg-white table-responsive">
          <SpecificationsFormFields editAction={editAction} setEditAction={setEditAction} />
        </div>
      </form>
    </FormProvider>
  )
}

import { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import Loading from '../Loading'
import { Errors, Select, Switch } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'

const carGradeOptions = ['A', 'B', 'C', 'D', 'E'].map((o) => ({ value: o, label: o }))
const minimumLeadOptions = [1, 2, 3, 4, 5, 6, 7, 8].map((o) => ({ value: o, label: `${o} leads` }))
const dayOptions = [1, 2, 3, 4, 5, 6, 7, 8].map((o) => ({ value: o, label: `${o} days` }))
const hourOptions = [12, 24, 36, 48, 72, 84, 96, 120, 144, 168].map((o) => ({
  value: o,
  label: `${o} hours`,
}))

const SendCustomerSms = ({ setSmsTemplateId, smsTemplateId }) => {
  let [smsTemplates, setSmsTemplates] = useState([])

  let { dealershipSlug } = useParams()

  useEffect(() => {
    fetch(Routes.dealership_sms_templates_path(dealershipSlug), {
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then((res) => {
        setSmsTemplates(res)
      })
  }, [])

  let selectOptions = smsTemplates.map((st) => ({ label: st.title, value: st.id }))

  return (
    <div className="mt-3">
      <div className="text-muted">
        <p>
          <b>When a car is restricted, the customer will receive an SMS with the following:</b>
        </p>
        {smsTemplates.length > 0 && (
          <Select
            options={selectOptions}
            defaultValue={selectOptions.filter((o) => o.value === smsTemplateId)}
            label="Select SMS Template"
            onChange={(e) => {
              setSmsTemplateId(e.value)
            }}
          />
        )}
        <b>Default Fallback:</b>
        <p>
          The [car.make] [car.model] that you enquired on has been removed from Carsales as we have
          had a lot of interest in the car. The car may still be available while we engage potential
          buyers, but we recommend you contact the dealership ASAP if you wish to secure it.
        </p>
      </div>
    </div>
  )
}

const SmartPublisherSettings = ({
  smartPublisherRestriction,
  smartPublisher,
  setSmartPublisher,
}) => {
  let [loading, setLoading] = useState(false)

  let { dealershipSlug } = useParams()

  let [restrictByGrade, setRestrictByGrade] = useState(
    smartPublisherRestriction?.restrict_by_grade || false
  )
  let [restrictByLeadNumber, setRestrictByLeadNumber] = useState(
    smartPublisherRestriction?.restrict_by_leads || false
  )
  let [carGradesToRestrict, setCarGradesToRestrict] = useState(
    smartPublisherRestriction?.car_grades_to_restrict || []
  )
  let [gradeRestrictionNumberOfDays, setGradeRestrictionNumberOfDays] = useState(
    smartPublisherRestriction?.grade_restriction_number_of_days || 7
  )
  let [leadRestrictionNumberOfHours, setLeadRestrictionNumberOfHours] = useState(
    smartPublisherRestriction?.leads_restriction_number_of_hours || 48
  )
  let [minimumLeadNumberToRestrict, setMinimumLeadNumberToRestrict] = useState(
    smartPublisherRestriction?.minimum_lead_number_to_restrict || 3
  )
  let [leadCountingPeriod, setLeadCountingPeriod] = useState(
    smartPublisherRestriction?.lead_counting_period || 24
  )
  let [smsTemplateId, setSmsTemplateId] = useState(
    smartPublisherRestriction?.sms_template_id || null
  )
  let [errors, setErrors] = useState()
  let [sendCustomerSms, setSendCustomerSms] = useState(smartPublisherRestriction?.send_customer_sms)

  const submit = () => {
    setLoading(true)

    let data = {
      dealership: {
        smart_publisher: smartPublisher,
        smart_publisher_restriction_attributes: {
          restrict_by_grade: restrictByGrade,
          restrict_by_leads: restrictByLeadNumber,
          grade_restriction_number_of_days: gradeRestrictionNumberOfDays,
          leads_restriction_number_of_hours: leadRestrictionNumberOfHours,
          lead_counting_period: leadCountingPeriod,
          car_grades_to_restrict: carGradesToRestrict,
          minimum_lead_number_to_restrict: minimumLeadNumberToRestrict,
          send_customer_sms: sendCustomerSms,
          sms_template_id: smsTemplateId,
          ...(smartPublisherRestriction?.id && { id: smartPublisherRestriction.id }),
        },
      },
    }

    fetch(`/dealerships/${dealershipSlug}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: standardHeaders,
    })
      .then((response) => {
        if (response.status === 422) {
          return response.json().then((data) => {
            setLoading(false)
            setErrors(data)
            return Promise.reject(data)
          })
        }
        return response.json() // Handle other statuses normally
      })
      .then((res) => {
        setLoading(false)
      })
  }

  return (
    <div className="p-3 w-100">
      <div className="d-flex mb-2">
        <h4>Smart Publisher Settings</h4>
      </div>

      <div className="box mb-2">
        <div className="py-4 px-3">
          <Switch
            label="Smart Publisher"
            value={smartPublisher}
            onChange={() => setSmartPublisher(!smartPublisher)}
            id="smart-publisher-switch"
          />
        </div>
      </div>

      {smartPublisher && (
        <>
          <div className="box mb-2">
            <div className="py-4 px-3">
              <Switch
                label="Restrict cars by Car Grade"
                value={restrictByGrade}
                onChange={() => setRestrictByGrade(!restrictByGrade)}
                id="restrict-by-grade-switch"
              />
              {restrictByGrade && (
                <div className="mt-3">
                  <Select
                    options={carGradeOptions}
                    isMulti
                    label="Which car grades would you like to restrict?"
                    value={carGradesToRestrict.map((o) => ({ value: o, label: o }))}
                    onChange={(e) => {
                      setCarGradesToRestrict(e.map((o) => o.value))
                    }}
                  />
                  <Select
                    label="How long would you like to restrict these items? (in days)"
                    placeholder="Eg. 7 days"
                    options={dayOptions}
                    value={{
                      value: gradeRestrictionNumberOfDays,
                      label: `${gradeRestrictionNumberOfDays} days`,
                    }}
                    onChange={(e) => setGradeRestrictionNumberOfDays(e.value)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="box mb-2">
            <div className="py-4 px-3">
              <Switch
                label="Restrict cars by number of leads"
                value={restrictByLeadNumber}
                onChange={() => setRestrictByLeadNumber(!restrictByLeadNumber)}
                id="restrict-by-leads-switch"
              />
              {restrictByLeadNumber && (
                <div className="mt-3">
                  <Select
                    options={minimumLeadOptions}
                    label="How many leads should a car have before we restrict the car?"
                    value={{
                      label: `${minimumLeadNumberToRestrict} leads`,
                      value: minimumLeadNumberToRestrict,
                    }}
                    onChange={(value) => setMinimumLeadNumberToRestrict(value.value)}
                  />
                  <Select
                    options={hourOptions}
                    label="Select lead counting period (hours)"
                    hint="Specify the time window for counting leads"
                    value={{ label: `${leadCountingPeriod} hours`, value: leadCountingPeriod }}
                    onChange={(value) => setLeadCountingPeriod(value.value)}
                  />
                  <Select
                    options={hourOptions}
                    label="How long would you like to restrict these items? (in hours)"
                    placeholder="Eg. 48 hours"
                    value={{
                      label: `${leadRestrictionNumberOfHours} hours`,
                      value: leadRestrictionNumberOfHours,
                    }}
                    onChange={(e) => setLeadRestrictionNumberOfHours(e.value)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="box">
            <div className="py-4 px-3">
              <Switch
                label="Send SMS when car is restricted"
                value={sendCustomerSms}
                onChange={() => setSendCustomerSms(!sendCustomerSms)}
                id="send-customer-sms-switch"
              />
              {sendCustomerSms && (
                <SendCustomerSms
                  smsTemplateId={smsTemplateId}
                  setSmsTemplateId={setSmsTemplateId}
                />
              )}
            </div>
          </div>
        </>
      )}
      <Errors errors={errors} />
      <div
        className={'btn btn-outline-secondary mt-3' + (loading ? ' disabled' : '')}
        onClick={submit}
        disabled={loading}
      >
        {loading ? 'Loading...' : 'Save Changes'}
      </div>
    </div>
  )
}

const Wrapper = () => {
  let [loading, setLoading] = useState(false)
  let [smartPublisherRestriction, setSmartPublisherRestriction] = useState(false)
  let [smartPublisher, setSmartPublisher] = useState(false)

  let { dealershipSlug } = useParams()

  useEffect(() => {
    setLoading(true)
    fetch(`/dealerships/${dealershipSlug}/cars/smart_publisher_settings.json`)
      .then((res) => res.json())
      .then((res) => {
        setSmartPublisherRestriction(res.smart_publisher_restriction)
        setSmartPublisher(res.smart_publisher)
        setLoading(false)
      })
  }, [])

  if (loading) {
    return (
      <div className="p-3">
        <Loading />
      </div>
    )
  }

  return (
    <SmartPublisherSettings
      smartPublisherRestriction={smartPublisherRestriction}
      smartPublisher={smartPublisher}
      setSmartPublisher={setSmartPublisher}
    />
  )
}

export default Wrapper

import { useEffect, useState } from 'react'

import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'

import * as Routes from '../../routes'
import Loading from '../Loading'

const Actions = (rowData) => {
  return (
    <>
      <a
        href={Routes.incoming_email_path(rowData.id)}
        className="btn btn-sm btn-outline-primary mr-2"
      >
        <i className="fa fa-eye"></i>
      </a>
      <a
        href={Routes.edit_incoming_email_path(rowData.id)}
        className="btn btn-sm btn-outline-primary mr-2"
      >
        <i className="fa fa-edit"></i>
      </a>
      <a
        href={Routes.incoming_email_path(rowData.id)}
        className="btn btn-sm btn-outline-danger"
        data-method="delete"
      >
        <i className="fa fa-trash"></i>
      </a>
    </>
  )
}

const IncomingEmails = () => {
  let [loading, setLoading] = useState(false)
  let [incomingEmails, setIncomingEmails] = useState([])

  useEffect(() => {
    setLoading(true)
    fetch('/incoming_emails.json')
      .then((res) => res.json())
      .then((data) => {
        setIncomingEmails(data)
        setLoading(false)
      })
  }, [])

  if (loading) {
    return (
      <div className="p-3">
        <Loading></Loading>
      </div>
    )
  }

  return (
    <div className="container py-3">
      <h4>Incoming Emails</h4>
      <div className="d-flex align-items-center mb-2">
        <p className="mb-0">
          This table is used to map incoming emails to different services / mailboxes / dealerships.
        </p>
        <div className="ml-auto">
          <a href={Routes.new_incoming_email_path()} className="btn btn-outline-primary">
            <i className="fa fa-plus mr-1" />
            Incoming Email
          </a>
        </div>
      </div>
      <div className="box mb-3">
        <DataTable value={incomingEmails}>
          <Column field="id" header="ID" />
          <Column field="email" header="From" sortable />
          <Column field="dealership.name" header="Dealership" sortable />
          <Column field="service" header="Service" sortable />
          <Column field="location.name" header="Location" sortable />
          <Column header="Actions" body={Actions} />
        </DataTable>
      </div>
    </div>
  )
}

export default IncomingEmails

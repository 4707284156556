import { useContext, useState } from 'react'

import moment from 'moment-timezone'
import { Column } from 'primereact/column'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Panel } from 'primereact/panel'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { AppointmentService } from '../appointments/appointmentService'
import { AppointmentContext, CurrentUserContext, LeadClusterContext } from '../contexts'
import { standardHeaders } from '../entries/utils'
import showToast from '../shared/ShowToast'
import AppointmentForm from './AppointmentForm'

const appointmentAssignment = (appointment) => {
  if (!appointment.user) return null
  const { length_in_minutes, user } = appointment
  return (
    <div>
      <p>
        <span className="text-secondary">{user.name}</span>
        <br />
        <small className="text-secondary">{length_in_minutes} minutes</small>
      </p>
    </div>
  )
}

const appointmentSchedulable = (appointment) => {
  if (appointment.purpose === 'Test Drive') {
    const testDriveUrl = `/dealerships/${appointment.dealership_id}/test_drives/${appointment.schedulable_id}/edit`
    return (
      <div>
        <p>
          <span className="text-secondary">
            <Link to={testDriveUrl}>Test Drive</Link>
          </span>
          <br />
          <small className="text-secondary">{appointment.car_for_test_drive}</small>
        </p>
      </div>
    )
  } else {
    return (
      <div>
        <p>
          <span className="text-secondary">
            {appointment.purpose === 'Other' ? 'Appointment' : appointment.purpose}
          </span>
        </p>
      </div>
    )
  }
}

const appointmentDate = (appointment) => {
  let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const localDate = new Date(appointment.starts_at)
  // Get the local time offset in minutes and convert it to milliseconds
  const localTimeOffset = localDate.getTimezoneOffset() * 60000

  // Adjust localDate by the local time offset to get the correct local time
  const adjustedDate = new Date(localDate.getTime() - localTimeOffset)
  return (
    <div>
      <p>
        <span className="text-secondary">
          <i className="fa-regular fa-calendar mr-2"> </i>
        </span>
        <span className="text-secondary">
          {' '}
          {moment(adjustedDate).tz(userTimezone).format('DD/MM/YYYY')}
        </span>
        <br />
        <span className="text-secondary">
          <i className="fa-regular fa-clock mr-2"> </i>
        </span>
        <span className="text-secondary">
          {' '}
          {moment(adjustedDate).tz(userTimezone).format('hh:mm A')}
        </span>
      </p>
    </div>
  )
}

const appointmentNote = (appointment) => {
  return (
    <div style={{ wordWrap: 'break-word' }}>
      <p>
        <span className="text-secondary">
          {appointment.note?.length > 50
            ? `${appointment.note.substring(0, 50)}... `
            : appointment.note}
          {appointment.note?.length > 50 && (
            <a href="#" onClick={() => alert(appointment.note)}>
              See More
            </a>
          )}
        </span>
      </p>
    </div>
  )
}

const AppointmentItem = ({ appointment, onEdit, onDelete }) => {
  const { starts_at, length_in_minutes, note, user } = appointment
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return (
    <div className="border-bottom">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          {/* <div>
            <strong>Assigned to:</strong> {user.name} 
          </div>
          <div>
            <strong>Date:</strong> {moment(starts_at).format('DD/MM/YYYY HH:mm')}
          </div>
          <div>
            <strong>Length:</strong> {length_in_minutes} minutes
          </div> */}
          <p>
            <span className="text-secondary">{user.name}</span>
            <br />
            <small className="text-secondary">{length_in_minutes} minutes</small>
          </p>
        </div>

        <div>
          <p>
            <span className="text-secondary">
              <i className="fa-regular fa-calendar mr-2"> </i>
            </span>
            <b>Date:</b>
            <span className="text-secondary">
              {' '}
              {moment(starts_at).tz(userTimezone).format('DD/MM/YYYY')}
            </span>
            <br />
            <span className="text-secondary">
              <i className="fa-regular fa-clock mr-2"> </i>
            </span>
            <b>Time:</b>
            <span className="text-secondary">
              {' '}
              {moment(starts_at).tz(userTimezone).format('hh:mm A')}
            </span>
          </p>
        </div>

        <div>
          <div>
            <strong>Note:</strong> {note}
          </div>
        </div>
        <div className="d-flex">
          <button
            className="btn btn-outline-primary ml-auto btn-sm mb-2 mr-2"
            onClick={() => onEdit(appointment)}
          >
            <i className="fas fa-edit"></i>
            &nbsp;Edit
          </button>
          <button
            className="btn btn-outline-danger ml-auto btn-sm mb-2"
            onClick={() => onDelete(appointment)}
          >
            <i className="fas fa-trash"></i>
            &nbsp;Delete
          </button>
        </div>
      </div>
    </div>
  )
}

AppointmentItem.propTypes = {
  appointment: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

const Appointments = () => {
  const { leadCluster, notification, setLeadCluster } = useContext(LeadClusterContext)
  const [appointments, setAppointments] = useState(leadCluster.appointments || [])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentAppointment, setCurrentAppointment] = useState(null)
  const currentUser = useContext(CurrentUserContext)
  const csrf = document.querySelector("meta[name='csrf-token']")?.getAttribute('content')

  const openModal = (appointment = null) => {
    setCurrentAppointment(appointment)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentAppointment(null)
  }

  const isAppointmentSlotAvailable = (appointmentData) => {
    const startsAt = moment(appointmentData.startsAt)
    const endsAt = moment(startsAt.toDate().getTime() + appointmentData.lengthInMinutes * 60000)

    const isSlotAvailable = appointments.every((existingAppointment) => {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

      if (appointmentData.id && appointmentData.id === existingAppointment.id) return true
      if (appointmentData.user_id !== existingAppointment.user_id) return true

      const existingStartsAt = moment.tz(existingAppointment.starts_at, userTimezone)
      const existingEndsAt = moment
        .tz(existingStartsAt, userTimezone)
        .add(existingAppointment.length_in_minutes, 'minutes')

      // Check if the new appointment overlaps with existing appointments
      const isOverlap =
        startsAt.isBetween(existingStartsAt, existingEndsAt, undefined, '[)') ||
        endsAt.isBetween(existingStartsAt, existingEndsAt, undefined, '(]') ||
        existingStartsAt.isBetween(startsAt, endsAt, undefined, '[)')

      return !isOverlap
    })

    return isSlotAvailable
  }

  const checkAssigneeAvailability = (appointmentData) => {
    const startsAtDate = new Date(appointmentData.startsAt).toISOString()
    const endsAtminutes =
      new Date(appointmentData.startsAt).getTime() + appointmentData.lengthInMinutes * 60000
    const endsAtDate = new Date(endsAtminutes).toISOString()

    let params = {
      starts_at: startsAtDate.toLocaleString('en-GB'),
      user_id: appointmentData.user_id,
      ends_at: endsAtDate.toLocaleString('en-GB'),
    }

    return AppointmentService.checkingOverlapingUnavailableIntervals(
      params,
      csrf,
      leadCluster.dealership_id
    )
  }

  const handleSave = async (appointmentData) => {
    appointmentData.user = appointmentData.user || currentUser
    appointmentData.user_id = appointmentData.user?.id || currentUser.id
    appointmentData.lead_cluster_id = leadCluster.id
    const startsAtDate = new Date(appointmentData.startsAt).toISOString()
    appointmentData.starts_at = startsAtDate.toLocaleString('en-GB')
    appointmentData.length_in_minutes = appointmentData.lengthInMinutes
    appointmentData.notify_assignee = appointmentData.notifyAssignee
    appointmentData.notify_contact = appointmentData.notifyContact
    appointmentData.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    if (appointmentData.carForTestDrive) {
      const testDriveData = await createTestDrive(appointmentData)
      appointmentData.schedulable_id = testDriveData.id
      appointmentData.schedulable_type = 'TestDrive'
    }

    if (!isAppointmentSlotAvailable(appointmentData)) {
      showToast(
        notification,
        'warn',
        'Warning',
        'There is already an appointment for this user on this timeslot.'
      )
    }

    const assigneeUnavailable = await checkAssigneeAvailability(appointmentData)

    // check if assigneeUnavailable is undefined
    if (assigneeUnavailable === undefined || assigneeUnavailable.data === undefined) {
      showToast(
        notification,
        'error',
        'An error occurred',
        'The assignee availability could not be checked. Due to assignee not belonging to the dealership.'
      )
      return
    }

    if (assigneeUnavailable.data.length > 0) {
      showToast(notification, 'error', 'The assignee is unavailable during the selected timeslot.')
      return
    }

    if (appointmentData.id) {
      const url = `/dealerships/${leadCluster.dealership_id}/appointments/${appointmentData.id}`

      const response = await fetch(url, {
        method: 'PUT',
        headers: standardHeaders,
        body: JSON.stringify(appointmentData),
      })

      if (!response.ok) {
        showToast(notification, 'error', 'An error occurred', 'The appointment could not be saved.')
      } else {
        const data = await response.json()
        data.starts_at = data.starts_at.replace('T', ' ').replace('Z', '')
        setAppointments(
          appointments.map((appointment) => (appointment.id === data.id ? data : appointment))
        )

        // Update leadCluster context
        leadCluster.appointments = leadCluster.appointments.map((appointment) =>
          appointment.id === data.id ? data : appointment
        )
        setLeadCluster(leadCluster)

        closeModal()
        showToast(
          notification,
          'success',
          'Appointment saved',
          'The appointment has been saved successfully.'
        )
      }
    } else {
      // Creating a new Appointment
      const url = `/dealerships/${leadCluster.dealership_id}/appointments`

      const response = await fetch(url, {
        method: 'POST',
        headers: standardHeaders,
        body: JSON.stringify(appointmentData),
      })

      if (!response.ok) {
        showToast(notification, 'error', 'An error occurred', 'The appointment could not be saved.')
      } else {
        const data = await response.json()
        data.starts_at = data.starts_at.replace('T', ' ').replace('Z', '')
        setAppointments([...appointments, data])

        // Update leadCluster context
        leadCluster.appointments = [...appointments, data]
        setLeadCluster(leadCluster)

        closeModal()
        showToast(
          notification,
          'success',
          'Appointment saved',
          'The appointment has been created successfully.'
        )
      }
    }
  }

  const createTestDrive = async (appointmentData) => {
    const url = `/dealerships/${leadCluster.dealership_id}/test_drives.json`
    const { value: car_id } = appointmentData.carForTestDrive
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const formData = {
      contact_id: leadCluster.contact.id,
      car_id,
      dealership_id: leadCluster.dealership_id,
      location_id: leadCluster.location_id,
      timezone,
      lead_cluster_id: leadCluster.id,
    }
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      body: JSON.stringify(formData),
    })

    if (response.ok) {
      const testDriveData = await response.json()
      return testDriveData
    } else {
      notification.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to create test drive',
      })
    }
  }

  const handleDelete = (appointment) => {
    confirmDialog({
      message: 'Are you sure you want to delete the appointment?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const url = `/dealerships/${leadCluster.dealership_id}/appointments/${appointment.id}`

        fetch(url, {
          method: 'DELETE',
          headers: standardHeaders,
        })
          .then((response) => {
            if (!response.ok) {
              // Handle non-200 responses
              showToast(
                notification,
                'error',
                'An error occurred',
                'The appointment could not be removed.'
              )
              throw new Error('Network response was not ok')
            } else {
              showToast(
                notification,
                'success',
                'Appointment removed',
                'The appointment has been removed successfully.'
              )
              // Update state to remove the deleted appointment
              setAppointments(
                appointments.filter(
                  (existingAppointment) => existingAppointment.id !== appointment.id
                )
              )
            }
          })
          .catch((error) => {
            console.error('There was a problem with the fetch operation:', error)
          })
      },
    })
  }

  const handleChangeStatus = async (appointment, status) => {
    const url = `/dealerships/${leadCluster.dealership_id}/appointments/${appointment.id}`
    const data = { status }
    const response = await fetch(url, {
      method: 'PUT',
      headers: standardHeaders,
      body: JSON.stringify(data),
    })
    if (!response.ok) {
      showToast(notification, 'error', 'An error occurred', 'The appointment could not be saved.')
    } else {
      const updatedAppointment = await response.json()
      setAppointments(
        appointments.map((appointment) =>
          appointment.id === updatedAppointment.id ? updatedAppointment : appointment
        )
      )
      showToast(
        notification,
        'success',
        'Appointment saved',
        'The appointment status has been updated successfully.'
      )
    }
  }

  const Footer = () => (
    <div className="btn btn-outline-primary ml-auto btn-sm mb-2" onClick={() => openModal()}>
      <i className="fa fa-plus mr-2"></i>
      Add Appointment
    </div>
  )

  return (
    <>
      <AppointmentContext.Provider value={{ appointments, setAppointments }}>
        <ConfirmDialog />
        <div className="px-3 py-1">
          <Panel
            header={`Upcoming appointments (${appointments.length})`}
            footer={Footer}
            toggleable
            collapsed={appointments.length === 0}
          >
            <div className="box">
              <DataTable value={appointments} paginator rows={5}>
                <Column
                  field="user.name"
                  body={appointmentAssignment}
                  style={{ maxWidth: '10rem' }}
                  header="Assigned to"
                />
                <Column
                  field="schedulable_type"
                  body={appointmentSchedulable}
                  style={{ maxWidth: '10rem' }}
                  header="Purpose"
                />
                <Column
                  field="starts_at"
                  body={appointmentDate}
                  style={{ maxWidth: '8rem' }}
                  header="Date/Time"
                />
                <Column
                  field="note"
                  body={appointmentNote}
                  style={{ maxWidth: '8rem' }}
                  header="Notes"
                />
                <Column
                  style={{ maxWidth: '6.2rem' }}
                  header="Actions"
                  body={(rowData) => (
                    <div>
                      <button
                        className="btn btn-outline-primary ml-auto btn-sm mb-2 mr-2"
                        onClick={() => openModal(rowData)}
                      >
                        <i className="fas fa-edit"></i>
                        &nbsp;Edit
                      </button>
                      <button
                        className="btn btn-outline-danger ml-auto btn-sm mb-2"
                        onClick={() => handleDelete(rowData)}
                      >
                        <i className="fas fa-trash"></i>
                        &nbsp;Delete
                      </button>
                      <div className="mt-2">
                        <select
                          className="form-control form-control-sm"
                          value={rowData.status} // Set the select value to the current status
                          onChange={(e) => handleChangeStatus(rowData, e.target.value)}
                        >
                          <option value="pending">Pending</option>
                          <option value="no-show">No-Show</option>
                          <option value="completed">Completed</option>
                          <option value="cancelled">Cancelled</option>
                        </select>
                      </div>
                    </div>
                  )}
                />
              </DataTable>
            </div>
          </Panel>
        </div>
      </AppointmentContext.Provider>
      {isModalOpen && (
        <Dialog
          header={currentAppointment ? 'Edit Appointment' : 'New Appointment'}
          visible={isModalOpen}
          style={{ minWidth: '400px' }}
          onHide={closeModal}
          dismissableMask={true}
        >
          <AppointmentForm appointment={currentAppointment} onSave={handleSave} />
        </Dialog>
      )}
    </>
  )
}

export default Appointments

import { useEffect, useState } from 'react'

import { Dialog } from 'primereact/dialog'
import { ProgressBar } from 'primereact/progressbar'
import { Tooltip } from 'react-tooltip'

import { humanize } from '../../entries/utils'
import { carGradeBadgeClass } from './utils'

const TwgScore = ({ hit }) => {
  let [visible, setVisible] = useState(false)
  let [data, setData] = useState(null)

  useEffect(() => {
    if (!visible) return

    fetch(`/cars/${hit.id}/car_score_breakdown`)
      .then((response) => response.json())
      .then((data) => {
        setData(data)
      })
  }, [visible])

  return (
    <>
      <div
        className={`badge ${carGradeBadgeClass(hit.autograb_data.car_grade)}`}
        id={`car-grade-${hit.id}`}
        onClick={() => setVisible(true)}
      >
        {hit.autograb_data?.car_grade} ({hit.autograb_data.car_score})
      </div>
      <Tooltip anchorSelect={`#car-grade-${hit.id}`} place="bottom-start" style={{ zIndex: 10000 }}>
        <b>Car Score</b>
        <div className="small">
          <div>Average days to sell: {hit.autograb_data?.average_days_to_sell}</div>
          <div>Days Supply: {hit.autograb_data?.market_days_supply}</div>
          <div>Price Rank: {hit.autograb_data?.price_rank}</div>
        </div>

        <div className="mt-2">
          <b>Car Grading</b>

          <div className="small">
            <div>
              <div className={`badge ${carGradeBadgeClass('A')}`}>A</div> = 75-100
            </div>
            <div>
              <div className={`badge ${carGradeBadgeClass('B')}`}>B</div> = 60-79
            </div>
            <div>
              <div className={`badge ${carGradeBadgeClass('C')}`}>C</div> = 45-59
            </div>
            <div>
              <div className={`badge ${carGradeBadgeClass('D')}`}>D</div> = 30-39
            </div>
            <div>
              <div className={`badge ${carGradeBadgeClass('E')}`}>E</div> = 15-29
            </div>
            <div>
              <div className={`badge ${carGradeBadgeClass('F')}`}>E</div> = 0-15
            </div>
          </div>
        </div>
      </Tooltip>
      <Dialog
        header={`Car Score Breakdown ${hit.stocknum}`}
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        dismissableMask={true}
      >
        {data && (
          <>
            {['days_to_sell_score', 'days_supply_score'].map((key) => (
              <div className="mb-3">
                <div className="mb-2">
                  <b>{humanize(key)}:</b> {data[key]}/10
                </div>
                <ProgressBar value={data[key] * 10}></ProgressBar>
              </div>
            ))}
            <div className="mb-3">
              <div className="mb-2">
                <b>Age Score:</b> {data.age_score}/10 - {hit.days_old} days old
              </div>
              <ProgressBar value={data.age_score * 10}></ProgressBar>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                <b>km Score:</b> {data.km_score}/10 - {hit.km} kms
              </div>
              <ProgressBar value={data.km_score * 10}></ProgressBar>
            </div>
            <div className="mb-3">
              <div className="mb-2">
                <b>Price Rank Score:</b> {data.price_rank_score}/10 - Rank ={' '}
                {hit.autograb_data.price_rank}/{hit.autograb_data.sample_size}
              </div>
              <ProgressBar value={data.price_rank_score * 10}></ProgressBar>
            </div>
            <hr />
            <div>
              <b>Total Score:</b> {data.total_score}/100
            </div>
            <div>
              <b>Grade:</b>{' '}
              <div className={`badge ${carGradeBadgeClass(data.car_grade)}`}>{data.car_grade}</div>
            </div>
            <p className="small text-secondary mt-2">
              Higher scores indicate a car's sellability; that is, a higher score suggests that a
              car is more attractive to potential buyers and, theoretically, should sell more
              quickly.
            </p>
          </>
        )}
      </Dialog>
    </>
  )
}

export default TwgScore

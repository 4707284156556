import { useEffect, useState } from 'react'

import AnimateHeight from 'react-animate-height'
import { useInstantSearch } from 'react-instantsearch'
import { useParams } from 'react-router-dom'

// Westside had requested a sidebar with collapsible panels
// However, this seemed to cause some major client side performance issues
// And was buggy for me
const SearchPanelWestside = ({ title, children }) => {
  const [show, setShow] = useState(() => {
    // Retrieve the panel state from localStorage or default to true
    const storedState = JSON.parse(localStorage.getItem('searchPanelState')) || {}
    return storedState[title] !== undefined ? storedState[title] : true
  })

  const { status } = useInstantSearch()

  const toggle = () => {
    setShow(!show)
  }

  useEffect(() => {
    // Save the current panel state to localStorage
    const storedState = JSON.parse(localStorage.getItem('searchPanelState')) || {}
    storedState[title] = show
    localStorage.setItem('searchPanelState', JSON.stringify(storedState))
  }, [show, title])

  return (
    <div className="search-panel">
      <div className="d-flex">
        <h5>{title}</h5>
        <div className="ml-auto">
          <button className="btn btn-sm text-secondary" onClick={toggle}>
            <i className={`fa ${show ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
          </button>
        </div>
      </div>
      <AnimateHeight
        duration={300}
        height={status === 'idle' && show ? 'auto' : 0} // `auto` for expanded, `0` for collapsed
      >
        <div className="px-1">{children}</div>
      </AnimateHeight>
    </div>
  )
}

const SearchPanelDefault = ({ title, children }) => {
  return (
    <div className="search-panel">
      <div className="d-flex">
        <h5>{title}</h5>
      </div>
      <div className="px-1">{children}</div>
    </div>
  )
}

const SearchPanel = ({ title, children }) => {
  let { dealershipSlug } = useParams()

  if (dealershipSlug === 'westside') {
    return <SearchPanelWestside title={title}>{children}</SearchPanelWestside>
  } else {
    return <SearchPanelDefault title={title}>{children}</SearchPanelDefault>
  }
}

export default SearchPanel

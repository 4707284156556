import { CSSProperties } from 'react'

import { Chart, registerables } from 'chart.js'
import { Bar, Line, Pie } from 'react-chartjs-2'

import { ChartDataConfig, ChartDataConfigDataset } from '../types'

Chart.register(...registerables)

const PieChart = ({
  chartDataConfig,
  chartOptionsConfig = {},
  responsiveLayoutSizeStyles,
}: {
  chartDataConfig: ChartDataConfig
  chartOptionsConfig?: object
  responsiveLayoutSizeStyles?: CSSProperties
}) => {
  return chartDataConfig?.datasets?.some(
    (dataset: ChartDataConfigDataset) => dataset.data?.length > 0
  ) ? (
    <div style={responsiveLayoutSizeStyles}>
      <Pie data={chartDataConfig} options={chartOptionsConfig} />
    </div>
  ) : (
    <p className="text-center text-secondary">No Data Available</p>
  )
}

const BarChart = ({
  chartDataConfig,
  chartOptionsConfig = {},
  responsiveLayoutSizeStyles,
}: {
  chartDataConfig: ChartDataConfig
  chartOptionsConfig?: object
  responsiveLayoutSizeStyles?: CSSProperties
}) => {
  return chartDataConfig?.datasets?.some(
    (dataset: ChartDataConfigDataset) =>
      dataset.data?.length > 0 && dataset.data?.some((dataItem) => dataItem !== 0)
  ) ? (
    <div style={responsiveLayoutSizeStyles}>
      <Bar data={chartDataConfig} options={chartOptionsConfig} />
    </div>
  ) : (
    <p className="text-center text-secondary">No Data Available</p>
  )
}

const LineChart = ({
  chartDataConfig,
  chartOptionsConfig = {},
  responsiveLayoutSizeStyles,
}: {
  chartDataConfig: ChartDataConfig
  chartOptionsConfig?: object
  responsiveLayoutSizeStyles?: CSSProperties
}) => {
  return chartDataConfig?.datasets?.some(
    (dataset: ChartDataConfigDataset) =>
      dataset.data?.length > 0 && dataset.data?.some((dataItem) => dataItem !== 0)
  ) ? (
    <div style={responsiveLayoutSizeStyles}>
      <Line data={chartDataConfig} options={chartOptionsConfig} />
    </div>
  ) : (
    <p className="text-center text-secondary">No Data Available</p>
  )
}
export { BarChart, LineChart, PieChart }

// MANAGED BY App.js
import { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Panel } from 'primereact/panel'
import { Skeleton } from 'primereact/skeleton'
import { Toast } from 'primereact/toast'
import { useLocation, useParams } from 'react-router-dom'

import { LeadClusterContext } from '../contexts'
import LeadContainer from './LeadContainer'

const LeadMain = () => {
  let { leadCluster } = useContext(LeadClusterContext)

  return (
    <div className="px-3 py-2">
      <Panel header={`Leads (${leadCluster.leads.length})`} toggleable>
        {leadCluster.leads.map((lead, i) => (
          <LeadContainer lead={lead} key={lead.id} position={i} />
        ))}
      </Panel>
    </div>
  )
}

const Show = ({ defaultLeadClusterId, sidebar = true }) => {
  const { dealershipSlug, leadClusterId } = useParams()
  const notification = useRef(null)

  const clusterId = leadClusterId || defaultLeadClusterId

  const location = useLocation()
  const locationState = location.state

  const [status, setStatus] = useState('loading')

  // Always call useState and useContext unconditionally
  const [localLeadCluster, setLocalLeadCluster] = useState(locationState)
  const leadClusterContext = useContext(LeadClusterContext)

  // Decide which leadCluster and setLeadCluster to use based on 'sidebar'
  const leadCluster = sidebar ? localLeadCluster : leadClusterContext.leadCluster
  const setLeadCluster = sidebar ? setLocalLeadCluster : leadClusterContext.setLeadCluster

  useEffect(() => {
    if (status !== 'completed' && dealershipSlug) {
      fetch(`/dealerships/${dealershipSlug}/lead_clusters/${clusterId}/show_lite.json`)
        .then((response) => {
          if (!response.ok) {
            if (response.status === 404) {
              throw new Error('Not Found')
            } else {
              throw new Error('An error occurred')
            }
          }
          return response.json()
        })
        .then((data) => {
          setLeadCluster(data)
          setStatus('completed')
        })
        .catch((error) => {
          if (error.message === 'Not Found') {
            setStatus('not_found')
          } else {
            setStatus('error')
          }
        })
    }
  }, [dealershipSlug])

  const contextValue = useMemo(
    () => ({ leadCluster, setLeadCluster, notification }),
    [leadCluster, setLeadCluster, notification]
  )

  if (status === 'not_found') {
    return (
      <div className="text-center p-3 text-danger">
        <h2>Lead Cluster Not Found</h2>
      </div>
    )
  }

  if (status === 'error') {
    return (
      <div className="text-center p-3 text-danger">
        <h2>An unknown error has occured</h2>
      </div>
    )
  }

  const Content = (
    <div className="w-100">
      <div className="row no-gutters h-100">
        <div className={sidebar ? 'col-md-9' : 'col-md-12'}>
          <div className="py-3">
            {status === 'completed' ? (
              <LeadMain sidebar={false} />
            ) : (
              <div className="px-3 pt-1 py-3">
                <Skeleton width="10rem" className="mb-2 w-100" height="10rem" />
                <Skeleton width="10rem" className="mb-2 w-100" height="10rem" />
                <Skeleton width="10rem" className="mb-2 w-100" height="10rem" />
              </div>
            )}
          </div>
        </div>
        <Toast ref={notification} />
      </div>
    </div>
  )

  // Wrap in LeadClusterContext.Provider if sidebar is false
  // Otherwise we end up in a situation where we have LeadClusterContext.Provider twice (nested)
  // And when we call setLeadCluster from the grandchild it does not update the grandfather
  return sidebar ? (
    <LeadClusterContext.Provider value={contextValue}>{Content}</LeadClusterContext.Provider>
  ) : (
    Content
  )
}

export default Show

import { useContext, useEffect, useRef, useState } from 'react'

import { PrimeReactProvider } from 'primereact/api'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { SplitButton } from 'primereact/splitbutton'
import { Tag } from 'primereact/tag'
import { Toast } from 'primereact/toast'
import { useParams } from 'react-router-dom'

import { PageContext } from '../contexts'
import { useFetchManufacturer, useFetchWebsite } from '../dataHooks'
import { deparameterize } from '../editor/common/commonUtils'
import { getPageCategoryOptions } from '../editor/toolbar/ToolbarUtils'
import { convertLegacyPage } from '../editor/utils/legacyPageConversion'
import DataTableSearch from '../entries/DataTableSearch'
import { time_ago, truncateString } from '../entries/utils'
import { csrfToken } from '../entries/utils'
import AuditTrailSlideIn from '../shared/AuditTrailSlideIn'
import showToast from '../shared/ShowToast'
import ItemStatus from './ItemStatus'
import NewPageModal from './newPageModal'
import { PageService } from './pageService'

const Action = (page) => {
  const { notification, loadLazyData, ownerUrl } = useContext(PageContext)
  const [showSlideIn, setShowSlideIn] = useState(false)
  const handleHideSlideIn = () => {
    setShowSlideIn(false)
  }
  const items = [
    {
      label: 'View Live',
      icon: 'fas fa-external-link-alt',
      command: () => {
        window.open(page.page_url, '_blank')
      },
    },
    {
      label: page.status == 'Visible' ? 'Hide' : 'Show',
      icon: page.status == 'Visible' ? 'fas fa-eye-slash' : 'fas fa-eye',
      command: () => {
        const action = page.status == 'Visible' ? 'hidden' : 'made visible'
        fetch(page.toggle_visibility_url, {
          method: 'PUT',
          headers: {
            'X-CSRF-Token': csrfToken,
          },
        }).then(() => {
          const message = `Page ${action} successfully`
          showToast(notification, 'success', message)
          loadLazyData()
        })
      },
    },
    {
      label: 'Duplicate',
      icon: 'fas fa-copy',
      command: () => {
        const userConfirmation = window.confirm('Are you sure?')

        if (!userConfirmation) {
          return
        }
        fetch(page.duplicate_url, {
          method: 'POST',
          headers: {
            'X-CSRF-Token': csrfToken,
          },
        }).then((response) => {
          window.location.href = response.url
        })
      },
    },
    {
      label: 'Audit Trail',
      icon: 'fa fa-history',
      command: () => {
        setShowSlideIn(true)
      },
    },
    {
      label: 'Destroy',
      icon: 'fa fa-trash',
      command: () => {
        const userConfirmation = window.confirm('Are you sure?')

        if (!userConfirmation) {
          return
        }

        fetch(page.base_url, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': csrfToken,
          },
        }).then((response) => {
          if (response.ok) {
            const message = 'Page deleted successfully'
            showToast(notification, 'success', message)
            loadLazyData()
          } else {
            const message = 'Failed to delete page'
            showToast(notification, 'error', message)
          }
        })
      },
    },
  ]

  // Remove the view live button if the page has no url
  if (!page.page_url) {
    items.shift()
  }

  // Add conversion option for legacy pages (admin only)
  if (page.is_legacy_page && page.user_is_admin) {
    items.push({
      label: 'Convert Legacy Page',
      icon: 'fa fa-file',
      command: () => {
        convertLegacyPage(page, notification)
      },
    })
  }

  if (page.user_is_admin || page.can_edit_page) {
    return (
      <div style={{ position: 'relative' }}>
        <SplitButton
          label="Edit"
          buttonProps={{ id: 'edit-button' }}
          onClick={() => {
            window.location.href = page.edit_url
          }}
          model={items}
          menuButtonProps={{ id: 'action-button' }}
          outlined
          rounded
        />
        {showSlideIn && (
          <AuditTrailSlideIn
            AuditTrailObject={page}
            AuditTrailObjectName="Page"
            Url={`${page.base_url}/audit_trail`}
            ShowSlideIn={showSlideIn}
            onHide={handleHideSlideIn}
          />
        )}
      </div>
    )
  }

  if (!page.user_is_admin && page.oem_page) {
    return (
      <div style={{ position: 'relative' }}>
        <a
          href={ownerUrl + '/pages/' + page.slug}
          target="_blank"
          rel="noopener noreferrer"
          className="p-button font-bold"
        >
          View Live
        </a>
      </div>
    )
  }
}
const LastEditBy = (page) => {
  return (
    <div>
      <div>{page.last_edit_by.name}</div>
      <div className="small text-secondary">{time_ago(page.updated_at)}</div>
    </div>
  )
}

const Image = (page) => {
  if (page.featured_image) {
    return (
      <img
        src={`https://res.cloudinary.com/total-dealer/image/fetch/c_scale,h_150/c_crop,h_150,w_150/${page.featured_image}`}
        height={60}
        className="rounded-circle"
      />
    )
  } else {
    if (!page.image_key) return null
    return (
      <img
        src={`https://res.cloudinary.com/total-dealer/image/upload/c_scale,h_150/c_crop,h_150,w_150/v1/${process.env.RAILS_ENV}/${page.image_key}`}
        height={60}
        className="rounded-circle"
      />
    )
  }
}

const Title = (page) => {
  let badge = null
  let pageType = null

  if (page.oem_page) {
    badge = (
      <span className="badge badge-success ml-2">
        {!page.user_is_admin && <i className="fa fa-lock"></i>} OEM
      </span>
    )
  }

  // Indicate page type if it's not a default page
  if (page.home_page) {
    // Homepage
    pageType = <span className="badge badge-info small">Homepage</span>
  } else if (page?.template_override && page?.template_override !== '') {
    let text = null
    // Template override (e.g. /models, /brands)
    if (
      page.template_override.startsWith('/models/') ||
      page.template_override.startsWith('/brands/')
    ) {
      text = `${
        page.template_override.startsWith('/models/') ? 'Models' : 'Brands'
      } - ${deparameterize(page?.template_override?.split('/')[2])}`
    } else {
      text = page?.template_override // Fallback for other template overrides
    }
    pageType = <span className="badge badge-info small">{text}</span>
  } else if (page?.template && page?.template !== 'default') {
    // Custom template (e.g. /service, /finance, etc)
    pageType = <span className="badge badge-info small">{deparameterize(page?.template)}</span>
  }

  return (
    <>
      <div className="d-flex flex-wrap align-items-center" style={{ gap: '0rem 0.5rem' }}>
        <a href={page.edit_url}>{truncateString(page.title, 50)}</a> {pageType}
      </div>
      <div className="small text-secondary">
        Created: {time_ago(page.created_at)} {badge}
      </div>
    </>
  )
}

const Category = (page) => {
  // Find the category object that matches the page's category
  const category = getPageCategoryOptions().filter(
    (category) => category.value === page.page_category
  )[0]

  return category ? <>{category.label}</> : null
}

const App = ({ ownerId = '', ownerClass = '', ownerUrl = '', ownerName = '' }) => {
  const notification = useRef(null)
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    status: { value: null, matchMode: 'equals' },
    title: { value: null, matchMode: 'contains' },
    page_category: { value: null, matchMode: 'equals' },
  })

  const [loading, setLoading] = useState(false)

  const getSeverity = (status) => {
    switch (status) {
      case 'Hidden':
        return 'danger'

      case 'Visible':
        return 'success'
    }
  }

  const [statuses] = useState(['Visible', 'Hidden'])

  const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />
  }

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Select Status"
        className="p-column-filter"
        showClear
        style={{ minWidth: '12rem' }}
      />
    )
  }

  const categoryRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={getPageCategoryOptions()}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select Category"
        className="p-column-filter"
        showClear
        style={{ minWidth: '12rem' }}
      />
    )
  }

  const [totalRecords, setTotalRecords] = useState(0)
  const [pages, setPages] = useState(null)

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 50,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      global: { value: '', matchMode: 'contains' }, // FilterMatchMode.CONTAINS is not used because lazyState was not updating
      status: { value: null, matchMode: 'equals' },
      title: { value: null, matchMode: 'contains' },
      page_category: { value: null, matchMode: 'equals' },
    },
  })

  useEffect(() => {
    loadLazyData()
  }, [lazyState])

  const loadLazyData = () => {
    setLoading(true)
    if (lazyState.page === undefined) {
      return
    }
    let page_number = 0
    if (lazyState.page === 0 || lazyState.first === 0) {
      page_number = 1
    } else {
      page_number = lazyState.page + 1
    }
    let params = { page: page_number, rows: lazyState.rows }
    try {
      PageService.getPages(params, csrfToken, ownerId, ownerClass).then((data) => {
        setTotalRecords(data.data.total_records)
        setPages(data.data.pages)
        setLoading(false)
      })
    } catch (error) {
      console.error(error)
    }
  }

  const header = DataTableSearch({ filters: lazyState.filters, setFilters: setFilters })

  return (
    <div className="w-100 p-3">
      <PageContext.Provider value={{ notification, loadLazyData, ownerUrl }}>
        <Toast ref={notification} />
        <div className="d-flex mb-2">
          <div>
            <h4 className="mb-0">{ownerName} Pages</h4>
          </div>
          <div className="ml-auto">
            <NewPageModal />
          </div>
        </div>
        <PrimeReactProvider>
          <div className="box">
            <DataTable
              value={pages}
              tableStyle={{ minWidth: '50rem' }}
              paginator
              rows={lazyState.rows}
              removableSort
              sortMode="multiple"
              scrollable
              globalFilterFields={['title', 'status', 'last_edit_by']}
              style={{ overflow: 'visible' }}
              wrapper={{ style: { overflow: 'visible' } }}
              filterDisplay="row"
              header={header}
              first={lazyState.first}
              totalRecords={totalRecords}
              filters={lazyState.filters}
              loading={loading}
            >
              <Column body={Image} field="image" header="Image" />
              <Column
                sortable
                body={Title}
                field="title"
                header="Title"
                showFilterMenu={false}
                filterField="title"
                filter
                filterPlaceholder="Search by title"
              />
              {/* Page Categories are disabled for Manufacturer pages */}
              {ownerClass !== 'Manufacturer' && (
                <Column
                  header="Category"
                  body={Category}
                  field="page_category"
                  showFilterMenu={false}
                  sortable
                  filter
                  filterField="page_category"
                  filterElement={categoryRowFilterTemplate}
                />
              )}
              <Column
                field="status"
                header="Status"
                sortable
                showFilterMenu={false}
                filterMenuStyle={{ width: '14rem' }}
                body={ItemStatus}
                filter
                filterElement={statusRowFilterTemplate}
              />
              <Column sortable body={LastEditBy} field="last_edit_by" header="Last Edited By" />
              <Column body={Action} header="Actions" />
            </DataTable>
          </div>
        </PrimeReactProvider>
      </PageContext.Provider>
    </div>
  )
}

const Wrapper = () => {
  let { websiteSlug, manufacturerSlug } = useParams()
  let { website } = useFetchWebsite()
  let { manufacturer } = useFetchManufacturer()

  let ownerId = websiteSlug || manufacturerSlug
  let ownerClass = 'Website'
  let ownerUrl
  let ownerName = website?.name || manufacturer?.name
  if (manufacturerSlug) {
    ownerClass = 'Manufacturer'
  }
  if (website) {
    ownerUrl = website.url
  }

  return <App ownerId={ownerId} ownerClass={ownerClass} ownerUrl={ownerUrl} ownerName={ownerName} />
}

export default Wrapper

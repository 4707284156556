import { useEffect, useState } from 'react'

import moment from 'moment'
import { useParams } from 'react-router-dom'
import Select from 'react-select'

import Loading from '../Loading'
import { standardHeaders } from '../entries/utils'
import { humanize } from '../entries/utils'
import { CustomReports } from './reports/CustomReports'

const GenerateReport = ({ scope, carType, manufacturers, locations, fetchData }) => {
  let { dealershipSlug } = useParams()
  let [status, setStatus] = useState(false)

  const generateReport = () => {
    setStatus('loading')
    fetch(`/dealerships/${dealershipSlug}/cars/create_report.json?generate=true`, {
      method: 'POST',
      headers: standardHeaders,
      body: JSON.stringify({
        scope: scope,
        car_type: carType,
        manufacturer_id: manufacturers,
        location_id: locations,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setStatus('completed')
        fetchData()
      })
  }

  let disabled = status == 'loading' || status == 'completed'

  return (
    <button
      className={'btn btn-outline-secondary btn-sm' + (disabled ? ' disabled' : '')}
      onClick={generateReport}
      disabled={disabled}
    >
      {status === 'loading' ? (
        <i className="fa fa-refresh fa-spin mr-1"></i>
      ) : status === 'completed' ? (
        <>
          <i className="fa fa-check mr-1"></i>
          Requested
        </>
      ) : (
        <>
          <i className="fa fa-refresh mr-1"></i>
          Generate Report
        </>
      )}
    </button>
  )
}

const Reports = () => {
  let { dealershipSlug } = useParams()

  let [data, setData] = useState(false)
  let [carType, setCarType] = useState(false)
  let [manufacturers, setManufacturers] = useState(false)
  let [locations, setLocations] = useState(false)
  let [params, setParams] = useState(false)
  let [loading, setLoading] = useState(false)

  const generateParams = () => {
    const params = new URLSearchParams()

    if (carType && carType.length > 0) {
      params.append('car_type', carType)
    }

    if (manufacturers && manufacturers.length > 0) {
      params.append('manufacturer_id', manufacturers)
    }

    if (locations && locations.length > 0) {
      params.append('location_id', locations)
    }

    return `${params}` || ''
  }

  useEffect(() => {
    setParams(generateParams().toString())
  }, [carType, manufacturers, locations])

  const fetchData = () => {
    let url = `/dealerships/${dealershipSlug}/cars/reports.json`

    const params = generateParams()

    if (params.toString() !== '') {
      url += `?${params.toString()}`
    }

    setLoading(true)

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setData(data)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchData()
  }, [carType, manufacturers, locations])

  return (
    <div className="p-3 w-100">
      <div className="box">
        <div className="d-flex p-3">
          <div className="mr-auto">
            <h4>Download Inventory data</h4>
          </div>
        </div>
        {data.car_types && (
          <div className="row pb-3 px-3">
            <div className="col-4">
              <Select
                placeholder="Select New, Used, Demo..."
                options={Object.keys(data.car_types).map((carType) => ({
                  label: `${carType} (${data.car_types[carType]})`,
                  value: carType,
                }))}
                onChange={(selectedOption) => setCarType(selectedOption.map((o) => o.value))}
                isMulti={true}
              />
            </div>
            <div className="col-4">
              <Select
                placeholder="Select Make..."
                options={data.manufacturers.map((manufacturer) => ({
                  label: `${manufacturer.manufacturer_name} (${manufacturer.count})`,
                  value: manufacturer.manufacturer_id,
                }))}
                onChange={(selectedOption) => setManufacturers(selectedOption.map((o) => o.value))}
                isMulti={true}
              />
            </div>
            <div className="col-4">
              <Select
                placeholder="Select Location..."
                options={data.locations.map((location) => ({
                  label: `${location.location_name} (${location.count})`,
                  value: location.location_id,
                }))}
                onChange={(selectedOption) => setLocations(selectedOption.map((o) => o.value))}
                isMulti={true}
              />
            </div>
          </div>
        )}
        {loading && (
          <div className="p-3">
            <Loading />
          </div>
        )}
        {data && data.available_reports && (
          <table className="table">
            <thead>
              <tr>
                <th>File Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.available_reports
                .filter((r) => data[r.scope])
                .map((report, index) => (
                  <tr key={index}>
                    <td>
                      {report.name}
                      {data && data[report.scope] && (
                        <span className="badge badge-secondary ml-2">{data[report.scope]}</span>
                      )}
                    </td>
                    <td>
                      <GenerateReport
                        scope={report.scope}
                        carType={carType}
                        manufacturers={manufacturers}
                        locations={locations}
                        fetchData={fetchData}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
      {data && <CustomReports data={data} fetchData={fetchData} />}
      {data && data.reports && (
        <div className="box mt-3">
          <div className="p-3 d-flex">
            <div className="mr-auto">
              <h5>Reports</h5>
              <p className="mb-0 text-secondary">Reports are removed after 7 days.</p>
            </div>
            <div>
              <div
                className={'btn btn-outline-secondary btn-sm' + (loading ? ' disabled' : '')}
                disabled={loading}
                onClick={fetchData}
              >
                {loading ? 'Loading...' : 'Refresh data'}
              </div>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Created at</th>
                <th>Report Name</th>
                <th>Params</th>
                <th>Count</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {data.reports.map((report) => (
                <tr key={`report-${report.id}`}>
                  <td>
                    {moment(report.created_at).fromNow()}
                    <div className="small text-secondary">
                      {moment(report.created_at).format('DD/MM/YYYY')}
                    </div>
                  </td>
                  <td>{report.custom_report?.name}</td>
                  <td>{report.params.scope && humanize(report.params.scope)}</td>
                  <td>{report.count}</td>
                  <td>
                    {report.file_url && report.status == 'completed' && (
                      <a href={report.file_url} className="btn btn-outline-primary btn-sm">
                        <i className="fa fa-download mr-1" />
                        Download
                      </a>
                    )}
                    {report.status == 'pending' && (
                      <span>
                        <i className="fa fa-refresh fa-spin mr-2" />
                        Generating...
                      </span>
                    )}
                    {report.status == 'failed' && (
                      <span className="text-danger">
                        <i className="fa fa-times mr-2" />
                        Failed
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default Reports

import React from 'react'

import BeforeAfterSlider from 'react-before-after-slider'

const BeforeAfterPhoto = ({ first_image, second_image }) => {
  if (!first_image || !second_image) {
    return null
  }

  return <BeforeAfterSlider before={first_image} after={second_image} height={400} width={600} />
}

export default BeforeAfterPhoto

import { RefObject } from 'react'

import { Toast } from 'primereact/toast'

import showToast from '../../shared/ShowToast'
import { generateRandomId } from '../common/commonUtils'

// const testContent = `<a href="javascript:alert('XSS!')" onClick="alert('XSS!')">Unsafe Link Conversion</a><a href="/" onClick="alert('XSS!')">Safe Link Conversion</a><p><button onClick="alert('XSS!')">Click Me!</button>Welcome to the Finance Department at [website_name]. Here you can use our online form to get a quick and accurate response on financing your next vehicle.</p><h4>Our Vehicle Finance Service Includes:</h4><ul><li>Corporate lending</li><li>Novated Leases</li><li>Consumer Lending</li><li>Loan Payment Protection</li><li>Vehicle Insurance</li></ul><p>Our Business Manager has a great knowledge of all the above products and is always available to attend to your business and personal finance needs.</p>`

// Elements that can be converted to blocks
const elementToBlockDefaults = {
  p: { type: 'paragraph', data: { text: '' } },
  h1: { type: 'header', data: { text: '', level: 1 } },
  h2: { type: 'header', data: { text: '', level: 2 } },
  h3: { type: 'header', data: { text: '', level: 3 } },
  h4: { type: 'header', data: { text: '', level: 4 } },
  h5: { type: 'header', data: { text: '', level: 5 } },
  h6: { type: 'header', data: { text: '', level: 6 } },
  img: { type: 'image', data: { file: { url: '' }, caption: '' } },
}

// Define a set of safe href
const safeHref = ['http:', 'https:', 'mailto:', 'tel:', 'www.', '/']

function neutraliseUnsafeURL(anchorElement: HTMLElement) {
  const anchor = anchorElement as HTMLAnchorElement
  // Check if the href is safe
  if (!safeHref.some((href) => anchor.href.startsWith(href))) {
    anchor.href = '#' // Neutralize unsafe URLs
  }
}

function safelyHandleElement(element: HTMLElement) {
  // Remove any onAction events from the element
  Array.from(element.attributes).forEach((attr) => {
    if (attr.name.startsWith('on')) {
      element.removeAttribute(attr.name)
    }
  })

  if ('href' in element) {
    neutraliseUnsafeURL(element)
  }
}

function traverseElementRecursively(
  element: HTMLElement,
  callback: (el: HTMLElement) => void
): void {
  // Apply the callback to the current element
  callback(element)

  // Recursively traverse all child elements
  Array.from(element.children).forEach((child) => {
    traverseElementRecursively(child as HTMLElement, callback)
  })
}

export function convertHtmltoEditorJSJson(htmlString: string) {
  // Create a new DOMParser instance
  const parser = new DOMParser()

  // Parse the HTML string into a document
  const doc = parser.parseFromString(htmlString, 'text/html')

  // Get the body element's children
  const elements = doc.body.children

  // Function to convert an element to a JSON representation
  const elementToJson = (element: HTMLElement) => {
    // Check if the element is an image block or an image link block
    const isImageBlock =
      element.children.length === 1 && element.children[0].tagName.toLowerCase() === 'img'
    const isImageLinkBlock =
      element.children.length === 1 &&
      element.children[0].tagName.toLowerCase() === 'a' &&
      element.children[0].children.length === 1 &&
      element.children[0].children[0].tagName.toLowerCase() === 'img'

    const json = {
      id: generateRandomId(10),
      type:
        elementToBlockDefaults[
          isImageBlock || isImageLinkBlock ? 'img' : element.tagName.toLowerCase()
        ]?.type || 'paragraph',
      data: {
        ...(elementToBlockDefaults[
          isImageBlock || isImageLinkBlock ? 'img' : element.tagName.toLowerCase()
        ]?.data || elementToBlockDefaults.p.data),
      },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'none', customTextColor: '#000000' },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    }

    // Correctly convert the element to a JSON representation
    if (isImageBlock) {
      const firstImgElement = Array.from(element.children).find(
        (child: HTMLElement) => child.tagName.toLowerCase() === 'img'
      ) as HTMLImageElement
      if (firstImgElement) {
        json.data.file = {
          url: firstImgElement.src,
        }
        json.data.caption = firstImgElement.alt
      }
    } else if (isImageLinkBlock) {
      const firstImgElement = Array.from(element.children[0].children).find(
        (child: HTMLElement) => child.tagName.toLowerCase() === 'img'
      ) as HTMLImageElement
      if (firstImgElement) {
        json.data.file = {
          url: firstImgElement.src,
        }
      }
      const firstLinkElement = Array.from(element.children).find(
        (child: HTMLElement) => child.tagName.toLowerCase() === 'a'
      ) as HTMLAnchorElement
      if (firstLinkElement) {
        neutraliseUnsafeURL(firstLinkElement)
        json.data.caption = firstLinkElement.href
      }
    } else if (element.tagName.toLowerCase() === 'a') {
      traverseElementRecursively(element, safelyHandleElement)
      json.data.text = element.outerHTML
    } else {
      traverseElementRecursively(element, safelyHandleElement)
      json.data.text = element.innerHTML
    }

    return json
  }

  // Convert each element to a JSON representation
  const jsonElements = Array.from(elements).map(elementToJson)

  // Always add a location block at the end
  jsonElements.push({
    id: generateRandomId(10),
    type: 'locations',
    data: {
      filter: 'primary',
      manufacturer: false,
      subtype: false,
      filteredLocations: [],
    },
    tunes: {
      alignment: { alignment: 'left' },
      container: { contain: false },
      backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
      textColor: { textColor: 'none', customTextColor: '#000000' },
      margin: {
        spacingTop: 0,
        spacingBottom: 0,
        mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
        tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
      },
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
        mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
        tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
      },
      classname: { className: '' },
    },
  })

  // Construct the final JSON object
  const jsonObject = {
    time: Date.now(),
    blocks: jsonElements,
  }

  // Convert the JSON object to a JSON string
  return JSON.stringify(jsonObject)
}

export async function convertLegacyPage(
  page: {
    base_url: string
    page_url: string
  },
  notification: RefObject<Toast>
) {
  // Fetch page
  const pageData = await fetch(page.base_url + '.json')
    .then((response) => response.json())
    .then((data) => data)

  // Convert html content to editorJS blocks json
  const convertedContent = convertHtmltoEditorJSJson(pageData.content)

  const formData = new FormData()

  // ! Use this to generate a legacy page for testing
  // formData.append('page[content]', testContent)
  // formData.append('page[draft_blocks_json]', '{}')

  // Don't publish the changes, just provide a saved draft version
  formData.append('page[draft_blocks_json]', convertedContent)
  formData.append('page[content]', '')
  formData.append('save_mode', 'save_draft')

  // Update the page with the converted content
  try {
    const response = await fetch(`${page.base_url}?beta=true`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute('content'),
        Accept: 'application/json',
      },
      body: formData,
    })

    if (!response.ok) {
      const errors = await response.json()
      showToast(
        notification,
        'error',
        'Error converting the page',
        'Please screenshot the logs and report the issue'
      )
      throw errors
    } else {
      showToast(
        notification,
        'success',
        'Successful page conversion',
        'You will now be redirected to the new page'
      )
      // Redirect to the new page
      window.location.href = `${page.base_url}/edit?beta=true`
      if (page?.page_url) {
        window.open(page.page_url, '_blank') // Open live page for checking content
      }
    }
  } catch (errors) {
    showToast(
      notification,
      'error',
      'Error converting the page',
      'Please screenshot the logs and report the issue'
    )
    throw errors
  }
}

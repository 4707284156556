import { useViewportData } from '../../../hooks'
import type { ModuleProps } from '../../../types'

const LeadsByMake: React.FC<ModuleProps> = ({ item, timeRange, selectedLocation }) => {
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)
  const chartData = data?.[item?.module] || []
  return (
    <div ref={viewportRef}>
      {chartData?.length === 0 && <p className="text-center text-secondary">No Data available</p>}
      {chartData?.length > 0 && !loading && (
        <div className="position-relative" style={{ overflowY: 'auto' }}>
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Brand</th>
                <th>Leads</th>
              </tr>
            </thead>
            <tbody>
              {chartData?.slice(0, 10)?.map(([brand, count]: [string, number]) => (
                <tr key={`make_${brand}`}>
                  <td>{brand}</td>
                  <td>{count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default LeadsByMake

import { ProgressSpinner } from 'primereact/progressspinner'

import { fetchOnClient, useQuery } from '../tanstackPlaceholderUtils'
import { ListFromObject } from './ListFromObject'

const PricingSummary = ({
  dealershipSlug,
  checkoutId,
}: {
  dealershipSlug: string
  checkoutId: number
}) => {
  const { data, loading, error } = useQuery<any>({
    query: fetchOnClient,
    queryKey: `/dealerships/${dealershipSlug}/checkouts/${checkoutId}/pricing_summary?offer_accepted=true`,
  })

  if (loading) {
    return (
      <div className="p-5">
        <ProgressSpinner />
      </div>
    )
  }

  if (error) return <div>An error fetching pricing summary</div>

  return <ListFromObject fields={data ?? {}} />
}

export default PricingSummary

import { useFormContext } from 'react-hook-form'
import AsyncSelect from 'react-select/async'

import * as Routes from '../../routes'
import { Label } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'

const VercelSelect = ({ website, onChange, ...props }) => {
  const { watch } = useFormContext()
  let vercelAccountId = watch('vercel_account_id')

  const loadOptions = async (inputValue) => {
    let query = inputValue || website.name

    if (!query || query.length < 2) {
      return []
    }

    if (!vercelAccountId) {
      return []
    }

    let url = `${Routes.vercel_projects_website_path(website.id)}.json?query=${query}&vercel_account_id=${vercelAccountId}`

    const response = await fetch(url, {
      headers: standardHeaders,
    })

    const data = await response.json()

    return data
  }

  return (
    <>
      <Label label="Vercel Project" />
      <AsyncSelect
        cacheOptions
        defaultOptions
        defaultValue={{ label: website.vercel_repo_name, value: website.vercel_project_id }}
        placeholder="Type to search..."
        loadOptions={loadOptions}
        onChange={onChange}
        isClearable
        isDisabled={!vercelAccountId}
        {...props}
      />
      {!vercelAccountId ? (
        <small className="text-danger">Please select a Vercel account first</small>
      ) : (
        <small className="text-secondary">Type to search...</small>
      )}
    </>
  )
}

export default VercelSelect

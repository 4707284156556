import { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { Switch } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'

let settings = {
  serviceHistory: { defaultValue: false, type: 'boolean', label: 'Enable Service History' },
  serviceSpinner: { defaultValue: false, type: 'boolean', label: 'Enable Service Spinner' },
  allowBookService: {
    defaultValue: false,
    type: 'boolean',
    label: 'Allow Service Booking from portal',
  },
  invertGarageLogo: {
    defaultValue: false,
    type: 'boolean',
    label: 'Invert MyGarage Logo (White → Black)',
  },
  sendEmailIfSavedVehicleSold: {
    defaultValue: true,
    type: 'boolean',
    label: 'Send Email If Saved Vehicle Sold',
  },
}

const defaultValues = Object.fromEntries(
  Object.entries(settings).map(([key, value]) => [key, value.defaultValue])
)

const GarageSettings = ({ garageSettings, garageEnabled, setGarageEnabled }) => {
  let { websiteSlug } = useParams()
  let [loading, setLoading] = useState(false)

  const initialValues = { ...defaultValues, ...garageSettings }
  const [formState, setFormState] = useState(initialValues)

  const handleChange = (key, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    fetch(Routes.website_path(websiteSlug), {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify({
        website: {
          garage_enabled: garageEnabled,
          garage_settings: formState,
        },
      }),
    }).then(() => {
      setLoading(false)
    })
  }

  return (
    <div className="p-3">
      <h4>Garage Settings</h4>
      <div className="box p-3">
        <form onSubmit={handleSubmit}>
          <div className="setting-item mb-3">
            <Switch
              label="Garage/Portal Enabled"
              value={garageEnabled}
              id="garageEnabled"
              onChange={() => setGarageEnabled(!garageEnabled)}
            />
          </div>
          {garageEnabled &&
            Object.keys(settings).map((key) => (
              <div key={key} className="setting-item mb-2">
                <label>
                  {settings[key].type === 'boolean' ? (
                    <Switch
                      label={settings[key]['label']}
                      value={formState[key]}
                      id={key}
                      onChange={() => handleChange(key, !formState[key])}
                    />
                  ) : (
                    <input
                      type="text"
                      value={formState[key]}
                      onChange={(e) => handleChange(key, e.target.value)}
                    />
                  )}
                </label>
              </div>
            ))}
          <button
            type="submit"
            className={'mt-3 btn btn-outline-primary' + (loading ? ' disabled' : '')}
          >
            {loading ? 'Saving...' : 'Save Settings'}
          </button>
        </form>
      </div>
    </div>
  )
}

const Wrapper = () => {
  let [garageSettings, setGarageSettings] = useState(null)
  let [garageEnabled, setGarageEnabled] = useState()
  let [loading, setLoading] = useState(true)
  let { websiteSlug } = useParams()

  useEffect(() => {
    fetch(Routes.garage_settings_website_path(websiteSlug), {
      method: 'GET',
      headers: standardHeaders,
    })
      .then((response) => response.json())
      .then((data) => {
        setGarageSettings(data.garage_settings)
        setGarageEnabled(data.garage_enabled)
        setLoading(false)
      })
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <GarageSettings
      garageSettings={garageSettings}
      garageEnabled={garageEnabled}
      setGarageEnabled={setGarageEnabled}
    />
  )
}

export default Wrapper

import { useClearRefinements } from 'react-instantsearch'
import { useParams } from 'react-router-dom'

function ClearRefinements({ translations, excludedAttributes }) {
  const { refine, isRefined } = useClearRefinements({ excludedAttributes })
  let { dealershipSlug } = useParams()

  const handleClick = () => {
    localStorage.removeItem(`toggle_${dealershipSlug}`)
    localStorage.removeItem(`refinementList_${dealershipSlug}`)
    localStorage.removeItem(`sortBy_${dealershipSlug}`)
    localStorage.removeItem(`hierarchicalMenu_${dealershipSlug}`)
    refine()
  }

  return (
    <div className="ais-ClearRefinements">
      <button className="ais-ClearRefinements-button" onClick={handleClick}>
        {translations.resetButtonText}
      </button>
    </div>
  )
}

export default ClearRefinements

// MANAGED BY App.js
import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Link, useParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

import * as Routes from '../../routes'
import { useFetchDealership } from '../dataHooks'
import { Select } from '../entries/FormElements'
import { formatPhone } from '../entries/utils'
import { truncateString } from '../entries/utils'
import { DateRangePickerComponent } from '../leads/DateRangePicker'
import { CopyableText } from '../shared/CopyableText'
import ContactForm from './ContactForm'

const fetchContacts = async ({ queryKey }) => {
  const [_key, { dealershipSlug, page, rows, query, minDate, maxDate, portalAccess }] = queryKey

  const params = {
    page,
    per_page: rows,
    query,
    min_date: minDate,
    max_date: maxDate,
    portalAccess,
  }

  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value != null)
  )

  const queryString = new URLSearchParams(filteredParams).toString()
  const url = `${Routes.index_react_dealership_contacts_path(dealershipSlug)}.json?${queryString}`

  const response = await fetch(url)
  if (!response.ok) throw new Error('Failed to fetch contacts')

  return response.json()
}

const Header = ({ dealershipId }) => {
  let [formVisible, setFormVisible] = useState(false)

  return (
    <div className="clearfix mb-2">
      <ContactForm visible={formVisible} setVisible={setFormVisible} />
      <h3>
        Listing contacts
        <div className="float-right">
          <a
            href={`/dealerships/${dealershipId}/contacts.csv`}
            className="btn btn-outline-primary mr-2"
          >
            <i className="fa fa-download" /> Export to CSV
          </a>
          <a
            href={Routes.upload_dealership_contacts_path(dealershipId)}
            className="btn btn-outline-secondary mr-2"
          >
            <i className="fa fa-upload" /> Upload CSV
          </a>
          <a
            onClick={(e) => {
              e.preventDefault()
              setFormVisible(true)
            }}
            className="btn btn-outline-success"
          >
            <i className="fa fa-plus mr-1" />
            Contact
          </a>
        </div>
      </h3>
    </div>
  )
}

const Name = (rowData) => {
  let { dealershipSlug } = useParams()
  return (
    <Link to={Routes.dealership_contact_path(dealershipSlug, rowData.id)}>
      {truncateString(rowData.full_name, 50)}
    </Link>
  )
}

const Email = (rowData) => {
  if (!rowData.email) {
    return <span className="text-secondary">No email</span>
  }

  return <CopyableText text={rowData.email} />
}

const PhoneNumber = (rowData) => {
  return <CopyableText text={formatPhone(rowData.phone_number)} />
}

const Actions = (rowData) => {
  let { dealershipSlug } = useParams()
  return (
    <>
      <Link
        to={Routes.dealership_contact_path(dealershipSlug, rowData.id)}
        className="btn btn-sm btn-outline-secondary mr-2"
      >
        <i className="fa fa-eye" />
      </Link>
      <a
        href={Routes.dealership_contact_path(dealershipSlug, rowData.id)}
        className="btn btn-sm btn-outline-secondary"
        data-method="delete"
      >
        <i className="fa fa-trash" />
      </a>
    </>
  )
}

const PortalAccess = ({ signed_in_to_portal }) => {
  if (signed_in_to_portal) {
    return (
      <span className="text-success">
        <i className="fa fa-check" />
      </span>
    )
  } else {
    return (
      <span className="text-danger">
        <i className="fa fa-times" />
      </span>
    )
  }
}

const portalAccessOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

const MakeHeader = ({}) => {
  return (
    <div>
      Make
      <span id="makeHeading">
        <i className="fa fa-question-circle ml-1" data-tip data-for="makeHeading" />
      </span>
      <Tooltip anchorSelect={'#makeHeading'} place="top" effect="solid">
        Interested Vehicle Make.
        <div>This could either be from a customers lead, watchlist or their vehicle list</div>
      </Tooltip>
    </div>
  )
}

const App = () => {
  const { dealership } = useFetchDealership()
  let { dealershipSlug } = useParams()
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState('')
  const [minDate, setMinDate] = useState(null)
  const [maxDate, setMaxDate] = useState(null)
  const [portalAccess, setPortalAccess] = useState(null)
  const [initialised, setInitialised] = useState(false)
  const rows = 25

  // Sync state with URL parameters
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const params = {
      page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
      query: searchParams.get('query') || '',
      minDate: searchParams.get('min_date') || null,
      maxDate: searchParams.get('max_date') || null,
      portalAccess:
        searchParams.get('portalAccess') === 'true'
          ? true
          : searchParams.get('portalAccess') === 'false'
            ? false
            : null,
    }

    setPage(params.page)
    setQuery(params.query)
    setMinDate(params.minDate)
    setMaxDate(params.maxDate)
    setPortalAccess(params.portalAccess)

    setInitialised(true)
  }, [])

  // Fetch contacts using React Query
  const { data, isLoading, isError } = useQuery({
    queryKey: ['contacts', { dealershipSlug, page, rows, query, minDate, maxDate, portalAccess }],
    queryFn: fetchContacts,
    enabled: initialised, // Prevents execution until initialised
    keepPreviousData: true, // Avoids flickering on page changes
  })

  // Update the URL when filters change
  useEffect(() => {
    if (!initialised) return

    const params = {
      page,
      per_page: rows,
      query,
      min_date: minDate,
      max_date: maxDate,
      portalAccess,
    }
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value != null)
    )
    const queryString = new URLSearchParams(filteredParams).toString()
    const currentUrl = `${window.location.pathname}?${queryString}`
    window.history.replaceState(null, '', currentUrl)
  }, [page, query, minDate, maxDate, portalAccess, initialised])

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  return (
    <div className="p-3 w-100">
      <Header dealershipId={dealership?.id || 0} />

      <div className="box p-3 mb-3">
        <div className="row">
          <div className="col-md-4 mb-2 mb-md-0">
            <input
              className="form-control"
              type="text"
              value={query}
              placeholder="Search Contacts"
              onChange={(e) => {
                setQuery(e.target.value)
                setPage(1)
              }}
            />
          </div>
          <div className="col-md-4 mb-2 mb-md-0">
            <DateRangePickerComponent
              refine={(min, max) => {
                setMinDate(min)
                setMaxDate(max)
                setPage(1)
              }}
            />
          </div>
          <div className="col-md-4 mb-2 mb-md-0">
            <Select
              options={portalAccessOptions}
              onChange={(e) => setPortalAccess(e?.value)}
              value={portalAccessOptions.find((option) => option.value === portalAccess)}
              isClearable={true}
              placeholder="Portal/Garage Access"
            />
          </div>
        </div>
      </div>

      <div className="box">
        {isError ? (
          <p className="text-danger">Error loading contacts</p>
        ) : (
          <DataTable
            value={data?.contacts || []}
            loading={isLoading}
            lazy
            paginator
            rows={rows}
            first={(page - 1) * rows}
            totalRecords={data?.total_count || 0}
            onPage={onPageChange}
          >
            <Column field="full_name" header="Name" body={Name} />
            <Column field="email" header="Email" body={Email} />
            <Column field="phone_number" header="Phone" body={PhoneNumber} />
            <Column field="interested_vehicle_make" header={MakeHeader} />
            <Column field="interested_vehicle_model" header="Model" />
            <Column field="signed_in_to_portal" header="Portal Access" body={PortalAccess} />
            <Column header="Actions" body={Actions} />
          </DataTable>
        )}
      </div>
    </div>
  )
}

export default App

import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'

import { Contact, CreatedAt, LastEditBy, Location, Status, Title } from '../Search'

const editButton = (carLoan) => {
  return (
    <a
      className="btn btn-primary"
      href={Routes.edit_dealership_car_loan_path(carLoan.dealership_id, carLoan.id)}
    >
      <i className="fa fa-edit mr-1"></i>
      Edit
    </a>
  )
}
const CarLoansBox = ({ carLoans, ownerType, ownerId }) => {
  const firstColumnComponent = ownerType === 'contact' ? Title : Contact
  const firstColumnHeader = ownerType === 'contact' ? 'Title' : 'Contact'
  const firstColumnField = ownerType === 'contact_name' ? 'title' : 'contact_name'

  // TODO: Implement a "Add Car Loan" button that prefills the form with car or contact info, depending on the ownerType
  return (
    <div className="box mb-3 overflow-hidden">
      <div className="p-3">
        <h4 className="mb-1">
          <i className="fa fa-car mr-1"></i>Loans
        </h4>
      </div>
      <DataTable value={carLoans} tableStyle={{ minWidth: '50rem' }} paginator rows={10}>
        <Column
          sortable
          body={firstColumnComponent}
          field={firstColumnField}
          header={firstColumnHeader}
        ></Column>
        <Column sortable field="status" header="Status" body={Status} />
        <Column
          sortable
          body={Location}
          field="location"
          header="Location"
          showFilterMenu={false}
          filterMenuStyle={{ width: '14rem' }}
        />
        <Column
          sortable
          body={CreatedAt}
          field="created_at"
          header="Creation Date"
          dataType="date"
        />
        <Column
          sortable
          body={LastEditBy}
          field="last_edit_by"
          header="Last Edited By"
          dataType="date"
        ></Column>
        <Column body={(carLoan) => editButton(carLoan)}></Column>
      </DataTable>
    </div>
  )
}

export default CarLoansBox

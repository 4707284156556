import { useContext, useEffect, useState } from 'react'

import { useParams, useSearchParams } from 'react-router-dom'

import * as Routes from '../../../../routes'
import { DealershipContext } from '../../../contexts'
import ContactSelector from '../../../shared/ContactSelector'
import VehicleSelector from '../../../shared/VehicleSelector'

export const NewTestDrive = ({ onCreateTestDrive }) => {
  const [selectedCar, setSelectedCar] = useState(null)
  const [selectedContact, setSelectedContact] = useState(null)
  const { dealershipSlug } = useParams()

  const { dealership }: any = useContext(DealershipContext)
  const [searchParams] = useSearchParams()
  const contactId = searchParams.get('contact_id')
  const carId = searchParams.get('car_id')

  useEffect(() => {
    if (selectedCar && selectedContact) {
      onCreateTestDrive({
        carId: selectedCar.value,
        contactId: selectedContact.value,
      })
    }
  }, [selectedCar, selectedContact])

  useEffect(() => {
    if (contactId) {
      fetch(Routes.dealership_contact_path(dealershipSlug, contactId) + '.json')
        .then((res) => res.json())
        .then((data) => {
          setSelectedContact({
            value: data.id,
            label: `${data.first_name} ${data.last_name}`,
            ...data,
          })
        })
    }
  }, [contactId])

  useEffect(() => {
    if (carId) {
      fetch(Routes.car_path(carId) + '.json')
        .then((res) => res.json())
        .then((data) => {
          setSelectedCar({
            value: data.id,
            label: data.name,
            ...data,
          })
        })
    }
  }, [carId])

  return (
    <div>
      <div className="row">
        <ContactSelector
          dealership={dealership}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
        />
        <VehicleSelector
          dealership={dealership}
          selectedCar={selectedCar}
          setSelectedCar={setSelectedCar}
        />
      </div>
    </div>
  )
}

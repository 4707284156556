import { useContext, useEffect, useState } from 'react'

import { Tooltip } from 'primereact/tooltip'
import { useParams } from 'react-router-dom'
import Select from 'react-select'

import * as Routes from '../../routes'
import { CurrentUserContext } from '../contexts'
import Modal from '../entries/modal'
import { csrfToken } from '../entries/utils'

const AvailableTemplatePages = [
  { label: 'Trade In', value: 'trade-in', availability: 'admin' },
  { label: 'Finance', value: 'finance', availability: 'admin' },
  { label: 'Service', value: 'service', availability: 'admin' },
  {
    label: 'Vehicle Parts & Accessories',
    value: 'vehicle-parts-and-accessories',
    availability: 'admin',
  },
  { label: 'Contact', value: 'contact', availability: 'admin' },
  { label: 'About Us', value: 'about-us', availability: 'admin' },
  { label: 'Careers', value: 'careers', availability: 'admin' },
  { label: 'Fleet', value: 'fleet', availability: 'admin' },
  { label: 'Privacy Policy', value: 'privacy-policy', availability: 'admin' },
  { label: 'Terms & Conditions', value: 'terms-and-conditions', availability: 'admin' },
  { label: 'Search', value: 'search', availability: 'admin' },
]

const App = ({ ownerClass, ownerId, isAdmin, allPages, manufacturers }) => {
  let defaultManufacturer = manufacturers.length === 1 ? manufacturers[0].id : false

  let [opened, setOpened] = useState(false)
  let [loading, setLoading] = useState(false)
  let [formCompleted, setFormCompleted] = useState(false)
  let [title, setTitle] = useState('')
  let [pageType, setPageType] = useState('blankPage')
  let [selectedTemplate, setSelectedTemplate] = useState(false)
  let [selectedManufacturer, setSelectedManufacturer] = useState(defaultManufacturer)
  let [selectedModel, setSelectedModel] = useState(false)
  let [selectedPage, setSelectedPage] = useState(false)
  let [pages, setPages] = useState([])
  let [models, setModels] = useState([])

  let pageTypeOptions = [
    { value: 'blankPage', label: 'Blank Page' },
    { value: 'duplicatedPage', label: 'Duplicate a Page' },
  ]

  // * Only show template pages if there are any available or user is an admin
  if (
    AvailableTemplatePages.some((template) => template.availability === 'all' || isAdmin) &&
    ownerClass === 'Website'
  ) {
    pageTypeOptions.push({ value: 'templatePage', label: 'Template Page' })
  }

  // * Only show manufacturer and model pages if there linked manufacturers
  if (manufacturers.length >= 1 && isAdmin) {
    if (ownerClass === 'Website') {
      pageTypeOptions.push({ value: 'manufacturerPage', label: 'Manufacturer Page' })
    }
    pageTypeOptions.push({ value: 'modelPage', label: 'Model Page' })
  }

  let submitRoute = () => `/${ownerClass.toLowerCase()}s/${ownerId}/pages/prepare`

  useEffect(() => {
    if (opened) {
      setPageType('blankPage')
      setPages([])
      setModels([])
      setSelectedManufacturer(defaultManufacturer)
      setSelectedModel(false)
      setSelectedPage(false)
      setSelectedTemplate(false)
      $(`#new-page`).modal('show')
    } else {
      $(`#new-page`).modal('hide')
    }
  }, [opened])

  useEffect(() => {
    if (opened && pageType == 'duplicatedPage') {
      setPages(allPages)
      setSelectedManufacturer(defaultManufacturer)
      setSelectedModel(false)
      setSelectedPage(false)
    } else if (opened && selectedManufacturer && pageType == 'modelPage') {
      fetchModels
    } else {
      setPages([])
    }
  }, [opened, pageType])

  useEffect(() => {
    if (opened && selectedManufacturer && pageType == 'modelPage') {
      fetchModels()
    } else {
      setModels([])
    }
  }, [opened, pageType, selectedManufacturer])

  useEffect(() => {
    // Check if form is completed before enabling submit button
    switch (pageType) {
      case 'blankPage':
        // Title added
        setFormCompleted(title.length >= 1)
        break
      case 'duplicatedPage':
        // Title added, page selected
        setFormCompleted(title.length >= 1 && selectedPage)
        break
      case 'manufacturerPage':
        // Title added, manufacturer selected
        setFormCompleted(title.length >= 1 && selectedManufacturer)
        break
      case 'modelPage':
        // Title added, manufacturer selected, model selected
        setFormCompleted(title.length >= 1 && selectedManufacturer && selectedModel)
        break
      case 'templatePage':
        // Title added, template selected
        setFormCompleted(title.length >= 1 && selectedTemplate)
        break
    }
  }, [title, pageType, selectedManufacturer, selectedModel, selectedPage, selectedTemplate])

  let fetchModels = () => {
    setLoading(true)
    fetch(Routes.manufacturer_models_path(selectedManufacturer) + '.json')
      .then((res) => res.json())
      .then((res) => {
        setLoading(false)
        setModels(res.map((model) => ({ value: model.id, label: model.name })))
        setSelectedModel(false)
      })
  }

  let Submit = () => {
    setLoading(true)
    fetch(submitRoute(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        page: {
          pageable_id: ownerId,
          pageable_type: ownerClass,
          title,
        },
        page_type: pageType,
        template: {
          type: selectedTemplate,
          manufacturer_id: selectedManufacturer,
          page_id: selectedPage,
          model_id: selectedModel,
        },
        authenticity_token: csrfToken,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
        throw new Error('Something went wrong')
      })
      .then((res) => {
        setLoading(false)
        // close modal
        $(`#new-page`).modal('hide')
        if (res.edit_url) {
          window.location = res.edit_url
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // TODO: Add labels above each select field
  return (
    <>
      <div className="ml-auto">
        <div
          id="new-page-button"
          className="btn btn-outline-primary w-100"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setOpened(!opened)
            $(`#new-page`).modal()
          }}
        >
          <i className="fa fa-plus mr-1" />
          New Page
        </div>
      </div>
      <Modal id={`new-page`} header={`Add New Page`} width="md" onClose={() => setOpened(false)}>
        {(pageType != 'templatePage' ||
          (pageType == 'templatePage' && selectedTemplate == 'search')) &&
          pageType != 'manufacturerPage' &&
          pageType != 'modelPage' && (
            <div className="form-group string page_title">
              <label className="form-control-label string optional">Page Title</label>
              <input
                className="form-control string optional"
                type="text"
                name="page[title]"
                id="page_title"
                placeholder="Enter a title for your page..."
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          )}

        <div className="form-group string page_type">
          <label className="form-control-label select optional">Page Type</label>
          <Select
            id="page_type"
            disabled={loading}
            options={pageTypeOptions}
            onChange={(e) => {
              setPageType(e.value)
              // If default manufacturer is set, set title to manufacturer name
              if (e.value == 'manufacturerPage' && selectedManufacturer) {
                setTitle(
                  manufacturers.find((manufacturer) => manufacturer.id === selectedManufacturer)
                    .name
                )
              }
            }}
            value={pageTypeOptions.find((option) => option.value === pageType)}
          />
        </div>

        {pageType == 'duplicatedPage' && (
          <div className="form-group string duplicated_page">
            <label className="form-control-label select optional">Page to duplicate</label>
            <Select
              disabled={loading}
              options={pages}
              onChange={(e) => setSelectedPage(e.value)}
              placeholder={loading ? 'Loading...' : 'Select a Page...'}
            />
          </div>
        )}

        {pageType == 'templatePage' && (
          <div className="form-group string template_page">
            <label className="form-control-label select optional">Template</label>
            <div id="template_page">
              <Select
                options={AvailableTemplatePages.map((template) => {
                  if (template.availability === 'all' || isAdmin) {
                    return { value: template.value, label: template.label }
                  }
                })}
                onChange={(e) => {
                  setTitle(e.value === 'search' ? '' : e.label)
                  setSelectedTemplate(e.value)
                }}
                placeholder="Select a template..."
              />
            </div>
          </div>
        )}

        {(pageType == 'manufacturerPage' || pageType == 'modelPage') &&
          manufacturers.length >= 1 &&
          isAdmin && (
            <div className="form-group string manufacturer">
              <label className="form-control-label select optional">Manufacturer</label>
              <Select
                disabled={loading}
                options={manufacturers.map((manufacturer) => ({
                  value: manufacturer.id,
                  label: manufacturer.name,
                }))}
                // Default to first manufacturer if only one
                defaultValue={
                  selectedManufacturer
                    ? {
                        label: manufacturers.find(
                          (manufacturer) => manufacturer.id === selectedManufacturer
                        ).name,
                        value: selectedManufacturer,
                      }
                    : undefined
                }
                onChange={(e) => {
                  if (pageType == 'manufacturerPage') {
                    setTitle(e.label)
                  }
                  setSelectedManufacturer(e.value)
                }}
                placeholder={loading ? 'Loading...' : 'Select a Manufacturer...'}
              />
            </div>
          )}

        {pageType == 'modelPage' &&
          (manufacturers.length === 1 || selectedManufacturer) &&
          isAdmin && (
            <div className="form-group string model">
              <label className="form-control-label select optional">Model</label>
              <Select
                disabled={loading}
                options={models}
                onChange={(e) => {
                  setTitle(
                    `${
                      manufacturers.find((manufacturer) => manufacturer.id === selectedManufacturer)
                        .name
                    } ${e.label}`
                  )
                  setSelectedModel(e.value)
                }}
                placeholder={loading ? 'Loading...' : 'Select a Model...'}
                value={selectedModel ? models.find((model) => model.value === selectedModel) : null}
              />
            </div>
          )}

        <div
          // This is required for disabled elements to show tooltips
          id="new-page-submit-tooltip"
          data-pr-position="top"
          data-pr-at="center top-10"
          data-pr-my="center bottom"
          data-pr-event="both"
        >
          <button
            id="new-page-submit"
            onClick={() => Submit()}
            className={`btn btn-success btn-block mt-2 ${loading ? 'disabled' : ''} `}
            disabled={loading || !formCompleted}
          >
            {loading ? 'Loading...' : 'Start Building Page'}
          </button>
        </div>
        <Tooltip target="#new-page-submit-tooltip">
          {formCompleted ? null : (
            <>
              Please complete the following:
              <ul className="mb-0 pl-3">
                {title.length < 1 &&
                  (pageType != 'templatePage' ||
                    (pageType == 'templatePage' && selectedTemplate == 'search')) &&
                  pageType != 'manufacturerPage' &&
                  pageType != 'modelPage' && <li>Add a title</li>}
                {pageType == 'duplicatedPage' && !selectedPage && (
                  <li>Select a page to duplicate</li>
                )}
                {pageType == 'templatePage' && !selectedTemplate && <li>Select a Template page</li>}
                {(pageType == 'manufacturerPage' || pageType == 'modelPage') &&
                  !selectedManufacturer && <li>Select a manufacturer</li>}
                {pageType == 'modelPage' && !selectedModel && <li>Select a model</li>}
              </ul>
            </>
          )}
        </Tooltip>
      </Modal>
    </>
  )
}

const Wrapper = () => {
  let [manufacturers, setManufacturers] = useState([])
  let [allPages, setAllPages] = useState([])
  let [ownerId, setOwnerId] = useState(false)

  let { websiteSlug, manufacturerSlug } = useParams()

  let currentUser = useContext(CurrentUserContext)
  let isAdmin = currentUser?.admin

  let url
  let ownerClass
  if (websiteSlug) {
    url = Routes.new_page_modal_website_pages_path(websiteSlug)
    ownerClass = 'Website'
  } else if (manufacturerSlug) {
    url = Routes.new_page_modal_manufacturer_pages_path(manufacturerSlug)
    ownerClass = 'Manufacturer'
  }

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        setManufacturers(res.manufacturers)
        setAllPages(res.allPages)
        setOwnerId(res.ownerId)
      })
  }, [])

  return (
    <App
      ownerClass={ownerClass}
      ownerId={ownerId}
      isAdmin={isAdmin}
      allPages={allPages}
      manufacturers={manufacturers}
    />
  )
}

export default Wrapper

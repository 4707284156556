import React, { useEffect, useState } from 'react'

import MeiliSearch from 'meilisearch'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'

import * as Routes from '../../routes'
import { getMeilisearchConfig } from '../entries/utils'

interface DepositRequestModalProps {
  isOpen: boolean
  onClose: () => void
  dealershipId: number
  contactId: number
}

const DepositRequestModal: React.FC<DepositRequestModalProps> = ({
  isOpen,
  onClose,
  dealershipId,
  contactId,
}) => {
  const [availableCars, setAvailableCars] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const { meilisearchHost, meilisearchApiKey } = getMeilisearchConfig()

  const client = new MeiliSearch({
    host: meilisearchHost,
    apiKey: meilisearchApiKey,
  })

  const index = client.index(`Car_${process.env.RAILS_ENV}`)

  useEffect(() => {
    if (!isOpen) return

    const fetchCars = async () => {
      const searchResults = await index.search('', {
        limit: 5,
        offset: 0,
        filter: [`dealership_id=${dealershipId}`],
      })
      setAvailableCars(searchResults.hits)
    }

    fetchCars()
  }, [dealershipId, isOpen])

  const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchValue(value)
    const searchResults = await index.search(value, {
      limit: 5,
      offset: 0,
      filter: [`dealership_id=${dealershipId}`],
    })
    setAvailableCars(searchResults.hits)
  }

  const footer = (
    <div onClick={onClose} className="btn btn-outline-primary">
      Cancel
    </div>
  )

  return (
    <Dialog
      header="Send Deposit Request"
      visible={isOpen}
      style={{ width: '50vw' }}
      onHide={onClose}
      footer={footer}
      dismissableMask
    >
      <div className="p-inputgroup">
        <span className="p-inputgroup-addon">
          <i className="fa fa-search"></i>
        </span>
        <InputText
          value={searchValue}
          onChange={handleSearchChange}
          placeholder="Stock number, make, model..."
        />
      </div>

      <div className="car-box my-3">
        {availableCars.length > 0 ? (
          availableCars.map((car) => (
            <div key={car.id} className="d-flex box mb-2 p-2">
              <div>
                <img
                  src={car.primary_image_url}
                  className="rounded border mr-2"
                  alt={car.name}
                  height={50}
                />
              </div>
              <div className="w-100">
                {car.name}
                <div className="small text-secondary">
                  {car.status && (
                    <span className="subtitle-item text-success">
                      {car.status.replace('_', ' ').toUpperCase()}
                    </span>
                  )}
                  {car.car_type && <span className="subtitle-item">{car.car_type}</span>}
                  {car.colour && <span className="subtitle-item">{car.colour}</span>}
                  {car.stocknum && <span className="subtitle-item">{car.stocknum}</span>}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <form
                  action={Routes.send_deposit_request_dealership_contact_path(
                    dealershipId,
                    contactId
                  )}
                  method="post"
                >
                  <input type="hidden" name="car_id" value={car.id} />
                  <input
                    type="hidden"
                    name="authenticity_token"
                    value={
                      document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') ||
                      ''
                    }
                  />
                  <button className="btn btn-outline-primary" type="submit">
                    Send
                  </button>
                </form>
              </div>
            </div>
          ))
        ) : (
          <div id="no-results" className="text-center">
            No results found
          </div>
        )}
      </div>
    </Dialog>
  )
}

export default DepositRequestModal

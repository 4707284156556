import { components } from 'react-select'

import { capitalize } from '../../../utils'

// Custom MultiValue component to display type as superscript
export const MultiValue = (props) => {
  const { data } = props
  // Assuming 'type' is a property in the campaign object
  const { label, type } = data

  return (
    <components.MultiValue {...props}>
      <span>{label}</span>
      {type && (
        <span style={{ fontSize: '0.6em', verticalAlign: 'super', marginLeft: '4px' }}>
          {capitalize(type.split('_').join(' ').toLowerCase())}
        </span>
      )}
    </components.MultiValue>
  )
}

// Custom Option for dropdown options
export const Option = (props) => {
  return <components.Option {...props}>{formatLabel(props.data.label)}</components.Option>
}

// Custom MultiValueLabel for selected values
export const MultiValueLabel = (props) => {
  return (
    <components.MultiValueLabel {...props}>
      {formatLabel(props.data.label)}
    </components.MultiValueLabel>
  )
}

// Custom MultiValueRemove component to disable the delete button when only one item is selected
export const MultiValueRemove = (props) => {
  // Disable the remove button if there's only one item left
  if (props.selectProps.value.length === 1) {
    return null
  }
  // Otherwise, render the default remove button
  return <components.MultiValueRemove {...props} />
}

// Reusable function to format the label
const formatLabel = (label: string) => {
  const [beforeColon, afterColon] = label.split(': ') // Split label at colon
  return (
    <span>
      <strong>{beforeColon}</strong>: {afterColon}
    </span>
  )
}

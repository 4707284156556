import { useContext } from 'react'

import { LeadContext } from '../contexts'

const LeadPayment = ({ payment }) => {
  const { lead } = useContext(LeadContext)

  let leadPayment = lead?.payment || payment

  return (
    <div className="border bg-white rounded p-3 mb-3">
      <p className="mb-1">Lead includes deposit:</p>
      <h4 className="text-success">
        {leadPayment.amount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
      </h4>
      <small className="text-secondary">
        Transaction ID: {leadPayment.external_transaction_id}
      </small>
    </div>
  )
}

export default LeadPayment

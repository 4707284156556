import * as Routes from '../../routes'

export const BlogService = {
  getBlogs(params, csrf, websiteId) {
    const queryParams = params
      ? Object.keys(params)
          .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
          .join('&')
      : ''

    return fetch(`${Routes.website_blogs_path(websiteId)}.json?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      credentials: 'same-origin',
    }).then((res) => res.json())
  },
}

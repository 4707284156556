import { useContext, useEffect, useState } from 'react'

import { Dialog } from 'primereact/dialog'
import { useParams } from 'react-router-dom'

import { LeadClusterContext } from '../../contexts'

const SmsTemplates = ({ setMessage }) => {
  let [visible, setVisible] = useState(false)
  let [templates, setTemplates] = useState([])
  let { dealershipSlug } = useParams()
  let { leadCluster } = useContext(LeadClusterContext)

  useEffect(() => {
    if (!dealershipSlug || !leadCluster) return
    if (!visible) return

    fetch(
      `/dealerships/${dealershipSlug}/sms_templates.json?contact_id=${leadCluster.contact_id}&lead_cluster_id=${leadCluster.id}`
    )
      .then((response) => response.json())
      .then((data) => setTemplates(data))
  }, [visible])

  return (
    <>
      <button onClick={() => setVisible(true)} className="btn btn-outline-primary btn-sm mt-2">
        SMS Templates
      </button>
      <Dialog
        header="SMS Templates"
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        dismissableMask
      >
        {templates.map((template) => (
          <div key={template.id} className="rounded border p-3 mb-2">
            <div className="row d-flex align-items-center">
              <div className="col-10 text-secondary">
                <h6>{template.title}</h6>
                <p>{template.content}</p>
              </div>
              <div className="col-2">
                <div
                  className="btn btn-outline-primary btn-sm btn-block"
                  onClick={() => {
                    setMessage(template.processed_content)
                    setVisible(false)
                  }}
                >
                  Insert
                </div>
              </div>
            </div>
          </div>
        ))}
        <a
          href={`/dealerships/${dealershipSlug}/sms_templates`}
          target="_blank"
          className="btn btn-outline-primary btn-sm mt-2"
        >
          Edit SMS Templates
        </a>
      </Dialog>
    </>
  )
}

export default SmsTemplates

import React, { useEffect, useState } from 'react'

import { ProgressSpinner } from 'primereact/progressspinner'

import LoginForm from './LoginForm'

type AppDetails = {
  app_name: string
  subtitle: string
  image_url: string
  dealerships: null
}

const WestSideAutoIndexPage: React.FC = () => {
  const [data, setData] = useState<AppDetails | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [showLoginForm, setShowLoginForm] = useState<boolean>(false)

  useEffect(() => {
    const fetchAppDetails = async () => {
      try {
        setLoading(true)

        const response = await fetch('/welcome/app_details.json')

        if (!response.ok) {
          throw new Error('Error fetch app details')
        }

        const appDetails = await response.json()

        setData(appDetails)
      } catch (error) {
        console.error('Failed to fetch app details:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchAppDetails()
  }, [])

  if (loading) {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center">
        <ProgressSpinner />
      </div>
    )
  }

  return (
    <div className="flex-fill d-flex align-items-center justify-content-center westside-auto-home-page">
      <div className="container">
        <div className="row d-flex">
          <div className="d-flex flex-column flex-md-row flex-fill mh-100 justify-content-center">
            {/* Left Section */}
            <div className="col-md-6 col-lg-5 d-flex align-items-center justify-content-center bg-teal text-white p-4 left-pane">
              <div className="py-0 py-md-5 px-3 text-center w-100">
                <img
                  src={data?.image_url}
                  className="mt-0 mt-md-4 mb-0 mb-md-5 py-3 py-md-0 westside-auto-logo w-100"
                  alt={data?.app_name}
                />

                <div className="py-5 text-left d-none d-md-block">
                  <p className="h3 westside-auto-title mb-0">Simplified</p>
                  <p className="h3 westside-auto-title text-grey mb-0">Inventory</p>
                  <p className="h3 westside-auto-title text-grey mb-3">Management</p>
                  <div className="westside-auto-subtitle">
                    Up-to-date inventory with real-time status, modifications, and configurations -
                    streamlining operations.
                  </div>
                </div>
              </div>
            </div>

            {/* Right Section */}
            <div className="col-md-6 col-lg-5 d-flex align-items-top justify-content-center bg-dark text-white right-pane">
              <div className="d-flex flex-column pt-5 text-center w-100">
                <div className="flex-grow-1 py-5 px-4">
                  <h2 className="h1 westside-auto-title mb-0" style={{ fontSize: '2.75rem' }}>
                    Welcome
                  </h2>
                  <p className="westside-auto-welcome-message text-grey mb-0">
                    Access is provided by your IT department
                  </p>

                  {showLoginForm ? (
                    <LoginForm ssoEnabled={false} isMobileClient={false} />
                  ) : (
                    <div className="mt-5 mx-auto westside-auto-login-button-container">
                      <a
                        className="btn btn-light btn-lg btn-block py-3 px-5 position-relative d-flex align-items-center justify-content-center"
                        rel="nofollow"
                        data-method="post"
                        href="/users/auth/saml"
                      >
                        <img
                          src="/microsoft-logo.svg"
                          style={{
                            position: 'absolute',
                            left: 8,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            width: 34,
                            height: 34,
                          }}
                        />
                        <span className="westside-auto-login-button">Sign in with Microsoft</span>
                      </a>
                    </div>
                  )}
                </div>

                <div className="mt-auto d-flex justify-content-end">
                  <button
                    className="btn text-white"
                    onClick={() => setShowLoginForm(!showLoginForm)}
                  >
                    <i className="fa-regular fa-pi" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WestSideAutoIndexPage

import { useEffect, useState } from 'react'

import Loading from '../Loading'
import { Input, TextArea } from '../entries/FormElements'
import { humanize } from '../entries/utils'
import { standardHeaders } from '../entries/utils'

const Ticket = ({ ticket }) => (
  <div className="border rounded p-2 mb-2 bg-white">
    <h5>{ticket.subject}</h5>
    <div className="badge badge-secondary">{humanize(ticket.status)}</div>
    <p>Waiting since: {ticket.customerWaitingSince.friendly}</p>
  </div>
)

const SupportTickets = () => {
  let [loading, setLoading] = useState(false)
  let [subject, setSubject] = useState('')
  let [content, setContent] = useState('')
  let [tickets, setTickets] = useState(false)
  let [loadingTickets, setLoadingTickets] = useState(false)

  const onSubmit = () => {
    setLoading(true)
    fetch('/support_tickets', {
      method: 'POST',
      headers: standardHeaders,
      body: JSON.stringify({ subject, content }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false)
        if (data.success) {
          alert('Ticket submitted successfully')
          setSubject('')
          setContent('')
        } else {
          alert('There was an error submitting the ticket')
        }
      })
  }

  useEffect(() => {
    setLoadingTickets(true)
    fetch('/support_tickets.json')
      .then((res) => res.json())
      .then((res) => {
        setTickets(res.conversations)
        setLoadingTickets(false)
      })
  }, [])

  return (
    <div className="container">
      <div className="border rounded p-3 bg-white my-3">
        <h2>Support Tickets</h2>
        <h4>Lodge a Support Ticket</h4>
        <Input
          placeholder="Subject"
          label="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <TextArea
          placeholder="Content"
          label="Ticket Content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <div
          className={'btn btn-block btn-outline-success' + (loading ? ' disabled' : '')}
          onClick={onSubmit}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Submit'}
        </div>
      </div>
      <>
        <h4>My Existing Tickets</h4>
        {loadingTickets && (
          <div className="border rounded bg-white p-2">
            <Loading />
          </div>
        )}
        {tickets &&
          (tickets.length > 0 ? (
            tickets.map((ticket) => <Ticket ticket={ticket} key={ticket.id} />)
          ) : (
            <p>You have no active tickets.</p>
          ))}
      </>
    </div>
  )
}

export default SupportTickets

import { useContext } from 'react'

import { Controller } from 'react-hook-form'

import { FormContext } from '../contexts'
import { Input, NumberInput, PhoneInput, Select, Switch } from '../entries/FormElements'

const Field = ({ attribute, hint }) => {
  let { control, errors, formData, attributes } = useContext(FormContext)

  if (!attributes || !attributes[attribute]) {
    return null
  }

  let type = attributes[attribute].type

  const renderInput = (field) => {
    switch (type) {
      case 'number':
        return (
          <NumberInput
            className={`form-control ${errors && errors[attribute] ? 'is-invalid' : ''}`}
            {...field}
            {...attributes[attribute]}
            onChange={(value) => {
              field.onChange(value.target.rawValue)
            }}
          />
        )
      case 'phone':
        return (
          <PhoneInput
            className={`form-control ${errors && errors[attribute] ? 'is-invalid' : ''}`}
            {...field}
            {...attributes[attribute]}
            onChange={(value) => {
              field.onChange(value.target.rawValue)
            }}
          />
        )
      case 'date':
        return (
          <DateInput
            label={attributes[attribute].label}
            className={`${errors && errors[attribute] ? 'is-invalid' : ''}`}
            {...field}
            onChange={(value) => {
              field.onChange(value)
            }}
          />
        )
      case 'text':
        return (
          <Input
            className={`form-control ${errors && errors[attribute] ? 'is-invalid' : ''}`}
            {...attributes[attribute]}
            {...field}
          />
        )
      case 'switch':
        return (
          <div className="mb-3">
            <Switch
              {...field}
              label={attributes[attribute].label}
              id={attribute}
              value={field.value}
              onChange={(e) => field.onChange(!field.value)}
            />
            {hint && <small className="form-text text-muted">{hint()}</small>}
            {attributes[attribute].hint && (
              <small className="form-text text-muted">{attributes[attribute].hint}</small>
            )}
          </div>
        )
      case 'select':
        let options = formData[attributes[attribute].options]

        return (
          <Select
            label={attributes[attribute].label}
            className={`${errors && errors[attribute] ? 'is-invalid' : ''}`}
            options={options}
            value={options.filter((o) => o.value === field.value)}
            onChange={(e) => field.onChange(e.value)}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <Controller name={attribute} control={control} render={({ field }) => renderInput(field)} />
      {errors && errors[attribute] && (
        <div className="invalid-feedback">{errors[attribute].message}</div>
      )}
    </>
  )
}

export default Field

import { useContext, useState } from 'react'

import { LeadClusterContext } from '../contexts'
import AuditTrailSlideIn from '../shared/AuditTrailSlideIn'
import SlideIn from './SlideIn'

const Wrapper = ({ setVisible, children }) => (
  <div className="btn btn-sm mr-1 position-relative event-stat" onClick={() => setVisible(true)}>
    {children}
  </div>
)

const LeadEventStats = () => {
  const { leadCluster } = useContext(LeadClusterContext)
  const [showSlideIn, setShowSlideIn] = useState(false)
  const handleHideSlideIn = () => {
    setShowSlideIn(false)
  }

  if (!leadCluster) return <></>

  return (
    <>
      <SlideIn
        leadCluster={leadCluster}
        buttonComponent={({ setVisible }) => (
          <>
            <Wrapper setVisible={setVisible}>
              {leadCluster.event_counts?.email && (
                <span className="event-count">{leadCluster.event_counts.email}</span>
              )}
              <i className="fa fa-envelope mr-1" />
            </Wrapper>
            <Wrapper setVisible={setVisible}>
              {leadCluster.event_counts?.phone && (
                <span className="event-count">{leadCluster.event_counts.phone}</span>
              )}
              <i className="fa fa-phone mr-1" />
            </Wrapper>
            <Wrapper setVisible={setVisible}>
              {leadCluster.event_counts?.sms && (
                <span className="event-count">{leadCluster.event_counts.sms}</span>
              )}
              <i className="fa fa-comment mr-1" />
            </Wrapper>
            <Wrapper setVisible={setVisible}>
              <i className="fa fa-car mr-1" />
            </Wrapper>
          </>
        )}
      />
      <div
        className="btn btn-sm mr-1 position-relative event-stat"
        onClick={() => setShowSlideIn(true)}
      >
        <i className="fa fa-history mr-1" />
      </div>
      {showSlideIn && (
        <AuditTrailSlideIn
          AuditTrailObject={leadCluster}
          AuditTrailObjectName="Lead Cluster"
          Url={`/dealerships/${leadCluster.dealership_id}/lead_clusters/${leadCluster.id}/audit_trail`}
          ShowSlideIn={showSlideIn}
          onHide={handleHideSlideIn}
        />
      )}
    </>
  )
}

export default LeadEventStats

import { useContext, useEffect, useState } from 'react'

const COLOUR_OPTIONS = [
  'red',
  'blue',
  'green',
  'yellow',
  'purple',
  'orange',
  // Add more colors as needed
]

const OrderStatusOptionForm = ({ orderStatusOption, onSave, colourOptions }) => {
  const handleSubmit = (e) => {
    e.preventDefault()
    onSave({
      id: orderStatusOption?.id,
      name,
      colour_name: colourName,
    })
  }

  const [name, setName] = useState(orderStatusOption?.name || '')
  const [colourName, setColourName] = useState(orderStatusOption?.colour_name || '')
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          className="form-control"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="colour">Status Colour</label>
        <select
          className="form-control"
          id="colour"
          value={colourName}
          onChange={(e) => setColourName(e.target.value)}
          required
        >
          <option value="" disabled>
            Select a colour
          </option>
          {colourOptions.map((colour) => (
            <option key={colour.name} value={colour.name}>
              {colour.name.charAt(0).toUpperCase() + colour.name.slice(1)}
            </option>
          ))}
        </select>
      </div>
      <button type="submit" className="btn btn-primary">
        Save
      </button>
    </form>
  )
}

export default OrderStatusOptionForm

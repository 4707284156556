import React, { useState } from 'react'

import { RefinementList, SearchBox } from 'react-instantsearch'

import { humanize } from '../entries/utils'
import Modal from './Modal'

const MobileFilters = ({ humanizeTransformItems }) => {
  const [showSearch, setShowSearch] = useState(false)
  const [showFilters, setShowFilters] = useState(false)

  return (
    <div>
      <div className="btn-group d-flex" role="group">
        <button type="button" className="btn btn-info" onClick={() => setShowSearch(true)}>
          <i className="fa fa-search" aria-hidden="true"></i> Search
        </button>
        <button type="button" className="btn btn-info" onClick={() => setShowFilters(true)}>
          <i className="fa fa-filter" aria-hidden="true"></i> Filter
        </button>
      </div>

      <Modal title="Search" show={showSearch} closeClickHandler={() => setShowSearch(false)}>
        <SearchBox placeholder="Stock number, Make, model..." />
      </Modal>

      <Modal title="Filters" show={showFilters} closeClickHandler={() => setShowFilters(false)}>
        <div className="search-panel">
          <h5>Car Type</h5>
          <RefinementList attribute="car_type" transformItems={humanizeTransformItems} />
        </div>
        <div className="search-panel">
          <h5>Make</h5>
          <RefinementList attribute="make" showMore={true} showMoreLimit={100} />
        </div>
      </Modal>
    </div>
  )
}

export default MobileFilters

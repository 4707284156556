import { EditingPreviewTotal } from '../../../contexts/PreviewDataContext'
import { ModuleComponentConfig, ModuleItem } from '../../../types'
import { getColor } from '../../../utils'

const PreviousTimePeriod: React.FC<{
  previousPeriod: number
  lastYear: number
  moduleConfig: ModuleComponentConfig
  item: ModuleItem
  editingPreviewTotal: EditingPreviewTotal | null
}> = ({ previousPeriod, lastYear, moduleConfig, item, editingPreviewTotal }) => {
  const shouldReverse =
    moduleConfig.title === 'Cost Per Click' || moduleConfig.title === 'Cost Per Lead'

  const lastYearToShow = editingPreviewTotal?.[item?.module]?.lastYear || lastYear
  const previousPeriodToShow = editingPreviewTotal?.[item?.module]?.previousPeriod || previousPeriod

  return (
    <div className="row">
      <div className="col-6">
        <small className="text-secondary">Previous year</small>
        <p className="mb-0">
          <b className={getColor(lastYearToShow, shouldReverse)}>
            {lastYearToShow ? lastYearToShow.toLocaleString() + '%' : 'N/A'}
          </b>
        </p>
      </div>
      <div className="col-6 d-flex justify-content-end flex-column align-items-end">
        <small className="text-secondary">Previous period</small>
        <p className="mb-0">
          <b className={getColor(previousPeriodToShow, shouldReverse)}>
            {previousPeriodToShow ? previousPeriodToShow.toLocaleString() + '%' : 'N/A'}
          </b>
        </p>
      </div>
    </div>
  )
}

export default PreviousTimePeriod

import { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import Select from 'react-select'

import * as Routes from '../../routes'
import { fetchResultData } from '../AnalyticsDashboard/utils'
import { showLoading } from '../entries/utils'
import ChartSection from './components/ChartSection'

const EmailAnalytics: React.FC = () => {
  const { dealershipSlug } = useParams()
  const [loading, setLoading] = useState<boolean>(true)
  const [template, setTemplate] = useState<any>()
  const [emailData, setEmailData] = useState<any>()

  useEffect(() => {
    if (!dealershipSlug) return

    const fetchEmailData = async () => {
      setLoading(true)
      try {
        const fetchUrl = Routes.analytics_dealership_emails_path(dealershipSlug, {
          template: template,
        })
        const data = await fetchResultData(fetchUrl)
        if (data) {
          setEmailData(data)
        }
      } catch (err) {
        setLoading(false)
        throw err
      } finally {
        setLoading(false)
      }
    }

    fetchEmailData()
  }, [dealershipSlug, template])

  const emailStatusToClass = (status: string) => {
    switch (status) {
      case 'click':
      case 'open':
        return 'text-success'
      case 'bounce':
      case 'blocked':
        return 'text-warning'
      default:
        return ''
    }
  }

  const subText = (
    <ul className="mt-3">
      <li>
        {emailData?.emails_sent?.total} total emails in the {emailData?.emails_sent?.timeframe_name}
      </li>
    </ul>
  )

  return (
    <>
      {emailData && !loading ? (
        <div className="p-4 w-100">
          <div className="d-flex align-items-center mb-3">
            <h4 className="mb-0">Email analytics</h4>
            <div className="ml-auto">
              <Select
                placeholder="Template..."
                options={emailData.email_templates}
                onChange={(option: { value: string }) => setTemplate(option.value)}
              />
            </div>
          </div>
          <div className="row">
            {emailData?.statuses?.map((data: any, index: number) => (
              <div className="col-md-3" key={`email-status-${index}`}>
                <div className="box p-2 text-center rounded-corners">
                  <h4 className={emailStatusToClass(data.status.toLowerCase())}>
                    {data.percentage}%
                  </h4>
                  <p className="mb-0 text-secondary">{data.status}</p>
                  <small className="text-secondary">
                    {data.count} {data.status}
                  </small>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-md-6">
              <ChartSection
                title="Emails Sent"
                subtitle={emailData.emails_sent?.timeframe_name || 'Last 30 days'}
                chartData={emailData.emails_sent?.daily_counts}
                chartType="Bar"
                subText={subText}
              />
            </div>
            <div className="col-md-6">
              <ChartSection
                title="Email Status"
                subtitle={emailData.email_status?.timeframe_name || 'Last 30 days'}
                chartData={emailData.email_status?.status_distribution}
                chartType="Pie"
              />
            </div>
          </div>
        </div>
      ) : (
        showLoading()
      )}
    </>
  )
}

export default EmailAnalytics

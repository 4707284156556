import React, { useContext, useEffect, useRef, useState } from 'react'

import { Toast } from 'primereact/toast'
import { Link, useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { User } from '../AnalyticsDashboard/types'
import Loading from '../Loading'
import { CarLoanService } from '../carLoans/carLoanService'
import CarLoansBox from '../carLoans/components/CarLoansBox'
import { CurrentUserContext } from '../contexts'
import { useFetchDealership } from '../dataHooks'
import { csrfToken } from '../entries/utils'
import { standardHeaders } from '../entries/utils'
import NotesList from '../notes/NotesList'
import showToast from '../shared/ShowToast'
import TestDrivesBox from '../testDrives/components/TestDrivesBox'
import { TestDriveService } from '../testDrives/testDriveService'
import CompletedCheckoutsSection from './CompletedCheckoutsSection'
import ContactDetails from './ContactDetails'
import CreditScoreRequestsSection from './CreditScoreRequestSection'
import DepositRequestModal from './DepositRequestModal'
import LeadClustersSection from './LeadClusterSection'
import LoyaltyBalance from './LoyaltyBalance'
import Prizes from './Prizes'
import RecentEmailsSection from './RecentEmailsSection'
import RecentTextsSection from './RecentTextsSection'
import ServiceBookingsSection from './ServiceBookingsSection'
import VehiclesSection from './VehiclesSection'
import WatchlistSection from './WatchlistSection'
import { Contact, Dealership, Vehicle } from './types'

const ShowContact: React.FC = () => {
  const [contact, setContact] = useState<Contact | null>(null)
  const [vehicles, setVehicles] = useState<Vehicle[] | null>([])
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false)
  const [testDrives, setTestDrives] = useState(null)
  const [carLoans, setCarLoans] = useState(null)
  const [loading, setLoading] = useState(true)
  const { contactId, dealershipSlug } = useParams<{ contactId: string; dealershipSlug: string }>()
  const notification = useRef(null)
  const csrf: any = csrfToken

  // Dealership context
  const { dealership } = useFetchDealership() as { dealership: Dealership }

  const loadTestDrives = async () => {
    const params = { contact_id: contactId, page: 1, rows: 50 }

    TestDriveService.getTestDrives({
      params,
      csrf,
      dealershipId: dealershipSlug,
      urlParams: [],
    }).then((data) => {
      setTestDrives(data.data.test_drives)
    })
  }

  const loadCarLoans = async () => {
    const params = { contact_id: contactId, page: 1, rows: 50 }

    CarLoanService.getCarLoans({ params, csrf, dealershipId: dealershipSlug, urlParams: [] }).then(
      (data) => {
        setCarLoans(data.data.car_loans)
      }
    )
  }

  useEffect(() => {
    loadTestDrives()
    loadCarLoans()
  }, [contact])

  const currentUser = useContext(CurrentUserContext) as User

  async function getContact(dealershipId: number): Promise<void> {
    try {
      setLoading(true)
      const url = `${Routes.dealership_contact_path(dealershipId, contactId)}.json`
      const response = await fetch(url)
      setContact(await response.json())
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  function can_accept_delivery_error() {
    if (dealership && contact) {
      if (!contact.can_accept_delivery) {
        if (!contact.email_or_phone_present) {
          return (
            <div className="text-center">
              <small className="text-secondary">Please add email</small>
            </div>
          )
        } else if (contact.gmb_profiles_count < 1) {
          return (
            <div className="text-center">
              <small className="text-secondary">Please add GMB profile to dealership</small>
            </div>
          )
        }
      }
    }
  }

  const handleDeleteVehicle = async (vehicle: Vehicle) => {
    try {
      const url = `${Routes.dealership_vehicle_path(dealership.slug, vehicle.id)}.json`

      const response = await fetch(url, {
        method: 'DELETE',
        headers: standardHeaders,
      })
      if (response.ok) {
        setVehicles(vehicles?.filter((v) => v.id !== vehicle.id))

        showToast(
          notification,
          'success',
          'Vehicle deleted!',
          'The vehicle was successfully deleted.'
        )
      } else {
        console.error('Failed to delete vehicle')

        showToast(notification, 'error', 'Failed to delete vehicle.', 'Please try again.')
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (dealership) {
      getContact(dealership?.id)
    }
  }, [dealership])

  useEffect(() => {
    if (contact) {
      setVehicles(contact.vehicles)
    }
  }, [contact])

  const { has_loyalty: contactHasLoyalty } = contact || {}
  const { has_loyalty: dealershipHasLoyalty } = dealership || {}

  if (loading) {
    return (
      <div className="p-3">
        <Loading />
      </div>
    )
  }

  return (
    <>
      {dealership && contact ? (
        <div className="container-fluid py-3">
          <Toast ref={notification} />
          <div className="row">
            {/* Left column */}
            <div className="col-md-2 mb-3">
              <Link
                className="btn btn-primary btn-block mb-2"
                to={Routes.dealership_contacts_path(dealership.slug)}
              >
                <i className="fa fa-caret-left" /> Back
              </Link>

              <a
                className="btn btn-outline-secondary btn-block mb-2"
                href={Routes.new_dealership_vehicle_path(dealership.id, { contact_id: contact.id })}
              >
                <i className="fa fa-plus" /> Vehicle
              </a>

              <button
                className="btn btn-outline-primary btn-block mb-2"
                onClick={() => setIsDialogVisible(true)}
              >
                <i className="fa fa-dollar-sign" /> Send Deposit
              </button>

              {contact.can_accept_delivery ? (
                <a
                  className="btn btn-outline-success btn-block mb-2"
                  href={Routes.new_dealership_delivery_path(dealership.id, {
                    contact_id: contact.id,
                  })}
                >
                  <i className="fa fa-plus" /> Delivery
                </a>
              ) : null}

              {dealershipHasLoyalty && contactHasLoyalty ? (
                <>
                  <a
                    className="btn btn-outline-success btn-block mb-2"
                    href={Routes.new_dealership_contact_contact_point_path(
                      dealership.id,
                      contact.id
                    )}
                  >
                    <i className="fa fa-plus" /> Points
                  </a>

                  <a
                    className="btn btn-outline-danger btn-block mb-2"
                    href={Routes.new_dealership_contact_contact_point_path(
                      dealership.id,
                      contact.id,
                      { redeem: true }
                    )}
                  >
                    Redeem Points
                  </a>
                </>
              ) : null}

              {can_accept_delivery_error()}
            </div>

            {/* Right column */}
            <div className="col-md-10">
              {contactHasLoyalty ? (
                <div className="row mb-3">
                  <div className="col-lg-6 mb-3 mb-lg-0">
                    <ContactDetails contact={contact} setContact={setContact} />
                  </div>
                  <div className="col-lg-6">
                    <div className="box flex-grow h-100 d-flex flex-column">
                      <LoyaltyBalance contact={contact} dealership={dealership} />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mb-3">
                  <ContactDetails contact={contact} setContact={setContact} />
                </div>
              )}

              {/* Lead Clusters Section */}
              {contact.lead_clusters && contact.lead_clusters.length > 0 && (
                <LeadClustersSection dealership={dealership} leadClusters={contact.lead_clusters} />
              )}

              {/* Credit Score Requests Section */}
              {contact.credit_score_requests && contact.credit_score_requests.length > 0 && (
                <CreditScoreRequestsSection creditScoreRequests={contact.credit_score_requests} />
              )}

              {/* Recent Emails Section */}
              {contact.emails && contact.emails.length > 0 && (
                <RecentEmailsSection dealership={dealership} emails={contact.emails} />
              )}

              {/* Recent Texts Section */}
              {contact.texts && contact.texts.length > 0 && (
                <RecentTextsSection texts={contact.texts} />
              )}

              {/* Prizes Section */}
              {contact.prizes && contact.prizes.length > 0 && <Prizes prizes={contact.prizes} />}

              {/* Vehicles Section */}
              {vehicles && vehicles.length > 0 && (
                <VehiclesSection
                  dealership={dealership}
                  vehicles={vehicles}
                  handleDeleteVehicle={handleDeleteVehicle}
                />
              )}

              {/* Watchlist Section */}
              {contact.cars && contact.cars.length > 0 && <WatchlistSection cars={contact.cars} />}

              {/* Completed Checkouts Section */}
              {contact?.completed_checkouts && contact.completed_checkouts.length > 0 && (
                <CompletedCheckoutsSection completedCheckouts={contact.completed_checkouts} />
              )}

              {/* Service Bookings Section */}
              {contact.service_bookings && contact.service_bookings.length > 0 && (
                <ServiceBookingsSection serviceBookings={contact.service_bookings} />
              )}

              {/* Notes Section */}
              {currentUser ? (
                <div className="box mb-3">
                  <div className="p-3 border-bottom">
                    <h4 className="mb-0">
                      <i className="fa fa-note" /> Notes
                    </h4>
                  </div>

                  <div className="p-3">
                    <NotesList
                      initialNotes={[]}
                      notesPath={Routes.dealership_contact_notes_path(dealership, contact)}
                      currentUserId={currentUser?.id}
                      isAdmin={currentUser?.admin}
                    />
                  </div>
                </div>
              ) : null}
              <div>
                <TestDrivesBox testDrives={testDrives} ownerType="contact" ownerId={contact.id} />
              </div>
              <div>
                <CarLoansBox carLoans={carLoans} ownerType="contact" ownerId={contact.id} />
              </div>
            </div>
          </div>

          <DepositRequestModal
            isOpen={isDialogVisible}
            onClose={() => setIsDialogVisible(false)}
            dealershipId={dealership.id}
            contactId={contact.id}
          />
        </div>
      ) : null}
    </>
  )
}

export default ShowContact

import { useContext, useEffect, useState } from 'react'

import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Panel } from 'primereact/panel'

const VehicleDetailLookups = () => {
  const [data, setData] = useState([])
  const [summary, setSummary] = useState([])
  const [loading, setLoading] = useState(false)
  const [expandedRows, setExpandedRows] = useState(null)
  const currentMonthName = moment().format('MMMM')
  const previousMonthName = moment().subtract(1, 'month').format('MMMM')
  const monthBeforePreviousMonthName = moment().subtract(2, 'month').format('MMMM')

  useEffect(() => {
    if (true) {
      setLoading(true)
      fetch(`/admin/vehicle_detail_lookups.json`)
        .then((response) => response.json())
        .then((data) => {
          setData(data.dealership_vehicle_detail_lookups)
          setSummary(data.summary_details)
          setLoading(false)
        })
    }
  }, [])

  const rowExpansionTemplate = (rowData) => {
    return (
      <div className="p-3">
        <h5>Websites for {rowData.dealership_name}</h5>
        <DataTable value={rowData.dealership_websites} className="p-datatable-sm">
          <Column field="website_id" header="Website ID" />
          <Column field="website_name" header="Website Name" />
          <Column
            field="website_periods.this_month.count"
            body={(rowData) => cellData(rowData['website_periods'], 'this_month')}
            header={`Quantity (${currentMonthName})`}
          />
          <Column
            field="website_periods.last_month.count"
            body={(rowData) => cellData(rowData['website_periods'], 'last_month')}
            header={`Quantity (${previousMonthName})`}
          />
          <Column
            field="website_periods.month_before_last.count"
            body={(rowData) => cellData(rowData['website_periods'], 'month_before_last')}
            header={`Quantity (${monthBeforePreviousMonthName})`}
          />
        </DataTable>
      </div>
    )
  }

  const cellData = (rowData, field) => {
    if (
      rowData[field] &&
      rowData[field]['count'] !== undefined &&
      rowData[field]['price'] !== undefined
    ) {
      return `${rowData[field]['count']} ( $ ${rowData[field]['price']} )`
    }
    return 0
  }

  return (
    <div className="p-3 container">
      <Panel header="Vehicle Detail Lookups">
        <ul>
          <li>{summary.total_lookups_this_month} successful lookups this month</li>
          <li>{summary.total_lookups_last_month} successful lookups last month</li>
          <li>{summary.total_lookups_last_3_months} successful lookups last 3 months</li>
          <li>${summary.approximate_cost_this_month} approximate cost this month</li>
          <li>${summary.approximate_cost_last_month} approximate cost last month</li>
        </ul>
      </Panel>
      <DataTable
        value={data}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="dealership_id"
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 20]}
        className="p-datatable-sm"
        loading={loading}
      >
        <Column expander style={{ width: '3em' }} />
        <Column field="dealership_id" header="Dealership ID" sortable />
        <Column field="dealership_name" header="Dealership Name" sortable />
        <Column
          field="dealership_periods.this_month.count"
          body={(rowData) => cellData(rowData['dealership_periods'], 'this_month')}
          header={`Quantity (${currentMonthName})`}
          sortable
        />
        <Column
          field="dealership_periods.last_month.count"
          body={(rowData) => cellData(rowData['dealership_periods'], 'last_month')}
          header={`Quantity (${previousMonthName})`}
          sortable
        />
        <Column
          field="dealership_periods.month_before_last.count"
          body={(rowData) => cellData(rowData['dealership_periods'], 'month_before_last')}
          header={`Quantity (${monthBeforePreviousMonthName})`}
          sortable
        />
      </DataTable>
    </div>
  )
}

export default VehicleDetailLookups

import { useRef, useState } from 'react'

import { Toast } from 'primereact/toast'

import { Errors } from '../entries/FormElements'
import { Input, PhoneInput } from '../entries/FormElements'
import { formatPhone } from '../entries/utils'
import { updateContactAPI } from '../leads/utils'

const formatValue = (field, value) => {
  if (!value) return value

  if (field === 'phone_number') {
    return formatPhone(value)
  } else if (field === 'date_of_birth') {
    return new Date(value).toLocaleDateString()
  } else {
    return value
  }
}

const Form = ({ value, field, setContact, setEditing, placeholder, contact }) => {
  const [newValue, setNewValue] = useState(value)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(false)

  const onSubmit = () => {
    setLoading(true)
    const data = {
      contact: {
        [field]: newValue,
      },
    }

    updateContactAPI(contact.id, data)
      .then((res) => {
        if (res.status === 422) {
          return res.json().then((errors) => {
            setLoading(false)
            setErrors(errors)
            return Promise.reject(errors)
          })
        }
        return res.json()
      })
      .then((res) => {
        setEditing(false)
        setLoading(false)
        setContact(res)
      })
  }

  const InputComponent = field === 'phone_number' ? PhoneInput : Input

  return (
    <div>
      <InputComponent
        type="text"
        className="form-control mb-2 w-100"
        value={newValue}
        onChange={(e) => {
          setNewValue(e.target.value)
        }}
        id={`editable-item-${field}-input`}
        placeholder={placeholder}
        disabled={loading}
        wrapperClass=""
        label={false}
      />
      {errors && <Errors errors={errors} />}
      <div
        className={'btn btn-outline-secondary btn-sm mr-2' + (loading ? ' disabled' : '')}
        onClick={onSubmit}
        id={`editable-item-${field}-save`}
        disabled={loading}
      >
        {loading ? 'Loading...' : 'Save'}
      </div>
      <div
        className="btn btn-outline-secondary btn-sm"
        onClick={() => setEditing(false)}
        disabled={loading}
      >
        Cancel
      </div>
    </div>
  )
}

const EditableItem = ({ contact, field, label, value, setContact, placeholder }) => {
  const [onHover, setOnHover] = useState(false)
  const [editing, setEditing] = useState(false)
  const toast = useRef(null)

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'Copied',
          detail: 'Text copied to clipboard!',
        })
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err)
      })
  }

  return (
    <div
      className={onHover ? 'bg-light py-2' : 'py-2'}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      id={`editable-item-${field}`}
      style={{ maxWidth: '500px' }}
    >
      {editing ? (
        <Form
          value={value}
          setContact={setContact}
          contact={contact}
          field={field}
          setEditing={setEditing}
          placeholder={placeholder}
        />
      ) : (
        <div className="d-flex">
          <Toast ref={toast} />
          <div>
            <div style={{ fontSize: '12px', textTransform: 'uppercase', fontWeight: 'bold' }}>
              {label}:
            </div>
            <div>
              {formatValue(field, value) || <span className="text-secondary">{label}</span>}
            </div>
          </div>
          <div className={onHover ? 'ml-auto' : 'd-none'} id={`editable-item-${field}-button`}>
            <div className="btn btn-sm btn-outline-secondary" onClick={() => setEditing(true)}>
              <i className="fa fa-edit"></i>
            </div>
            {value && (
              <div className="btn btn-sm btn-outline-secondary ml-1" onClick={copyToClipboard}>
                <i className="fa fa-copy"></i>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default EditableItem

import { useState } from 'react'

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import { isMobile } from 'react-device-detect'

import * as Routes from '../../routes'
import { SortableItem } from './SortableItem'

const SortPhotos = ({ photos, setUpdatedPhotos, openLightbox, car, setIndex, dealership }) => {
  const [items, setItems] = useState(photos)
  const sensors = isMobile
    ? useSensors(useSensor(TouchSensor))
    : useSensors(
        useSensor(PointerSensor, {
          activationConstraint: {
            distance: 8,
          },
        }),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
        })
      )

  let filteredItems = items.filter((i) => i.id)

  if (filteredItems.length === 0) {
    return <div className="text-secondary">No photos</div>
  }

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <div className="row">
        <SortableContext items={items} strategy={rectSortingStrategy}>
          {filteredItems.map((item, index) => (
            <SortableItem
              key={item.id}
              item={item}
              index={index}
              openLightbox={openLightbox}
              setIndex={setIndex}
              car_slug={car?.slug}
              photos={items}
              setUpdatedPhotos={setItems}
              dealership={dealership}
            />
          ))}
        </SortableContext>
        <div className="col-6 col-md-2 mb-2">
          <a
            href={Routes.new_image_car_path(car.slug)}
            className="d-flex align-items-center justify-content-center border rounded h-100 w-100 text-secondary"
            style={{ cursor: 'pointer' }}
          >
            <i className="fa fa-plus mr-1"></i>
            Photos
          </a>
        </div>
      </div>
    </DndContext>
  )

  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id)
        const newIndex = items.findIndex((item) => item.id === over.id)

        let newItems = arrayMove(items, oldIndex, newIndex)
        setUpdatedPhotos(newItems)
        return newItems
      })
    }
  }
}

export default SortPhotos

import { useState } from 'react'

import { Dialog } from 'primereact/dialog'
import CreatableSelect from 'react-select/creatable'

import { standardHeaders } from '../entries/utils'

const Tags = ({ leadCluster, setLeadCluster, uniqueTags = [], renderButton }) => {
  let [visible, setVisible] = useState(false)
  let [tags, setTags] = useState(
    (leadCluster?.tags || []).map((tag) => ({ value: tag, label: tag }))
  )
  let [loading, setLoading] = useState(false)

  const onSave = () => {
    let selectedTags = tags.map((tag) => tag.value)
    setLoading(true)
    fetch(`/dealerships/${leadCluster.dealership_id}/lead_clusters/${leadCluster.id}`, {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify({ lead_cluster: { tags: selectedTags } }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLeadCluster(res)
        setLoading(false)
        setVisible(false)
      })
  }

  const handleChange = (selectedTags) => {
    // Update the state with the new tags
    setTags(selectedTags.map((tag) => ({ value: tag.value, label: tag.label })))
  }

  // Combine existing tags with unique tags for the select options
  const combinedTags = [...tags, ...uniqueTags.map((tag) => ({ value: tag, label: tag }))]

  return (
    <>
      <Dialog
        header="Tags"
        visible={visible}
        style={{ width: '35vw', minHeight: '500px' }}
        onHide={() => setVisible(false)}
        dismissableMask
      >
        <div className="mb-2">Add Tags</div>
        <CreatableSelect
          isClearable
          isMulti
          onChange={handleChange}
          value={tags}
          options={combinedTags}
        />
        <div
          className={'btn btn-outline-primary mt-3 btn-block' + (loading ? ' disabled' : '')}
          onClick={onSave}
        >
          {loading ? 'Saving...' : 'Save'}
        </div>
      </Dialog>
      {renderButton(setVisible)}
    </>
  )
}

export default Tags

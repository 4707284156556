import { Dispatch, RefObject, SetStateAction, useState } from 'react'

import _ from 'lodash'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { confirmDialog } from 'primereact/confirmdialog'
import { InputSwitch } from 'primereact/inputswitch'
import { SelectButtonChangeEvent } from 'primereact/selectbutton'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'

import * as Routes from '../../../routes'
import { parameterize } from '../../entries/utils'
import showToast from '../../shared/ShowToast'
import Dialog from '../common/Dialog'
import { Info } from '../common/Icons'
import Tooltip from '../common/Tooltip'
import { capitalize } from '../common/commonUtils'

/**
 * Copies the provided URL to the clipboard.
 * @param url - The URL to be copied.
 */
export function copyToClipboard(url: string) {
  const textArea = document.createElement('textarea')
  textArea.value = url
  document.body.appendChild(textArea)
  textArea.select()
  document.execCommand('copy')
  document.body.removeChild(textArea)
}

/**
 * Handles the states related to draft and published content, determining if they are unsaved or published.
 * @param draftBlocksJson - JSON representation of draft blocks.
 * @param blocksJson - JSON representation of published blocks.
 * @param setDraftState - Function to set the state for draft content presence.
 * @param setPublishedState - Function to set the state for published content presence.
 */
export function handleDraftAndPublishedStates(
  draftBlocksJson: string,
  blocksJson: string,
  setDraftState: Dispatch<SetStateAction<boolean>>,
  setPublishedState: Dispatch<SetStateAction<boolean>>
) {
  try {
    // Attempt to parse JSON for draft and published blocks
    const draftBlocks = JSON.parse(draftBlocksJson)?.blocks
    const publishedBlocks = JSON.parse(blocksJson)?.blocks

    // If draftBlocks are defined, then page state is ready
    setDraftState(!!draftBlocks)
    // If publishedBlocks are defined, then page is published
    setPublishedState(!!publishedBlocks)

    // Determine if the content is unsaved by comparing draft and published blocks
    console.log('States set successfully.')
  } catch (error) {
    // Easier debugging of live pages
    console.log('Error parsing JSON:', error)

    // Handle case where JSON parsing fails (draftBlocks is valid JSON by default)
    const draftBlocks = JSON.parse(draftBlocksJson)?.blocks

    // If draftBlocks are defined, then page state is ready
    setDraftState(!!draftBlocks)
  }
}

/**
 * Deletes an image using a DELETE request to the specified route with the provided CSRF token.
 * @param route - The route for the DELETE request to delete the image.
 * @param csrf - The CSRF token required for authentication.
 * @returns A promise that resolves to a boolean indicating the success of the deletion.
 */
export async function deleteImage(route: string, csrf: string) {
  const response = await fetch(route, {
    method: 'DELETE',
    headers: {
      'X-CSRF-Token': csrf,
      Accept: 'application/json',
    },
  })

  return response.ok
}

/**
 * Handles image deletion, updates states and provides a success/error message.
 * @param deleteImageUrl - The route for the DELETE request to delete the image.
 * @param csrf - The CSRF token required for authentication.
 * @param setImageThumbUrl - The Image thumbnail setStateAction.
 * @param setUploadedFile - The Image file setStateAction.
 * @param notification - The toast notification RefObject.
 */
export const handleDeleteImage = async (
  deleteImageUrl: string,
  csrf: string,
  setImageThumbUrl: Dispatch<SetStateAction<string>>,
  setUploadedFile: Dispatch<SetStateAction<File>>,
  notification: RefObject<Toast>
) => {
  const response = await deleteImage(deleteImageUrl, csrf)

  if (response) {
    setImageThumbUrl(null)
    setUploadedFile(null)
    showToast(notification, 'success', 'Image deleted', 'The image was successfully deleted')
  } else {
    console.error('Failed to delete the image')
    showToast(notification, 'error', 'Failed to delete the image', 'Please try again')
  }
}

/**
 * Validates the input to redundant '{website.name}' as it's automatically appended to metaTitles.
 * Used for both title & metaTitle inputs.
 * @param fieldName - The name of the input being validates.
 * @param value - The input value.
 * @param setValue - The field setStateAction.
 * @param setShowWebsiteNameVariableWarning - The setStateAction for showing a warning tooltip.
 */
export const handleWebsiteNameVariableCheck = (
  fieldName: string,
  value: string,
  setValue: Dispatch<SetStateAction<string>>,
  setShowWebsiteNameVariableWarning: Dispatch<
    SetStateAction<{ title: boolean; metaTitle: boolean }>
  >
) => {
  const includesWebsiteName = value.includes('{website.name}')

  setShowWebsiteNameVariableWarning((prev) => ({
    ...prev,
    [fieldName]: includesWebsiteName,
  }))
  setValue(value)
}

/**
 * Validates the title input to prevent empty titles.
 * @param value - The input value.
 * @param setTitle - The title setStateAction.
 * @param setShowWebsiteNameVariableWarning - The setStateAction for showing a warning tooltip.
 */
export const validateTitle = (
  type: string,
  value: string,
  setTitle: Dispatch<SetStateAction<string>>,
  setShowWebsiteNameVariableWarning?: Dispatch<
    SetStateAction<{ title: boolean; metaTitle: boolean }>
  >
) => {
  if (value === '') {
    alert(
      `${capitalize(
        type
      )} title cannot be empty.\n\nPlease use the "Slug Override" & "Meta Title" fields to control the ${type} url and meta title.`
    )
  } else if (type === 'page') {
    handleWebsiteNameVariableCheck('title', value, setTitle, setShowWebsiteNameVariableWarning)
  } else {
    setTitle(value)
  }
}

/**
 * Handles the modification and setting of a slug override.
 * @param slug - The current slug override value.
 * @param setSlugOverride - Function to set the modified slug override.
 * @param type - The type of slug being handled.
 */
export function handleSlugFormatting(
  slug: string,
  setSlug: Dispatch<SetStateAction<string>>,
  type: 'default' | 'canonical' | 'search' = 'default'
) {
  let modifiedSlug = slug

  // Replace all spaces with dashes
  modifiedSlug = modifiedSlug.replace(/\s+/g, '-')

  // Ensure the slug is in lowercase and only contains valid characters
  // If type is 'canonical', allow forward slashes
  const regex = type === 'canonical' || type === 'search' ? /[^a-z0-9/-]+/g : /[^a-z0-9-]+/g
  modifiedSlug = modifiedSlug.toLowerCase().replace(regex, '')

  // If type is 'canonical', strip the first character if it is a forward slash
  if ((type === 'canonical' || type === 'search') && modifiedSlug.startsWith('/')) {
    modifiedSlug = modifiedSlug.slice(1)
  }

  setSlug(modifiedSlug)
}

/**
 * Cleans the provided slug override by converting it to a valid slug format if it ends with a dash.
 * @param slug - The slug override to be cleaned.
 * @param setSlug - Function to set the cleaned slug override.
 */
export function cleanSlug(
  slug: string,
  setSlug: Dispatch<SetStateAction<string>>,
  type: 'default' | 'canonical' | 'search' = 'default'
) {
  let cleanedSlug = slug

  if (
    (type === 'canonical' || type === 'search') &&
    (cleanedSlug.endsWith('/') || cleanedSlug.endsWith('-'))
  ) {
    // Remove all trailing slashes and dashes
    cleanedSlug = cleanedSlug.replace(/[-\/]+$/, '')
  } else if (cleanedSlug.endsWith('-')) {
    cleanedSlug = parameterize(slug)
  }
  setSlug(cleanedSlug)
}

/**
 * Loads available authors for a given dealership by making a fetch request.
 * @param dealershipId - The identifier for the dealership.
 * @param setLoading - Function to set the loading state.
 * @param setAvailableAuthors - Function to set the available authors based on the fetch response.
 */
export function loadAvailableAuthors(
  dealershipId: string | boolean,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setAvailableAuthors: Dispatch<SetStateAction<object[]>>
) {
  if (!dealershipId) {
    return false
  }

  setLoading(true)

  try {
    fetch(Routes.dealership_users_path(dealershipId) + '.json?available_authors=true', {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }
        return response.json()
      })
      .then((data) => {
        setAvailableAuthors(data)
      })
      .catch((error) => {
        console.error('Fetch error:', error)
        // Handle error as needed
        setLoading(false)
      })
  } catch (error) {
    console.error('Unexpected error:', error)
    // Handle unexpected errors
    setLoading(false)
  }
}

/**
 * Opens a confirm dialog where users can choose to undo their draft changes.
 * i.e. Set draft blocks back to published blocks.
 * @param blocksJson - The blocksJson string.
 * @param setDraftBlocksJson - The draft blocks setStateAction.
 * @param setShouldSaveDraft - A setStateAction used to trigger a save after the undo action is complete.
 */
export function undoDraftChanges(
  blocksJson: string,
  setDraftBlocksJson: Dispatch<SetStateAction<string>>,
  setShouldSaveDraft: Dispatch<SetStateAction<boolean>>
) {
  return confirmDialog({
    message: 'Are you sure you want to undo your changes?',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      // Check if blocksJson is not empty and is not just an empty object '{}' to prevent parsing errors
      if (!blocksJson || blocksJson === '{}' || _.isEmpty(blocksJson)) {
        setDraftBlocksJson(JSON.stringify({ blocks: [] })) // Reset draftBlocksJson to its initial empty structure when blocksJson is empty
      } else {
        setDraftBlocksJson(blocksJson)
      }
      setShouldSaveDraft(true) // Trigger save draft with the new (blocks) state to finalise undo action
    },
  })
}

/**
 *
 * @param event - The select button change event.
 * @param togglePageVisibilityUrl - The route used to toggle the page's visibility.
 * @param csrf - The CSRF token required for authentication.
 * @param visible - The current visibility state of the page.
 * @param setVisible - the page visibilty setStateAction.
 * @param notification - the notification refObject.
 */
export const togglePageVisibility = (
  type: 'page' | 'blog',
  event: SelectButtonChangeEvent,
  togglePageVisibilityUrl: string,
  csrf: string,
  visible: boolean,
  setVisible: Dispatch<SetStateAction<boolean>>,
  notification: RefObject<Toast>
) => {
  // Custom handling for SelectButton
  // If user clicks active button, do nothing
  if (event.target.value === null) {
    return
  }

  fetch(togglePageVisibilityUrl, {
    method: 'PUT',
    headers: {
      'X-CSRF-Token': csrf,
      'Content-Type': 'application/json',
    },
  })
    .then(() => {
      setVisible(!visible)
      showToast(
        notification,
        'success',
        `${capitalize(type)} ${visible ? 'hidden' : 'made visible'} successfully`,
        ''
      )
    })
    .catch((error) => {
      showToast(notification, 'error', `Error changing ${type} visibility`, 'Please try again')
      console.error(`Error toggling ${type} visibility:`, error)
    })
}

// Returns an array of options for the page category dropdown
// These match some of the existing FE routes that we use
export function getPageCategoryOptions() {
  return [
    { label: 'About Us', value: 'about-us' },
    { label: 'Careers', value: 'careers' },
    { label: 'Contact', value: 'contact' },
    { label: 'Finance', value: 'finance' },
    { label: 'Fleet', value: 'fleet' },
    { label: 'Parts & Accessories', value: 'parts-and-accessories' },
    { label: 'Service', value: 'service' },
    { label: 'New Cars', value: 'new-cars' },
    { label: 'Used Cars', value: 'used-cars' },
    { label: 'Enquiries', value: 'enquiries' },
    { label: 'Our Commitment', value: 'our-commitment' },
    { label: 'News', value: 'news' },
  ]
}

/**
 * Generates the full path for a page based on a number of variables.
 * @param usePagesPath - True if the website uses /pages/
 * @param template - The page's template.
 * @param pageCategory - The page's category.
 * @param templateOverride - The page's template override.
 * @param slug - The page's slug.
 * @returns the full path of the page.
 */
export function generateFullPath(
  usePagesPath: boolean,
  template: string,
  pageCategory: string,
  templateOverride: string,
  slug: string
) {
  // Priority = pageCategory > template > usePagesPath
  const subPath = pageCategory
    ? `${pageCategory}/`
    : template === 'default'
      ? usePagesPath
        ? 'pages/'
        : ''
      : template === 'search'
        ? 'search/'
        : ''
  // Return the full path (template override takes precedence)
  // Remove any leading slashes from the template override
  return templateOverride ? templateOverride.replace(/^\/+/, '') : `${subPath}${slug}`
}

/**
 * Displays a SERP preview of the page's data.
 * @param title - The page's metaTitle.
 * @param description - The page's metaDescription.
 * @param url - The page's url.
 */
export const SERPPreview = ({
  title,
  description,
  url,
}: {
  title: string
  description: string
  url: string
}) => {
  const [show, setShow] = useState(false)

  const truncateTitle = (title: string) => {
    return title.length > 60 ? title.substring(0, 57) + '...' : title
  }

  const truncateDescription = (description: string) => {
    return description.length > 160 ? description.substring(0, 157) + '...' : description
  }

  const Content = ({ type = 'mobile' }) => {
    return (
      <div className="d-flex flex-column w-100 overflow-hidden">
        <div
          id="serp_preview_url"
          className={classNames('text-success small', type === 'mobile' ? 'order-1' : 'order-2')}
        >
          {url}
        </div>
        <div
          id="serp_preview_title"
          className={classNames('text-primary', type === 'mobile' ? 'order-2' : 'order-1')}
          style={{ fontSize: '1.5rem' }}
        >
          {truncateTitle(title)}
        </div>
        <div id="serp_preview_description" className="text-muted small order-3">
          {truncateDescription(description)}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="d-block d-lg-none mb-3">
        <Accordion>
          <AccordionTab header="SERP Preview">
            <div className="small text-muted pt-3">
              This is a preview of how your page will appear in search engine results.
            </div>
            <div className="border-top mt-3 mr-3 py-3">
              <Content type="mobile" />
            </div>
          </AccordionTab>
        </Accordion>
      </div>
      <div className="d-none d-lg-block">
        <div className="form-group d-flex align-items-center justify-content-between">
          <span className="d-flex align-items-center">
            SERP Preview
            <Tooltip
              tooltipTrigger={
                <div
                  className="d-inline-flex justify-content-center align-items-center ml-1"
                  style={{ cursor: 'pointer' }}
                >
                  <Info size="20px" stroke="var(--primary)" />
                </div>
              }
              tooltipContent={
                <span className="small">
                  This is a preview of how your page will appear in search engine results.
                </span>
              }
            />
          </span>
          <InputSwitch id="serp_preview_toggle" checked={show} onChange={(e) => setShow(e.value)} />
        </div>
        <Dialog
          isModal={false}
          title="SERP Preview"
          show={show}
          closeClickHandler={() => setShow(false)}
          customStyles={{ width: 600, height: 'fit-content' }}
        >
          <Content type="desktop" />
        </Dialog>
      </div>
    </>
  )
}

/**
 * Generates the meta title for a page.
 * @param title - The page's title. Used as a fallback if metaTitle is empty.
 * @param metaTitle - The page's meta title.
 * @param websiteName - The website's name.
 * @param homePage - True if the page is the home page.
 * @returns the meta title for the page.
 */
export function getMetaTitle(
  title: string,
  metaTitle: string,
  websiteName: string,
  homePage: boolean
) {
  return homePage
    ? `${metaTitle || title}`
    : `${metaTitle || title} | ${websiteName === 'N/A' ? 'Website Name' : websiteName}`
}

import { useParams } from 'react-router-dom'

import ExportDestination from './ExportDestination'

const ExportDestinations = ({ car, setCar, hit, carSwitches }) => {
  let { dealershipSlug } = useParams()

  let canExportToThirdParty =
    (hit.restrict_third_party && !hit.ignore_manufacturer_restriction) || hit.status === 'sold'

  let canShowOnDealerWebsite =
    hit.restricted_on_all_websites_from_stock_rule && !hit.ignore_manufacturer_restriction

  return (
    <>
      <ExportDestination
        car={car}
        destination="Website"
        fieldName="show_on_dealer_website"
        disabled={canShowOnDealerWebsite || !dealershipSlug}
        setCar={setCar}
      />
      {carSwitches.includes('autogate') && (
        <ExportDestination
          car={car}
          destination="Autogate"
          fieldName="load_to_autogate"
          disabled={canExportToThirdParty || !dealershipSlug}
          setCar={setCar}
        />
      )}
      {carSwitches.includes('autotrader') && (
        <ExportDestination
          car={car}
          destination="Autotrader"
          fieldName="load_to_autotrader"
          disabled={canExportToThirdParty || !dealershipSlug}
          setCar={setCar}
        />
      )}
      {carSwitches.includes('drive') && (
        <ExportDestination
          car={car}
          destination="Drive"
          fieldName="load_to_drive"
          disabled={canExportToThirdParty || !dealershipSlug}
          setCar={setCar}
        />
      )}
      {carSwitches.includes('facebook') && (
        <ExportDestination
          car={car}
          destination="Facebook"
          fieldName="load_to_facebook"
          setCar={setCar}
        />
      )}
    </>
  )
}

export default ExportDestinations

import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'

const CreatedAt = ({ created_at }) => {
  return <div>{moment(created_at).fromNow()}</div>
}

const Prizes = ({ prizes }) => {
  return (
    <div className="box mb-3">
      <div className="p-3">
        <h4>
          <i className="fa fa-award mr-1" /> Prizes
        </h4>
      </div>
      <DataTable value={prizes}>
        <Column field="created_at" header="Created" body={CreatedAt} />
        <Column field="name" header="Name" />
      </DataTable>
    </div>
  )
}

export default Prizes

import { useEffect, useState } from 'react'

import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'

import { LeadClusterContext } from '../contexts'
import { useFetchDealership } from '../dataHooks'
import { time_ago } from '../entries/utils'
import { humanize } from '../entries/utils'
import LeadSourceBadge from '../leadCluster/LeadSourceBadge'
import SlideIn from '../leadCluster/SlideIn'

const Name = (cluster) => {
  let [leadCluster, setLeadCluster] = useState(cluster)

  useEffect(() => {
    setLeadCluster(cluster)
  }, [cluster])

  return (
    <LeadClusterContext.Provider value={{ leadCluster, setLeadCluster }}>
      <SlideIn
        leadCluster={leadCluster}
        buttonComponent={({ setVisible }) => (
          <button className="btn btn-link" onClick={() => setVisible(true)}>
            {leadCluster?.contact?.first_name} {leadCluster?.contact?.last_name}
          </button>
        )}
      />
    </LeadClusterContext.Provider>
  )
}

const CreatedAt = ({ created_at }) => (
  <>
    <div>{time_ago(created_at)}</div>
    <div className="small text-secondary">{moment(created_at).format('DD/MM/YYYY')}</div>
  </>
)

const LeadClusterSource = (leadCluster) => {
  return (
    <div>
      <LeadSourceBadge fallbackSource={leadCluster.source} />
    </div>
  )
}

const Status = (leadCluster) => {
  return (
    <div
      className={`badge badge-${leadCluster.lead_status_option?.colour_class_name || 'secondary'}`}
    >
      {humanize(leadCluster.lead_state)}
    </div>
  )
}

const AssignedTo = (leadCluster) => {
  if (leadCluster.user && leadCluster.user.name) {
    return <div>{leadCluster.user.name}</div>
  }

  return (
    <div className="text-danger">
      <i className="fa fa-exclamation-circle mr-1"></i>
      Unassigned
    </div>
  )
}

const LeadClusterBox = ({ leadClusters, dealership }) => {
  useFetchDealership(dealership?.slug)
  return (
    <div className="box mb-3">
      <div className="p-3">
        <h4 className="mb-1">
          <i className="fa fa-bolt mr-1"></i>Leads
        </h4>
      </div>
      <div className="table-responsive">
        <DataTable value={leadClusters} className="table mb-0" style={{ tableLayout: 'fixed' }}>
          <Column field="contact.first_name" header="Name" body={Name} />
          <Column field="lead_state" header="Status" sortable body={Status} />
          <Column field="user.name" header="Assigned to" sortable body={AssignedTo} />
          <Column field="source" header="Source" sortable body={LeadClusterSource} />
          <Column field="created_at" header="Created at" body={CreatedAt} sortable />
        </DataTable>
      </div>
    </div>
  )
}

export default LeadClusterBox

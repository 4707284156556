import { useEffect, useRef, useState } from 'react'

const scrollBy = 300

const ScrollButton = ({ direction, onClick, position }) => (
  <button
    onClick={onClick}
    style={{
      position: 'absolute',
      [position]: 0,
      top: '250px',
      zIndex: 10,
      height: 200,
      background: 'transparent',
      borderRadius: '0.5rem',
      color: '#6c757d',
      border: '1px solid #6c757d',
    }}
  >
    <i className={`fa fa-${direction}`}></i>
  </button>
)

const ScrollBars = ({ children }) => {
  const scrollableDivRef = useRef(null)
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false)
  const [isScrolledToStart, setIsScrolledToStart] = useState(true)

  const handleScrollRight = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollBy({ left: scrollBy, behavior: 'smooth' })
    }
  }

  const handleScrollLeft = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollBy({ left: -scrollBy, behavior: 'smooth' })
    }
  }

  const handleScroll = () => {
    if (scrollableDivRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollableDivRef.current
      setIsScrolledToEnd(scrollLeft + clientWidth >= scrollWidth)
      setIsScrolledToStart(scrollLeft <= 0)
    }
  }

  useEffect(() => {
    handleScroll() // Initial check
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      <div
        className="d-flex"
        style={{ overflowX: 'scroll' }}
        ref={scrollableDivRef}
        onScroll={handleScroll}
      >
        {children}
      </div>
      {!isScrolledToStart && (
        <ScrollButton direction="left" onClick={handleScrollLeft} position="left" />
      )}
      {!isScrolledToEnd && (
        <ScrollButton direction="right" onClick={handleScrollRight} position="right" />
      )}
    </div>
  )
}

export default ScrollBars

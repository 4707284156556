import { useRef } from 'react'

import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import { useParams } from 'react-router-dom'

import { slugToTitle } from '../AnalyticsDashboard/utils'
import { formatSimpleDuration } from '../entries/timeAgo'
import { fetchOnClient, useQuery } from '../tanstackPlaceholderUtils'

export interface Verification {
  id: number
  created_at: Date
  user_id: null | string
  website_slug: string
  dealership_slug: string
  phone: string
  verification_type: 'verify' | 'sms' | null
}

const supabaseVerificationsRoute = (dealershipSlug: string) => {
  // To see actual results: use `/dealerships/${dealershipSlug}/supabase/verifications.json?dealership_slug=big-box-cars`
  return `/dealerships/${dealershipSlug}/supabase/verifications.json?dealership_slug=${dealershipSlug}`
}

const Supabase = () => {
  const { dealershipSlug } = useParams()
  const notification = useRef(null)
  const { data, loading, error } = useQuery<Verification[]>({
    query: fetchOnClient,

    queryKey: supabaseVerificationsRoute(dealershipSlug),
  })

  if (error) {
    return (
      <div className="p-3">
        <h2>An error occurred</h2>
        <p className="text-danger">{error?.message}</p>
      </div>
    )
  }

  const checkouts = data ?? []

  const dateTemplate = (rowData: Verification, field: 'created_at' | 'updated_at') => {
    const date = new Date(rowData[field])
    const now = new Date()
    return formatSimpleDuration(date, now) + ' ago'
  }

  return (
    <div className="p-5">
      <Toast ref={notification} />
      <div className="px-3 py-1">
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <h2>SMS Verifications</h2>
              <p>View the history of SMS verifications to enable user logins on your websites</p>
            </div>
            <DataTable value={data} loading={loading}>
              <Column
                field="website_slug"
                header="Website"
                body={(rowData) => <div>{slugToTitle(rowData.website_slug)}</div>}
              />
              <Column field="phone" header="Phone" />
              <Column
                field="created_at"
                header="Event Time"
                body={(rowData) => dateTemplate(rowData, 'created_at')}
              />
              <Column
                field="verification_type"
                header="Event Type"
                body={(rowData) => (
                  <div>
                    {rowData.verification_type === 'verify' ? (
                      <span className="badge bg-success text-white">Code Verified</span>
                    ) : (
                      <span className="badge bg-primary text-white">SMS Sent</span>
                    )}
                  </div>
                )}
              />
              <Column field="cost" header="Cost" body={(rowData) => <div>$0.07</div>} />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Supabase

import { useContext, useEffect, useState } from 'react'

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import { LeadClusterContext } from '../contexts'
import { DateInput, Input } from '../entries/FormElements'
import { humanize } from '../entries/utils'
import { standardHeaders } from '../entries/utils'

const Form = ({ event, setLeadEvents, label }) => {
  const { leadCluster, setLeadCluster } = useContext(LeadClusterContext)
  let [loading, setLoading] = useState(false)
  let [description, setDescription] = useState('')
  let [errors, setErrors] = useState(null)
  let [followupReason, setFollowupReason] = useState('')
  let [followupDatetime, setFollowupDatetime] = useState()

  useEffect(() => {
    // Calculate tomorrow's date at midday
    const tomorrowAtMidday = new Date()
    tomorrowAtMidday.setDate(tomorrowAtMidday.getDate() + 1)
    tomorrowAtMidday.setHours(12, 0, 0, 0)
    setFollowupDatetime(tomorrowAtMidday)
  }, [])

  const onSubmit = () => {
    if (description.length === 0) {
      return
    }

    const url = `/lead_clusters/${leadCluster.id}/lead_events`

    setLoading(true)
    fetch(url, {
      method: 'POST',
      headers: standardHeaders,
      body: JSON.stringify({
        lead_event: {
          event_type: event,
          description: description,
          followup_reason: followupReason,
          followup_datetime: followupDatetime,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          console.log(data)
          setErrors(data.errors)

          throw new Error('An error occured')
        }

        setLoading(false)
        setLeadEvents(data.leadEvents)
        setLeadCluster({ ...leadCluster, event_counts: data.event_counts })
        setDescription('')
      })
      .catch((error) => {
        setLoading(false)
        console.error(error)
      })
  }

  const disabled = loading || description.length === 0

  return (
    <div className="p-3">
      <div className="form-group">
        <label htmlFor="lead_event_description">
          {label ? label : <>{humanize(event)} event details</>}
        </label>
        <textarea
          className="form-control"
          id="lead_event_description"
          rows="3"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder={`Describe your ${humanize(
            event
          )} here: eg. 'Called customer, will call back tomorrow'`}
        />
        {errors && <div className="text-danger mt-2">{errors}</div>}
      </div>
      {!disabled && leadCluster.dealership.tasks_view && (
        <div className="row">
          <div className="col-md-6">
            <Input
              placeholder="Eg. call customer"
              label="Followup Reason/Event"
              required={true}
              onChange={(e) => setFollowupReason(e.target.value)}
            />
          </div>
          <div className="col-md-6">
            <DateInput
              placeholder="Followup Datetime"
              label="Followup Date & time"
              value={followupDatetime}
              required={true}
              hourFormat="12"
              showTime
              onChange={(value) => {
                setFollowupDatetime(value)
              }}
            />
          </div>
        </div>
      )}
      <div
        className={`btn btn-outline-primary btn-block${disabled ? ' disabled' : ''}`}
        onClick={onSubmit}
        disabled={description.length === 0}
        id="add-event-btn"
      >
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="loading-spinner"></div>
          </div>
        ) : (
          'Add Event'
        )}
      </div>
    </div>
  )
}

const LeadEventForm = ({ setLeadEvents }) => {
  return (
    <Tabs>
      <div className="p-2 border-bottom lead-event-form">
        <TabList>
          <Tab>
            <i className="fa fa-envelope mr-1"></i>
            Email
          </Tab>
          <Tab>
            <i className="fa fa-phone mr-1"></i>
            Phone Call
          </Tab>
          <Tab>
            <i className="fa fa-sms mr-1"></i>
            SMS
          </Tab>
          <Tab>
            <i className="fa fa-note mr-1"></i>
            Notes
          </Tab>
        </TabList>
      </div>
      <TabPanel>
        <Form event="email" setLeadEvents={setLeadEvents} label="Email Details" />
      </TabPanel>
      <TabPanel>
        <Form event="phone" setLeadEvents={setLeadEvents} label="Phone Call Details" />
      </TabPanel>
      <TabPanel>
        <Form event="sms" setLeadEvents={setLeadEvents} label="SMS Details" />
      </TabPanel>
      <TabPanel>
        <Form event="note" setLeadEvents={setLeadEvents} label="Notes" />
      </TabPanel>
    </Tabs>
  )
}

export default LeadEventForm

import { useEffect, useRef, useState } from 'react'

import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import { Link, useParams } from 'react-router-dom'

import { csrfToken } from '../entries/utils'
import showToast from './../shared/ShowToast'

const LeadClusterAssignmentRulesIndex = () => {
  const notification = useRef(null)
  let { dealershipSlug } = useParams()
  let [leadClusterAssignmentRules, setLeadClusterAssignmentRules] = useState([])

  useEffect(() => {
    fetchLeadClusterAssignmentRules(dealershipSlug)
  }, [])

  const ActionButtons = (leadClusterAssignmentRule) => {
    return (
      <>
        <Link
          to={Routes.edit_dealership_lead_cluster_assignment_rule_path(
            dealershipSlug,
            leadClusterAssignmentRule.id
          )}
          className={'btn btn-outline-primary btn-sm mr-3'}
        >
          <i className="fa fa-edit"></i>
        </Link>
        <a
          className={'btn btn-outline-primary btn-sm'}
          onClick={() => {
            destroyLeadClusterAssignmentRule(leadClusterAssignmentRule)
          }}
        >
          <i className="fa fa-trash"></i>
        </a>
      </>
    )
  }

  const destroyLeadClusterAssignmentRule = async (leadClusterAssignmentRule) => {
    const userConfirmation = window.confirm('Are you sure?')

    if (!userConfirmation) {
      return
    }

    try {
      await fetch(
        `${Routes.dealership_lead_cluster_assignment_rule_path(
          dealershipSlug,
          leadClusterAssignmentRule.id
        )}.json`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken,
          },
          credentials: 'same-origin',
        }
      )
      await fetchLeadClusterAssignmentRules(dealershipSlug)
      showToast(notification, 'success', 'Delete successful', '')
    } catch (err) {
      console.error('Error deleting lead cluster assignment rule:', err)
      showToast(notification, 'error', 'Error deleting lead cluster assignment rule.', '')
    }
  }

  const fetchLeadClusterAssignmentRules = async (dealershipSlug) => {
    try {
      const URL = Routes.dealership_lead_cluster_assignment_rules_path(dealershipSlug)
      const response = await fetch(URL, {
        headers: {
          Accept: 'application/json',
        },
      })
      const data = await response.json()
      setLeadClusterAssignmentRules(data)
    } catch (error) {
      console.error('Error fetching lead cluster assignment rules:', error)
      showToast(notification, 'error', 'Error fetching lead cluster assignment rules.', '')
    }
  }

  return (
    <>
      <Toast ref={notification} />
      <div className="box">
        <div className="p-3">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h5 className="m-0">Lead Cluster Assignment Rules</h5>
            <Link
              to={Routes.new_dealership_lead_cluster_assignment_rule_path(dealershipSlug)}
              className={'btn btn-outline-primary btn-sm'}
            >
              <i className="fa fa-plus" /> New
            </Link>
          </div>
          <DataTable value={leadClusterAssignmentRules}>
            <Column field="location.name" header="Location" />
            <Column field="category" header="Category" />
            <Column field="dealership_user.email" header="Assignee" />
            <Column body={ActionButtons} header=""></Column>
          </DataTable>
        </div>
      </div>
    </>
  )
}

export default LeadClusterAssignmentRulesIndex

import LoadingBoxes from '../../../../entries/LoadingBoxes'
import { useAnalyticsDashboard, usePreviewData } from '../../../contexts/hooks'
import ChartComponent from '../../../features/Charts/components/ChartComponent'
import { useViewportData } from '../../../hooks'
import type { ModuleProps, TimeRange } from '../../../types'
import { getModuleComponentFromName } from '../../../utils'
import PreviousTimePeriod from '../../Charts/components/PreviousTimePeriod'
import { TotalForTimePeriod } from '../../Charts/components/TotalForTimePeriod'
import { usePreviousTimePeriod } from '../../Charts/hooks'
import { useModuleData } from './hooks'

const allowedModulesForTotal = [
  'Google Adwords Ads',
  'Google Display Ads',
  'Google VLA Ads',
  'Google Ads',
  'Facebook Ads',
  'Google Analytics',
]

const PaymentsFooter: React.FC<{ data: any; timeRange: TimeRange }> = ({ data, timeRange }) => {
  const timeframe = (timeRange?.timeframe || 'Last 30 Days').toLowerCase()
  return (
    <>
      {data?.number_of_payments > 0 ? (
        <ul className="mt-4">
          <li>
            {data.number_of_payments} payments {timeframe}
          </li>
          <li>
            {data.total_amount} total in payments {timeframe}
          </li>
        </ul>
      ) : null}
    </>
  )
}

const SoldCarsFooter: React.FC<{ data: any; timeRange: TimeRange }> = ({ data, timeRange }) => {
  const timeframe = (timeRange?.timeframe || 'Last 30 Days').toLowerCase()
  return (
    <>
      {data?.total_sold_count > -1 ? (
        <ul className="mt-4">
          <li>
            {data.total_sold_count} total cars withdrawn {timeframe}
          </li>
        </ul>
      ) : null}
    </>
  )
}

const CarWatchlistFooter: React.FC<{ data: any; timeRange: TimeRange }> = ({ data, timeRange }) => {
  const timeframe = (timeRange?.timeframe || 'Last 30 Days').toLowerCase()

  return (
    <>
      {data?.total_watchlist > -1 ? (
        <span>
          <ul className="mt-4">
            <li>
              {data.total_watchlist} total car watches {timeframe}
            </li>
          </ul>
        </span>
      ) : null}
    </>
  )
}

const PortalLoginsFooter: React.FC<{ data: any; timeRange: TimeRange }> = ({ data, timeRange }) => {
  const timeframe = (timeRange?.timeframe || 'Last 30 Days').toLowerCase()

  return (
    <>
      {data?.total_portal_logins > -1 ? (
        <span>
          <ul className="mt-4">
            <li>
              {data.total_portal_logins} total portal logins {timeframe}
            </li>
          </ul>
        </span>
      ) : null}
    </>
  )
}

const GenericModuleComponent: React.FC<ModuleProps> = ({
  timeRange,
  item,
  selectedLocation,
  isOverlay = false,
}) => {
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)
  const moduleConfig = getModuleComponentFromName(item?.module)
  const { editModeEnabled, dashboardLevel } = useAnalyticsDashboard()
  const { editingPreviewData, editingPreviewTotal } = usePreviewData()
  const {
    totalForTimePeriod,
    previousPeriod,
    lastYear,
    loading: previousPeriodLoading,
  } = usePreviousTimePeriod(item?.module)
  const dataToShow = useModuleData(data, item, editingPreviewData, editModeEnabled)

  //  If it's an allowed module, we take previous loading into account, so that everything gets loaded together. If it's not relevant i.e. that module doesnt have a previous period, we don't care about it
  const isAllowedModule = allowedModulesForTotal.includes(moduleConfig?.group)
  const globalLoading = loading || (isAllowedModule && previousPeriodLoading)

  // do not get this total if the module is Google Analytics for Dealership level or Pie/Bar chart types as they are not eligible for total
  const notEligibleForTotal =
    moduleConfig?.group === 'Google Analytics' &&
    (dashboardLevel === 'Dealership' || ['Pie', 'Bar'].includes(moduleConfig?.defaultChartType))

  const isEligibleForTotal =
    allowedModulesForTotal.includes(moduleConfig?.group) && !notEligibleForTotal

  return (
    <div className="h-100" style={{ minHeight: 'inherit' }} ref={viewportRef}>
      {!globalLoading && dataToShow ? (
        <>
          {isEligibleForTotal ? (
            <TotalForTimePeriod
              item={item}
              moduleConfig={moduleConfig}
              totalForTimePeriod={totalForTimePeriod}
              editingPreviewTotal={editingPreviewTotal}
            />
          ) : null}
          <ChartComponent
            chartData={dataToShow || []}
            isOverlay={isOverlay}
            module={item?.module}
          />
          {isEligibleForTotal ? (
            <PreviousTimePeriod
              previousPeriod={previousPeriod}
              lastYear={lastYear}
              moduleConfig={moduleConfig}
              item={item}
              editingPreviewTotal={editingPreviewTotal}
            />
          ) : null}
        </>
      ) : (
        <LoadingBoxes />
      )}
      {moduleConfig?.title === 'Payments' ? (
        <PaymentsFooter data={data} timeRange={timeRange} />
      ) : null}
      {moduleConfig?.title === 'Cars Sold' ? (
        <SoldCarsFooter data={data} timeRange={timeRange} />
      ) : null}
      {moduleConfig?.title === 'Car Watchlist' ? (
        <CarWatchlistFooter data={data} timeRange={timeRange} />
      ) : null}
      {moduleConfig?.title === 'Portal Logins' ? (
        <PortalLoginsFooter data={data} timeRange={timeRange} />
      ) : null}
    </div>
  )
}

export default GenericModuleComponent

import { useContext } from 'react'

import moment from 'moment'

import { CurrentUserContext } from '../contexts'
import { time_ago } from '../entries/utils'

const ApiLogs = ({ dealership, car }) => {
  const currentUser = useContext(CurrentUserContext)

  if (!currentUser) {
    return null
  }

  return (
    <div className="box p-3 mb-3 d-none d-md-block">
      <h4 className="mb-1">Apilogs</h4>
      <table className="table table-bordered mb-0 rounded table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Created at</th>
            <th>Stock Number</th>
            <th>Sale Status</th>
            <th>Dealer Name</th>
            <th>Success?</th>
            <th>Changed?</th>
            {false && ( // Clients should not be able to view Apilogs
              <th>View Data</th>
            )}
          </tr>
        </thead>
        <tbody>
          {car.apilogs.map((apilog) => (
            <tr key={`apilog-${apilog.id}`}>
              <td>{apilog.id}</td>
              <td>
                {time_ago(apilog.created_at)}
                <br />
                <small className="text-secondary">
                  {' '}
                  {moment(apilog.created_at).utc().format('YYYY-MM-DD HH:mm:ss')}
                </small>
              </td>
              <td>
                {apilog.logable && (
                  <>
                    <a href={Routes.car_path(car.slug)}>{car.stocknum}</a>
                    <br />
                    <small className="text-secondary">{car.name}</small>
                  </>
                )}
              </td>
              <td
                className={
                  apilog.data['SaleStatus'] === 'WITHDRAWN' ? 'text-danger' : 'text-success'
                }
              >
                {apilog.data['SaleStatus']}
              </td>
              <td>
                {apilog.logable ? (
                  <a href={Routes.dealership_path(car.dealership_slug)}>{dealership.name}</a>
                ) : (
                  <span>{apilog.data['Seller']?.['Name']}</span>
                )}
              </td>
              <td>
                {apilog.success ? (
                  <i className="fa fa-check text-success"></i>
                ) : (
                  <i className="fa fa-times text-danger"></i>
                )}
              </td>
              {apilog.photos_added && (
                <td>
                  <div className="small text-success">Photos added = {apilog.photos_added}</div>
                  <div className="small text-danger">Photos deleted = {apilog.photos_removed}</div>
                </td>
              )}
              <td>
                <i
                  className={
                    apilog.loggable_changed ? 'fa fa-check text-success' : 'fa fa-times text-danger'
                  }
                ></i>
              </td>
              {currentUser.admin && ( // Clients should not be able to view Apilogs
                <td>
                  <a href={`/apilogs/${apilog.id}`}>View Data</a>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ApiLogs

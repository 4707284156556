import { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'

const LeadClusterAssignmentRulesForm = ({
  dealershipSlug,
  leadClusterAssignmentRule,
  handleBack,
  submitFunction,
}) => {
  let [categories, setCategories] = useState([])
  let [dealershipUsers, setDealershipUsers] = useState([])
  let [locations, setLocations] = useState([])
  const {
    handleSubmit,
    formState: { errors },
    register,
    resetField,
  } = useForm({
    defaultValues: {
      location_id: leadClusterAssignmentRule.location_id,
      category: leadClusterAssignmentRule.category,
      dealership_user_id: leadClusterAssignmentRule.dealership_user_id,
    },
  })
  let buttonLabel = 'Create'
  if (leadClusterAssignmentRule.id) {
    buttonLabel = 'Update'
  }

  useEffect(() => {
    fetchDealershipUsers(dealershipSlug)
    fetchLeadCategories()
    fetchLocations(dealershipSlug)
  }, [])

  useEffect(() => {
    if (categories.length) {
      resetField('category', {
        defaultValue: leadClusterAssignmentRule.category || categories[0],
      })
    }
  }, [categories])

  useEffect(() => {
    if (dealershipUsers.length) {
      resetField('dealership_user_id', {
        defaultValue: leadClusterAssignmentRule.dealership_user_id || dealershipUsers[0].id,
      })
    }
  }, [dealershipUsers])

  useEffect(() => {
    if (locations.length) {
      resetField('location_id', {
        defaultValue: leadClusterAssignmentRule.location_id || locations[0].id,
      })
    }
  }, [locations])

  const fetchDealershipUsers = async (dealershipSlug) => {
    try {
      const URL = Routes.dealership_dealership_users_path(dealershipSlug)
      const response = await fetch(URL, {
        headers: {
          Accept: 'application/json',
        },
      })
      const data = await response.json()
      setDealershipUsers(data)
    } catch (error) {
      console.error('Error fetching dealership users:', error)
      showToast(notification, 'error', 'Error fetching dealership users.', '')
    }
  }

  const fetchLeadCategories = async () => {
    try {
      const URL = Routes.categories_leads_path()
      const response = await fetch(URL, {
        headers: {
          Accept: 'application/json',
        },
      })
      const data = await response.json()
      setCategories(data.categories)
    } catch (error) {
      console.error('Error fetching categories:', error)
      showToast(notification, 'error', 'Error fetching categories.', '')
    }
  }

  const fetchLocations = async (dealershipSlug) => {
    try {
      const URL = Routes.dealership_locations_path(dealershipSlug)
      const response = await fetch(URL, {
        headers: {
          Accept: 'application/json',
        },
      })
      const data = await response.json()
      setLocations(data)
    } catch (error) {
      console.error('Error fetching locations:', error)
      showToast(notification, 'error', 'Error fetching locations.', '')
    }
  }

  return (
    <form onSubmit={handleSubmit(submitFunction)}>
      <div className="form-inputs">
        <div className="form-group">
          <label className="form-control-label select">Location</label>
          <select {...register('location_id')} className="form-control">
            {locations.map(function (location) {
              return (
                <option key={location.id} value={location.id}>
                  {location.name}
                </option>
              )
            })}
          </select>
        </div>
        <div className="form-group">
          <label className="form-control-label select">Category</label>
          <select {...register('category')} className="form-control">
            {categories.map(function (category) {
              return (
                <option key={category} value={category}>
                  {category}
                </option>
              )
            })}
          </select>
        </div>
        <div className="form-group">
          <label className="form-control-label select">Dealership User</label>
          <select {...register('dealership_user_id')} className="form-control">
            {dealershipUsers.map(function (dealershipUser) {
              return (
                <option key={dealershipUser.id} value={dealershipUser.id}>
                  {dealershipUser.email}
                </option>
              )
            })}
          </select>
        </div>
      </div>
      <div className="form-actions mt-3">
        <button onClick={handleBack} className="btn btn-link">
          Back
        </button>
        <button type="submit" className="btn btn-primary">
          {buttonLabel}
        </button>
      </div>
    </form>
  )
}

export default LeadClusterAssignmentRulesForm

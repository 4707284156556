import { useEffect, useRef, useState } from 'react'

import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'

import * as Routes from '../../routes'
import Loading from '../Loading'
import { useFetchDealership } from '../dataHooks'
import { standardHeaders } from '../entries/utils'
import showToast from '../shared/ShowToast'
import type { Website } from '../types'

type FinanceProvider = {
  id: number
  name: string
  label: string
  websites: Website[]
  interest_rate: string
}

const FinanceProviders: React.FC = () => {
  const { dealership, loading: dealershipLoading } = useFetchDealership()
  const [currentFinanceProviders, setCurrentFinanceProviders] = useState<FinanceProvider[]>([])
  const [financeProviderOptions, setFinanceProviderOptions] = useState<string[]>([])
  const [selectedFinanceProvider, setSelectedFinanceProvider] = useState<FinanceProvider>()
  const [interestRates, setInterestRates] = useState<{ [key: number]: string }>({})
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    if (!dealership) return

    const fetchFinanceProviders = async () => {
      try {
        const response = await fetch(`${Routes.dealership_finance_providers_path(dealership.id)}`)
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        if (!data) {
          throw new Error('Failed to fetch data')
        }
        setCurrentFinanceProviders(data.current_providers)
        setFinanceProviderOptions(data.provider_options)

        // Fetch interest rates for all providers
        const rates: { [key: number]: string } = {}
        for (const provider of data.current_providers) {
          rates[provider.id] = await getInterestRate(provider)
        }
        setInterestRates(rates)
      } catch (error) {
        console.error('Error fetching finance providers:', error)
      }
    }
    fetchFinanceProviders()
  }, [dealership])

  const fetchDappriceWithRate = async (financeProviderId: number): Promise<string> => {
    try {
      const response = await fetch(
        `${Routes.dapprice_with_rate_dealership_finance_provider_path(dealership.id, financeProviderId)}`
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to fetch data')
      }
      return data.interest_rate
    } catch (error) {
      console.error('Error fetching finance providers:', error)
    }
  }

  const getInterestRate = async (financeProvider: FinanceProvider): Promise<string> => {
    if (financeProvider.name === 'Manual' && financeProvider.interest_rate) {
      return `${financeProvider.interest_rate} %`
    } else {
      const interestRate = await fetchDappriceWithRate(financeProvider.id)
      if (interestRate) {
        return `${interestRate} %`
      }
    }
    return 'N/A'
  }

  const notification = useRef(null)

  if (dealershipLoading) {
    return (
      <div className="d-flex justify-content-center mt-3">
        <Loading />
      </div>
    )
  }

  return (
    <>
      <Toast ref={notification} />
      <div className="FinanceProviders">
        <h1>Listing Finance Providers</h1>
        <div className="box mb-2">
          <table className="table">
            <thead>
              <tr>
                <th>Label</th>
                <th>Name</th>
                <th>Websites</th>
                <th>Current Interest Rate</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentFinanceProviders && currentFinanceProviders.length > 0
                ? currentFinanceProviders.map((financeProvider, index) => (
                    <tr key={financeProvider.id}>
                      <td>{financeProvider.label}</td>
                      <td>{financeProvider.name}</td>
                      <td>{financeProvider.websites?.map((website) => website.name).join(', ')}</td>
                      <td>{interestRates[financeProvider.id]}</td>
                      <td>
                        <a
                          href={`${Routes.edit_dealership_finance_provider_path(dealership?.slug, financeProvider.id)}`}
                        >
                          Edit
                        </a>
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            setVisible(true)
                            setSelectedFinanceProvider(currentFinanceProviders[index])
                          }}
                          className="btn btn-link text-danger"
                        >
                          Destroy
                        </button>
                        <DeleteDialog
                          visible={visible}
                          setVisible={setVisible}
                          financeProvider={selectedFinanceProvider}
                          dealership={dealership}
                          setCurrentFinanceProviders={setCurrentFinanceProviders}
                          notification={notification}
                        />
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
        <br />
        <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            New Finance Provider
          </button>
          <div className="dropdown-menu">
            {financeProviderOptions.map((provider) => (
              <a
                key={provider}
                href={`${Routes.new_dealership_finance_provider_path(dealership.id)}?name=${provider}`}
                className="dropdown-item"
              >
                {provider}
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

const DeleteDialog: React.FC<{
  visible: boolean
  setVisible: (boolean: boolean) => void
  financeProvider: FinanceProvider
  dealership: { id: number; slug: string }
  setCurrentFinanceProviders: (financeProviders: FinanceProvider[]) => void
  notification: any
}> = ({
  visible,
  setVisible,
  financeProvider,
  dealership,
  setCurrentFinanceProviders,
  notification,
}) => {
  async function deleteFinanceProvider(
    dealershipId: number,
    financeProviderId: number,
    notification: any
  ): Promise<FinanceProvider[]> {
    try {
      const response = await fetch(
        `${Routes.dealership_finance_provider_path(dealershipId, financeProviderId)}`,
        {
          method: 'DELETE',
          headers: standardHeaders,
        }
      )
      if (!response.ok) {
        showToast(notification, 'error', 'Error', 'Failed to delete finance provider')
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to fetch data')
      }
      showToast(
        notification,
        'success',
        'Finance Provider Deleted',
        'The finance provider has been deleted successfully'
      )
      return data
    } catch (error) {
      showToast(notification, 'error', 'Error', 'Failed to delete finance provider')
      console.error('Error delete finance provider:', error)
    }
  }

  return (
    <Dialog
      header={`Are you sure you want to delete this ${financeProvider?.name} finance provider?`}
      visible={visible}
      style={{ width: '50vw' }}
      onHide={() => setVisible(false)}
      headerClassName="text-center"
      modal
      dismissableMask
    >
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <button
          className="btn btn-primary mt-2 "
          onClick={() => setVisible(false)}
          style={{ width: '150px' }}
        >
          Cancel
        </button>
        <button
          className="btn btn-danger mt-2 "
          onClick={async () => {
            const newProviders = await deleteFinanceProvider(
              dealership.id,
              financeProvider.id,
              notification
            )
            setCurrentFinanceProviders(newProviders)
            setVisible(false)
          }}
          style={{ width: '150px' }}
        >
          Delete
        </button>
      </div>
    </Dialog>
  )
}

export default FinanceProviders

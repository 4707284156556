import { useContext, useEffect, useRef, useState } from 'react'

import { Column } from 'primereact/column'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { SplitButton } from 'primereact/splitbutton'
import { Toast } from 'primereact/toast'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { CurrentUserContext } from '../contexts'
import { useFetchDealership } from '../dataHooks'
import AuditTrailSlideIn from '../shared/AuditTrailSlideIn'
import showToast from '../shared/ShowToast'
import PhoneNumberForm from './PhoneNumberForm'
import { PhoneNumbersService } from './PhoneNumbersService'

const PhoneNumbers = () => {
  const [phoneNumbers, setPhoneNumbers] = useState([])
  const [providers, setProviders] = useState([])
  const [sources, setSources] = useState([])
  const [numberTypes, setNumberTypes] = useState([])
  const [globalFilter, setGlobalFilter] = useState(null)
  const [loading, setLoading] = useState(true)
  const { dealership } = useFetchDealership()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null)
  const currentUser = useContext(CurrentUserContext)
  const notification = useRef(null)

  let { dealershipSlug } = useParams()
  const [showSlideIn, setShowSlideIn] = useState(false)
  const handleHideSlideIn = () => {
    setShowSlideIn(false)
  }

  useEffect(() => {
    if (dealership) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
      PhoneNumbersService.getPhoneNumbers({}, csrf, dealershipSlug).then((data) => {
        setPhoneNumbers(data.data.phone_numbers)
        setProviders(data.data.providers)
        setSources(data.data.sources)
        setNumberTypes(data.data.number_types)
        setLoading(false)
      })
    }
  }, [dealership])

  const openModal = (phoneNumber) => {
    setSelectedPhoneNumber(phoneNumber)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setSelectedPhoneNumber(null)
  }

  const validatePhoneNumber = (number) => {
    const regex = /^\+61/
    return regex.test(number)
  }

  const handleSave = async (phoneNumberData) => {
    if (phoneNumberData.number === '') {
      showToast(notification, 'error', 'The number field is required.')
      return
    }
    if (phoneNumberData.redirectTo === '') {
      showToast(notification, 'error', 'The redirect to field is required.')
      return
    }
    if (phoneNumberData.locationId === '') {
      showToast(notification, 'error', 'The location field is required.')
      return
    }

    if (!validatePhoneNumber(phoneNumberData.number)) {
      showToast(notification, 'error', 'The number must start with +61.')
      return
    }

    if (!validatePhoneNumber(phoneNumberData.redirectTo)) {
      showToast(notification, 'error', 'The redirect to must start with +61.')
      return
    }

    phoneNumberData.dealership_id = dealership.id
    phoneNumberData.location_id = phoneNumberData.locationId
    phoneNumberData.enable_lead = phoneNumberData.enableLead
    phoneNumberData.enable_transcript = phoneNumberData.enableTranscript
    phoneNumberData.enable_sms = phoneNumberData.enableSms
    phoneNumberData.export_phone_call_data = phoneNumberData.exportPhoneCallData
    phoneNumberData.redirect_to = phoneNumberData.redirectTo
    phoneNumberData.provider = phoneNumberData.provider
    phoneNumberData.number_type = phoneNumberData.numberType

    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')

    if (phoneNumberData.id) {
      const response = await fetch(
        Routes.dealership_phone_number_path(phoneNumberData.dealership_id, phoneNumberData.id),
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrf,
            Accept: 'application/json',
          },
          body: JSON.stringify({ phone_number: phoneNumberData }),
        }
      )
      if (!response.ok) {
        showToast(notification, 'error', 'The phone number could not be saved.')
      } else {
        const data = await response.json()
        setPhoneNumbers(
          phoneNumbers.map((phoneNumber) => (phoneNumber.id === data.id ? data : phoneNumber))
        )
        showToast(notification, 'success', 'Phone number updated successfully.')
        setIsModalOpen(false)
        setSelectedPhoneNumber(null)
      }
    } else {
      const response = await fetch(
        Routes.dealership_phone_numbers_path(phoneNumberData.dealership_id),
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrf,
            Accept: 'application/json',
          },
          body: JSON.stringify({ phone_number: phoneNumberData }),
        }
      )
      if (!response.ok) {
        showToast(notification, 'error', 'The phone number could not be saved.')
      } else {
        const data = await response.json()
        setPhoneNumbers([...phoneNumbers, data])
        showToast(notification, 'success', 'Phone number created successfully.')
        setIsModalOpen(false)
        setSelectedPhoneNumber(null)
      }
    }
  }

  const actionBodyTemplate = (phoneNumber) => {
    const items = [
      {
        label: 'Audit Trail',
        icon: 'fa fa-history',
        command: () => {
          setShowSlideIn(true)
        },
      },
      {
        label: 'Delete',
        icon: 'fa fa-trash',
        command: () => {
          const userConfirmation = window.confirm('Are you sure?')

          if (!userConfirmation) {
            return
          }
          fetch(phoneNumber.delete_url, {
            method: 'DELETE',
            headers: {
              'X-CSRF-Token': document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute('content'),
            },
          }).then(() => {
            window.location.reload()
          })
        },
      },
    ]

    return (
      <div style={{ position: 'relative' }}>
        <SplitButton
          label="Edit"
          buttonProps={{ id: 'edit-button' }}
          onClick={() => openModal(phoneNumber)}
          model={items}
          menuButtonProps={{ id: 'action-button' }}
          outlined
          rounded
        />
        {showSlideIn && (
          <AuditTrailSlideIn
            AuditTrailObject={phoneNumber}
            AuditTrailObjectName="Phone Number"
            Url={Routes.audit_trail_dealership_phone_number_path(dealership?.id, phoneNumber.id)}
            ShowSlideIn={showSlideIn}
            onHide={handleHideSlideIn}
          />
        )}
      </div>
    )
  }

  const header = (
    <div className="table-header">
      <h2>Phone Numbers</h2>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  )

  return (
    <div className="p-3">
      <Toast ref={notification} />
      <ConfirmDialog />
      <div className="d-flex mb-2">
        {currentUser?.admin && (
          <div className="ml-auto">
            <div className="btn btn-outline-primary" onClick={() => openModal()}>
              <i className="fa fa-plus mr-2"></i>
              Buy Phone Number
            </div>
          </div>
        )}
      </div>
      <div className="box">
        <DataTable
          value={phoneNumbers}
          paginator
          rows={10}
          dataKey="id"
          loading={loading}
          globalFilter={globalFilter}
          header={header}
          emptyMessage="No phone numbers found."
          className="p-datatable-striped"
        >
          <Column field="number" header="Number" sortable />
          <Column field="redirect_to" header="Redirect To" sortable />
          <Column field="location_name" header="Location" sortable />
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }} />
        </DataTable>
      </div>
      {isModalOpen && (
        <Dialog
          header={selectedPhoneNumber ? 'Edit Phone Number' : 'New Phone Number'}
          visible={isModalOpen}
          style={{ minWidth: '800px' }}
          onHide={closeModal}
          dismissableMask={true}
        >
          <PhoneNumberForm
            phoneNumber={selectedPhoneNumber}
            dealership={dealership}
            currentUser={currentUser}
            providers={providers}
            sources={sources}
            numberTypes={numberTypes}
            onSave={handleSave}
          />
        </Dialog>
      )}
    </div>
  )
}

export default PhoneNumbers

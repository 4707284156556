import { useRef } from 'react'

import { Toast } from 'primereact/toast'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { csrfToken } from '../entries/utils'
import showToast from './../shared/ShowToast'
import DealershipEmailAddressesForm from './form'

const DealershipEmailAddressesNew = () => {
  let dealershipEmailAddress = {
    email_address: '',
    service: 'mailjet',
    verified: false,
    dkim_verified: false,
    spf_verified: false,
  }
  const navigate = useNavigate()
  const notification = useRef(null)
  let { dealershipSlug } = useParams()

  // Use react-router-dom's useNavigate hook to navigate back
  const handleBack = () => {
    navigate(`/dealerships/${dealershipSlug}/dealership_email_addresses`)
  }

  const submitFunction = async (data) => {
    const URL = Routes.dealership_dealership_email_addresses_path(dealershipSlug)
    try {
      let response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify(data),
      })
      if (response.ok) {
        notification.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Data saved successfully',
        })
        handleBack()
      } else {
        throw response
      }
    } catch (err) {
      console.error('Error creating dealership email address:', err)
      showToast(notification, 'error', 'Error creating dealership email address.', '')
    }
  }

  return (
    <>
      <div className="container py-3">
        <Toast ref={notification} />
        <div className="row d-flex justify-content-center">
          <div className="col-lg-8">
            <div className="box p-3">
              <h1>New Dealership Email Address</h1>
              <DealershipEmailAddressesForm
                dealershipEmailAddress={dealershipEmailAddress}
                submitFunction={submitFunction}
                handleBack={handleBack}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DealershipEmailAddressesNew

import React, { useEffect, useState } from 'react'

import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import { Link, useParams } from 'react-router-dom'

import * as Routes from '../../../routes'
import { TimeRange } from '../../AnalyticsDashboard/types'
import Loading from '../../Loading'
import TimeRangePicker from '../../entries/TimeRange'
import { standardHeaders } from '../../entries/utils'

type AnalyticsDetail = {
  id: number
  name: string
  dealershipSlug: string
  slug: string
  conversionRate: string
  pageViews: number
  leads: number
  uniqueVisits: number
  gaProfileExists: boolean
}

const ConversionRate = (rowData: any) => {
  return <span>{`${rowData.conversionRate}%`}</span>
}

const Overview: React.FC = () => {
  const { manufacturerSlug } = useParams()
  const { dealershipGroupId } = useParams()
  const [slug, setSlug] = useState<string | null>(manufacturerSlug || dealershipGroupId)
  const [data, setData] = useState<AnalyticsDetail[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')
  let [sortField, setSortField] = useState<string>('conversionRate')
  let [sortOrder, setSortOrder] = useState<any>(-1)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(30)
  const [timeRange, setTimeRange] = useState<TimeRange>({
    start: 0,
    end: 0,
    timeframe: '',
    type: '',
  })

  const fetchOverviewData = async (url: string) => {
    const response = await fetch(url, {
      headers: standardHeaders,
    })

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const result = await response.json()
    return result.websites.map((detail: any) => ({
      id: detail.id,
      name: detail.name,
      dealershipSlug: detail.dealership_slug,
      slug: detail.slug,
      conversionRate: detail.conversion_rate,
      pageViews: detail.page_views,
      leads: detail.leads,
      uniqueVisits: detail.unique_visits,
      gaProfileExists: detail.ga_profile === true,
    }))
  }

  useEffect(() => {
    if (!manufacturerSlug && !dealershipGroupId) return

    const fetchData = async () => {
      setLoading(true)

      try {
        const fetchUrl = `/${manufacturerSlug ? 'manufacturers' : 'dealership_groups'}/${slug}/websites/overview.json`
        const fetchedData = await fetchOverviewData(fetchUrl)
        setData(fetchedData)
      } catch (err) {
        setError('Error fetching data')
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [manufacturerSlug, dealershipGroupId])

  useEffect(() => {
    if (!slug) return

    if (timeRange.start === 0 && timeRange.end === 0) {
      return
    }
    const fetchDataWithTimeRange = async () => {
      setLoading(true)
      try {
        const queryString = new URLSearchParams(timeRange as any).toString()
        const fetchUrl = `/${manufacturerSlug ? 'manufacturers' : 'dealership_groups'}/${slug}/websites/overview.json?${queryString}`
        const fetchedData = await fetchOverviewData(fetchUrl)
        setData(fetchedData)
      } catch (err) {
        setError('Error fetching data')
      } finally {
        setLoading(false)
      }
    }

    fetchDataWithTimeRange()
  }, [timeRange])

  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handlePageChange = (event: { first: number; rows: number }) => {
    setCurrentPage(event.first / event.rows)
    setRowsPerPage(event.rows)
  }

  const handleTimeRangeChange = (dateRange: TimeRange): void => {
    setTimeRange(dateRange)
  }

  return (
    <div className="p-4">
      <div className="row">
        <h4 className="col-6 p-0 pl-3">Websites Overview</h4>
      </div>
      <div className="row mb-3">
        <div className="col-6 p-0 pl-3">
          <InputText
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search by name"
          />
        </div>
        <div className="float-right col-6 d-flex justify-content-end">
          <div className="d-flex flex-column align-items-end">
            <TimeRangePicker handleUpdateTimeRange={handleTimeRangeChange} />
            <div className="mt-1" style={{ color: 'orange', fontSize: '0.9em' }}>
              <i className="fa fa-exclamation-triangle" style={{ color: 'orange' }} /> GA Profile
              not configured.
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Loading />
        </div>
      ) : (
        <DataTable
          value={filteredData.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage)}
          rows={rowsPerPage}
          sortField={sortField}
          onSort={(e) => {
            setSortField(e.sortField)
            setSortOrder(e.sortOrder)
          }}
          sortOrder={sortOrder}
          onPage={handlePageChange}
          totalRecords={filteredData.length}
          className="table table-striped table-bordered"
        >
          <Column
            field="name"
            header="Name"
            sortable
            body={(rowData: AnalyticsDetail) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {rowData.name}
                {!rowData.gaProfileExists && (
                  <i
                    className="fa fa-exclamation-triangle"
                    style={{ marginLeft: '8px', color: 'orange', cursor: 'pointer' }}
                  />
                )}
              </div>
            )}
          />
          <Column field="leads" sortable header="Leads" />
          <Column field="conversionRate" sortable header="Conversion Rate" body={ConversionRate} />
          <Column
            field="pageViews"
            sortable
            header="Page Views"
            body={({ pageViews }) => pageViews.toLocaleString()}
          />
          <Column
            field="uniqueVisits"
            sortable
            header="Unique Visits"
            body={({ uniqueVisits }) => uniqueVisits.toLocaleString()}
          />
          <Column
            header="View Dashboard"
            body={(rowData: any) => (
              <Link to={Routes.dealership_website_path(rowData.dealershipSlug, rowData.slug)}>
                View
              </Link>
            )}
          />
        </DataTable>
      )}
      {filteredData.length > rowsPerPage && (
        <Paginator
          first={currentPage * rowsPerPage}
          rows={rowsPerPage}
          totalRecords={filteredData.length}
          onPageChange={handlePageChange}
          className="mt-2"
        />
      )}
    </div>
  )
}

export default Overview

import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
} from 'date-fns'

export function formatSimpleDuration(startDate, endDate) {
  const units = [
    { unit: 'y', differenceFunction: differenceInYears },
    { unit: 'mo', differenceFunction: differenceInMonths },
    { unit: 'w', differenceFunction: differenceInWeeks },
    { unit: 'd', differenceFunction: differenceInDays },
    { unit: 'h', differenceFunction: differenceInHours },
    { unit: 'm', differenceFunction: differenceInMinutes },
    { unit: 's', differenceFunction: differenceInSeconds },
  ]

  for (let { unit, differenceFunction } of units) {
    const difference = differenceFunction(endDate, startDate)
    if (difference !== 0) {
      return `${difference}${unit}`
    }
  }

  return '0s' // or return 'now' if the duration is less than a second
}

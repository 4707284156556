import React, { ReactNode, createContext, useState } from 'react'

import { Location, TimeRange } from '../types'

type TimeRangeLocationContextType = {
  timeRange: TimeRange
  handleUpdateTimeRange: (newTimeRange: TimeRange) => void
  selectedLocation: Location | null
  handleUpdateSelectedLocation: (newLocation: Location) => void
}

export const TimeRangeLocationContext = createContext<TimeRangeLocationContextType>({
  timeRange: { start: 0, end: 0, timeframe: '', type: '' },
  handleUpdateTimeRange: (newTimeRange) => {},
  selectedLocation: null,
  handleUpdateSelectedLocation: (newLocation) => {},
})

export const TimeRangeLocationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [timeRange, setTimeRange] = useState<TimeRange>({
    start: 0,
    end: 0,
    timeframe: '',
    type: '',
  })
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null)

  const handleUpdateTimeRange = (newTimeRange: TimeRange) => {
    setTimeRange(newTimeRange)
  }

  const handleUpdateSelectedLocation = (newLocation: Location) => setSelectedLocation(newLocation)

  return (
    <TimeRangeLocationContext.Provider
      value={{ timeRange, handleUpdateTimeRange, selectedLocation, handleUpdateSelectedLocation }}
    >
      {children}
    </TimeRangeLocationContext.Provider>
  )
}

import React, { useState } from 'react'

import { time_ago } from '../entries/utils'

function Note({ note, onDeleteClick, onSubmit, currentUserId, isAdmin }) {
  const [isEditing, setIsEditing] = useState(false)
  const [editedContent, setEditedContent] = useState(note.content)

  const handleEditButtonClick = () => {
    setIsEditing(true)
  }

  const handleSaveButtonClick = () => {
    onSubmit(note.id, editedContent)
    setIsEditing(false)
  }

  const handleCancelButtonClick = () => {
    setIsEditing(false)
    setEditedContent(note.content)
  }

  return (
    <div className="card mb-4">
      <div className="card-header d-flex justify-content-between align-items-center">
        <div>
          <strong>{note.user?.name || note.external_service}</strong> {time_ago(note.created_at)}
        </div>
        <div>
          {isEditing ? (
            <>
              <a style={{ cursor: 'pointer' }} title="Save">
                <i
                  onClick={handleSaveButtonClick}
                  className="fas fa-save fa-lg mr-2"
                  title="Save"
                ></i>
              </a>
              <a style={{ cursor: 'pointer' }} title="Cancel">
                <i
                  onClick={handleCancelButtonClick}
                  className="fas fa-times fa-lg"
                  title="Cancel"
                ></i>
              </a>
            </>
          ) : (
            <>
              {(isAdmin || note.user?.id === currentUserId) && (
                <>
                  <a style={{ cursor: 'pointer' }} title="Edit">
                    <i
                      onClick={handleEditButtonClick}
                      className="fas fa-pencil-alt fa-lg mr-2"
                      title="Edit"
                    ></i>
                  </a>
                  <a style={{ cursor: 'pointer' }} title="Delete">
                    <i onClick={onDeleteClick} className="fas fa-trash fa-lg" title="Delete"></i>
                  </a>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="card-body">
        {isEditing ? (
          <textarea
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            className="form-control"
          />
        ) : (
          <>{note.content}</>
        )}
      </div>
    </div>
  )
}

export default Note

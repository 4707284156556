import { useState } from 'react'

import { Sidebar } from 'primereact/sidebar'

import Changes from './Changes'

const sidebarStyle = () => ({
  height: '100%',
  width: '100%',
  maxHeight: '100vh',
  maxWidth: '1000px',
  borderRadius: 0,
  backgroundColor: '#fff',
})

let AuditTrailSlideIn = ({ AuditTrailObject, AuditTrailObjectName, Url, ShowSlideIn, onHide }) => {
  let [showSlideIn, setShowSlideIn] = useState(ShowSlideIn)
  const handleHide = () => {
    setShowSlideIn(false)
    onHide() // Call the parent's hide handler
  }

  return (
    <>
      <Sidebar
        visible={showSlideIn}
        onHide={handleHide}
        blockScroll={true}
        dismissable={true}
        position="right"
        style={{ ...sidebarStyle() }}
        header={'Audit Trail - ' + AuditTrailObjectName}
      >
        <Changes
          object={AuditTrailObject}
          url={Url}
          objectName={AuditTrailObjectName}
          wrapperClassName="mt-2"
        />
      </Sidebar>
    </>
  )
}

export default AuditTrailSlideIn

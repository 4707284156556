import React, { ReactNode, createContext, useEffect, useState } from 'react'

import { fetchAnalyticsBlock } from '../api'
import { useFetchModuleLayoutByTab, useSetModuleLayouts } from '../hooks'
import { ChartType, ChartTypesByTab, ModuleItem, SetState } from '../types'
import { getModuleComponentFromName, getTabTitles } from '../utils'
import { useAnalyticsDashboard, useConfig, useTabs } from './hooks'

type ItemsContextType = {
  items: ModuleItem[]
  itemsLength: number
  chartTypes: ChartTypesByTab[]
  setItems: SetState<ModuleItem[]>
  handleUpdateItems: (newItems: ModuleItem[]) => void
  handleAddItem: (item: ModuleItem, position: string) => void
  handleRemoveItem: (item: ModuleItem) => void
  handleDrop: (updatedItems: ModuleItem[]) => void
  handleSetPreviousItems: () => void
  handleSetChartTypes: (module: string, newType: ChartType) => void
}

export const ItemsContext = createContext<ItemsContextType>({
  items: [],
  itemsLength: 0,
  chartTypes: [],
  setItems: () => {},
  handleUpdateItems: () => {},
  handleAddItem: () => {},
  handleRemoveItem: () => {},
  handleDrop: () => {},
  handleSetPreviousItems: () => {},
  handleSetChartTypes: () => {},
})

export const ItemsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { selectedTab, handleUpdateSelectedTab, tabLayouts, setTabLayouts, handleUpdateTabTitles } =
    useTabs()
  const { handleUpdateConfig } = useConfig()
  const { setModuleLayouts } = useSetModuleLayouts()
  const fetchModuleLayoutByTab = useFetchModuleLayoutByTab()
  const {
    analyticsBlock,
    dealership,
    website,
    manufacturer,
    dealershipGroup,
    dashboardLevel,
    dashboardLevelLoaded,
  } = useAnalyticsDashboard()

  const [items, setItems] = useState<ModuleItem[]>(tabLayouts[selectedTab])
  const [chartTypes, setChartTypes] = useState<ChartTypesByTab[]>([])

  useEffect(() => {
    if (
      Object.keys(tabLayouts).length > 0 &&
      chartTypes.length === 0 &&
      tabLayouts[selectedTab].length > 0
    ) {
      const initialChartTypes: ChartTypesByTab[] = []
      for (const tabId in tabLayouts) {
        initialChartTypes[tabId] = Object.fromEntries(
          tabLayouts[tabId].map((module) => [
            module.module,
            module.filters?.chartType ??
              getModuleComponentFromName(module.module)?.defaultChartType ??
              'Bar',
          ])
        )
      }
      setChartTypes(initialChartTypes)
    }
  }, [tabLayouts])

  const handleUpdateItems = (newItems: ModuleItem[]): void => {
    setItems(newItems)
  }
  const handleAddItem = (item: ModuleItem, position: string): void => {
    // Add the item to the tab layout, as well as the current tabs items
    setTabLayouts((prevTabLayouts) => {
      const updatedItems =
        position === 'start'
          ? [item, ...prevTabLayouts[selectedTab]]
          : [...prevTabLayouts[selectedTab], item]

      setItems(updatedItems)
      return {
        ...prevTabLayouts,
        [selectedTab]: updatedItems,
      }
    })
  }

  const handleRemoveItem = (item: ModuleItem): void => {
    // Remove the item from the tab layout, as well as the current tabs items
    setTabLayouts((prevTabLayouts) => {
      const updatedItems = prevTabLayouts[selectedTab].filter((i) => i.module !== item.module)
      setItems(updatedItems)
      return {
        ...prevTabLayouts,
        [selectedTab]: updatedItems,
      }
    })
  }

  const handleDrop = (updatedItems: ModuleItem[]): void => {
    setTabLayouts((prevTabLayouts) => {
      const newLayouts = { ...prevTabLayouts, [selectedTab]: updatedItems }
      return newLayouts
    })
    setItems(updatedItems)
  }

  const handleTabChange = (tabIndex: number): void => {
    handleUpdateSelectedTab(tabIndex)
    setItems(tabLayouts[tabIndex])
  }

  const handleSetPreviousItems = async (): Promise<void> => {
    const titles = getTabTitles(analyticsBlock)
    const layouts = {
      ...(fetchModuleLayoutByTab(0) && { 0: fetchModuleLayoutByTab(0) }),
      ...(fetchModuleLayoutByTab(1) && { 1: fetchModuleLayoutByTab(1) }),
      ...(fetchModuleLayoutByTab(2) && { 2: fetchModuleLayoutByTab(2) }),
      ...(fetchModuleLayoutByTab(3) && { 3: fetchModuleLayoutByTab(3) }),
      ...(fetchModuleLayoutByTab(4) && { 4: fetchModuleLayoutByTab(4) }),
    }

    const config = await getConfigFromAnalyticsBlock()
    if (config) handleUpdateConfig(config)

    setModuleLayouts(layouts, config)
    handleUpdateTabTitles(titles)

    setTabLayouts(() => {
      // Set the tab layouts, and set the selected tab if there is one, otherwise fall back to the first tab
      const newLayouts = layouts
      let selectedTabFound = false

      Object.keys(layouts)
        .reverse()
        .forEach((tabId) => {
          if (parseInt(tabId) === selectedTab) {
            handleTabChange(parseInt(tabId))
            selectedTabFound = true
          }
        })

      if (!selectedTabFound) {
        handleTabChange(0)
      }

      return newLayouts
    })

    const newChartTypes = Object.entries(layouts).map(([tab, modules]) =>
      Object.fromEntries(
        modules.map((module) => [
          module.module,
          module.filters?.chartType ??
            getModuleComponentFromName(module.module)?.defaultChartType ??
            'Bar',
        ])
      )
    )

    setChartTypes(newChartTypes)
  }

  const handleSetChartTypes = (module: string, newType: ChartType) => {
    setChartTypes((prevChartTypes) => {
      const updatedChartTypes = [...prevChartTypes]
      updatedChartTypes[selectedTab] = {
        ...updatedChartTypes[selectedTab],
        [module]: newType,
      }
      return updatedChartTypes
    })

    setTabLayouts((prevTabLayouts) => {
      const updatedItems = prevTabLayouts[selectedTab].map((item) => {
        if (item.module === module) {
          // Check if the old chartType exists, if it does, remove it
          if (item.chartType) delete item.chartType
          return {
            ...item,
            filters: {
              ...item.filters,
              chartType: newType,
            },
          }
        }
        return item
      })

      setItems(updatedItems)
      return {
        ...prevTabLayouts,
        [selectedTab]: updatedItems,
      }
    })
  }

  const getConfigFromAnalyticsBlock = async () => {
    const response = await fetchAnalyticsBlock(
      dashboardLevel,
      dashboardLevelLoaded,
      dealership,
      dealershipGroup,
      manufacturer,
      website
    )
    return response?.data?.config
  }

  return (
    <ItemsContext.Provider
      value={{
        items,
        itemsLength: items?.length,
        chartTypes,
        setItems,
        handleUpdateItems,
        handleAddItem,
        handleRemoveItem,
        handleDrop,
        handleSetPreviousItems,
        handleSetChartTypes,
      }}
    >
      {children}
    </ItemsContext.Provider>
  )
}

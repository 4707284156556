import { useEffect, useRef, useState } from 'react'

import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'

import * as Routes from '../../../routes'
import { capitalize } from '../../AnalyticsDashboard/utils'
import Loading from '../../Loading'
import { useFetchDealership } from '../../dataHooks'
import { humanize, standardHeaders, truncateString } from '../../entries/utils'
import showToast from '../../shared/ShowToast'

export type PaymentProcessor = {
  id: number
  name: string
  locations: {
    id: number
    name: string
  }[]
  types: string[]
  deposit_amount: string
  authorization_key: string
}

const PaymentProcessors: React.FC = () => {
  const { dealership, loading: dealershipLoading } = useFetchDealership()
  const [currentPaymentProcessors, setCurrentPaymentProcessors] = useState<PaymentProcessor[]>([])
  const [paymentProcessorsOptions, setPaymentProcessorsOptions] = useState<string[]>([])
  const [selectedPaymentProcessor, setSelectedPaymentProcessor] = useState<PaymentProcessor>()
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    if (!dealership) return

    const fetchPaymentProcessors = async () => {
      try {
        const response = await fetch(`${Routes.dealership_payment_processors_path(dealership.id)}`)
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        if (!data) {
          throw new Error('Failed to fetch data')
        }
        setCurrentPaymentProcessors(data.current_processors)
        setPaymentProcessorsOptions(data.processor_options)
      } catch (error) {
        console.error('Error fetching payment processors:', error)
      }
    }
    fetchPaymentProcessors()
  }, [dealership])

  const notification = useRef(null)

  if (dealershipLoading) {
    return (
      <div className="d-flex justify-content-center mt-3">
        <Loading />
      </div>
    )
  }

  return (
    <>
      <Toast ref={notification} />
      <div className="FinanceProviders">
        <h1>Listing Payment Processors</h1>
        <div className="box mb-2">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Locations</th>
                <th>Types</th>
                <th>Deposit Amount</th>
                <th>Key</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentPaymentProcessors && currentPaymentProcessors.length > 0
                ? currentPaymentProcessors.map((paymentProcessor, index) => (
                    <PaymentProcessorRow
                      key={paymentProcessor.id}
                      paymentProcessor={paymentProcessor}
                      index={index}
                      dealership={dealership}
                      visible={visible}
                      setVisible={setVisible}
                      selectedPaymentProcessor={selectedPaymentProcessor}
                      setSelectedPaymentProcessor={setSelectedPaymentProcessor}
                      currentPaymentProcessors={currentPaymentProcessors}
                      setCurrentPaymentProcessors={setCurrentPaymentProcessors}
                      notification={notification}
                    />
                  ))
                : null}
            </tbody>
          </table>
        </div>
        <br />
        <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            New Payment Processor
          </button>
          <div className="dropdown-menu">
            {paymentProcessorsOptions.map((processor) => (
              <a
                key={processor}
                href={`${Routes.new_dealership_payment_processor_path(dealership.id)}?authorization_type=${processor}`}
                className="dropdown-item"
              >
                {humanize(processor)}
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

const PaymentProcessorRow: React.FC<{
  paymentProcessor: PaymentProcessor
  index: number
  dealership: { id: number; slug: string }
  visible: boolean
  setVisible: (visible: boolean) => void
  selectedPaymentProcessor: PaymentProcessor
  setSelectedPaymentProcessor: (processor: PaymentProcessor) => void
  currentPaymentProcessors: PaymentProcessor[]
  setCurrentPaymentProcessors: (processors: PaymentProcessor[]) => void
  notification: any
}> = ({
  paymentProcessor,
  index,
  dealership,
  visible,
  setVisible,
  selectedPaymentProcessor,
  setSelectedPaymentProcessor,
  currentPaymentProcessors,
  setCurrentPaymentProcessors,
  notification,
}) => {
  return (
    <tr>
      <td>{paymentProcessor.name}</td>
      <td>{paymentProcessor.locations.map((location) => location.name).join(', ')}</td>
      <td>{paymentProcessor.types.map((type) => capitalize(type)).join(', ')}</td>
      <td>${paymentProcessor.deposit_amount}</td>
      <td>{truncateString(paymentProcessor.authorization_key, 30)}</td>
      <td>
        <a
          href={`${Routes.edit_dealership_payment_processor_path(dealership?.slug, paymentProcessor.id)}`}
        >
          Edit
        </a>
      </td>
      <td>
        <button
          onClick={() => {
            setVisible(true)
            setSelectedPaymentProcessor(currentPaymentProcessors[index])
          }}
          className="btn btn-link text-danger"
        >
          Destroy
        </button>
        <DeleteDialog
          visible={visible}
          setVisible={setVisible}
          paymentProcessor={selectedPaymentProcessor}
          dealership={dealership}
          setCurrentPaymentProcessors={setCurrentPaymentProcessors}
          notification={notification}
        />
      </td>
    </tr>
  )
}

const DeleteDialog: React.FC<{
  visible: boolean
  setVisible: (boolean: boolean) => void
  paymentProcessor: PaymentProcessor
  dealership: { id: number; slug: string }
  setCurrentPaymentProcessors: (paymentProcessors: PaymentProcessor[]) => void
  notification: any
}> = ({
  visible,
  setVisible,
  paymentProcessor,
  dealership,
  setCurrentPaymentProcessors,
  notification,
}) => {
  async function deletePaymentProcessor(
    dealershipId: number,
    paymentProcessorId: number,
    notification: any
  ): Promise<PaymentProcessor[]> {
    try {
      const response = await fetch(
        `${Routes.dealership_payment_processor_path(dealershipId, paymentProcessorId)}`,
        {
          method: 'DELETE',
          headers: standardHeaders,
        }
      )
      if (!response.ok) {
        showToast(notification, 'error', 'Error', 'Failed to delete payment processor')
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to fetch data')
      }
      showToast(
        notification,
        'success',
        'Payment Processor Deleted',
        'The Payment Processor has been deleted successfully'
      )
      return data
    } catch (error) {
      showToast(notification, 'error', 'Error', 'Failed to delete payment processor')
      console.error('Error deleting Payment Processor:', error)
    }
  }

  return (
    <Dialog
      header={`Are you sure you want to delete this ${paymentProcessor?.name} Payment Processor?`}
      visible={visible}
      style={{ width: '50vw' }}
      onHide={() => setVisible(false)}
      headerClassName="text-center"
      modal
      dismissableMask
    >
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <button
          className="btn btn-primary mt-2 "
          onClick={() => setVisible(false)}
          style={{ width: '150px' }}
        >
          Cancel
        </button>
        <button
          className="btn btn-danger mt-2 "
          onClick={async () => {
            const newProcessors = await deletePaymentProcessor(
              dealership.id,
              paymentProcessor.id,
              notification
            )
            setCurrentPaymentProcessors(newProcessors)
            setVisible(false)
          }}
          style={{ width: '150px' }}
        >
          Delete
        </button>
      </div>
    </Dialog>
  )
}

export default PaymentProcessors

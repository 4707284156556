import { useState } from 'react'

import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Tooltip } from 'react-tooltip'

import { Select } from '../../entries/FormElements'
import { timeAgoInDays } from '../../entries/utils'
import { humanize } from '../../entries/utils'

const imageTemplate = (car) => {
  let default_image_url =
    'https://res.cloudinary.com/total-dealer/image/upload/v1692498338/td_dashboard/placeholder-image_l1iz3a.png'
  let image_url = car.cover_image_url ? car.cover_image_url : default_image_url
  return (
    <a href={car.listing_url} target="_blank">
      <img
        src={image_url}
        height={66}
        width={100}
        className="border rounded"
        style={{ objectFit: 'cover' }}
      />
    </a>
  )
}

const Locations = (car) => {
  console.log(car.listing_sources)
  return (
    <>
      {car.listing_sources
        .filter((s) => s !== 'autograb.com.au')
        .map((source) => (
          <div key={`${car.id}-${source}`} className="mb-1">
            <div className={`circle-${source[0]}`} id={`${car.id}-${source}`}>
              {source[0].toUpperCase()}
            </div>
            <Tooltip anchorId={`${car.id}-${source}`} place="bottom" style={{ zIndex: 10000 }}>
              {source}
            </Tooltip>
          </div>
        ))}
    </>
  )
}

const Kms = (car) => {
  return (
    <>
      {car.kms.toLocaleString()}
      <div className="small text-secondary">kms</div>
    </>
  )
}

const Age = (car) => {
  return <>{timeAgoInDays(car.listed_at)}d</>
}

const Price = (car) => {
  return (
    <>
      <div>${car.price.toLocaleString()} </div>
      <small className="text-secondary">{car.price_includes_govt_charges ? 'DAP' : 'EGC'}</small>
    </>
  )
}

const DelistedAt = (car) => {
  if (!car.removed_at) {
    return <></>
  }

  return <>{moment(car.removed_at).fromNow()}</>
}

let minimumDaysOptions = [
  { value: 30, label: '30' },
  { value: 60, label: '60' },
  { value: 90, label: '90' },
  { value: 120, label: '120' },
  { value: 150, label: '150' },
]

let delistedOptions = [
  { value: true, label: 'Delisted Only' },
  { value: false, label: 'Active Only' },
]

let Cars = ({ cars, car, minimumDays, setMinimumDays }) => {
  let [state, setState] = useState(car.state)
  let [delisted, setDelisted] = useState(null)

  let stateCounts = cars.reduce((acc, car) => {
    acc[car.state] = (acc[car.state] || 0) + 1
    return acc
  }, {})

  let stateFilterOptions = Object.entries(stateCounts).map(([state, count]) => ({
    value: state,
    label: `${state} (${count})`,
  }))

  let filteredCars = cars
  if (state) {
    filteredCars = cars.filter((c) => c.state === state)
  }
  if (delisted) {
    filteredCars = filteredCars.filter((c) => c.removed_at)
  } else if (delisted === false) {
    filteredCars = filteredCars.filter((c) => !c.removed_at)
  }

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          {stateFilterOptions && (
            <Select
              options={stateFilterOptions}
              label="State"
              onChange={(option) => setState(option?.value)}
              isClearable={true}
              defaultValue={stateFilterOptions.find((s) => s.value === state)}
            />
          )}
        </div>
        <div className="col-md-3">
          <Select
            options={minimumDaysOptions}
            label="Minimum Days"
            onChange={(option) => setMinimumDays(option.value)}
            defaultValue={minimumDaysOptions.find((s) => s.value === minimumDays)}
            isClearable={false}
          />
        </div>
        <div className="col-md-3">
          <Select
            options={delistedOptions}
            label="Delisted"
            placeholder="Delisted"
            onChange={(option) => setDelisted(option?.value)}
            defaultValue={delistedOptions.find((s) => s.value === delisted)}
            isClearable={true}
          />
        </div>
      </div>
      {filteredCars && (
        <DataTable value={filteredCars} tableStyle={{ minWidth: '50rem' }} sortMode="multiple">
          <Column body={imageTemplate} header="Image" />
          <Column field="year" header="Year" />
          <Column field="color" header="Color" />
          <Column field="removed_at" header="Delisted" body={DelistedAt} sortable />
          <Column field="state" header="State" />
          <Column field="price" body={Price} header="Price" sortable />
          <Column field="kms" body={Kms} header="Kms" sortable />
          <Column field="listed_at" body={Age} header="Age" sortable />
          <Column
            field="seller_type"
            header="Seller Type"
            body={({ seller_type }) => humanize(seller_type)}
          />
          <Column body={Locations} header="Locations" />
        </DataTable>
      )}
    </>
  )
}

export default Cars

import { formatDistanceToNow } from 'date-fns'
import { useParams } from 'react-router-dom'
import * as Routes from 'routes'

const DealerEmail = ({ dealerEmail }) => {
  const timeAgo = formatDistanceToNow(new Date(dealerEmail.created_at), { addSuffix: true })
  let { dealershipSlug } = useParams()

  return (
    <div className="px-3 py-1">
      <div className="box bg-white rounded p-3">
        <div className="row align-items-center">
          <div className="d-flex px-3 w-100">
            <div className="mr-3">
              <i className="fa fa-envelope fa-success"></i>
            </div>
            <div>
              <p className="m-0">Email sent to dealership {timeAgo}</p>
              <small className="text-secondary">{dealerEmail.emails.join(', ')}</small>
            </div>
            <div className="ml-auto d-flex align-items-center">
              <a
                href={Routes.dealership_dealer_email_path(dealershipSlug, dealerEmail.id)}
                className="btn btn-outline-primary btn-sm mr-2 "
              >
                View
              </a>
              <a
                className="btn btn-outline-primary btn-sm"
                href={Routes.dealership_notifications_path(dealershipSlug)}
              >
                <i className="fa fa-pencil fa-success"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DealerEmail

import * as Routes from '../../routes'

export const PageService = {
  getPages(params, csrf, ownerId, ownerClass) {
    const queryParams = params
      ? Object.keys(params)
          .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
          .join('&')
      : ''
    let pagesRoute = ''

    if (ownerClass === 'Website') {
      pagesRoute = Routes.website_pages_path(ownerId)
    } else if (ownerClass == 'Manufacturer') {
      pagesRoute = Routes.manufacturer_pages_path(ownerId)
    } else if (ownerClass == 'Dealership') {
      pagesRoute = Routes.dealership_pages_path(ownerId)
    }
    return fetch(`${pagesRoute}.json?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      credentials: 'same-origin',
    }).then((res) => res.json())
  },
}

import { useRefinementList } from 'react-instantsearch'
import { RefinementList } from 'react-instantsearch'

import SearchPanel from './SearchPanel'

// this RefinemntList will only render if there are items available
const ConditionalRefinementList = ({ attribute, title }) => {
  const { items } = useRefinementList({ attribute })

  // Only render if there are items available
  if (items.length === 0) {
    return null
  }

  return (
    <SearchPanel title={title}>
      <RefinementList attribute={attribute} />
    </SearchPanel>
  )
}

export default ConditionalRefinementList

import { Link } from 'react-router-dom'

import * as Routes from '../../../../../routes'
import { useAnalyticsDashboard } from '../../../contexts/hooks'
import { useModuleConditionals } from '../../../hooks'

const HeaderButtons = () => {
  const { website, dealership, dashboardLevel, dashboardLevelLoaded } = useAnalyticsDashboard()
  const { hasFacebookAds, hasGA4 } = useModuleConditionals()

  const websiteOrDealership = dashboardLevel === 'Website' || dashboardLevel === 'Dealership'

  if (!dashboardLevelLoaded || !websiteOrDealership) {
    return null
  }
  return (
    <>
      {!hasGA4 ? (
        <a
          href={Routes.fetch_access_token_dealership_ga_profiles_path(
            dealership?.slug || website?.dealership_slug
          )}
          className="text-decoration-none"
        >
          <div className="btn px-4 btn-primary ml-auto mr-2 d-block">
            <i className="fas fa-plus mr-2"></i>Add GA4 Profile
          </div>
        </a>
      ) : null}
      {!hasFacebookAds ? (
        <Link
          to={Routes.dealership_facebook_ads_path(dealership.slug)}
          className="text-decoration-none"
        >
          <div className="btn px-4 btn-primary ml-auto mr-2 d-block">
            <i className="fas fa-plus mr-2"></i>Add FB Ads Profile
          </div>
        </Link>
      ) : null}
      {/* Turned off for now, until Google ads works well for clients */}
      {/* {!hasGoogleAds ? (
        <a href={Routes.dealership_google_ads_path(dealership)} className="text-decoration-none">
          <div className="btn px-4 btn-primary ml-auto mr-2 d-block">
            <i className="fas fa-plus mr-2"></i>Add Google Ads Profile
          </div>
        </a>
      ) : null} */}
    </>
  )
}

export default HeaderButtons

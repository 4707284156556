import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'

export const WebsiteService = {
  getWebsites(params, csrf, ownerId, ownerClass) {
    const queryParams = params
      ? Object.keys(params)
          .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
          .join('&')
      : ''
    let websitesRoute = ''

    if (ownerClass == 'Manufacturer') {
      websitesRoute = Routes.manufacturer_websites_path(ownerId)
    } else if (ownerClass == 'Dealership') {
      websitesRoute = Routes.dealership_websites_path(ownerId)
    } else {
      websitesRoute = Routes.websites_path()
    }
    return fetch(`${websitesRoute}.json?${queryParams}`, {
      method: 'GET',
      headers: standardHeaders,
      credentials: 'same-origin',
    }).then((res) => res.json())
  },
}

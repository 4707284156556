import { createContext, useContext, useEffect, useState } from 'react'

import moment from 'moment'
import { Message } from 'primereact/message'
import { Sidebar } from 'primereact/sidebar'
import { Controller, useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { Tooltip } from 'react-tooltip'

import * as Routes from '../../../routes'
import Loading from '../../Loading'
import { useFetchDealership } from '../../dataHooks'
import {
  CurrencyInput,
  DateInput,
  Errors,
  Input,
  NumberInput,
  Select,
  TextArea,
} from '../../entries/FormElements'
import { standardHeaders } from '../../entries/utils'
import { attributes } from './formFields'

const EditCarContext = createContext({})

const LockButton = ({ attribute }) => {
  let { lockedFields, setLockedFields } = useContext(EditCarContext)
  let [locked, setLocked] = useState(lockedFields.includes(attribute))

  let bsClass = locked ? 'danger' : 'secondary'

  useEffect(() => {
    if (locked) {
      setLockedFields([...lockedFields, attribute])
    } else {
      setLockedFields(lockedFields.filter((f) => f !== attribute))
    }
  }, [locked])

  return (
    <div className="d-flex mb-1 align-items-end">
      <label className="mb-0">{attributes[attribute].label}</label>
      <div className="ml-auto">
        <i
          className={`fa fa-info-circle mr-2 text-${bsClass} lock-tooltip`}
          data-tip="Lock this field to prevent accidental changes"
        />
        <Tooltip anchorSelect=".lock-tooltip" place="bottom">
          Locking a field will prevent the incoming (DMS) feeds from updating this field.
        </Tooltip>
        <div
          className={`btn btn-sm btn-outline-${bsClass}` + (locked ? ' active' : '')}
          onClick={() => setLocked(!locked)}
        >
          {locked ? <i className="fa fa-lock mr-1" /> : <i className="fa fa-unlock mr-1" />}
          {locked && 'Locked'}
        </div>
      </div>
    </div>
  )
}

const AiComments = ({ attribute, field }) => {
  let { car, errors } = useContext(EditCarContext)
  let [loading, setLoading] = useState(false)

  const generateAiComments = () => {
    setLoading(true)
    fetch(`/dealerships/${car.dealership_id}/cars/${car.id}/generate_ai_comments.json`, {
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        field.onChange(data.message)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error:', error.message)
      })
  }

  return (
    <>
      <TextArea
        label={attributes[attribute].lockable ? false : attributes[attribute].label}
        className={`form-control ${errors[attribute] ? 'is-invalid' : ''}`}
        {...field}
        rows={10}
      />
      <div className="btn btn-outline-secondary btn-sm mb-2" onClick={generateAiComments}>
        {loading ? (
          <i className="fa fa-spin fa-spinner" />
        ) : (
          <>
            <i className="fa fa-bolt mr-1"></i>Generate AI comments
          </>
        )}
      </div>
    </>
  )
}

const Field = ({ attribute, hint }) => {
  let type = attributes[attribute].type
  let { car, control, errors, formOptions } = useContext(EditCarContext)

  const renderInput = (field) => {
    switch (type) {
      case 'currency':
        return (
          <CurrencyInput
            label={false}
            className={`form-control ${errors[attribute] ? 'is-invalid' : ''}`}
            {...field}
            onChange={(value) => {
              field.onChange(value.target.rawValue)
            }}
            hint={hint}
          />
        )
      case 'number':
        return (
          <NumberInput
            label={false}
            className={`form-control ${errors[attribute] ? 'is-invalid' : ''}`}
            {...field}
            onChange={(value) => {
              field.onChange(value.target.rawValue)
            }}
          />
        )
      case 'date':
        return (
          <DateInput
            label={attributes[attribute].label}
            className={`${errors[attribute] ? 'is-invalid' : ''}`}
            {...field}
            placeholder={attributes[attribute].placeholder}
            onChange={(value) => {
              field.onChange(value)
            }}
          />
        )
      case 'text':
        return (
          <Input
            label={attributes[attribute].lockable ? false : attributes[attribute].label}
            className={`form-control ${errors[attribute] ? 'is-invalid' : ''}`}
            {...field}
          />
        )
      case 'textarea':
        return <AiComments field={field} attribute={attribute} />
      case 'select':
        let options = formOptions[attributes[attribute].options]

        if (
          ['colour', 'interior_colour', 'trans'].includes(attribute) &&
          !options.some((option) => option.value === car[attribute])
        ) {
          options.push({ label: car[attribute], value: car[attribute] })
        }

        return (
          <Select
            label={attributes[attribute].lockable ? false : attributes[attribute].label}
            className={`${errors[attribute] ? 'is-invalid' : ''}`}
            options={options}
            value={options.filter((o) => o.value === field.value)}
            onChange={(e) => field.onChange(e.value)}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      {attributes[attribute].lockable && <LockButton attribute={attribute} />}
      <Controller
        name={attribute}
        control={control}
        defaultValue={car[attribute] || ''}
        render={({ field }) => renderInput(field)}
      />
      {errors[attribute] && <div className="invalid-feedback">{errors[attribute].message}</div>}
    </>
  )
}

const CurrencyChange = () => {
  let { car } = useContext(EditCarContext)

  let last_dapprice_version = car.last_dapprice_version

  if (!last_dapprice_version) return null

  const formatCurrency = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0, // Remove decimals
      maximumFractionDigits: 0, // Remove decimals
    }).format(value)

  return (
    <>
      Last changed from {formatCurrency(last_dapprice_version['dapprice'][0]) || '$0'} to{' '}
      {formatCurrency(last_dapprice_version['dapprice'][1])} (
      {moment(last_dapprice_version['updated_at'][1]).fromNow()})
    </>
  )
}

const PricingTab = () => (
  <>
    <Field attribute="dapprice" hint={() => <CurrencyChange attribute="dapprice" />} />
    <Field attribute="egcprice" />
    <Field attribute="before_price" />
    <Field attribute="subscription_price" />
    <Field attribute="daily_hire_price" />
    <Field attribute="buyer_id" />
  </>
)

const IdentifiersTab = () => (
  <>
    {['vin', 'stocknum', 'regplate', 'rego_state', 'rego_expiry'].map((attribute) => (
      <Field key={attribute} attribute={attribute} />
    ))}
  </>
)

const RedbookBox = ({ car }) => {
  let variant = car.variant
  let { dealership } = useFetchDealership()

  let { dealershipSlug } = useParams()

  if (!variant)
    return (
      <div className="border rounded p-2 mb-2 bg-white">
        <div className="d-flex align-items-center">
          <h6 className="text-danger m-0">
            <i className="fa fa-exclamation-triangle mr-2" />
            {dealership.preferred_catalogue === 'redbook'
              ? 'No Redbook Variant Selected'
              : 'No Glasses Variant Selected'}
          </h6>
          <Link
            to={`/dealerships/${dealershipSlug}/cars/${car.slug}/select_vehicle`}
            className="btn btn-sm btn-outline-secondary ml-auto"
          >
            Select {dealership.preferred_catalogue === 'redbook' ? 'Redbook' : 'Glasses'} Variant
          </Link>
        </div>
      </div>
    )

  return (
    <div className="border rounded p-2 mb-2 bg-white">
      <div className="d-flex align-items-center">
        <div>
          <h6>
            {car.variant.yeargroupyear} {car.make} {car.model} {car.badge} {car.series}{' '}
            {car.variant.geartype}
          </h6>
          <p className="text-secondary mb-0 small">
            {[
              variant.bodystyle,
              variant.enginetype,
              variant.geartype,
              variant.fueltype,
              variant.enginesizelitres && `Engine Size (L) - ${variant.enginesizelitres}`,
              variant.doorno && `Doors - ${variant.doorno}`,
              variant.yeargroupyear,
              variant.bodyconfiguration,
            ]
              .filter(Boolean)
              .join(' | ')}
          </p>
          <Link
            to={Routes.edit_redbook_details_dealership_car_path(dealershipSlug, car.slug)}
            className="small"
          >
            Edit Redbook Details
          </Link>
        </div>
        <div className="ml-auto">
          <Link
            to={`/dealerships/${dealershipSlug}/cars/${car.slug}/select_vehicle`}
            className="btn btn-sm btn-outline-secondary"
          >
            Change Redbook Variant
          </Link>
        </div>
      </div>
    </div>
  )
}

const DetailsTab = () => (
  <>
    <Field attribute="status" />
    <Field attribute="car_type" />
    <Field attribute="location_id" />
    <Field attribute="colour" />
    <Field attribute="interior_colour" />
    <Field attribute="trans" />
    <Field attribute="km" />
    <Field attribute="build_date" />
    <Field attribute="compliance_date" />
  </>
)

const EditComments = () => {
  return <Field attribute="dealercomments" />
}

const EditCarForm = ({ car, setCar, setVisible, formOptions }) => {
  const [loading, setLoading] = useState(false)
  const [lockedFields, setLockedFields] = useState(car.locked_fields || [])
  let [formErrors, setFormErrors] = useState([])

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      car_type: car.car_type,
      km: car.km || '',
      dapprice: car.dapprice || '',
      egcprice: car.egcprice || '',
      before_price: car.before_price || '',
      before_price: car.before_price || '',
      subscription_price: car.subscription_price || '',
      rego: car.rego || '',
      vin: car.vin || '',
      colour: car.colour || '',
      interior_colour: car.interior_colour || '',
      stocknum: car.stocknum || '',
      trans: car.trans || '',
      rego_state: car.rego_state || '',
      status: car.status || '',
    },
  })

  const onSubmit = (data) => {
    setLoading(true)
    setFormErrors(null) // Clear any previous errors
    fetch(`/cars/${car.id}`, {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify({
        car: {
          ...data,
          locked_fields: lockedFields,
        },
      }),
    })
      .then((res) => {
        if (res.status === 422) {
          return res.json().then((data) => {
            setLoading(false)
            setFormErrors(data)
            return Promise.reject(data)
          })
        }
        return res.json()
      })
      .then((data) => {
        setCar(data)
        setVisible(false)
      })
      .catch((error) => {
        console.error('Error:', error.message)
      })
      .finally(() => setLoading(false))
  }

  return (
    <EditCarContext.Provider
      value={{ lockedFields, setLockedFields, car, setCar, control, errors, formOptions }}
    >
      <div className="p-2 lead-event-form">
        <Message
          text="This is the new beta version of the Edit Car form. Please report any issues to the development team."
          className="mb-2"
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Tabs>
            <div className="p-2 border mb-2 bg-white rounded">
              <TabList>
                <Tab>
                  <i className="fa fa-dollar-sign mr-2"></i>
                  Pricing
                </Tab>
                <Tab>
                  <i className="fa fa-info mr-2"></i>
                  Identifiers
                </Tab>
                <Tab>
                  <i className="fa fa-info mr-2"></i>
                  Details
                </Tab>
                <Tab>
                  <i className="fa fa-comment mr-2"></i>
                  Comments
                </Tab>
              </TabList>
            </div>
            <TabPanel>
              <div className="p-2 border rounded mb-2 bg-white">
                <PricingTab />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="p-2 border rounded mb-2 bg-white">
                <IdentifiersTab />
              </div>
            </TabPanel>
            <TabPanel>
              <RedbookBox car={car} />
              <div className="p-2 border rounded mb-2 bg-white">
                <DetailsTab />
              </div>
            </TabPanel>
            <TabPanel>
              <EditComments />
            </TabPanel>
          </Tabs>
          <Errors errors={formErrors?.errors} />
          <button type="submit" className={'btn btn-primary' + (loading ? ' disabled' : '')}>
            {loading ? <i className="fa fa-spin fa-spinner" /> : 'Save Changes'}
          </button>
          <button
            type="button"
            className={'btn btn-secondary ml-2' + (loading ? ' disabled' : '')}
            onClick={() => setVisible(false)}
          >
            Cancel
          </button>
        </form>
      </div>
    </EditCarContext.Provider>
  )
}

const FormWrapper = ({ carId, carStocknum, setCar }) => {
  const [car, setLocalCar] = useState(false)
  const [visible, setVisible] = useState(false)
  let [loading, setLoading] = useState(false)
  let [formOptions, setFormOptions] = useState({})
  let { dealershipSlug } = useParams()

  if (!dealershipSlug) {
    return null
  }

  useEffect(() => {
    if (!visible) return

    // this is required to prevent the form from loading the old car data
    setLocalCar(false)
    setLoading(true)

    fetch(`/cars/${carId}/edit.json`)
      .then((res) => res.json())
      .then((data) => {
        setLocalCar(data.car)
        setFormOptions(data.form_options)
      })
      .finally(() => setLoading(false))
  }, [carId, visible])

  return (
    <>
      <div className="btn btn-outline-secondary btn-sm mr-1" onClick={() => setVisible(true)}>
        <i className="fa fa-pencil" />
      </div>
      <Sidebar
        header={`Edit Car ${carStocknum}`}
        visible={visible}
        style={{ width: '60vw' }}
        position="right"
        onHide={() => setVisible(false)}
        content={() => (
          <div className="bg-light h-100 p-sidebar-content p-0">
            <div className="border-bottom bg-white p-3">
              <h5 className="m-0">Edit Car {carStocknum}</h5>
            </div>
            <div className="p-2">
              {loading && (
                <div className="p-2">
                  <Loading />
                </div>
              )}
              {car && (
                <EditCarForm
                  car={car}
                  setCar={setCar}
                  visible={visible}
                  setVisible={setVisible}
                  formOptions={formOptions}
                />
              )}
            </div>
          </div>
        )}
      ></Sidebar>
    </>
  )
}

export default FormWrapper

import { useContext, useEffect, useRef, useState } from 'react'

import { PrimeReactProvider } from 'primereact/api'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { SplitButton } from 'primereact/splitbutton'
import { Toast } from 'primereact/toast'
import { useParams } from 'react-router-dom'

import { BlogContext } from '../contexts'
import { CurrentUserContext } from '../contexts'
import DataTableSearch from '../entries/DataTableSearch'
import { time_ago, truncateString } from '../entries/utils'
import { csrfToken } from '../entries/utils'
import AuditTrailSlideIn from '../shared/AuditTrailSlideIn'
import showToast from '../shared/ShowToast'
import ItemStatus from './ItemStatus'
import { BlogService } from './blogService'

const Action = (blog) => {
  const { notification, loadLazyData } = useContext(BlogContext)
  let url = `/websites/${blog.website_slug}/blogs/${blog.id}`
  const [showSlideIn, setShowSlideIn] = useState(false)
  const handleHideSlideIn = () => {
    setShowSlideIn(false)
  }

  const items = [
    {
      label: 'View Live',
      icon: 'fas fa-external-link-alt',
      command: () => {
        window.open(blog.live_url, '_blank')
      },
    },
    {
      label: blog.status == 'Visible' ? 'Hide' : 'Show',
      icon: blog.status === 'Visible' ? 'fas fa-eye-slash' : 'fas fa-eye',
      command: () => {
        const action = blog.status === 'Visible' ? 'hidden' : 'made visible'
        fetch(url + '/toggle_visibility', {
          method: 'PUT',
          headers: {
            'X-CSRF-Token': csrfToken,
          },
        }).then(() => {
          const message = `Blog ${action} successfully`
          showToast(notification, 'success', message)
          loadLazyData()
        })
      },
    },
    {
      label: 'Audit Trail',
      icon: 'fa fa-history',
      command: () => {
        setShowSlideIn(true)
      },
    },
    {
      label: 'Destroy',
      icon: 'fa fa-trash',
      command: () => {
        const userConfirmation = window.confirm('Are you sure?')

        if (!userConfirmation) {
          return
        }
        fetch(url, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': csrfToken,
          },
        }).then(() => {
          const message = `Blog destroyed successfully`
          showToast(notification, 'success', message)
          loadLazyData()
        })
      },
    },
  ]

  return (
    <div style={{ position: 'relative' }}>
      <SplitButton
        label="Edit"
        buttonProps={{ id: 'edit-button' }}
        onClick={() => {
          window.location.href = `${url}/edit`
        }}
        model={items}
        menuButtonProps={{ id: 'action-button' }}
        outlined
        rounded
      />
      {showSlideIn && (
        <AuditTrailSlideIn
          AuditTrailObject={blog}
          AuditTrailObjectName="Blog"
          Url={`${url}/audit_trail`}
          ShowSlideIn={showSlideIn}
          onHide={handleHideSlideIn}
        />
      )}
    </div>
  )
}

const LastEditBy = (blog) => {
  return (
    <div>
      <div>{blog.last_edit_by.name}</div>
      <div className="small text-secondary">{time_ago(blog.updated_at)}</div>
    </div>
  )
}

const Image = (blog) => {
  if (!blog.image_key) return null

  return (
    <img
      src={`https://res.cloudinary.com/total-dealer/image/upload/c_scale,h_150/c_crop,h_150,w_150/v1/${process.env.RAILS_ENV}/${blog.image_key}`}
      height={60}
      className="rounded-circle"
    />
  )
}

const Title = (blog) => {
  let url = `/websites/${blog.website_slug}/blogs/${blog.id}/edit`
  return (
    <>
      <a href={url}>{truncateString(blog.title, 50)}</a>
      <div className="small text-secondary">Created: {time_ago(blog.created_at)}</div>
    </>
  )
}

const Featured = (blog) => {
  return (
    <div className={blog.featured ? 'text-success' : 'text-danger'}>
      {blog.featured && <i className="fa fa-check" />}
    </div>
  )
}

const BlogHeader = () => {
  let { websiteSlug } = useParams()
  let currentUser = useContext(CurrentUserContext)

  return (
    <div className="mb-3 d-flex align-items-center">
      <h4 className="m-0">Blog Posts</h4>
      <div className="ml-auto">
        {currentUser?.admin && (
          <a href={`/websites/${websiteSlug}/blog_settings`} className="btn btn-outline-dark mr-2">
            Blog Settings
          </a>
        )}
        <a href={`/websites/${websiteSlug}/blog_categories`} className="btn btn-outline-dark mr-2">
          Categories
        </a>
        <a
          href={`/websites/${websiteSlug}/blogs/new`}
          className="btn btn-outline-primary"
          id="new-blog-button"
        >
          <i className="fa fa-plus"></i> New Post
        </a>
      </div>
    </div>
  )
}

const BlogsIndex = ({}) => {
  const notification = useRef(null)
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
  })

  let { websiteSlug } = useParams()
  let [loading, setLoading] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [blogs, setBlogs] = useState(null)
  let rows = 50

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: rows,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      global: { value: '', matchMode: 'contains' }, // FilterMatchMode.CONTAINS is not used because lazyState was not updating
    },
  })

  useEffect(() => {
    loadLazyData()
  }, [lazyState])

  const loadLazyData = () => {
    if (lazyState.page === undefined) {
      return
    }
    setLoading(true)
    let params = { page: lazyState.page, rows: lazyState.rows, per_page: rows }
    try {
      BlogService.getBlogs(params, csrfToken, websiteSlug).then((data) => {
        setTotalRecords(data.data.total_records)
        setBlogs(data.data.blogs)
        setLoading(false)
      })
    } catch (error) {
      console.error(error)
    }
  }

  const header = DataTableSearch({ filters: lazyState.filters, setFilters: setFilters })

  const onPageChange = (event) => {
    setlazyState({
      ...lazyState,
      page: event.page + 1,
    })
  }

  return (
    <div className="p-3" id="search-blogs-react">
      <BlogHeader />
      <BlogContext.Provider value={{ notification, loadLazyData }}>
        <Toast ref={notification} />
        <PrimeReactProvider>
          <div className="box">
            <DataTable
              value={blogs}
              tableStyle={{ minWidth: '50rem' }}
              paginator
              rows={lazyState.rows}
              removableSort
              sortMode="multiple"
              scrollable
              globalFilterFields={['title']}
              style={{ overflow: 'visible' }}
              wrapper={{ style: { overflow: 'visible' } }}
              header={header}
              first={(lazyState.page - 1) * rows}
              totalRecords={totalRecords}
              filters={lazyState.filters}
              loading={loading}
              onPage={onPageChange}
              lazy
            >
              <Column body={Image} field="image" header="Image"></Column>
              <Column sortable body={Title} field="title" header="Title"></Column>
              <Column sortable field="category" header="Category"></Column>
              <Column sortable body={ItemStatus} field="status" header="Status"></Column>
              <Column sortable body={Featured} field="featured" header="Featured"></Column>
              <Column
                sortable
                body={LastEditBy}
                field="last_edit_by"
                header="Last Edited By"
              ></Column>
              <Column body={Action} header="Actions"></Column>
            </DataTable>
          </div>
        </PrimeReactProvider>
      </BlogContext.Provider>
    </div>
  )
}

export default BlogsIndex

import { useEffect, useState } from 'react'

import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Input, Switch } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'

const Settings = ({ websiteSettings }) => {
  let [visible, setVisible] = useState(false)
  let [loading, setLoading] = useState(false)
  let { websiteSlug } = useParams()

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      location_make_search_title: websiteSettings.location_make_search_title || '',
      make_location_search_pages: websiteSettings.make_location_search_pages || '',
      make_search_title: websiteSettings.make_search_title || '',
    },
  })

  const onSubmit = (data) => {
    setLoading(true)

    fetch(`/websites/${websiteSlug}`, {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        setFeed(res)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setLoading(false)
      })
  }

  const makeLocationSearchPages = watch('make_location_search_pages')

  return (
    <div className="ml-auto pr-3 pt-2">
      <button onClick={() => setVisible(true)} className="btn btn-outline-primary">
        Settings
      </button>
      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        dismissableMask
        style={{ width: '50vw' }}
        header="SEO Settings"
      >
        <div className="p-3">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="make_search_title"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Make search title"
                  required={true}
                  hint="Title for pages like: /search/toyota. Accepts: [make]"
                  placeholder="[make] cars for sale"
                />
              )}
            />
            <Controller
              name="make_location_search_pages"
              control={control}
              render={({ field }) => (
                <Switch
                  {...field}
                  label="Generate make/city Search Pages (eg. /search/brisbane/toyota)"
                  id="make_location_search_pages"
                  className="mb-2"
                />
              )}
            />
            {makeLocationSearchPages && (
              <Controller
                name="location_make_search_title"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Make/Location search title"
                    required={true}
                    hint="Title for pages like: /search/brisbane/toyota. Accepts: [make], [city]"
                    placeholder="[make] cars for sale in [city]"
                  />
                )}
              />
            )}
            <button
              type="submit"
              className={'btn btn-outline-primary' + (loading ? ' disabled' : '')}
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </form>
        </div>
      </Dialog>
    </div>
  )
}

const SearchPages = () => {
  let { websiteSlug } = useParams()
  let [pages, setPages] = useState([])
  let [websiteSettings, setWebsiteSettings] = useState({})
  let [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    fetch(`/websites/${websiteSlug}/search_pages`, {
      method: 'GET',
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then((data) => {
        setPages(data.pages)
        setWebsiteSettings(data.settings)
        setLoading(false)
      })
  }, [])

  return (
    <div className="m-3 box">
      <div className="d-flex pt-2">
        <h5 className="p-3">Search Pages</h5>
        {!loading && <Settings websiteSettings={websiteSettings} />}
      </div>

      <DataTable value={pages} loading={loading}>
        <Column field="title" header="Title" />
        <Column field="path" header="URL" />
      </DataTable>
    </div>
  )
}

export default SearchPages

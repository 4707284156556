import { useEffect, useState } from 'react'

import { Configure, InstantSearch, Pagination, SearchBox } from 'react-instantsearch'
import { useParams } from 'react-router-dom'

import Loading from '../Loading'
import MenuSelect from '../entries/MenuSelect'
import { meilisearchClient } from '../entries/utils'
import CustomHits from './customHits'

const App = ({ searchFilters }) => {
  let [sort, setSort] = useState('created_at:desc')
  const { dealershipSlug } = useParams()

  const searchClient = meilisearchClient(dealershipSlug)

  return (
    <div className="p-3">
      <InstantSearch
        indexName={`Valuation_${process.env.RAILS_ENV}:${sort}`}
        searchClient={searchClient}
        routing={true}
      >
        <Configure filters={searchFilters} hitsPerPage={40} />
        <div className="box p-3 mb-3">
          <div className="row">
            <div className="col-md-3 mb-2">
              <SearchBox className="mb-0" placeholder="Search Valuations" />
            </div>
            <div className="col-md-3 mb-2">
              <MenuSelect attribute="location_name" limit={30} seeAllOption="All Locations" />
            </div>
            <div className="col-md-3 mb-2">
              <MenuSelect attribute="make" limit={30} seeAllOption="All Makes" />
            </div>
            <div className="col-md-3 mb-2">
              <MenuSelect attribute="lead.lead_state" limit={30} seeAllOption="All Lead Status" />
            </div>
            <div className="col-md-3 mb-2">
              <MenuSelect attribute="provider" limit={30} seeAllOption="All Providers" />
            </div>
          </div>
        </div>
        <CustomHits setSort={setSort} sort={sort} />
        <div className="py-3">
          <Pagination />
        </div>
      </InstantSearch>
    </div>
  )
}

const Wrapper = () => {
  const { dealershipSlug } = useParams()
  let [searchFilters, setSearchFilters] = useState({})
  let [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch(`/dealerships/${dealershipSlug}/valuations.json`)
      .then((res) => res.json())
      .then((data) => {
        setSearchFilters(data.search_filters)
        setLoading(false)
      })
  }, [])

  if (loading) {
    return (
      <div className="p-2">
        <Loading />
      </div>
    )
  }

  return <App searchFilters={searchFilters} />
}

export default Wrapper

import { useEffect, useState } from 'react'

import { Content, Provider, Root, Trigger } from '@radix-ui/react-tooltip'
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox'

import { useConfig } from '../../../contexts/hooks'
import { TimeRangeOverrideGroup } from '../../../types'
import { TooltipContentStyles } from '../../AnalyticsModules/components/ModuleWrapperComponent'

const OverrideDefaultTimeRange = () => {
  const { config, handleUpdateConfig } = useConfig()

  const [selectedGroups, setSelectedGroups] = useState<TimeRangeOverrideGroup[]>(
    (config?.defaultTimeRangeOverride as TimeRangeOverrideGroup[]) || []
  )

  const onGroupsChange = (e: CheckboxChangeEvent) => {
    let newSelectedGroups = [...selectedGroups]

    // If the user selects 'Select All', we want to select/deselect all groups
    if (e.value === 'Select All') {
      if (e.checked) {
        newSelectedGroups = groupsForTimeRangeOverride
      } else {
        newSelectedGroups = []
      }
    }

    if (e.checked) {
      newSelectedGroups.push(e.value)
    } else {
      newSelectedGroups.splice(newSelectedGroups.indexOf(e.value), 1)
    }

    setSelectedGroups(newSelectedGroups)
    handleUpdateConfig({
      defaultTimeRangeOverride: newSelectedGroups.filter((group) => group !== 'Select All'),
    })
  }

  const groupsForTimeRangeOverride: TimeRangeOverrideGroup[] = [
    'Select All',
    'Facebook Ads',
    'Google Ads',
    'Google Analytics',
    'Google Adwords Ads',
    'Google Display Ads',
    'Google VLA Ads',
  ]

  useEffect(() => {
    setSelectedGroups((config?.defaultTimeRangeOverride as TimeRangeOverrideGroup[]) || [])
  }, [config])

  return (
    <>
      <h5 className="mr-3 mb-0 col-12">
        Override default time range behaviour:
        <Provider delayDuration={50}>
          <Root>
            <Trigger asChild>
              <i className="fas fa-info-circle ml-2" style={{ cursor: 'pointer' }}></i>
            </Trigger>
            <Content style={TooltipContentStyles} sideOffset={5}>
              This setting allows you to override the default time range behaviour for each group of
              modules. The graphs on the modules will be ALWAYS set to yearly when this is enabled.
            </Content>
          </Root>
        </Provider>
      </h5>
      <div className="col-12">
        {groupsForTimeRangeOverride.map((group) => {
          return (
            <div key={group} className={`my-2 ${group === 'Select All' ? 'mb-4' : ''}`}>
              <Checkbox
                inputId={group}
                name="Group"
                value={group}
                onChange={onGroupsChange}
                checked={selectedGroups.includes(group)}
              />
              <label htmlFor={group} className="mb-0 ml-2">
                {group}{' '}
              </label>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default OverrideDefaultTimeRange
